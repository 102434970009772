import { Component, Input, ViewChild } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { retry } from 'rxjs';

@Component({
  selector: 'app-widget-dashboard-bar',
  templateUrl: './widget-dashboard-bar.component.html',
  styleUrls: ['./widget-dashboard-bar.component.scss'],
})
export class WidgetDashboardBarComponent {
  private _titolo: string = '';
  private _colore: string = '';
  private _maxValue: number | undefined;
  private _value: number | undefined;
  public coloreRGB: string = '';

  @Input()
  /* TITOLO */
  set titolo(titolo: string) {
    this._titolo = titolo;
  }
  get titolo() {
    return this._titolo;
  }

  @Input()
  /* COLORE */
  set colore(colore: string) {
    this._colore = colore;
    this.coloreRGB = this.hexToRgb(this._colore);
  }
  get colore() {
    return this._colore;
  }

  @Input()
  /* MAX-VALUE */
  set maxValue(maxValue: number) {
    this._maxValue = maxValue;
  }
  get maxValue(): number | undefined {
    return this._maxValue;
  }

  @Input()
  /* VALUE */
  set value(value: number) {
    this._value = value;
  }
  get value(): number | undefined {
    return this._value;
  }

  hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? parseInt(result[1], 16) +
          ',' +
          parseInt(result[2], 16) +
          ',' +
          parseInt(result[3], 16)
      : "255, 255, 255";
  }
}

import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appFormElement]'
})
export class FormElementDirective {

  private _larghezza = 1;
  private _larghezzaParent = 1;
  private _gapPxParent = 0;
  private _riempi = false;
  private _minWidthPx: number | 'auto' = 200;

  /**
   * Larghezza dell'elemento, rispetto alla larghezza del contenitore.
   * Es: Nella riga di un contenitore largho 10, entrano 10 elementi con larghezza 1 (10/1), o 5 elementi con larghezza 2 (10/2), eccetera.
   */
  @Input()
  set larghezza(valore: number) {
    if (isNaN(valore)) {
      console.warn('Direttiva FormContainer - larghezza non numerica : ', valore);
      valore = 1;
    }
    this._larghezza = valore;
    this.updateLarghezza();
  }

  /**
   * Se true, riempe lo spazio a disposizione.
   */
  @Input()
  set riempi(valore: boolean) {
    this._riempi = !!valore;
    this.updateLarghezza();
  }

  /**
   * Larghezza minima (in pixel) dell'elemento.
   */
  @Input()
  set minWidth(valore: number | 'auto') {
    if (valore !== 'auto' && isNaN(valore)) {
      console.warn('Direttiva FormContainer - min width non numerica : ', valore);
      valore = 200;
    }
    this._minWidthPx = valore;
    this.updateLarghezza();
  }

  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.classList.add('fill-sm-screen');
  }

  setLarghezzaParent(valore: number, gapPx: number) {
    this._larghezzaParent = valore;
    this._gapPxParent = gapPx;
    this.updateLarghezza();
  }

  updateLarghezza() {
    this.el.nativeElement.style.minWidth = this._minWidthPx === 'auto' ? this._minWidthPx : this._minWidthPx + 'px';
    this.el.nativeElement.style.flex = (this._riempi ? '1' : '0') + ' 1 calc(' + (100 / this._larghezzaParent * this._larghezza) + '% - ' + this._gapPxParent + 'px)';
  }

}

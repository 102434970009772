<h2 mat-dialog-title>Unita Di Misura</h2>
<app-spinner-overlay></app-spinner-overlay>
<div mat-dialog-content class="dialog-center-cont" style="padding-right: 10px !important;" #scrollContainer>
    <form [formGroup]="formUnitaDiMisura" appFormContainer [larghezza]="1">

        <div style="display: flex; flex: 1; column-gap: 10px;">
            <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
                <mat-label>Nome Breve</mat-label>
                <input matInput placeholder="Nome Breve" formControlName="nomeBreve" />
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
                <mat-label>Descrizione</mat-label>
                <input matInput placeholder="Descrizione" formControlName="descrizione">
                <mat-error appFormError></mat-error>
            </mat-form-field>
        </div>

        <div class="container-categorie">


            <div class="separatore" appFormElement [larghezza]="1">
                <app-separatore-label titoloSeparatore="Categorie" [styleSeparatore]="'small'"></app-separatore-label>
            </div>
            <div class="container-scroll-categorie">
                <ng-scrollbar class="ng-scroll-radio">
                    <mat-radio-group color="primary" appFormElement [larghezza]="1" [riempi]="true"
                        formControlName="idCategoriaUnitaDiMisura">

                        <div *ngFor="let cat of arrayCategorieUm" style="display: flex; align-content: space-between;">
                            <mat-radio-button [value]="cat.id" [id]="cat.id ? cat.id : ''">
                                {{cat.nome}}
                            </mat-radio-button>
                            <button mat-icon-button aria-label="Modifica Categoria " style="color: var(--grigio);"
                                (click)="editCategoria(cat); scrollToBottom()">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </mat-radio-group>
                </ng-scrollbar>
            </div>
            <div class="nome-categoria" [ngClass]="{'show': mostraCampoCategoria}">
                <mat-form-field *ngIf="mostraCampoCategoria" appFormElement [larghezza]="1" [riempi]="true">
                    <mat-label>Nome Categoria</mat-label>
                    <input matInput placeholder="Nome Categoria" [formControl]="controlDesCatUnitaMisura" />
                    <mat-error appFormError></mat-error>
                </mat-form-field>
            </div>

            <div appFormElement [larghezza]="1" [riempi]="true" class="btn-salvataggio-cat">
                <button *ngIf="!mostraCampoCategoria" mat-stroked-button (click)="visualizzaBottone()"
                    style="height: 56px;width: 100%;font-weight: bold; background-color: #F5F5F5;">
                    Aggiungi Categoria
                </button>
                <ng-container *ngIf="mostraCampoCategoria">
                    <button mat-stroked-button (click)="addCategoria()" id="aggiungiCategoriaBottone"
                        [disabled]="controlDesCatUnitaMisura.invalid"
                        style="height: 56px;width: 100%;font-weight: bold; background-color: #F5F5F5;">
                        Conferma
                    </button>
                    <button mat-stroked-button (click)="chiudiInsCategoria()"
                        style="height: 56px;width: 100%;font-weight: bold; background-color: #F5F5F5;">
                        Annulla
                    </button>
                </ng-container>

            </div>

        </div>
    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button"
            [disabled]="mostraCampoCategoria">
            Salva
        </button>
    </div>
</div>
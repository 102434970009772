import { Injectable } from '@angular/core';
import { LicenzeWebService } from './licenze-web.service';

export interface PromotoreLicenze {
  codice: string,
  descrizione: string,
  id: string,
}

@Injectable({
  providedIn: 'root'
})


export class LicenzeService {
  private codiceLingua = 'it-IT'
  constructor(
    public licenzeWebService: LicenzeWebService
  ) { }


  public getLicenzeByUtente(idUtente: string, codiceLicenza: string) {
    return this.licenzeWebService.getLicenzeByUtente(idUtente, codiceLicenza);
  }

  public putLicenzeByUtente(idUtente: string, codiceLicenza: string, licenza: any) {
    return this.licenzeWebService.putLicenzeByUtente(idUtente, codiceLicenza, licenza);
  }

  public postAbilitaLicenza(idUtente: string, licenza: any) {
    return this.licenzeWebService.postAbilitaLicenza(idUtente, licenza);
  }
  public postLicenzeList() {
    return this.licenzeWebService.postLicenzeList();
  }

  public postLicenzeCreate() {
    return this.licenzeWebService.postLicenzeCreate();
  }

  public getPromotoriList() {
    return this.licenzeWebService.getPromotoriList();
  }
  public postPromotori() {
    return this.licenzeWebService.postPromotori();
  }

  public getUserHasLicense(nomeModulo: string) {
    return this.licenzeWebService.getUserHasLicense(nomeModulo);
  }

}
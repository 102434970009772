<app-spinner-overlay></app-spinner-overlay>

<div class="container-titolo">
    <div>
        <div class="titolo">Policy di Sostenibilità
        </div>
    </div>
    <div class="separatore">
    </div>
</div>

<div style="margin-bottom: 15px;"></div>
<ng-container *ngIf="this.data.perimetroDestinatario">
    <div class="contenitore">
        <div class="descrizione-comitato">{{this.data.perimetroDestinatario.descrizione}}</div>
        <div class="info">Spiega il ruolo del {{this.data.perimetroDestinatario.chiaveElemento}} rispetto alla
            sostenibilità e al documento “policy di
            sostenibilità”</div>


        <form [formGroup]="formPerimDestComp" appFormContainer [larghezza]="3">
            <mat-form-field class="descrizione-destinatario" appFormElement [larghezza]="3" [riempi]="true">
                <mat-label>Descrizione Destinatario</mat-label>
                <input formControlName="descrizione" matInput [placeholder]="'Descrizione Destinatario'">
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <div class="linea-guida" *ngIf="this.data.perimetroDestinatario.chiaveElemento === 'perimetro'">
                {{this.data.perimetroDestinatario.lineaGuidaPolicy}}</div>
            <div class="linea-guida" *ngIf="this.data.perimetroDestinatario.chiaveElemento === 'destinatario'">
                {{this.data.perimetroDestinatario.lineaGuidaDestinatario}}</div>

            <mat-form-field class="descrizione-destinatario" appFormElement [larghezza]="3" [riempi]="true">
                <mat-label>Descrizione</mat-label>
                <textarea formControlName="lineaGuidaCompilata" matInput [placeholder]="'Descrizione'"
                    rows="4"></textarea>
                <mat-error appFormError></mat-error>
            </mat-form-field>

        </form>

        <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
    </div>

    <div mat-dialog-actions class="dialog-footer-cont">
        <div>
            <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
        </div>
        <div>
            <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
                Conferma
            </button>
        </div>
    </div>

</ng-container>
import { Component, Input } from '@angular/core';
import { SelezioneElementiQuestionarioComponent } from '../../selezione-elementi-questionario.component';
import { DialogCreaCfgAzioneEBuonaPraticaComponent } from 'src/app/page/configurazioni/cfg-azione-e-buona-pratica/dialog/dialog-crea-cfg-azione-e-buona-pratica/dialog-crea-cfg-azione-e-buona-pratica.component';
import { catchError, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { DialogCreaKPIComponent } from 'src/app/page/configurazioni/cfg-kpi/dialog/dialog-crea-kpi/dialog-crea-kpi.component';

@Component({
  selector: 'app-selezione-obiettivi-target-piano',
  templateUrl: '../../selezione-elementi-questionario.component.html',
  styleUrls: ['../../selezione-elementi-questionario.component.scss']
})
export class SelezioneObiettiviTargetPianoComponent extends SelezioneElementiQuestionarioComponent {
  public _idObiettivo: string = '';

  @Input() oggettoElementiSelezionatiTarget!: () => {};
  @Input() getTargetQualitativiQuantitativi!: () => any;

  @Input()
  set idObiettivo(idObiettivo: string) {
    this._idObiettivo = idObiettivo;
  }
  get idObiettivo() {
    return this._idObiettivo;
  }

  override editElementoListaChk(idAmbito: string, idTematica: string, target?: any): void {
    if (this.chiaveElementoSelezione === 'targetQualitativi') {
      const dialogCreaAzioneBP = this.dialog.open(DialogCreaCfgAzioneEBuonaPraticaComponent, {
        data: {
          azioneBP: target,
          idContest: this.idContest,
          idAmbito: idAmbito,
          idTematica: idTematica,
          contesto: 'piano',
          idObiettivo: this.idObiettivo
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        maxHeight: '95%',
        autoFocus: false,
      });

      dialogCreaAzioneBP.afterClosed().subscribe((result) => {

        if (result) {
          this.spinnerOver.show();
          this.salva(true, idAmbito, idTematica).subscribe(() => {
            this.getTargetQualitativiQuantitativi()
          });
        }
      });
    } else if (this.chiaveElementoSelezione === 'targetQuantitativi') {
      const dialogCreaKPI = this.dialog.open(DialogCreaKPIComponent, {
        data: {
          kpi: target,
          idContest: this.idContest,
          idAmbito: idAmbito,
          idTematica: idTematica,
          contesto: 'piano',
          idObiettivo: this.idObiettivo
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        maxHeight: '95%',
        autoFocus: false,
      });

      dialogCreaKPI.afterClosed().subscribe((result) => {

        if (result) {
          this.spinnerOver.show();
          this.salva(true, idAmbito, idTematica).subscribe(() => {
            this.getTargetQualitativiQuantitativi()
          });
        }
      });
    }
  }
  override addElementoListaChk(idAmbito: string, idTematica: string): void {
    // posso passare la stessa funzione poichè la gestione è identica, Cambia solameanete il passaggio del azioneBP 
    // a vuoto nella creazione.
    this.editElementoListaChk(idAmbito, idTematica);
  }

  override salvaFigAz(idAmbito: string, idTematica: string, idElemChk: string, idFigAziendale: string): void {
    let obsFigura: any;
    if (this.chiaveElementoSelezione === 'targetQualitativi') {
      obsFigura = this.pianoService.putAssegnaFiguraTargetQualitativiPiano(this.idContest, idAmbito, idTematica, idElemChk, this.idObiettivo, idFigAziendale)
    } else if (this.chiaveElementoSelezione === 'targetQuantitativi') {
      obsFigura = this.pianoService.putAssegnaFiguraTargetQuantitativiPiano(this.idContest, idAmbito, idTematica, idElemChk, this.idObiettivo, idFigAziendale)
    }

    this.spinnerOver.show();

    this.salva(true, idAmbito, idTematica).pipe(
      switchMap(() => obsFigura)
    ).subscribe({
      next: () => {
        this.getTargetQualitativiQuantitativi();
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    });
  }

  salva(noCtr?: boolean, idAmbito?: string, idTematica?: string) {
    this.spinnerOver.show();
    let targetQuantQual = this.oggettoElementiSelezionatiTarget();

    console.log(targetQuantQual)

    return this._salvaSurveyElemSel(targetQuantQual, idAmbito, idTematica);
  }

  private _salvaSurveyElemSel(targetQuantQual: any, idAmbito?: string, idTematica?: string): Observable<boolean> {

    if (this.idContest) {
      return this.pianoService.putTargetSelezionati(
        this.idContest, idAmbito!, idTematica!, this.idObiettivo, targetQuantQual).pipe(
          map((result: any) => {
            this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.salvato.emit();
            return true;

          }),
          catchError((err: any) => {
            console.error('Errore :', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore salvataggio (sconosciuto)',
              bottoni: [{ nome_btn: 'Ok' }]
            });
            return of(false);
          }),
          tap({
            finalize: () => this.spinnerOver.hide()
          })
        );
    } else {
      this.spinnerOver.hide();
      return of(false);
    }
  }
}

import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, catchError, map, of, switchMap } from 'rxjs';
import { KPI, KPIService, Stdr } from 'src/app/services/KPI/kpi.service';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { CategorieUm, UnitaDiMisura, UnitaDiMisuraService } from 'src/app/services/unita-di-misura/unita-di-misura.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogTarghetStdrComponent } from '../dialog-targhet-stdr/dialog-targhet-stdr.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { PianoService } from 'src/app/services/piano/piano.service';

@Component({
  selector: 'app-dialog-crea-kpi',
  templateUrl: './dialog-crea-kpi.component.html',
  styleUrls: ['./dialog-crea-kpi.component.scss']
})
export class DialogCreaKPIComponent implements AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public selectedSettori: string[] = [];
  public selectedCategorie: string[] = [];

  public selectedStdr: Stdr[] = [];

  public selectedUnitaDiMisura: string[] = [];

  public arraySettori: Settore[] = [];
  public arrayCategorie: CategorieUm[] = [];
  public filteredCategorie: Observable<CategorieUm[]> | undefined;

  private subFiltroCat = new Subject<string>();
  public arrayUnitaDiMisura: UnitaDiMisura[] = [];

  /* Form Controls */
  public formKPI = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    nomeBreve: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    settori: new FormControl<Settore[]>([], {
      nonNullable: true,
    }),
    stdr: new FormControl<Stdr[]>([], {
      nonNullable: true,
    }),
    idCategoriaUnitaDiMisura: new FormControl<string | CategorieUm>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    idUnitaDiRiferimento: new FormControl<string | UnitaDiMisura>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    note: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    /*    evidenziaNote: new FormControl<boolean>(false, {
         nonNullable: true,
       }), */
    tag: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    compilato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    figura: new FormControl<any>(undefined, {
      nonNullable: true,
      validators: [],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaKPIComponent>,
    private utilityService: UtilityService,
    private settoriService: SettoriService,
    private kpiService: KPIService,
    private tematicheService: TematicheService,
    private unitaDiMisura: UnitaDiMisuraService,
    private sintesiService: SintesiService,
    private bilancioService: BilancioService,
    private pianoService: PianoService,
    public dialog: MatDialog,
    public utenteService: UtenteService,
    @Inject(MAT_DIALOG_DATA) public data: {
      kpi: KPI,
      idContest: string,
      idAmbito: string,
      idTematica: string,
      contesto: string,
      idObiettivo: string
    }
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.datiIniziali();
    });
  }

  compareSettori(settore1: Settore, settore2: Settore): boolean {
    return settore1.id === settore2.id; // Sostituisci "id" con la proprietà univoca dei settori
  }
  compareCategorie(categoria1: CategorieUm, categoria2: CategorieUm): boolean {
    return categoria1.id === categoria2.id; // Sostituisci "id" con la proprietà univoca delle categorie
  }
  compareUnitaDiMisura(unitaDiMiusra1: UnitaDiMisura, unitaDiMiusra2: UnitaDiMisura): boolean {
    return unitaDiMiusra1.id === unitaDiMiusra2.id; // Sostituisci "id" con la proprietà unitaDiMisura
  }

  /**
   * salvataggio dell'oggetto KPI controllando se la form è valida
   * @returns 
   */
  public salva() {
    this._salvaKpi(true).subscribe();
  }

  private _salvaKpi(chiudiDialog: boolean): Observable<boolean> {

    if (this.formKPI.valid) {

      if (!this.formKPI.get('id')?.value && this.data.idContest) {
        this.formKPI.get('creatoDaContesto')?.setValue(true);
      }

      let formKpis = this.formKPI.getRawValue();
      const nuovoKpi: KPI = {
        ...formKpis,
        id: formKpis.id || '',
        idCategoriaUnitaDiMisura: (formKpis.idCategoriaUnitaDiMisura as CategorieUm | undefined)?.id || '',
        idUnitaDiRiferimento: (formKpis.idUnitaDiRiferimento as UnitaDiMisura | undefined)?.id || '',

      };

      // Se sono in sintesi
      if (this.data.idContest) {
        return this.salvaInContesto(nuovoKpi);
      }


      if (formKpis.id) {
        this.spinnerOver.show();
        return this.kpiService.putKPI(nuovoKpi).pipe(
          switchMap((result) => {
            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            if (chiudiDialog) {
              this.dialogRef.close();
            }
            this.spinnerOver.hide();
            return of(true);
          }),
          catchError(error => {
            console.error('Errore durante il salvataggio del kpi:', error);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel salvataggio del kpi',
              bottoni: [{ nome_btn: 'Chiudi' }]
            })
            this.spinnerOver.hide();
            return of(false);
          })
        )
      } else {
        this.spinnerOver.show();
        return this.kpiService.postKPI(nuovoKpi).pipe(
          switchMap((result) => {
            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.formKPI.get('id')?.setValue(result.body);

            if (chiudiDialog) {
              this.dialogRef.close();
            }
            this.spinnerOver.hide();
            return of(true);
          }),
          catchError(error => {
            console.error('Errore durante il salvataggio dei kpi:', error);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel salvataggio dei kpi',
              bottoni: [{ nome_btn: 'Chiudi' }]
            })
            this.spinnerOver.hide();
            return of(false);
          })
        )
      }
    } else {
      Object.values(this.formKPI.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      return of(false);
    }
  }

  /**
   * Metodo che prende gli stdr
   */
  private _getSettori() {
    this.settoriService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'desc'
    }]).subscribe({
      next: (result: any) => {


        this.arraySettori = result?.content || [];
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento dei settori:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei settori',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  seleziona(event: any) {

    const valore = event?.option?.value;
    if (valore) {

      this.formKPI.get('idCategoriaUnitaDiMisura')?.setValue(valore);
      // this.formKPI.get('idCategoriaUnitaDiMisura')?.setValidators([Validators.required]);
    }
  }

  opeDialogTarget() {
    this.spinnerOver.show();
    this._salvaKpi(false).subscribe({
      next: (risp) => {
        if (risp) {

          const dialog = this.dialog.open(DialogTarghetStdrComponent, {
            data: {
              idKpi: this.formKPI.get('id')?.value,
              stdr: this.data?.kpi?.stdr || []
            },
            panelClass: 'dialog-container',
            disableClose: false,
            width: '95%',
            maxHeight: '95%',
            autoFocus: false,
          });
          dialog.afterClosed().subscribe((risp) => {

            if (risp !== "") {
              this.selectedStdr = risp || [];
              this.data.kpi.stdr = this.selectedStdr;
              this.formKPI.get('stdr')?.setValue(this.selectedStdr);

            }
          });

        } else {

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Compila tutti i campi obbligatori',
            bottoni: [{ nome_btn: 'Ok' }]
          });

          console.error(risp);
        }
        this.spinnerOver.hide();
      },
      error: (err: any) => {
        this.spinnerOver.hide();
        console.error('Errore durante il salvataggio del kpi:', err);
      }
    });
  }

  /**
   * Metodo che prende le categorie
   */
  private _getCategorie() {
    return this.unitaDiMisura.getCategoria().pipe(map((resultCategoria: CategorieUm[]) => {

      this.arrayCategorie = resultCategoria || [];

      return true;
    }),
      catchError((err: any) => {
        console.error('Errore durante il caricamento delle Categorie:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle Categorie',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return of(false);
      })
    )
  }

  displayFn(categoria: CategorieUm): string {
    return categoria && categoria.nome ? categoria.nome : '';
  }

  getCategorie = (id: any, txtRicerca: string) => {

    let records: CategorieUm[] = [];

    if (id) {
      records = this.arrayCategorie.filter(option => option.id?.toLowerCase() === id);
    } else if (txtRicerca) {
      records = this._filter(txtRicerca as string);
    } else {
      records = this.arrayCategorie.slice();
    }

    return of(records);
  }

  private _filter(categoria: string): CategorieUm[] {
    const filterValue = categoria.toLowerCase();
    return this.arrayCategorie.filter(option => option.nome?.toLowerCase().includes(filterValue));
  }

  /**
   * Metodo che prende le unità di misura 
   */
  public getUnitaDiMisura(record: CategorieUm) {

    if (this.formKPI.get('idUnitaDiRiferimento')?.status === "DISABLED") {
      return;
    }

    this.formKPI.controls.idUnitaDiRiferimento.enable();
    this.arrayUnitaDiMisura = record.unitaDiMisura || [];

    const umVal = this.formKPI.get('idUnitaDiRiferimento')?.value;
    if (umVal && typeof umVal === 'object') {
      const trovato = this.arrayUnitaDiMisura.find((um) => um.id === umVal.id);
      if (!trovato) {
        this.formKPI.get('idUnitaDiRiferimento')?.setValue('');
      }
    }

  }

  /**
   *  Dati iniziali 
   */
  public datiIniziali() {
    this.spinnerOver.show();

    this._getSettori();
    this._getCategorie().subscribe(result => {
      if (result) {

        if (this.data?.kpi) {

          Object.keys(this.data.kpi).forEach((value) => {
            if (value === "idUnitaDiRiferimento") {
              return; // si salta l'unita di riferimento perchè viene valorizzata dentro idCategoriaUnitaDiMisura
            }
            if (value === "idCategoriaUnitaDiMisura") {
              const selectedCategoriaId = this.data.kpi.idCategoriaUnitaDiMisura;
              const selectedUnitaDiMisuraId = this.data.kpi.idUnitaDiRiferimento;
              const selectedCategoria = this.arrayCategorie.find(categoria => categoria.id === selectedCategoriaId);

              if (selectedCategoria && selectedCategoria.nome) {
                this.formKPI.get('idCategoriaUnitaDiMisura')?.setValue(selectedCategoria);

                this.formKPI.controls.idUnitaDiRiferimento.enable()
                this.arrayUnitaDiMisura = selectedCategoria.unitaDiMisura || [];

                const selectedUnitaMisura = this.arrayUnitaDiMisura.find(unitaMisura => unitaMisura.id === selectedUnitaDiMisuraId);
                if (selectedUnitaMisura) {
                  this.formKPI.get('idUnitaDiRiferimento')?.setValue(selectedUnitaMisura);
                }
              }
            } else {
              this.formKPI.get(value)?.setValue((this.data.kpi as any)[value]);
            }
          })
          this.selectedStdr = this.data.kpi.stdr || [];
          this.permessi();
        }

        if (this.data.contesto === 'sintesi' || this.data.contesto === 'bilancio') {
          /* this.formKPI.get('nomeBreve')?.clearValidators();
          this.formKPI.get('nomeBreve')?.updateValueAndValidity(); */

          this.formKPI.get('descrizione')?.addValidators([Validators.required]);
          this.formKPI.get('idUnitaDiRiferimento')?.addValidators([Validators.required]);
          this.formKPI.get('idCategoriaUnitaDiMisura')?.addValidators([Validators.required]);


        }
      }

      this.spinnerOver.hide();
    });
  }
  /**
     * Metodo che mi salva un nuovo impatto sulla materialità
     * @returns 
     */
  public salvaInContesto(nuovoKpi: any) {
    if (!this.data.idAmbito) {
      this.mostraMessaggio('Attenzione', 'Ambito non trovato o non riconosciuto');
      return of(false);
    } else if (!this.data.idTematica) {
      this.mostraMessaggio('Attenzione', 'Tematica non trovata o non riconosciuta');
      return of(false);
    }

    if (!this.formKPI.get('id')?.value) {
      this.formKPI.get('creatoDaContesto')?.setValue(true);
    }

    let serviceCall;

    if (this.data.contesto == 'sintesi') {
      serviceCall = this.sintesiService.putKpiEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        nuovoKpi)
    } else if (this.data.contesto == 'bilancio') {
      serviceCall = this.bilancioService.putKpiEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        nuovoKpi);
    } else if (this.data.contesto == 'piano') {
      serviceCall = this.pianoService.putTargetQuantitativiEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.data.idObiettivo,
        nuovoKpi);
    }

    if (serviceCall) {
      this.spinnerOver.show();
      return serviceCall.pipe(
        switchMap((result: any) => {
          this.mostraMessaggioSnackbar('Salvataggio effettuato');
          this.dialogRef.close(result);
          this.spinnerOver.hide();
          return of(true);
        }), catchError((error: any) => {
          console.error('Errore durante il salvataggio dell\'impatto:', error);
          this.mostraMessaggio('Attenzione', 'Errore nel salvataggio dell\'impatto');
          this.spinnerOver.hide();
          return of(false);
        })
      )
    } else {
      return of(false);
    }
  }

  private mostraMessaggio(titolo: string, descrizione: string) {
    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      bottoni: [{ nome_btn: 'Chiudi' }]
    });
  }

  private mostraMessaggioSnackbar(messaggio: string) {
    this.utilityService.opneSnackBar(messaggio, '', {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }

  /**
   * Se sono professionista e il kpi è di libreria allora proteggo i campi.
   */
  permessi() {
    if (!this.utenteService.isPermessoAttivo('EDIT_UNITA_MISURA') && !this.data.kpi.creatoDaContesto) {
      this.formKPI.get('idUnitaDiRiferimento')?.disable();
      this.formKPI.get('idCategoriaUnitaDiMisura')?.disable();
    }

  }
}
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { InformativaAziendale, InformativaAziendaleService } from 'src/app/services/informativa-aziendale/informativa-aziendale.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaInformativaAziendaleComponent } from './dialog/dialog-crea-informativa-aziendale/dialog-crea-informativa-aziendale.component';
import { DialogAnteprimaCompilazioneInfoAzComponent } from './dialog/dialog-anteprima-compilazione-info-az/dialog-anteprima-compilazione-info-az.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-cfg-informativa-aziendale',
  templateUrl: './cfg-informativa-aziendale.component.html',
  styleUrls: ['./cfg-informativa-aziendale.component.scss']
})
export class CfgInformativaAziendaleComponent {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Titolo',
      forControlName: 'titolo',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
    {
      titolo: 'Versione',
      forControlName: 'versione',
      input: 'number',
    },

  ];

  public colonne: { [key: string]: Colonna } = {

    titolo: {
      title: 'Titolo',
      value: 'titolo',
      sortable: true
    },
    descrizione: {
      title: 'Descrizione',
      value: 'descrizione',
      sortable: true
    },
    versione: {
      title: 'Versione',
      value: 'versione',
      sortable: true
    },
    stato: {
      title: 'Stato',
      sortable: true,
      campiSort: ['stato'],
      value: (record: any) => {
        if (record?.stato === 'PUBBLICATO') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#CCDBCD;width:130px;text-align: center;border-radius: 15px;height:27px"><p style="color:#729373;font-size:13px">Pubblicata</p></div>'
        } else if (record?.stato === 'BOZZA') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#FFE199;width:130px;text-align: center;border-radius: 15px;height:27px"><p style="color:#C7A045;font-size:13px">Bozza</p></div>'
        } else if (record?.stato === 'ARCHIVIATO') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c53032;width:130px;text-align: center;border-radius: 15px;height:27px"><p style="color:#fff;font-size:13px">Archiviato</p></div>'
        } else {
          return '';
        }
      }
    },
    anteprima: {
      type: 'button',
      buttonIcon: 'visibility',
      title: 'Anteprima',
      buttonId: 'anteprima',
      buttonMostraSempre: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record) => (record?.stato !== 'BOZZA' ? true : false)
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      buttonMostraSempre: true,
      nascondiButton: (record) => (record?.stato !== 'BOZZA' ? true : false)
    },
  };
  constructor(
    public dialog: MatDialog,
    private informativaAziendale: InformativaAziendaleService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {

  }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  async creaNuovo(record?: InformativaAziendale) {
    this.spinnerOver.show();

    if (!record) {
      const risp = await firstValueFrom(this.informativaAziendale.getBozza());
      record = risp;
    }
    this.spinnerOver.hide();

    const dialogCreaImpegno = this.dialog.open(DialogCreaInformativaAziendaleComponent, {
      data: {
        informativaAziendale: record,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaImpegno.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [{
        chiave: 'versione',
        sort: 'desc'
      }];
    }

    return this.informativaAziendale.get(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovo(event.data);
        break;
      case 'elimina':
        this._elimina(event.data.idInformativa);
        break;
      case 'anteprima':
        this._anteprima(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare 
   * @param idRiga id della riga da eliminare 
   */
  private _elimina(idRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare l\'Informativa Aziendale?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.informativaAziendale.delete(idRiga).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: 'Errore eliminazione' + err,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }
  /**
   * Metodo per la duplicazione di un Impegno
   */
  public _anteprima(infoAz: InformativaAziendale) {

    const dialogCreaTemplateMail = this.dialog.open(DialogAnteprimaCompilazioneInfoAzComponent, {
      data: {
        infoAz,
        inConf: true
      },
      panelClass: 'dialog-container',
      disableClose: true,
      width: '100%',
      height: '95%',
      autoFocus: false,
    });

    dialogCreaTemplateMail.afterClosed().subscribe((statoCompilatoInChiusura) => {


    });
  }
}

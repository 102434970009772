import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { InformativaAziendale, InformativaAziendaleService } from 'src/app/services/informativa-aziendale/informativa-aziendale.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';

@Component({
  selector: 'app-informativa-aziendale-esterna',
  templateUrl: './informativa-aziendale-esterna.component.html',
  styleUrls: ['./informativa-aziendale-esterna.component.scss']
})
export class InformativaAziendaleEsternaComponent implements OnInit, AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public logoPath: string = 'assets/images/logo/logo-sostenibile.png';
  public completato: boolean = false;
  public temporaryToken!: string;
  public descrizione: string = "";
  public messaggio: string = '';

  public infoAz!: InformativaAziendale;
  public infoAzVuota: boolean = false;
  public loading = true;

  constructor(
    private route: ActivatedRoute,
    private informativaAziendale: InformativaAziendaleService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.parametriDaURL();
  }

  /**
 * Metodo che mi prende il token dal URL
 */
  public parametriDaURL() {
    // Sottoscrivi all'observable paramMap per ottenere i parametri dall'URL
    this.spinnerOver.show();
    this.route.queryParams.subscribe(params => {
      // Ora puoi accedere ai parametri come segue
      this.temporaryToken = params['temporaryToken'];

      if (this.temporaryToken) {
        this._getSurveyByTemporaryToken();
      } else {
        this.spinnerOver.hide();
      }
    });
  }

  /**
 * Metodo che mi prende i dati della survey dal token
 */
  private _getSurveyByTemporaryToken() {

    this.messaggio = '';

    this.informativaAziendale.getInformativaAziendaleEsterna(
      this.temporaryToken
    ).subscribe({
      next: (esito) => {

        this.infoAz = esito;

        this.infoAzVuota = false;

        this.spinnerOver.hide();

        this.loading = false;
      },
      error: (err) => {
        console.error(err);

        this.messaggio = err?.error?.message;

        if (!this.messaggio) {
          this.messaggio = "Nessun dato trovato"
        }
        this.infoAzVuota = true;
        this.loading = false;
        this.spinnerOver.hide();
      }
    })
  }


  finitaCompilazione(compilazione: any) {
    

    if (compilazione) {
      this.spinnerOver.show();

      this.informativaAziendale.putCompilaInformativaAziendaleEsterna(
        this.temporaryToken,
        compilazione
      ).subscribe({
        next: (risp) => {
          
          this.spinnerOver.hide();
          this.completato = true;
          // this.dialogRef.close(true);
        },
        error: (err) => {
          this.spinnerOver.hide();
          console.error(err);
        }
      })
    }
  }
}

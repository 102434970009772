import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public hide = true;
  public logoPath: string = 'assets/images/logo/logo-sostenibile.png';
  public bgPath: string = 'assets/images/background/bg_login.png';

  public loginError: string = '';

  public formLogin = new FormGroup({
    username: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    rememberMe: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: []
    })
  })

  constructor(
    private utenteService: UtenteService,
    private router: Router
  ) { }

  public ctrAuth() {
    //chiamo il servizio di controllo autenticazione
    this.spinnerOver.show();

    this.loginError = '';

    this.formLogin.disable();

    this.utenteService.login(this.formLogin.getRawValue()).subscribe({
      next: (value) => {
     

        this.router.navigate(['home']).then((navigato) => {
          this.spinnerOver.hide();
        });
      },
      error: (err) => {
        this.spinnerOver.hide();
        console.error('Errore : ', err);

        if(err === 'AGGIORNA_PASSWORD'){
          this.loginError = 'La password è scaduta o è stato richiesto un reset password. Una mail di reset è stata inviata all\'indirizzo inserito. Se non l\'hai ricevuta, controlla la cartella "Spam", altrimenti contatta l\'assistenza.';
        } else if(err === 'MAIL_NON_VERIFICATA'){
          this.loginError = 'Indirizzo email non verificato. Controlla la casella email corrispondente per trovare la mail di verifica. Se non l\'hai ricevuta, controlla la cartella "Spam", altrimenti contatta l\'assistenza.';
        } else {
          this.loginError = err;
        }

        this.formLogin.enable();
      }
    }
    );

  }

  public passwordDimenticata() {
    this.router.navigate(['password-dimenticata']);
  }

}

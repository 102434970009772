<div class="linechart-white-container">
  <div class="titolo-periodo">
    <div class="titolo">Cambiamenti climatici e qualità dell'aria</div>
    <div class="filtro-periodo">
      <mat-form-field class="provaprova">
        <mat-label>Periodo</mat-label>
        <mat-select>
          <mat-option
            *ngFor="let periodo of periodi"
            [value]="periodo.value"
            (click)="getMonths(periodo.value)"
          >
            {{ periodo.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="line-chart">
    <canvas
      baseChart
      class="chart"
      [data]="lineChartData"
      [options]="lineChartOptions"
      [type]="lineChartType"
      (chartHover)="chartHovered($event)"
      (chartClick)="chartClicked($event)"
    ></canvas>
  </div>
</div>

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { QuestionariService } from 'src/app/services/questionari/questionari.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { AnalisiRisultatiComponent } from 'src/app/components/analisi-risultati/analisi-risultati.component';
import { DialogAssegnazioniComponent } from '../sintesi-sostenibilita/dialog/dialog-assegnazioni/dialog-assegnazioni.component';
import { DialogCreaBilancioSostenibilitaComponent } from '../bilancio-sostebilita/dialog/dialog-crea-bilancio-sostenibilita/dialog-crea-bilancio-sostenibilita.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { InfoUtente } from 'src/app/services/utente/utente-web.service';
import { Chart, ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { of } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

interface DatiBarChart {
  titolo: string,
  utilizzati: number,
  nonUtilizzati: number
}

interface RiepilogoGenerale {
  nomeAzienda: string,
  datiRiepilogoPerSettore: RiepilogoPerSettore[],
}

interface RiepilogoPerSettore {
  nomeSettore: string,
  anno: number,
  statoAutovalutazione: string,
  statoPolicy: string,
  statoBilancio: string,
  statoSintesi: string,
  statoMaterialita: string
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',]
})
export class DashboardComponent implements AfterViewInit, OnInit {
  @ViewChild(BaseChartDirective) barChart: BaseChartDirective<'bar'> | undefined;
  @ViewChild('tabella') tabellaRiepilogo!: ListaTabellareComponent;

  public infoUtente: InfoUtente | undefined;

  public colonneRiepilogo: { [key: string]: Colonna } = {
    nomeAzienda: {
      title: 'Azienda',
      value: 'nomeAzienda',
      sortable: true,
    },
    anno: {
      title: 'Anno',
      value: 'anno',
      sortable: true,
    },
    nomeSettore: {
      title: 'Settore',
      value: 'nomeSettore',
      sortable: true,
    },
    statoAutovalutazione: {
      title: 'Assessment',
      value: (record: any) => {
        if (record?.statoAutovalutazione === 'rosso') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.statoAutovalutazione === 'giallo') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.statoAutovalutazione === 'verde') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else {
          return '';
        }
      },
      sortable: true
    },

    statoMaterialita: {
      title: 'Materialità',
      value: 'statoMaterialita',
      sortable: true
    },
    statoPolicy: {
      title: 'Policy',
      value: 'statoPolicy',
      sortable: true
    },
    piano: {
      title: 'Piano',
      value: 'piano',
      sortable: true
    },
    statoSintesi: {
      title: 'Sintesi',
      value: 'statoSintesi',
      sortable: true
    },
    statoBilancio: {
      title: 'Bilancio',
      value: 'statoBilancio',
      sortable: true
    },
  };

  public datiBarChart: DatiBarChart[] | undefined = undefined;
  public datiRiepilogoGenerale: RiepilogoGenerale[] | undefined;

  constructor(
    public dialog: MatDialog,
    private utenteService: UtenteService,
    private dashboardService: DashboardService,
  ) {
    this.infoUtente = this.utenteService.infoUtente;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    this.getInfoLicenze();

  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.dashboardService.getRiepilogoGenerale(page, pageSize, ricerca, filters, sortBy);
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {

    scales: {
      x: {
        ticks: {
          color: 'black',
          font: {
            weight: 'bold'
          }
        }
      },
      y: {
        min: 0,
        ticks: {
          color: 'black',
          font: {
            weight: 'bold'
          }
        }
      },

    },
    plugins: {
      legend: {
        display: true,
        align: 'end',
      },

    },
  };
  public barChartType = 'bar' as const;

  public barChartData: ChartData<'bar'> = {

    labels: [],
    datasets: [
      {
        data: [],
        label: 'Non Utilizzati',
        backgroundColor: '#A5DFC6',
        borderColor: '#77CF7A',
        borderWidth: 1

      },
      {
        data: [],
        label: 'Utilizzati',
        backgroundColor: '#FFB1C1',
        borderColor: '#FF6384',
        borderWidth: 1

      }
    ],
  };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    /* console.log(event, active); */
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    /* console.log(event, active); */
  }

  /**
   * Metodo che setta i dati iniziali del grafico per Disponibilità Pacchetti 
   */
  private _setDatiBarChart() {
    if (this.datiBarChart) {
      this.datiBarChart.forEach((data: DatiBarChart | null) => {
        if (data) {
          this.barChartData.labels?.push(data.titolo);
          (this.barChartData.datasets[1].data as number[]).push(data.utilizzati);
          (this.barChartData.datasets[0].data as number[]).push(data.nonUtilizzati);
        }
      });
      this.barChart?.update();
    }
  }

  private getInfoLicenze() {
    if (this.infoUtente?.utente.id) {
      this.dashboardService.getInfoLicenze(this.infoUtente?.utente.id).subscribe({
        next: (esito) => {
          this.datiBarChart = esito.datiBarCharts;
          this._setDatiBarChart();
        },
        error: (err) => {
          console.error(err);
        }
      })
    }
  }
}


<div class="contenitore">
  <div class="titolo-pagina">
    <app-titolo-subtitolo titolo="Ambiti" subTitolo="Gestione degli ambiti"></app-titolo-subtitolo>
  </div>
  <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
    [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">
    <div btnEnd>
      <button mat-flat-button class="primary-button" color="primary" (click)="creaNuovoAmbito()"
        *ngIf="this.utenteService.isPermessoAttivo('CREATE_CONFIGURAZIONI')">
        Nuovo
      </button>
    </div>
  </app-lista-tabellare>
</div>
import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Observable, of } from 'rxjs';
import { AziendaService } from '../azienda/azienda.service';
import { Ambito } from '../ambiti/ambiti.service';
import { Settore, SottoSettore } from '../settori/settori.service';
import { Impatto } from '../impatto/impatto.service';
import { Soglie } from './materialita.service';



export interface RisultatiSurvey {
  tipoPunteggio: string
  tematiche: TematicaExp[];
}

export interface TematicaExp {
  idTematica: string;
  titolo: string;
  medieRaggr: {
    esterni: number,
    interni: number,
  }
  raggruppata: boolean;
  impatti: ImpattoExp[];
}
export interface ImpattoExp {
  idImpatto: string;
  titolo: string;
  valori: {
    interni: InterniEsterni;
    esterni: InterniEsterni;
  };
}

export interface InterniEsterni {
  media: number;
  categorie: CategorieIntEst[];
}

export interface CategorieIntEst {
  id: string,
  titolo: string,
  media: number,
}

@Injectable({
  providedIn: 'root'
})
export class MaterialitaWebService {
  private lang = '/it-IT';
  private nomeWs = 'materialita';
  private _idAzienda: string | undefined = undefined;

  constructor(
    private aziendaService: AziendaService,
    private webService: WebService
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }

  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + "/lang" + this.lang + "/crea", data);
  }

  public delete(id: any): Observable<any> {
    return this.webService.doDelete('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + "/" + id + '/delete', {})
  }

  public put(id: string, materialita: any): Observable<any> {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + "/" + id + "/lang" + this.lang + '/update', materialita);
  }

  public putMateStake(id: string, tipologia: 'ESTERNI' | 'INTERNI', stakeholder: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/aggiorna-stakeholders', stakeholder, {
      id,
      tipologia
    });
  }

  /**
    * ritorna materialità corrispondete all'id
    * @param idMaterialita 
    * @returns 
    */
  public getMaterialitaById(id: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + id, {});
  }

  /**
    * ritorna materialità pubblicate
    * @returns 
    */
  public getMaterialitaPubblicate(idSettore: string, idSottoSettori: string[]): Observable<any> {

    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/settore/' + idSettore + '/list', {
      idSottoSettori
    });

  }


  public putTemplateMail(idMaterialita: string, templateMail: any): Observable<any> {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/aggiorna-template', templateMail, { idMaterialita });
  }

  public getTemplateMail(idMaterialita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/get-template', { idMaterialita });
  }

  public getAnteprimaMaterialitaCompilata(idMaterialita: string, idAzienda: string, idSurvey: string): Observable<any> {
    return this.webService.doGet('/api/v1/lang' + this.lang + '/azienda/' + idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/survey-compilate/survey/' + idSurvey + '/anteprima', {});
  }

  /************************************************** SURVEY ******************/
  public getSurvey(idMaterialita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/survey', {});
  }

  public postSurvey(idMaterialita: string, ambiti: Ambito[], settori: Settore[], sottoSettori: SottoSettore[], titolo: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/lang' + this.lang + '/survey', {
      ambiti,
      settori,
      sottoSettori,
      materialita: [idMaterialita],
      aziende: [this._idAzienda],
      titolo
    });
  }

  public putSurvey(idMaterialita: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], sottoSettori: SottoSettore[], titolo: string, tipoPunteggio: string, scadenzaSurvey: string | Date, stato: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/survey/aggiorna', {
      id: idSurvey,
      ambiti,
      settori,
      sottoSettori,
      materialita: [idMaterialita],
      aziende: [this._idAzienda],
      titolo,
      tipoPunteggio,
      scadenzaSurvey,
      stato
    });
  }

  public getTotaliCruscotto(idMaterialita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/cruscotto/totali', {});
  }

  public getCruscotti(
    idMaterialita: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/cruscotto/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Metodo che invia una mail singolarmente per stakeholder (pre prendere il token e fare la compilazione esterna della survey)
   * @param idMaterialita 
   * @param idStakeholder 
   */
  public postInvioMailSingolo(idMaterialita: string, idStakeholder: string): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/stakeholder/' + idStakeholder + '/invio-singolo', {});
  }

  /**
  * Metodo che invia mail a tutti gli stakeholder che la devono ricevere (pre prendere il token e fare la compilazione esterna della survey)
  * @param idMaterialita 
  */
  public postInvioMailMultiplo(idMaterialita: string): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/invio-multiplo', {});
  }


  /**
   * Metodo che invia una notifica singolarmente per stakeholder 
   * @param idMaterialita 
   * @param idStakeholder 
   */
  public putInviaNotificaSingolo(idMaterialita: string, idStakeholder: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/stakeholder/' + idStakeholder + '/notifica-singolo');
  }
  /**
   * Metodo che invia una notifica singolarmente per stakeholder 
   * @param idMaterialita 
   */
  public putInviaNotificaMultiplo(idMaterialita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/notifica-multiplo');
  }

  public putTipoPunteggio(idMaterialita: string, tipoPunteggio: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/tipo-punteggio', { value: tipoPunteggio });
  }
  public putScadenzaSurvey(idMaterialita: string, scadenzaSurvey: Date) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/scadenza-survey', { value: scadenzaSurvey });
  }


  /************************************************** IMPATTI ******************/

  public getImpattiCheck(idMaterialita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/check-impatti', {});
  }
  public putImpattiEffimeri(idMaterialita: string, idAmbito: string, idTematica: string, impattiSelezionati: Impatto) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impatti-effimeri', impattiSelezionati);
  }

  public putImpattiCheck(idMaterialita: string, impattiCheck: any[]) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/check-impatti', impattiCheck);
  }


  /************************************************** ANALISI DI MATERIALITA ******************/

  public getEsclusioni() {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/esclusioni', {});
  }
  public putEsclusioni(idMaterialita: string, esclusioni: string[]) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/esclusioni', esclusioni);
  }

  public getRisultatiSurvey(idMaterialita: string): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/risultati-survey', {});
    /* return of(this.risultatiSurveyFake); */
  }

  public putSoglie(idMaterialita: string, soglie: Soglie) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/soglie', soglie);
  }

  public putRaggruppaImpatti(idMaterialita: string, idTematica: string, raggruppa: boolean) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/tematica/' + idTematica + '/raggruppa', { raggruppa });
  }

  public putCosolidaRisultato(idMaterialita: string, imgGraficoMaterialita: string, legendaGrafico: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/consolida-risultato', { imgGraficoMaterialita, legendaGrafico });
  }

  public stampa(idMaterialita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/stampa');
  }

  public invioTestMail(messaggio: string, email: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/test-invio-mail', { messaggio, email });
  }

   /**
   * Cambio stato a pubblicato della materialità
   * @param idMaterialita 
   * @returns 
   */
   public pubblicaMaterialita(idMaterialita: string){
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idMaterialita + '/pubblica', {});
  }
}

import { CanActivateFn, Router } from '@angular/router';
import { AziendaService } from '../services/azienda/azienda.service';
import { inject } from '@angular/core';

export const aziendaGuard: CanActivateFn = (route, state) => {

  const aziendaService = inject(AziendaService);
  const router = inject(Router);

  return aziendaService.azienda.then((az) => {
    if (az) {
      return true;
    } else {
      return router.parseUrl('/home/dashboard');
    }
  })

};

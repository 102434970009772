<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Sintesi di Sostenibilità"
            subTitolo="Panoramica delle prestazioni di un'organizzazione in termini di sostenibilità"></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [filtri]="filtri"
        (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button mat-flat-button class="primary-button" color="primary" (click)="crea()"
                *ngIf="this.utenteService.isPermessoAttivo('CREATE_SINTESI')">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>
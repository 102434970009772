import { Directive, ElementRef, Input, QueryList, ContentChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { FormElementDirective } from './form-element.directive';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFormContainer]'
})
export class FormContainerDirective implements AfterViewInit, OnDestroy {

  @ContentChildren(FormElementDirective, { descendants: true }) figli!: QueryList<FormElementDirective>;

  private _figliChangesSub!: Subscription;

  private _larghezza = 1;
  private _gapPx = 10;
  private _rowGap = 1;
  private _alignContent: 'baseline' | 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'initial' = 'initial';
  private _alignItems: 'baseline' | 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'initial' = 'initial';
  private _justifyContent: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly' | 'initial' = 'initial';

  /**
   * Spazio tra gli elementi nelle righe del contenitore.
   */
  @Input()
  set gap(valore: number) {
    if (!valore) {
      valore = 0;
    }
    if (isNaN(valore)) {
      console.warn('Direttiva FormContainer - gap non numerico : ', valore);
      valore = 0;
    }
    this._gapPx = valore || 0;
    if (this.el?.nativeElement?.style) {
      this.el.nativeElement.style.columnGap = this._gapPx + 'px';
    }
    this.updateLarghezza();
  }

  @Input()
  set rowGap(valore: number) {
    if (!valore) {
      valore = 0;
    }
    if (isNaN(valore)) {
      console.warn('Direttiva FormContainer - row gap non numerico : ', valore);
      valore = 0;
    }
    this._rowGap = valore || 0;
    if (this.el?.nativeElement?.style) {
      this.el.nativeElement.style.rowGap = this._rowGap + 'px';
    }
    this.updateLarghezza();
  }

  @Input()
  set alignItems(valore: 'baseline' | 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'initial') {
    if (!valore) {
      valore = 'initial';
    }

    this._alignItems = valore;
    if (this.el?.nativeElement?.style) {
      this.el.nativeElement.style.alignItems = this._alignItems;
    }
    this.updateLarghezza();
  }

  @Input()
  set alignContent(valore: 'baseline' | 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'initial') {
    if (!valore) {
      valore = 'initial';
    }

    this._alignContent = valore;
    if (this.el?.nativeElement?.style) {
      this.el.nativeElement.style.alignContent = this._alignContent;
    }
    this.updateLarghezza();
  }

  @Input()
  set justifyContent(valore: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly' | 'initial') {
    if (!valore) {
      valore = 'initial';
    }
    
    this._justifyContent = valore;
    if (this.el?.nativeElement?.style) {
      this.el.nativeElement.style.justifyContent = this._justifyContent;
    }
    this.updateLarghezza();
  }


  /**
   * Larghezza del contenitore, sui cui sarà calcolata la larghezza degli elementi figlio.
   * Il valore impostato corrisponde al numero di elementi di larghezza 1 che vengono inseriti in una singola riga.
   */
  @Input()
  set larghezza(valore: number) {
    if (isNaN(valore)) {
      console.warn('Direttiva FormContainer - larghezza non numerica : ', valore);
      valore = 1;
    }
    this._larghezza = valore;
    this.updateLarghezza();
  }

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.display = 'flex';
    this.el.nativeElement.style.flexDirection = 'row';
    this.el.nativeElement.style.flexWrap = 'wrap';
    this.el.nativeElement.style.columnGap = this._gapPx + 'px';
    this.el.nativeElement.style.rowGap = this._rowGap + 'px';
    this.el.nativeElement.style.alignItems = this._alignItems;
    this.el.nativeElement.style.alignContent = this._alignContent;
    this.el.nativeElement.style.justifyContent = this._justifyContent;
  }

  ngAfterViewInit() {
    this.updateLarghezza();

    this._figliChangesSub = this.figli.changes.subscribe((changes) => {
      this.updateLarghezza();
    });
  }

  ngOnDestroy() {
    this._figliChangesSub?.unsubscribe();
  }

  updateLarghezza() {
    this.figli?.forEach((figlio) => {
      figlio.setLarghezzaParent(this._larghezza, this._gapPx);
    });
  }

}

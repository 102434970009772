<div class="white-container">
  <div class="titolo">
    {{ titolo }}
  </div>
  <div class="info-line-chart">
    <div class="punteggio-chart">
      <div class="punteggio">
        <div *ngIf="isMaxValue; else elseBlock">{{ value }}/{{ maxValue }}</div>
        <ng-template #elseBlock>{{ value }}%</ng-template>
      </div>
      <div class="diff-percent" [ngStyle]="{ '--coloreFreccia': coloreFreccia }">
        <div class="freccia">
          <mat-icon class="material-symbols-outlined">{{iconaFreccia}}</mat-icon>
        </div>
        <div class="numero">{{valoreFreccia}}</div>
      </div>
    </div>
    <div class="line-chart" [ngStyle]="{ '--coloreRGB': coloreRGB }"></div>
  </div>
</div>

<div class="landing-page" *ngIf="landingPage" [innerHtml]="landingPage"></div>
<div style="align-items: center;display: flex;justify-content: space-between;width: 100%;height: 50px;">
    <div>
        <div style="font-size: 30px;color: var(--mat-color-accent);font-family: Abel;">{{titolo}} di Sostenibilità
            <span *ngIf="esercizio">{{esercizio}}</span>
        </div>
    </div>
    <div style="height: 1px;background-color: #707070;flex:1;margin-right: 20px;margin-left: 20px;">
    </div>

    <div style="font-size: 30px;color: var(--mat-color-accent);font-family: Abel;" *ngIf="esercizio">
        {{(currentSlideIndex + 1)}}/{{totTematiche}}
    </div>
</div>

<div mat-dialog-content class="dialog-center-compilazione">

    <app-spinner-overlay></app-spinner-overlay>

    <div class="ambito" *ngFor="let amb of ambiti; let idxAmb = index">
        <div class="titolo-sezione-dialog" style="padding-bottom: 5px;">
            {{amb.titolo}} - {{amb.descrizione}}
        </div>


        <div *ngFor="let tema of amb.tematiche; let idxTem = index">

            <div class="tematiche">

                <div *ngIf="tema.domanda" class="domanda">
                    {{tema.domanda}}
                </div>

                <ng-container *ngIf="tema.tipo === 'scelta_singola'">

                    <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group"
                        [value]="tema?.risposte?.length ? tema.risposte![0] : null"
                        (change)="onRadioChange($event,idxAmb,idxTem)" #radioGroup="matRadioGroup">

                        <ng-container *ngFor="let opz of tema.opzioni; let idxOpz = index">

                            <mat-radio-button [value]="opz.chiave">
                                {{opz.valore}}
                            </mat-radio-button>

                            <div class="sotto-opzioni"
                                *ngIf="opz.sottoOpzioni?.length && radioGroup.value === opz.chiave">

                                <div *ngFor="let opzSec of opz.sottoOpzioni" class="radio-group">

                                    <mat-checkbox [value]="opzSec.chiave"
                                        [checked]="checkSotto(opz.sottoRisposte , opzSec.chiave)"
                                        (change)="cambioCheck($event,idxAmb,idxTem,idxOpz,opzSec.chiave)">
                                        {{opzSec.valore}}
                                    </mat-checkbox>

                                </div>

                            </div>

                        </ng-container>

                    </mat-radio-group>

                </ng-container>

                <div *ngIf="tema.tipo === 'scelta_multipla_valore'" appFormContainer [larghezza]="3" [rowGap]="10">

                    <!------------------------------------------- N U M E R I C A ------------------------------>

                    <!---------------------------------------------- I M P A T T O --------------------------------->
                    <ng-container *ngIf="tema.impatti?.length">
                        <ng-container *ngFor="let impatto of tema.impatti">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{impatto.descrizione}}</mat-label>

                            <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                                <mat-label>Risposta</mat-label>
                                <input matInput [placeholder]="'Risposta'" [(ngModel)]="impatto.valoreAttribuito" appInputNumber
                                    type="number" step="0.01" />
                                <mat-error appFormError></mat-error>
                            </mat-form-field>

                            <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                        </ng-container>

                    </ng-container>




                    <!---------------------------------------------- T E S T U A L E ------------------------------->

                    <!---------------------------------------------- I M P E G N O --------------------------------->
                    <ng-container *ngIf="tema?.impegni?.length">
                        <ng-container *ngFor="let impegno of tema?.impegni">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{impegno.descrizione}}</mat-label>

                            <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                                <mat-label>Risposta</mat-label>
                                <textarea matInput [placeholder]="'Risposta'" rows="4"
                                    [(ngModel)]="impegno.risposta"></textarea>
                                <mat-error appFormError></mat-error>
                            </mat-form-field>

                            <div *ngIf="impegno.preCompilazioneInfos" class="info-risposta">
                                <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                    info
                                </span>
                                <mat-label class="risposta-read-only" appFormElement [larghezza]="3"
                                    [riempi]="true">{{getTooltip(impegno.preCompilazioneInfos)}}
                                </mat-label>
                            </div>

                            <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                        </ng-container>

                    </ng-container>

                    <!---------------------------------------------- A Z I O N I ------------------------------->
                    <ng-container
                        *ngIf="tema?.azioni?.length && (this._compilazione.tipo !== 'POLICY' && this._compilazione.tipo !== 'PIANO')">
                        <ng-container *ngFor="let azione of tema?.azioni">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{azione.descrizione}}</mat-label>

                            <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                                <mat-label>Risposta</mat-label>
                                <textarea matInput [placeholder]="'Risposta'" rows="4"
                                    [(ngModel)]="azione.risposta"></textarea>
                                <mat-error appFormError></mat-error>
                            </mat-form-field>

                            <div *ngIf="azione.preCompilazioneInfos" class="info-risposta">
                                <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                    info
                                </span>
                                <mat-label class="risposta-read-only" appFormElement [larghezza]="3"
                                    [riempi]="true">{{getTooltip(azione.preCompilazioneInfos)}}
                                </mat-label>
                            </div>

                            <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                        </ng-container>

                    </ng-container>



                    <!-- VALE SOLO PER LE AZIONI BUONE PRATICHE DENTRO ALLA POLICY o PIANO-->
                    <ng-container
                        *ngIf="tema?.azioni?.length && (this._compilazione.tipo === 'POLICY' || this._compilazione.tipo === 'PIANO')">
                        <ng-container *ngFor="let azione of tema?.azioni">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{azione.descrizione}}</mat-label>


                            <div *ngIf="this._compilazione.tipo === 'PIANO'" style="padding-bottom: 10px;"
                                class="descrizione-sezione-dialog">
                                {{azione?.titoloObiettivo}}
                            </div>

                            <ng-container *ngIf="this._compilazione.tipo === 'PIANO'">
                                <mat-form-field appFormElement [larghezza]="3">
                                    <mat-label>Anno</mat-label>
                                    <input matInput [placeholder]="'Anno'" [(ngModel)]="azione.anno"
                                        [required]="targetRequired" type="number" step="1" inputmode="numeric" appInputNumber
                                        [minLength]="4" />
                                    <mat-error appFormError></mat-error>
                                </mat-form-field>

                            </ng-container>

                            <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                                <mat-label>Risposta Breve</mat-label>
                                <textarea matInput [placeholder]="'Risposta Breve'" rows="4"
                                    [(ngModel)]="azione.rispostaBreve" [required]="targetRequired"></textarea>
                                <mat-error appFormError></mat-error>
                            </mat-form-field>

                            <div *ngIf="azione.risposta" class="info-risposta"
                                [matTooltip]="getTooltip(azione.preCompilazioneInfos)">
                                <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                    info
                                </span>
                                <mat-lanbel class="risposta-read-only" appFormElement [larghezza]="3" [riempi]="true">
                                    {{azione.risposta}}
                                </mat-lanbel>
                            </div>

                            <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                        </ng-container>

                    </ng-container>


                    <!---------------------------------------------- K P I --------------------------------->
                    <ng-container *ngIf="tema.kpis?.length">
                        <ng-container *ngFor="let kpis of $any(tema.kpis)">
                            <!-- VALORI KPI -->
                            <ng-container *ngIf="this._compilazione.tipo !== 'PIANO'">
                                <ng-container *ngFor="let valoriKpi of kpis.valoriKpi; first as isFirst">

                                    <mat-label *ngIf="isFirst" class="descrizione" appFormElement [larghezza]="3"
                                        [riempi]="true">{{kpis.descrizione}}</mat-label>

                                    <mat-form-field appFormElement [larghezza]="1">
                                        <mat-label>Unità di Misura</mat-label>
                                        <mat-select [placeholder]="'Unità di Misura'"
                                            [(ngModel)]="valoriKpi.idUnitaDiRiferimento">
                                            <mat-option *ngFor="let um of arrayUm[valoriKpi.idCategoriaUnitaDiMisura]"
                                                [value]="um.id">{{um.descrizione}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div appFormElement [larghezza]="valueToggleAnni ? 0.5 : 1" [riempi]="true"
                                        style="display: flex; align-items: center; column-gap: 10px;">
                                        <mat-form-field style="width: 100%;">
                                            <mat-label>Valore</mat-label>
                                            <input matInput [placeholder]="'Valore'"
                                                [(ngModel)]="valoriKpi.valoreAttribuito" type="number" appInputNumber step="0.01"
                                                inputmode="numeric" />
                                            <mat-error appFormError></mat-error>
                                        </mat-form-field>

                                        <div *ngIf="valoriKpi.preCompilazioneInfos" class="info-risposta"
                                            [matTooltip]="getTooltip(valoriKpi.preCompilazioneInfos)"
                                            style="padding-bottom: 20px;">
                                            <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                                info
                                            </span>
                                        </div>
                                    </div>

                                    <mat-label appFormElement [larghezza]="valueToggleAnni ? 0.5 : 0" [riempi]="true"
                                        style="padding-left: 50px;padding-top: 18px;">Anno <span
                                            style="font-weight: bold;">{{valoriKpi.anno}}</span></mat-label>

                                    <div class="separa" appFormElement [larghezza]="3" [riempi]="true" *ngIf="isFirst">
                                    </div>

                                </ng-container>
                            </ng-container>

                            <!-- TARGET -->

                            <div style="display: flex; flex-direction: column; width: 100%;">
                                <div style="display: flex; column-gap: 20px;">
                                    <div class="titolo-sezione-dialog">Compilazione Target</div>
                                    <div *ngIf="targetConsigliato" style="color: #40a36f;">
                                        *Consigliato
                                    </div>
                                </div>
                                <div style="padding-bottom: 10px;" class="descrizione-sezione-dialog">
                                    {{this._compilazione.tipo === 'PIANO'?kpis?.titoloObiettivo : "Per questo KPI è " +
                                    "prevista la compilazione di uno o più target." }}</div>
                                <ng-container *ngFor="let valoriTarget of kpis.valoriTarget; first as isFirst">
                                    <div style="display: flex; column-gap: 10px;">

                                        <mat-form-field appFormElement [larghezza]="0.5">
                                            <mat-label>Anno</mat-label>
                                            <input matInput [placeholder]="'Anno'" [(ngModel)]="valoriTarget.anno"
                                                [required]="targetRequired" type="number" appInputNumber step="1" inputmode="numeric"
                                                [minLength]="4" />
                                            <mat-error appFormError></mat-error>
                                        </mat-form-field>

                                        <mat-form-field>
                                            <mat-label>Tipo Azione</mat-label>
                                            <mat-select [placeholder]="'Tipo Azione'"
                                                [(ngModel)]="valoriTarget.tipoAzione" [required]="targetRequired">
                                                <mat-option *ngFor="let option of tipoAzione"
                                                    [value]="option.chiave">{{option.descrizione}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appFormElement [larghezza]="1">
                                            <mat-label>Unità di Misura</mat-label>
                                            <mat-select [placeholder]="'Unità di Misura'"
                                                [(ngModel)]="valoriTarget.idUnitaDiRiferimento"
                                                [required]="targetRequired">
                                                <mat-option
                                                    *ngFor="let um of arrayUm[valoriTarget.idCategoriaUnitaDiMisura]"
                                                    [value]="um.id">{{um.descrizione}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <div style="display: flex; column-gap: 59px; flex: 1;">
                                            <div appFormElement [larghezza]="1.5" [riempi]="true"
                                                style="display: flex; align-items: center; column-gap: 10px;">
                                                <mat-form-field style="width: 100%;">
                                                    <mat-label>Risposta</mat-label>
                                                    <input matInput [placeholder]="'Risposta'"
                                                        [required]="targetRequired"
                                                        [(ngModel)]="valoriTarget.valoreAttribuito" type="number" appInputNumber
                                                        step="0.01" inputmode="numeric" />
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>

                                                <div *ngIf="valoriTarget.preCompilazioneInfos" class="info-risposta"
                                                    [matTooltip]="getTooltip(valoriTarget.preCompilazioneInfos)"
                                                    style="padding-bottom: 20px;">
                                                    <span
                                                        class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                                        info
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="this._compilazione.tipo !== 'PIANO'">
                                    <div appFormElement [larghezza]="1" [riempi]="true" class="btn-salvataggio-cat"
                                        style="padding-bottom: 10px;">
                                        <button mat-stroked-button (click)="visualizzaCampiTarget(kpis)"
                                            style="height: 40px;width: 100%;font-weight: bold; background-color: #F5F5F5;">
                                            + Aggiungi Nuovo
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>




                    <!---------------------------------------------- T E S T U A L E ------------------------------->
                    <!---------------------------------------------- Certificazione ------------------------------->
                    <ng-container *ngIf="tema?.certificazioni?.length">
                        <ng-container *ngFor="let certif of tema?.certificazioni">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{certif.descrizione}}</mat-label>
                            <div style="display: flex; flex: 1; column-gap: 20px;" appFormElement [larghezza]="3"
                                [riempi]="true">
                                <div style="flex: 1;">
                                    <mat-form-field style="width: 100%" appFormElement [larghezza]="3" [riempi]="true">
                                        <mat-label>Dettaglio certificazione</mat-label>
                                        <textarea rows="7" matInput [placeholder]="'Dettaglio certificazione'"
                                            [(ngModel)]="certif.risposta"></textarea>
                                        <mat-error appFormError></mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <div style="  border: 1px solid #ececec; padding: 5px;">
                                        <div class="image-container">
                                            <div class="immagine-cont" [ngStyle]="{'background-image': 'url(' + getUrlImg(
                                                    amb.id,
                                                    tema.id,
                                                    certif.id,
                                                    certif?.urlFile)+')'}">
                                                <div class="overlay">
                                                    <div class="text">Importa una nuova immagine</div>
                                                </div>
                                            </div>
                                            <input id="fileInput" type="file" #fileInputImg
                                                (change)="fileSelezionato($any($event.target)?.files, amb.id, tema.id , certif.id, fileInputImg)"
                                                class="select-profile-picture" />
                                        </div>
                                    </div>
                                    <div *ngIf="isFilePresente( amb.id, tema.id , certif.id,certif.urlFile)">
                                        <button class="bottone-elimina" mat-flat-button color="warn"
                                            (click)="eliminaImg(amb.id, tema.id , certif.id)">Rimuovi</button>
                                    </div>


                                    <div *ngIf="!isFilePresente( amb.id, tema.id , certif.id,certif.urlFile)">
                                        <div class="fileinput">

                                            <button class="bottone-elimina" mat-flat-button color="accent">
                                                Importa
                                                <mat-icon>
                                                    file_upload
                                                </mat-icon>
                                            </button>
                                            <input #fileInput type="file" name="fileDaCaricare"
                                                (change)="fileSelezionato($any($event.target)?.files, amb.id, tema.id , certif.id, fileInput)"
                                                (click)="$any($event.target).value=null" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="certif.preCompilazioneInfos" ngClass="info-risposta">
                                <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                    info
                                </span>
                                <mat-label class="risposta-read-only" appFormElement [larghezza]="3"
                                    [riempi]="true">{{getTooltip(certif.preCompilazioneInfos)}}
                                </mat-label>
                            </div>
                            <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                        </ng-container>

                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button (click)="chiudi()" color="accent" class="accent-button">Chiudi</button>
    </div>
    <div *ngIf="!isAnteprima">
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Conferma
        </button>
    </div>
</div>
import { PolicyService } from './../../../services/policy/policy.service';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { DialogCreaPerimetroAppPolicyComponent } from './dialog/dialog-crea-perimetro-app-policy/dialog-crea-perimetro-app-policy.component';

@Component({
  selector: 'app-cfg-perimetro-applicazione-policy',
  templateUrl: './cfg-perimetro-applicazione-policy.component.html',
  styleUrls: ['./cfg-perimetro-applicazione-policy.component.scss']
})
export class CfgPerimetroApplicazionePolicyComponent {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Linea guida Policy di Sostenibilità',
      forControlName: 'lineaGuidaPolicy',
      input: 'text'
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
  ];

  public colonne: { [key: string]: Colonna } = {

    descrizione: {
      title: 'Descrizione',
      value: 'descrizione',
      sortable: true
    },

    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
    },
  };
  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private policyService: PolicyService

  ) { }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [{
        chiave: 'descrizione',
        sort: 'asc'
      }];
    }

    return this.policyService.getPerimetroPolicy(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovo(event.data);
        break;
      case 'elimina':
        this.elimina(event.data.id);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  creaNuovo(perimetroPolicy?: any) {

    const dialogCreaperimetroPolicy = this.dialog.open(DialogCreaPerimetroAppPolicyComponent, {
      data: {
        perimetroPolicy: perimetroPolicy
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '75%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaperimetroPolicy.afterClosed().subscribe((result) => {
       
      this.tabella.caricaDati();
    });
  }

  elimina(idRiga: string) {

    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare il Perimetro di Applicazione della Policy di Sostenibilità',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.policyService.deletePerimetroPolicy(idRiga).subscribe(
              {
                next: () => {
                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: 'Errore eliminazione' + err,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }
}

import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs'
import { UtenteWebService } from '../services/utente/utente-web.service';
import { AziendaService } from '../services/azienda/azienda.service';
import { UtenteService } from '../services/utente/utente.service';
import { UtilityService } from '../services/utility/utility.service';


export const authGuard: CanActivateFn = (route, state) => {

  const utenteService = inject(UtenteService);
  const utenteWebService = inject(UtenteWebService);
  const router = inject(Router);
  const utilityService = inject(UtilityService);

  return utenteWebService.checkAuth().pipe(
    switchMap((autorizzato) => {
      if (!autorizzato) {
        utilityService.closeDialog();
        return of(router.parseUrl('/login'));
      } else {
        return utenteService.aggiornaInfoUtente();
      }
    })
  );
};

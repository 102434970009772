<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Policy di Sostenibilità"
            subTitolo="L'Impegno aziendale ad operare in modo responsabile per un futuro migliore."></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [filtri]="filtri"
        (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button mat-flat-button class="primary-button" color="primary" (click)="crea()">
                Nuovo
            </button>
            <!-- *ngIf="this.utenteService.isPermessoAttivo('CREATE_POLICY')" -->
        </div>
    </app-lista-tabellare>
</div>
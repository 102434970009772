<div class="white-container">
  <div class="info-bar">
    <div class="titolo">
      {{ titolo }}
    </div>
    <div class="punteggio">{{ value }}/{{ maxValue }}%</div>
  </div>
  <div class="progress-bar" [ngStyle]="{ '--coloreRGB': coloreRGB }">
    <mat-progress-bar mode="determinate" value="{{ value }}" [ngStyle]="{
        '--mdc-linear-progress-active-indicator-color': colore,
        '--mdc-linear-progress-track-color': 'white',
        '--mdc-linear-progress-active-indicator-height': '7px',
        '--mdc-linear-progress-track-height': '7px'
      }"></mat-progress-bar>
  </div>
</div>
<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Destinatari Policy"
            subTitolo="Configurazione tipologie di destinatari della Policy di Sostenibilità."></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd style="display: flex;justify-content: center; column-gap: 23px;">
            <button mat-flat-button class="primary-button" color="primary" (click)="creaNuovo()">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>
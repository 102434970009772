<div class="contenitore" *ngIf="datiSurvey">
    <div class="landing-page" *ngIf="landingPage" [innerHtml]="landingPage"></div>
    <!----------------------------- T E S T A T A ------------------------>

    <div class="titolo">
        <app-titolo-subtitolo titolo={{datiSurvey.titolo}}></app-titolo-subtitolo>

        <div class="separatore" [riempi]="true" appFormElement>
            <app-separatore-label [styleSeparatore]="'medium'"></app-separatore-label>
        </div>

        <div *ngIf="idSurveyCompilato" class="data-compilazione">
            Data Compilazione:
            <div class="data"> {{dataCompilazione}}</div>
        </div>


    </div>

    <!----------------------------- A M B I T I ------------------------>
    <div class="cnt-tematica" *ngFor="let ambiti of datiSurvey.ambiti ; let idxAmbito=index">
        <!--         <div class="titolo-tematiche">
            {{ambiti.titolo}}
        </div> -->
        <!----------------------------- T E M A T I C H E ------------------------>
        <div class="cnt-tematica" *ngFor="let tematica of ambiti.tematiche ; let idxTematica=index" [id]="tematica.id">

            <!--             <div class="domanda-tematiche">
                {{tematica.domanda}} <mat-icon *ngIf="tematica.note" matTooltip={{tematica.note}}> info </mat-icon>
            </div>

            <div class="desc-note">

                <div class="descrizione-tematiche">
                    {{tematica.descrizione}}

                </div>
            </div> -->

            <!--  <div class="note-tematiche">
                {{tematica.note}}
            </div> -->

            <!----------------------------- I M P A T T I ------------------------>
            <div class="cnt-tematica" *ngFor="let impatto of tematica.impatti ; let idxImpatto=index">

                <div class="rating">
                    {{impatto.titolo}}
                    <div class="impatto-descrizione">{{impatto.descrizione}}</div>
                    <div class="star-rating">
                        <app-my-rating-star [idSurveyCompilato]="idSurveyCompilato" idRating={{impatto.id}}
                            [numeroStelle]="tipoPunteggio"
                            [valoreAtribuito]="impatto.valoreAttribuito ? impatto.valoreAttribuito : 0"
                            (valueChangePunteggio)="aggiornaPunteggioImpatto(idxAmbito, idxTematica ,idxImpatto, $event)"></app-my-rating-star>
                    </div>
                </div>
            </div>
            <!--  <div style="padding: 20px 0 10px 0;">
                <hr style="width: 100%;  border-top: 2px solid #bbb;">  SEPARATORE 
            </div> -->
        </div>

    </div>
</div>
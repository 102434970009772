import { Injectable } from '@angular/core';
import { ImpattoWebService } from './impatto-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Settore } from '../settori/settori.service';
import { Observable, of } from 'rxjs';

export interface Impatto {
  id: string,
  titolo: string,
  chiave?: string,
  impattoPotenzialeEffettivo?: string[],
  impattoPositivoNegativo?: string[],
  descrizione: string,
  note: string,
  fatturatoDa?: number,
  fatturatoA?: number,
  settori: Settore[],
  valoreAttribuito?: number
  selected?: boolean,
  consigliato?: boolean,
  creatoDaContesto?: boolean
  tag?: string
}

export interface ScritturaImpatto {
  codice: string;
  descrizione: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImpattoService {
  public nomeWsImp: string = 'impatti';
  public nomeWsWrtImp: string = 'guide-scritture-impatti';

  public impattoPotEff: ScritturaImpatto[] = [{
    codice: "Potenziale",
    descrizione: "Impatto Potenziale"
  },
  {
    codice: "Effettivo",
    descrizione: "Impatto Effettivo"
  }];

  public impattoPosNeg: ScritturaImpatto[] = [{
    codice: "Positivo",
    descrizione: "Impatto Positivo"
  },
  {
    codice: "Negativo",
    descrizione: "Impatto Negativo"
  }];

  constructor(private impattoWebService: ImpattoWebService) { }

  /**
   * Funzione per la creazione di un'Impatto
   * @param data dati form Impatto
   */
  public postImpatto(data: any) {
    
    return this.impattoWebService.post(this.nomeWsImp, data);
  }

  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getImpatti(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.impattoWebService.get(this.nomeWsImp, page, pageSize, ricerca, filters, sortBy);
  }
  /**
     * Funzione per selezionare i tag degli impatti
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getImpattiTags(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.impattoWebService.getImpattiTags(this.nomeWsImp, page, pageSize, ricerca, filters, sortBy);
  }

  /**
    * Funzione per eliminare una riga da una lista 
  * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteImpatto(idRiga: any) {
    return this.impattoWebService.delete(this.nomeWsImp, idRiga);
  }

  /**
   * Impatto
   * @param id 
   * @param impatto 
   * @returns 
  */
  public putImpatto(id: string, impatto: any) {
    return this.impattoWebService.put(this.nomeWsImp, id, impatto);
  }



  /**
   * Funzione per la creazione di Scrittura Impatto
   * @param data dati form Scrittura Impatto
   * @returns 
   */
  public postScritturaImpatto(data: any) {
    
    return this.impattoWebService.postScrittura(this.nomeWsWrtImp, data);
  }

  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getScritturaImpatti() {
    return this.impattoWebService.getScritturaImpatti(this.nomeWsWrtImp);
  }

  public getScritturaImpattoByCodice(codice: string) {
    return this.impattoWebService.getScritturaImpattoByCodice(this.nomeWsWrtImp, codice);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteScritturaImpatto(idRiga: any) {
    return this.impattoWebService.delete(this.nomeWsWrtImp, idRiga);
  }

  public putScritturaImpatto(id: string, impatto: any) {
    return this.impattoWebService.put(this.nomeWsWrtImp, id, impatto);
  }

  public getImpattoPotEff(): Observable<ScritturaImpatto[]> {
    return of(
      [{
        codice: "Potenziale",
        descrizione: "Impatto Potenziale"
      },
      {
        codice: "Effettivo",
        descrizione: "Impatto Effettivo"
      }
      ])
  };

  public getImpattoPosNeg(): Observable<ScritturaImpatto[]> {
    return of(
      [{
        codice: "Positivo",
        descrizione: "Impatto Positivo"
      },
      {
        codice: "Negativo",
        descrizione: "Impatto Negativo"
      }]
    )
  };

}

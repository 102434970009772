import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { PianoService } from 'src/app/services/piano/piano.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaObiettiviPianoComponent } from './dialog/dialog-crea-obiettivi-piano/dialog-crea-obiettivi-piano.component';

@Component({
  selector: 'app-cfg-obiettivi-piano',
  templateUrl: './cfg-obiettivi-piano.component.html',
  styleUrls: ['./cfg-obiettivi-piano.component.scss']
})
export class CfgObiettiviPianoComponent {
    @ViewChild('tabella') tabella!: ListaTabellareComponent;
  
    public filtri: Filtri[] = [
      {
        titolo: 'Titolo',
        forControlName: 'titolo',
        input: 'text'
      },
      {
        titolo: 'Descrizione',
        forControlName: 'descrizione',
        input: 'text',
      },
    ];
  
    public colonne: { [key: string]: Colonna } = {
  
      descrizione: {
        title: 'Descrizione',
        value: 'descrizione',
        sortable: true
      },
  
      modifica: {
        type: 'button',
        buttonIcon: 'edit',
        title: 'Modifica',
        buttonId: 'modifica',
        buttonMostraSempre: true
      },
      elimina: {
        type: 'button',
        buttonIcon: 'delete',
        title: 'Elimina',
        buttonId: 'elimina',
      },
    };
    constructor(
      public dialog: MatDialog,
      private utilityService: UtilityService,
      private pianoService: PianoService
  
    ) { }
  
    public fnCaricamentoDati: FnCaricamentoDati = (
      page: number,
      pageSize: number,
      ricerca?: string,
      filters?: Filters[],
      sortBy?: SortBy[]
    ) => {
  
      if (!sortBy) {
        sortBy = [{
          chiave: 'descrizione',
          sort: 'asc'
        }];
      }
  
      return this.pianoService.getObiettivi(page, pageSize, ricerca, filters, sortBy);
    };
  
    public bottoniListaClick(event: BottoniListaEvent) {
      switch (event.id) {
        case 'modifica':
          this.creaNuovo(event.data);
          break;
        case 'elimina':
          this.elimina(event.data.id);
          break;
        default:
          console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
      }
    }
  
    creaNuovo(obiettivoPiano?: any) {
  
  
      const dialogCreaObiettivoPiano = this.dialog.open(DialogCreaObiettiviPianoComponent, {
        data: {
          obiettivoPiano: obiettivoPiano
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '75%',
        maxHeight: '95%',
        autoFocus: false,
      });
  
      dialogCreaObiettivoPiano.afterClosed().subscribe((result) => {
         
        this.tabella.caricaDati();
      });
    }
  
    elimina(idRiga: string) {
  
      this.utilityService.openDialog({
        descrizione: 'Confermi di eliminare l\'obiettivo Piano',
        bottoni: [
          {
            nome_btn: 'NO',
            background_color: 'var(--mat-color-accent)',
          },
          {
            nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
              this.pianoService.deleteObiettiviPiano(idRiga).subscribe(
                {
                  next: () => {
                    this.tabella.caricaDati();
                  },
                  error: (err: any) => {
                    this.utilityService.openDialog({
                      titolo: 'Attenzione',
                      descrizione: 'Errore eliminazione' + err,
                      bottoni: [
                        {
                          nome_btn: 'Chiudi', handler: () => {
                            this.tabella.caricaDati();
                          }
                        }]
                    });
                  }
                }
              );
            }
          }]
      });
  
    }
}

import { UtenteService } from 'src/app/services/utente/utente.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-vis-utente',
  templateUrl: './dialog-vis-utente.component.html',
  styleUrls: ['./dialog-vis-utente.component.scss']
})
export class DialogVisUtenteComponent implements OnInit {

  public id: string | undefined = undefined;

  constructor(
    public utenteService: UtenteService,
    public dialogRef: MatDialogRef<DialogVisUtenteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { idAzienda: string }
  ) {
   
    this.id = data.idAzienda;
  }
ngOnInit(): void {
    this.utenteService.setNrChiamataDialogUsr(1);
}
}

import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MaterialitaService, Survey } from 'src/app/services/materialita/materialita.service';
import { NgStarRatingService } from 'src/app/services/ng-star-rating/ng-star-rating.service';

@Component({
  selector: 'app-anteprima-survey',
  templateUrl: './anteprima-survey.component.html',
  styleUrls: ['./anteprima-survey.component.scss']
})
export class AnteprimaSurveyComponent implements OnInit {
  public dataCompilazione: string = '';
  public tipoPunteggio: number = 0;
  private _datiSurvey: Survey | undefined = undefined;
  private _landingPage: SafeHtml = '';
  private _idSurveyCompilato: string = '';

  @Input()
  set datiSurvey(datiSurvey: Survey) {
    this._datiSurvey = JSON.parse(JSON.stringify(datiSurvey)) as Survey;
    
  }
  get datiSurvey() {
    return this._datiSurvey!;
  }

  @Input()
  set landingPage(_landingPage: string) {
    this._landingPage = this.sanitizer.bypassSecurityTrustHtml(_landingPage);
  }
  get landingPage(): SafeHtml {
    return this._landingPage;
  }

  @Input()
  set idSurveyCompilato(_idSurveyCompilato: string) {
    this._idSurveyCompilato = _idSurveyCompilato;
  }
  get idSurveyCompilato() {
    return this._idSurveyCompilato;
  }

  constructor(
    public ngStarRating: NgStarRatingService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    this.datiIniziali();

  }

  public aggiornaPunteggioImpatto(idxAmbito: number, idxTematica: number, idxImpatto: number, punteggioImpatto: number) {
    if (this._datiSurvey?.ambiti[idxAmbito]?.tematiche[idxTematica]?.impatti?.length) {
      this._datiSurvey.ambiti[idxAmbito].tematiche[idxTematica].impatti[idxImpatto].valoreAttribuito = punteggioImpatto;
    }
  }

  public datiIniziali() {
    //tipo punteggio
    
    if (this._datiSurvey?.tipoPunteggio === "BASE_5") {
      this.tipoPunteggio = 5;
    } else {
      this.tipoPunteggio = 10;
    }

    //data compilazione
    if (this._datiSurvey?.dataCompilazione) {
      // Creare un oggetto Date dal formato fornito
      const dataCompilazione: Date = new Date(this._datiSurvey.dataCompilazione);

      // Ottenere i componenti della data
      const giorno: string = String(dataCompilazione.getDate()).padStart(2, '0');
      const mese: string = String(dataCompilazione.getMonth() + 1).padStart(2, '0'); // Mese inizia da 0
      const anno: number = dataCompilazione.getFullYear();

      // Formattare la data nel tuo formato desiderato
      this.dataCompilazione = `${giorno}/${mese}/${anno}`;
    }
  }
}


import { UtilityService } from './../../../../services/utility/utility.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Esclusioni, MaterialitaService } from 'src/app/services/materialita/materialita.service';

@Component({
  selector: 'app-dialog-imposta-esclusioni',
  templateUrl: './dialog-imposta-esclusioni.component.html',
  styleUrls: ['./dialog-imposta-esclusioni.component.scss']
})
export class DialogImpostaEsclusioniComponent implements OnInit {

  public esclusioni: Esclusioni[] = [];
  codiciCheckati: string[] = [];

  ngOnInit(): void {
    this.valoriIniziali();
  }
  constructor(
    public dialogRef: MatDialogRef<DialogImpostaEsclusioniComponent>,
    private utilityService: UtilityService,
    private materialitaService: MaterialitaService,
    @Inject(MAT_DIALOG_DATA) public data: {
      matEsclusioni: any,
      idMaterialita: string
    }) { }

  /**
   * Setto i valori iniziali aperta la dialog 
   */
  valoriIniziali() {
    this.codiciCheckati = this.data.matEsclusioni;
    this.materialitaService.getEsclusioni().subscribe(
      {
        next: (result) => {
          this.esclusioni = result;
        },
        error: (err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Nessuna esclusione trovata' + err,
            bottoni: [
              {
                nome_btn: 'Chiudi', handler: () => {
                }
              }]
          });
        }

      })
  }

  /**
   * Metodo che mi salva le escusioni selezionate 
   */
  conferma() {
    this.materialitaService.putEsclusioni(this.data.idMaterialita, this.codiciCheckati).subscribe({

      next: (result: any) => {
        this.utilityService.opneSnackBar('Esclusioni selezionate ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.dialogRef.close();
      },
      error: (err: any) => {
        console.error('Errore durante la selezione delle esclusioni:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: err?.error?.message || 'Errore durante la selezione delle esclusioni',
          bottoni: [{ nome_btn: 'Chiudi' }]
        })
      }
    });
  };

  /**
   * metodo per controllare se è stata selezionata 
   * @param codice 
   * @returns 
   */
  isChecked(codice: string): boolean {
    return this.codiciCheckati && this.codiciCheckati.includes(codice);
  }

  /**
   * Aggiungo e rimuovo il codice se è stato o meno  selezionato 
   * @param codice 
   */
  esclusioniSelezionate(codice: string): void {
    if (this.isChecked(codice)) {
      // Se è già checkata, la rimuovo dalla lista
      this.codiciCheckati = this.codiciCheckati.filter(c => c !== codice);
    } else {
      // Se non è checkata, la aggiungo alla lista
      this.codiciCheckati.push(codice);
    }
  }

}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { ImportazioniService } from 'src/app/services/importazioni/importazioni.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-importazione-excel',
  templateUrl: './dialog-importazione-excel.component.html',
  styleUrls: ['./dialog-importazione-excel.component.scss']
})
export class DialogImportazioneExcelComponent implements OnInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public descrizioneErrore: string | undefined = undefined;
  public isIconaGuida: boolean = false;

  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private importazioniService: ImportazioniService,

    @Inject(MAT_DIALOG_DATA) public data: {
      titoloImportazione: string,
      subTitoloImportazione: string,
      pathTemplateExcel: string,
      azienda: boolean,
      nomeImport: string,
    }
  ) {
  }

  ngOnInit(): void {
  }

  public fileSelezionato(file: File[]) {
    if (file[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.isIconaGuida = false;
      this.spinnerOver.show();

      this.importazioniService.importFile(file[0], {}, this.data.azienda, this.data.nomeImport).pipe(filter((res) => res?.type === 4)).subscribe({
        next: (result: any) => {

          console.warn(result);

          this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });

          if (result.body) {

            let tableHTML = '<table style="border-collapse: collapse; padding: 10px;" border="1">';
            for (const riga in result.body) {
              if (result.body.hasOwnProperty(riga)) {
                tableHTML += '<tr>';
                if (riga === '-1') {
                  tableHTML += `<td colspan="2" style="color:var(--mat-color-accent); padding: 5px 10px 5px; text-align: center; ">${result.body[riga]}<br></td>`;
                } else {
                  tableHTML += `<td style="color:var(--mat-color-accent); padding: 5px 10px 5px; font-size: 15px;" >Riga ${riga} : </td>`;
                  tableHTML += '<td>';
                  for (const msgErrore of result.body[riga]) {
                    tableHTML += `<span style="font-size: smaller; padding: 5px 10px 5px">- ${msgErrore}</span><br>`;
                  }
                  tableHTML += '</td>';
                }
                tableHTML += '</tr>';
              }
            }
            tableHTML += '</table>';

            this.dialog.closeAll();

            this.utilityService.openDialog({
              titolo: 'Esito Importazione',
              descrizione: tableHTML,
              bottoni: [{ nome_btn: 'Chiudi' }]

            })

          }
          this.spinnerOver.hide();

        },
        error: (err: any) => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio ',
            bottoni: [{ nome_btn: 'Chiudi' }]
          })
          this.spinnerOver.hide();
        }

      });
    } else {
      this.descrizioneErrore = " Attenzione! Il file d'importazione deve essere un file excel (.xlsx)";
      this.isIconaGuida = true;
    }
  }

  scaricaTemplate(): void {
    // Percorso al tuo file Excel template
    const templatePath = this.data.pathTemplateExcel;

    // Effettua la richiesta HTTP per ottenere il file Excel template
    fetch(templatePath)
      .then(response => response.arrayBuffer())
      .then(data => {
        // Crea un blob dal buffer dei dati
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Crea un URL dal blob e crea un link temporaneo
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = this.data.nomeImport + '.xlsx';

        // Aggiungi il link al documento e attivalo
        document.body.appendChild(link);
        link.click();

        // Rimuovi il link dal documento
        document.body.removeChild(link);
      })
      .catch(error => console.error('Errore durante il download del template', error));
  }
}
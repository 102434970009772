<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>
    Crea Assessment di Sostenibilità
</h2>
<div mat-dialog-content class="dialog-center-cont">

    <form [formGroup]="formCreaAutovalutazione" appFormContainer [larghezza]="3" style="align-items: center;">

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Anno</mat-label>
            <mat-select formControlName="annoDiRiferimento" placeholder="Anno">
                <mat-option *ngFor="let aa of arrayAnni" [value]="aa">{{aa}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Sotto-settore</mat-label>
            <mat-select [formControl]="sottoSettore" placeholder="Sotto-settore">
                <mat-option value="">-</mat-option>
                <mat-option *ngFor="let sotSet of arraySottoSettori" [value]="sotSet.id">{{sotSet.titolo}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Assessment di Sostenibilità</mat-label>
            <mat-select formControlName="idAutovalutazione" placeholder="Assessment di Sostenibilità">
                <mat-option *ngFor="let auto of arrayAutovalutazioni" [value]="auto.id">{{auto.titolo}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
            <mat-label>Incaricato Compilazione</mat-label>
            <mat-select formControlName="idFigura" placeholder="Incaricato Compilazione">
                <mat-option *ngFor="let fig of arrayFigure" [value]="fig.id">{{fig.nome}} - {{fig.figura}}</mat-option>
            </mat-select>
        </mat-form-field>

    </form>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="salva()" [disabled]="formCreaAutovalutazione.invalid" class="primary-button">
            Salva
        </button>
    </div>
</div>
<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Sotto-settori" subTitolo="{{settore ? 'del settore \"' + settore.titolo + '\"' : ''}}"></app-titolo-subtitolo>
    </div>
    <app-spinner-overlay></app-spinner-overlay>
    <app-lista-tabellare class="ricerca-con-ombra" #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button mat-flat-button class="primary-button" color="primary" (click)="creaModifica()"
                *ngIf="this.utenteService.isPermessoAttivo('CREATE_CONFIGURAZIONI')">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>
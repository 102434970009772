import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ListaTabellareComponent, Filtri, Colonna, BottoniListaEvent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaBilancioSostenibilitaComponent } from './dialog/dialog-crea-bilancio-sostenibilita/dialog-crea-bilancio-sostenibilita.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { Subscription } from 'rxjs';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';

@Component({
  selector: 'app-bilancio-sostebilita',
  templateUrl: './bilancio-sostebilita.component.html',
  styleUrls: ['./bilancio-sostebilita.component.scss']
})
export class BilancioSostebilitaComponent implements AfterViewInit {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  private azienda: Azienda | undefined = undefined;

  public filtri: Filtri[] = [
    {
      titolo: 'Anno',
      forControlName: 'anno',
      input: 'text',
    }
  ];

  public colonne: { [key: string]: Colonna } = {
    anno: {
      title: 'Anno',
      value: 'anno'
    },
    settore: {
      title: 'Settore',
      value: (record: any) => (record?.settori ? record?.settori[0]?.titolo : ''),
    },
    risultatiImpegni: {
      title: 'Impegni',
      value: (record: any) => (record?.compilazioni?.impegni?.compilati || 0) + '/' + (record?.compilazioni?.impegni?.totali || 0)
    },
    risultatiAzioni: {
      title: 'Azioni',
      value: (record: any) => (record?.compilazioni?.azioni?.compilati || 0) + '/' + (record?.compilazioni?.azioni?.totali || 0)
    },
    risultatiKpi: {
      title: 'Kpi',
      value: (record: any) => (record?.compilazioni?.kpi?.compilati || 0) + '/' + (record?.compilazioni?.kpi?.totali || 0)
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => !this.utenteService.isPermessoAttivo('EDIT_BILANCIO')
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => !this.utenteService.isPermessoAttivo('DELETE_BILANCIO')
    },
  };

  private _subAziendaCorrente: Subscription | undefined;

  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private materialitaService: MaterialitaService,
    private aziendaService: AziendaService,
    private bilancioService: BilancioService,
    public utenteService: UtenteService,
    private licenzeService: LicenzeService,
  ) { }

  ngAfterViewInit() {
    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {

      let cambiato = false;

      if (this.azienda && azienda && this.azienda.id !== azienda.id) {
        cambiato = true;
      }

      this.azienda = azienda;

      if (cambiato) {
        this.tabella?.caricaDati();
      }
    });
  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.bilancioService.getBilancio(page, pageSize, ricerca, filters, sortBy);
  };

  /**
 * Metodo di modifica o creazione nuova bilancio 
 * @param record 
 */
  crea(record?: any) {
    if (!record) {
      this.licenzeService.getUserHasLicense('Bilancio').subscribe({
        next: (esito) => {
          this.apriDialogCreaBilancio(record);
        },
        error: (err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message,
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      });
    } else {
      this.apriDialogCreaBilancio(record);
    }
  }

  private apriDialogCreaBilancio(record: any) {
    const dialogCreaBilancio = this.dialog.open(DialogCreaBilancioSostenibilitaComponent, {
      data: {
        bilancio: record
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      height: '99%',
      maxHeight: '99%',
      autoFocus: false,
    });

    dialogCreaBilancio.afterClosed().subscribe((result) => {
      // Ricarica i dati della tabella dopo la chiusura del dialogo
      this.tabella.caricaDati();
    });
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'elimina':
        this._elimina(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
  * Funzione per eliminare una riga da una lista tabellare 
  * @param idRiga id della riga da eliminare 
  */
  private _elimina(bilancio: any) {
    let descrizioneElimina: string;
    let titolo: string;
    descrizioneElimina = '<div> <p><strong> Si prega di notare che questa operazione eliminerà anche tutte le compilazioni raccolte associate.</strong></p> <ul> <li>L’eliminazione è definitiva e <strong>NON può essere annullata</strong>.</li> <li>Tutte le informazioni e le compilazioni raccolte saranno perse <strong>permanentemente</strong>.</li></ul><p><strong>Sei sicuro di voler continuare?</strong></p>'
    titolo = 'Eliminazione Dati Irreversibile'


    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizioneElimina,
      fontWeight: '500',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)'
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {

            this.bilancioService.deleteBilancio(bilancio?.id).subscribe(
              {
                next: (result: any) => {
                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: 'Impossibile eliminare: ' + (err?.error?.message || 'Errore sconosciuto'),
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );

          }
        }]
    });

  }
}

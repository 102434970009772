import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Observable } from 'rxjs';
import { SottoSettore } from './settori.service';

@Injectable({
  providedIn: 'root'
})
export class SettoriWebService {

  private NOME_WS = 'settori';

  constructor(private webService: WebService) { }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.NOME_WS + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del Settore
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione postSettori per la creazione Settore
   */
  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.NOME_WS, data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.NOME_WS + '/delete', { id: idRiga })
  }

  public put(id: string, settore: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.NOME_WS + '/update', settore, {
      id
    });
  }

  public getSettoreGenerale(): Observable<any> {
    return this.webService.doGet('/api/v1/' + this.NOME_WS + '/generale', {});
  }

  /* SOTTOSETTORI */

  public getSottoSettoreByIdSettore(idSettore: string): Observable<any> {
    return this.webService.doGet('/api/v1/' + this.NOME_WS + '/' + idSettore + '/sotto-settori', {});
  }

  public deleteSottoSettore(idSettore: string, idSottoSettore: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.NOME_WS + '/' + idSettore + '/sotto-settori/' + idSottoSettore, {})
  }

  public putSottoSettore(idSettore: string, sottoSettore: SottoSettore): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.NOME_WS + '/' + idSettore + '/sotto-settori/' + sottoSettore.id, sottoSettore);
  }

  public postSottoSettore(idSettore: string, sottoSettore: SottoSettore): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.NOME_WS + '/' + idSettore + '/sotto-settori', sottoSettore);
  }

  /**
   * Recuepra un set di settori a partire dagli ambiti.
   * NOTA: Fa una POST, non una GET
   * @param idAmbiti 
   * @returns 
   */
  public getSettoriDaAmbiti(idAmbiti: string[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.NOME_WS + '/settori-da-ambiti', { idAmbiti });
  }
}

<h2 mat-dialog-title>Destinatario Policy</h2>
<div mat-dialog-content class="dialog-center-cont">
    <form [formGroup]="formDestinatarioPolicy" appFormContainer [larghezza]="2">

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Descrizione</mat-label>
            <input matInput placeholder="Descrizione" formControlName="descrizione" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Linea guida Destinatario</mat-label>
            <input matInput placeholder="Linea guida Destinatario" formControlName="lineaGuidaDestinatario" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
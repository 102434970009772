<app-spinner-overlay></app-spinner-overlay>

<app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [filtri]="filtri"
    (bottoniListaClick)="bottoniListaClick($event)">
    <div btnEnd>
        <button mat-flat-button color="primary" class="primary-button" (click)="crea()"
            *ngIf="this.utenteService.isPermessoAttivo('CREATE_UTENTI')">
            Nuovo
        </button>
    </div>
</app-lista-tabellare>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { of } from 'rxjs';

export interface DialogAlert {
  titolo?: string;
  descrizione?: string;
  bottoni: DialogAlertBtn[];
  fontWeight?: string
}

interface DialogAlertBtn {
  nome_btn: string;
  id_btn?: string;
  color?: string;
  background_color?: string;
  handler?: () => void;
}

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss']
})
export class DialogAlertComponent implements OnInit {

  public titolo: SafeHtml = '';
  public descrizione: SafeHtml = '';
  public fontWeight: SafeHtml = '';
  public bottoni!: DialogAlertBtn[];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogAlertComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: DialogAlert

  ) { }

  ngOnInit(): void {
    this.titolo = this.getTraduzione(this.data.titolo || '');
    this.descrizione = this.getTraduzione(this.data?.descrizione || '');
    this.bottoni = this.data.bottoni || [];
    this.fontWeight = this.data.fontWeight || 'bold'
    
    this.bottoni.map((bottone) => {
      if (bottone.id_btn === "N" && !bottone.background_color) {
        bottone.background_color = 'var(--mat-color-accent)';
      }
    });

  }

  getTraduzione(data?: string): SafeHtml {
    if (data) {
      return this.sanitizer.bypassSecurityTrustHtml(data);
    } else {
      return '';
    }
  }

}

<div class="contenitore">
    <app-spinner-overlay></app-spinner-overlay>
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Assegnazioni Figure Aziendali"></app-titolo-subtitolo>
    </div>
    <div style="padding: 10px 0 15px 0">
        <div class="cont-filtri">
            <mat-form-field style="width: 100%;" class="ricerca-input">
                <input matInput placeholder="Inserisci chiave di ricerca" [(ngModel)]="searchValue"
                    (keyup)="onSearchInputChange($event)">
                <mat-icon matSuffix style="color: var(--mat-color-primary);">search</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div style="display: flex; flex-direction: column; row-gap: 8px;">

        <div class="cont-titolo">
            <mat-label class="titolo">Tematiche</mat-label>
        </div>
        <div>
            <mat-accordion>
                <mat-expansion-panel *ngFor="let assoc of filteredDatiAssociati; first as isFirst" [expanded]="isFirst">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="font-weight: bold;">
                            {{assoc?.domanda}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table mat-table [dataSource]="assoc.elementi ? assoc.elementi: []" *ngIf="assoc.elementi">

                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef>
                                Tipo informazione
                            </th>
                            <td mat-cell *matCellDef="let elem">
                                <div style="display: flex;align-items: center;">
                                    {{ elem?.tipo?.descrizione }}

                                    <mat-icon *ngIf="data.contesto === 'piano'"
                                        class="material-icons-outlined icone-tem icone-label-chack"
                                        style="color: var(--grigio);padding: 0px; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; transform: scale(0.8);"
                                        [matTooltip]="elem?.titolo">
                                        info
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nomeBreve">
                            <th mat-header-cell *matHeaderCellDef>Nome Breve</th>
                            <td mat-cell *matCellDef="let elem">{{ elem?.nomeBreve }}</td>
                        </ng-container>
                        <ng-container matColumnDef="figura">
                            <th mat-header-cell *matHeaderCellDef>Figura</th>
                            <td mat-cell *matCellDef="let elem">{{ elem?.figura?.figura }}</td>
                        </ng-container>
                        <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef>Nome</th>
                            <td mat-cell *matCellDef="let elem">{{ elem?.figura?.nome }}</td>
                        </ng-container>
                        <ng-container matColumnDef="azioni">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let elem">
                                <div appFormElement [larghezza]="0" [minWidth]="50">
                                    <button class="icone-button" mat-icon-button *ngIf="data.stato !== 'PUBBLICATO'"
                                        (click)="modifica(assoc.idTematica ,elem)" title="Modifica"
                                        [attr.aria-label]="'Modifica'">
                                        <mat-icon class="icone">
                                            edit
                                        </mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"
                            [ngClass]="{ 'missing-extension': !row.Extension }">
                        </tr>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </div>
    <footer mat-dialog-actions class="dialog-footer-cont">
        <div style="padding-top: 20px;">
            <button mat-flat-button matDialogClose color="accent" class="accent-button"
                (click)="chiudi()">Chiudi</button>
        </div>
    </footer>
</div>
import { ComitatiService } from './../../../../../services/comitati/comitati.service';
import { Component, Inject, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-comitati',
  templateUrl: './dialog-crea-comitati.component.html',
  styleUrls: ['./dialog-crea-comitati.component.scss']
})
export class DialogCreaComitatiComponent implements AfterViewInit {


  public formComitati = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    guidaAllaScrittura: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    compilato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    figura: new FormControl<any>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    idAzienda: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaComitatiComponent>,
    private comitatiService: ComitatiService,
    private utilityService: UtilityService,
    private policyService: PolicyService,

    @Inject(MAT_DIALOG_DATA) public data: {
      comitati: any,
      idAzienda: string,
    }
  ) { }

  ngAfterViewInit(): void {
    if (this.data?.comitati) {
      Object.keys(this.data.comitati).forEach((value, index, array) => {
        this.formComitati.get(value)?.setValue((this.data.comitati)[value]);
      })
    }
  }

  public salva() {

    if (this.formComitati.valid) {

      const id = this.formComitati.get('id')?.value;

      if (this.data.idAzienda) {
        this.formComitati.get('idAzienda')?.setValue(this.data.idAzienda)
      }

      if (id) {
        return this.comitatiService.putComitati(id, this.formComitati.getRawValue()).subscribe(
          {
            next: (result) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close(result);
            },
            error: (err: any) => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio' + err,
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }
          }
        );
      } else {
        return this.comitatiService.postComitati(this.formComitati.getRawValue()).subscribe(
          {
            next: (result) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close(result);
            },
            error: () => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formComitati.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }
}

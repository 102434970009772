import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AzioneBP, AzioneEBuonaPraticaService } from 'src/app/services/azione-e-buona-pratica/azione-e-buona-pratica.service';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-cfg-azione-e-buona-pratica',
  templateUrl: './dialog-crea-cfg-azione-e-buona-pratica.component.html',
  styleUrls: ['./dialog-crea-cfg-azione-e-buona-pratica.component.scss']
})
export class DialogCreaCfgAzioneEBuonaPraticaComponent {
  public selectedSettori: string[] = [];
  public arrayStdr: {
    chiave: string;
    descrizione: string;
  }[] = [];;
  public arraySettori: Settore[] = [];

  /* Form Controls */
  public formAzioneBP = new FormGroup({


    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    settori: new FormControl<Settore[]>([], {
      nonNullable: true,
    }),

    stdr: new FormControl<string[]>([]),

    note: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    tag: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    compilato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    figura: new FormControl<any>(undefined, {
      nonNullable: true,
      validators: [],
    }),

  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaCfgAzioneEBuonaPraticaComponent>,
    public azioneBPService: AzioneEBuonaPraticaService,
    private utilityService: UtilityService,
    private settoriService: SettoriService,
    private tematicaService: TematicheService,
    private sintesiService: SintesiService,
    private bilancioService: BilancioService,
    private policyService: PolicyService,
    private pianoService: PianoService,
    @Inject(MAT_DIALOG_DATA) public data: {
      azioneBP: AzioneBP,
      idContest: string,
      idAmbito: string,
      idTematica: string,
      contesto: string,
      idObiettivo: string,
    }
  ) {
    if (this.data?.azioneBP) {
      Object.keys(this.data.azioneBP).forEach((value, index, array) => {
        this.formAzioneBP.get(value)?.setValue((this.data.azioneBP as any)[value]);
      })
    }
    this._getSettori();
    this.getStdr();
  }
  /**
   * Funzione di salvataggio di un nuovo AzioneBP
   * @returns 
   */
  compareSettori(settore1: Settore, settore2: Settore): boolean {
    return settore1.id === settore2.id; // Sostituisci "id" con la proprietà univoca degli AzioneBP
  }

  public salva() {


    if (this.formAzioneBP.valid) {

      const id = this.formAzioneBP.get('id')?.value;

      // Se sono in contesto: sintesi, bilancio, policy etc...
      if (this.data.idContest) {
        return this.salvaInContesto();
      }


      if (id) {
        return this.azioneBPService.putAzioneBP(id, this.formAzioneBP.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio dell\'AzioneBP:', err);

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio dell\'AzioneBP',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {
        return this.azioneBPService.postAzioneBP(this.formAzioneBP.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio degli AzioneBP:', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio degli AzioneBP',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formAzioneBP.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }
  private _getSettori() {

    this.settoriService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'desc'
    }]).subscribe({
      next: (result: any) => {


        this.arraySettori = result?.content || [];
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento dei settori:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei settori',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  private getStdr() {
    this.tematicaService.getStdr().subscribe({
      next: (result: any) => {


        this.arrayStdr = result || [];
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento dei stdr:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei stdr',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }


  /**
     * Metodo che mi salva una nuova azione e buona pratica effimera in bilancio/sintesi
     * @returns 
     */
  public salvaInContesto() {

    if (!this.data.idAmbito) {
      this.mostraMessaggio('Attenzione', 'Ambito non trovato o non riconosciuto');
      return;
    } else if (!this.data.idTematica) {
      this.mostraMessaggio('Attenzione', 'Tematica non trovata o non riconosciuta');
      return;
    }

    if (!this.formAzioneBP.get('id')?.value) {
      this.formAzioneBP.get('creatoDaContesto')?.setValue(true);
    }

    let serviceCall;

    if (this.data.contesto == 'sintesi') {
      serviceCall = this.sintesiService.putAzioneBPEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formAzioneBP.getRawValue());
    } else if (this.data.contesto == 'bilancio') {
      serviceCall = this.bilancioService.putAzioniBPEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formAzioneBP.getRawValue());
    } else if (this.data.contesto == 'policy') {
      serviceCall = this.policyService.putAzioniBPEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formAzioneBP.getRawValue());
    } else if (this.data.contesto == 'piano') {
      serviceCall = this.pianoService.putTargetQualitativiEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.data.idObiettivo,
        this.formAzioneBP.getRawValue());
    }

    if (serviceCall) {
      serviceCall.subscribe({
        next: (result: any) => {
          this.mostraMessaggioSnackbar('Salvataggio effettuato');
          this.dialogRef.close(result);
        },
        error: (err: any) => {
          console.error('Errore durante il salvataggio dell\'azione buona pratoca:', err);
          this.mostraMessaggio('Attenzione', 'Errore nel salvataggio dell\'azione buona pratoca');
        }
      });
    }
  }

  private mostraMessaggio(titolo: string, descrizione: string) {
    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      bottoni: [{ nome_btn: 'Chiudi' }]
    });
  }

  private mostraMessaggioSnackbar(messaggio: string) {
    this.utilityService.opneSnackBar(messaggio, '', {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }
}
<app-spinner-overlay></app-spinner-overlay>
<div>
    <header>
        <img [src]="logoPath" alt="Sostenibile" class="logo-path" />

    </header>
    <div *ngIf="isAnteprima" class="anteprima">Anteprima</div>
</div>
<div class="contenitore" *ngIf="!completato">
    <ng-scrollbar>
        <div class="contenuto">
            <app-compila-sintesi-sostenibilita [idSintesiSostenibilita]="idBilancioSostenibilita"
                [esercizio]="undefined" [compilazione]="compilazione" *ngIf="compilazione" [salvaCompilazione]="salva"
                [isAnteprima]="isAnteprima" titolo="Bilancio" [landingPage]="landingPage"  (loading)="loading($event)"
                [temporaryToken]="temporaryToken" [isCompEsterna]="true"></app-compila-sintesi-sostenibilita>
        </div>
    </ng-scrollbar>
</div>

<div class="compilato" *ngIf="completato">
    {{messaggio}}
</div>
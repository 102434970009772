import { Injectable } from '@angular/core';
import { InformativaAziendaleWebService } from './informativa-aziendale-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

export interface InformativaAziendale {
  titolo: string;
  /* status: string; */
  chiave: string;
  codiceLingua: string;
  descrizione: string;
  id: string;
  idInformativa: string;
  sezioni: Sezioni[];
  stato: string;
  versione: number;
}

export interface Sezioni {
  chiave: string;
  descrizione: string;
  domande: Domande[];
  id: string;
  nomeBreve: string;
  punteggio: string;
  punteggioMaxOttenibile: string;
  punteggioPercentuale: string;
  titolo: string;
}

export interface Domande {
  chiave: string;
  domanda: string;
  id: string;
  note: string;
  risposta: string | { valore?: string, um?: string };
  risposte: string[];
  data: number | Date; //long
  idCategoriaUnitaDiMisura: string;
  idUnitaDiRiferimento: string;
  obbligatorio: boolean;
  opzioni: Opzioni[];
  sottodomanda: boolean;
  tipo: "testo" | "scelta_singola" | "scelta_multipla" | "scelta_miltipla_valore" | "numero_intero" | "numero_decimale" | "data";
}

export interface Opzioni {
  chiave: string;
  id: string;
  valore: string;
}

@Injectable({
  providedIn: 'root'
})
export class InformativaAziendaleService {
  constructor(private informativaAziendale: InformativaAziendaleWebService) { }

  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public get(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.informativaAziendale.get(page, pageSize, ricerca, filters, sortBy);
  }

  public getBozza() {
    return this.informativaAziendale.getBozza();
  }

  /**
     * Funzine ws per la creazione del Ambito
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postAmbiti per la creazione Ambito
     */
  public post(data: any) {
    return this.informativaAziendale.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public delete(idRiga: any) {
    return this.informativaAziendale.delete(idRiga);
  }

  /**
 * Info Az
 * @param id 
 * @param informazioni 
 * @returns 
 */
  public put(id: string, informazioni: any) {
    return this.informativaAziendale.put(id, informazioni);
  }

  public putPubblica(id: string) {
    return this.informativaAziendale.putPubblica(id);
  }


  public getInformativaAziendaleEsterna(temporaryToken: string) {
    return this.informativaAziendale.getInformativaAziendaleEsterna(temporaryToken);
  }

  public putCompilaInformativaAziendaleEsterna(temporaryToken: string, informazioni: any) {
    return this.informativaAziendale.putCompilaInformativaAziendaleEsterna(temporaryToken, informazioni);
  }
}

<div class="contenitore">

    <div class="titolo-pagina">

        <app-titolo-subtitolo titolo="Figure Aziendali"
            subTitolo="Crea e configura le figure aziendali."></app-titolo-subtitolo>

    </div>

    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        *ngIf="idAzienda" [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button mat-flat-button class="primary-button" color="primary" (click)="creaNuovo()">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>
import { Injectable } from '@angular/core';
import { UnitaDiMisuraWebService } from './unita-di-misura-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

export interface UnitaDiMisura {
  id?: string,
  nomeBreve?: string,
  descrizione?: string
}

export interface CategorieUm {
  id?: string,
  nome?: string,
  unitaDiMisura?: UnitaDiMisura[]
}

export interface UnitaDiMisuraDTO extends UnitaDiMisura {
  idCategoriaUnitaDiMisura: string;
}

@Injectable({
  providedIn: 'root'
})
export class UnitaDiMisuraService {

  constructor(private unitaDiMisuraWebService: UnitaDiMisuraWebService) { }
  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getUnitaDiMisura(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.unitaDiMisuraWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
   * Recupera le info per unità di misura usando però un token temporaneo (es: compilazione da esterno)
   * 
   * @param temporaryToken 
   * @param page 
   * @param pageSize 
   * @param ricerca 
   * @param filters 
   * @param sortBy 
   * @returns 
   */
  public getUnitaDiMisuraConTempToken(
    temporaryToken: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.unitaDiMisuraWebService.getTempToken(temporaryToken, page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del unitaDiMisura
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postunitaDiMisura per la creazione unitaDiMisura
     */
  public postUnitaDiMisura(unitaDiMisura: UnitaDiMisuraDTO) {
    return this.unitaDiMisuraWebService.post(unitaDiMisura);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param datiRiga dati della riga da eliminare
    * @returns 
    */
  public deleteUnitaDiMisura(datiRiga: any) {
    return this.unitaDiMisuraWebService.delete(datiRiga);
  }

  /**
 * unitaDiMisura
 * @param id 
 * @param unitaDiMisura 
 * @returns 
 */
  public putUnitaDiMisura(id: string, unitaDiMisura: any) {
    return this.unitaDiMisuraWebService.put(id, unitaDiMisura);
  }

  /**
 * Metodo che modifica la categoria dentro l'unità di misura 
 * @param idCategoriaUnitaDiMisura 
 * @returns 
 */
  public putCategoria(categoriaUnitaDiMisura: CategorieUm) {
    return this.unitaDiMisuraWebService.putCategoria(categoriaUnitaDiMisura);
  }
  /**
 * Metodo che crea la categoria dentro l'unità di misura 
 * @param idCategoriaUnitaDiMisura 
 * @returns 
 */
  public postCategoria(categoriaUnitaDiMisura: CategorieUm) {
    return this.unitaDiMisuraWebService.postCategoria(categoriaUnitaDiMisura);
  }

  public getCategoria() {
    return this.unitaDiMisuraWebService.getCategorie();
  }

}

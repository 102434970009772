import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { BehaviorSubject, Observable, Subscription, catchError, delay, finalize, of } from "rxjs";
import { Colonna } from "../lista-tabellare.component";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSort, SortDirection } from "@angular/material/sort";

interface ResponseHttp {
    totalPages: number;
    totalElements: number;
    pageable: {
        pageNumber: number;
        pageSize: number;
        offset: number;
        sort: {
            sorted: boolean;
            empty: boolean;
            unsorted: boolean;
        },
        paged: boolean;
        unpaged: boolean;
    },
    first: number;
    last: number;
    size: number;
    content: any[]
    number: number;
    sort: {
        sorted: boolean;
        empty: boolean;
        unsorted: boolean;
    },
    numberOfElements: number;
    empty: boolean;
}

export interface FnCaricamentoDati {
    (
        page: number,
        pageSize: number,
        ricerca?: string,
        filters?: Filters[],
        sortBy?: SortBy[]
    ): Observable<ResponseHttp>
}

export interface FnCaricamentoStyle {
    (record: any): { [key: string]: any }
}

export interface Filters {
    values: any[];
    operator: 'eq' | 'bet' | 'lt' | 'gt' | 'lte' | 'gte' | 'ne' | 'lk' | 'is' | 'eqOrEmpty';
    chiave: string
}

export interface SortBy {
    chiave: string;
    sort: SortDirection;
}

export class ListaTabellareDataSource implements DataSource<any> {

    private _dataSubject = new BehaviorSubject<any[]>([]);
    private _loadingSubject = new BehaviorSubject<boolean>(false);
    private _chiamataRicercaSubscription!: Subscription;
    private _keyColonneValue: string[] = []; // chiavi di colonne di tipo 'value'

    public loading$ = this._loadingSubject.asObservable().pipe(delay(0));
    private paginator!: MatPaginator;
    private sort!: MatSort;


    connect(collectionViewer: CollectionViewer): Observable<readonly any[]> {
        return this._dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this._dataSubject.complete();
        this._loadingSubject.complete();
    }

    constructor(
        private fnCaricamentoDati: FnCaricamentoDati,
        private colonne: { [key: string]: Colonna },
        private triggerLoading = true,
        private sanitizer: DomSanitizer,
        private fnCaricamentoStyle?: FnCaricamentoStyle,
    ) {
        this._keyColonneValue = Object.keys(this.colonne).filter(value => this.colonne[value].type === 'value' || !this.colonne[value].type);
    }


    public caricaDati(
        ricerca?: string,
        filters?: Filters[]
    ) {

        if (this.triggerLoading) {
            this._loadingSubject.next(true);
        }

        let sortData: SortBy[] | undefined = undefined;

        if (this.sort.active && this.sort.active !== 'created' && this.sort.direction) {

            // Le colonne da ordinare sono quelle della chaive campiSort, se c'è, o direttamente la colonna indicata dal valore this.sort.active.
            let campiSort: string[] | undefined = this.colonne[this.sort.active].campiSort;

            if (!campiSort && typeof this.colonne[this.sort.active].value === 'string') {
                campiSort = [this.colonne[this.sort.active].value as string];
            }

            if (!campiSort) {
                campiSort = [this.sort.active];
            }

            sortData = campiSort.map((sortVal): SortBy => {
                return {
                    chiave: sortVal,
                    sort: this.sort.direction
                };
            });

        }

        if (this._chiamataRicercaSubscription && !this._chiamataRicercaSubscription.closed) {
            this._chiamataRicercaSubscription.unsubscribe();
        }

        this._chiamataRicercaSubscription = this.fnCaricamentoDati(
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
            ricerca,
            filters,
            sortData || undefined
        ).pipe(
            catchError((error) => {
                console.error('ERRORE fnCaricamentoDati : ', error);
                return of(null);
            }),
            finalize(() => {
                if (this.triggerLoading) {
                    this._loadingSubject.next(false);
                }
            })
        ).subscribe((response) => {
            if (response) {
                this.paginator.length = response.totalElements;

                const dati = response.content || [];

                dati.forEach(record => {
                    this._keyColonneValue.forEach(keyColonna => {
                        if (typeof this.colonne[keyColonna].value === 'function') {
                            record['_col_val_' + keyColonna] = this.sanitizer.bypassSecurityTrustHtml((this.colonne[keyColonna].value as Function)(record));
                        } else if (typeof this.colonne[keyColonna].value === 'string') {
                            record['_col_val_' + keyColonna] = record[this.colonne[keyColonna].value as string]
                        } else {
                            record['_col_val_' + keyColonna] = this.colonne[keyColonna].value
                        }

                    });
                    if (this.fnCaricamentoStyle) {
                        record['_style_riga'] = this.fnCaricamentoStyle(record);
                    }
                });

                this._dataSubject.next(dati);
            }
        })
    }

    public setLoading(isLoading: boolean) {
        this._loadingSubject.next(isLoading);
    }

    public setPaginator(paginator: MatPaginator) {
        this.paginator = paginator;
    }

    public setSort(sort: MatSort) {
        this.sort = sort;
    }


}

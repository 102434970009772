<div class="contenitore">
    <app-spinner-overlay></app-spinner-overlay>
    <div class="sezione-tabella" [ngStyle]="{ 'row-gap.px': erroreGetSurvey ? 150 : 5 }">
        <!-- ------------------------  H E A D E R   A C C O R D I O N ---------------------- -->
        <div class="cont-titolo">
            <div style="display: flex;
            align-items: center;
            justify-content: center;">
                <div class="titolo" style="font-size: 14px !important;DISPLAY: FLEX;
                ALIGN-ITEMS: CENTER;
                JUSTIFY-CONTENT: CENTER;">Risultati Survey

                    <button mat-icon-button aria-label="Informazioni Analisi Risultati" (click)="apriInfo()">
                        <mat-icon class="material-icons-outlined icone-label-chack">
                            info</mat-icon>
                    </button>
                </div>

            </div>

            <div>
                <div>
                    <button mat-flat-button color="accent" (click)=" impostaEsclud()" class="imp-esclud">
                        Imposta Esclusioni
                    </button>
                </div>
            </div>
        </div>

        <!-- ------------------------ A C C O R D I O N ---------------------- -->
        <mat-accordion class="example-headers-align" *ngIf="risultatiSurvey">
            <ng-scrollbar>

                <mat-expansion-panel *ngFor="let tematica of risultatiSurvey.tematiche ; let idxTematica=index"
                    [expanded]="(idxTematica === apriExpandTematica)"
                    [ngClass]="tematica.raggruppata ? 'disabled-expand': ''" (afterCollapse)="isCollapseFunx()"
                    (afterExpand)="isExpandFunx()">

                    <mat-expansion-panel-header [expandedHeight]="'auto'">
                        <mat-panel-title>
                            <!-- punto colore -->
                            <div [ngStyle]="{backgroundColor: puntoColoreTematica[idxTematica], borderColor:puntoColoreTematicaBorder[idxTematica]}"
                                style="flex: 0 0 auto;
                            width: 8px;
                        height: 8px;
                        border-radius: 50%;border: solid; border-width: 2px;"></div>
                            <div class="titolo-tematica"
                                [ngClass]="{'colore-in-range': (tematica.medieRaggr.interni >= interniValue && tematica.medieRaggr.esterni >= esterniValue) && (tematica.raggruppata)}">
                                {{tematica.titolo}} <span><mat-icon class="icona-check"
                                        *ngIf="(tematica.medieRaggr.interni >= interniValue && tematica.medieRaggr.esterni >= esterniValue) && (tematica.raggruppata)">check</mat-icon></span>
                            </div>
                        </mat-panel-title>
                        <mat-slide-toggle [checked]="tematica.raggruppata" [id]="tematica.idTematica" color="accent"
                            hideIcon="true" (change)="raggruppaImpatti($event, idxTematica)"
                            (click)="$event.stopPropagation()">
                        </mat-slide-toggle>
                    </mat-expansion-panel-header>

                    <div *ngFor="let impatto of tematica.impatti ; let idxImpatto=index; let first = first"
                        class="impatti">
                        <div style="padding: 10px 0 0 0;" *ngIf="!first">
                            <hr style="width: 100%;  border-top: 1px solid #dedede;"> <!-- SEPARATORE -->
                        </div>

                        <div style="font-weight: bold;"
                            [ngClass]="{'colore-in-range': impatto.valori.interni.media >= interniValue && impatto.valori.esterni.media >= esterniValue }">
                            {{impatto.titolo}} <span><mat-icon class="icona-check"
                                    *ngIf="impatto.valori.interni.media >= interniValue && impatto.valori.esterni.media >= esterniValue">check</mat-icon></span>
                        </div>
                        <div class="punteggio">
                            <!-- CATEGORIE -->
                            <div [matTooltip]="getTooltipText(impatto.valori.interni.categorie)"> Interni:
                                {{impatto.valori.interni.media}}

                            </div>
                            <div [matTooltip]="getTooltipText(impatto.valori.esterni.categorie)"> Esterni:
                                {{impatto.valori.esterni.media}}
                            </div>
                        </div>

                    </div>
                </mat-expansion-panel>
            </ng-scrollbar>
        </mat-accordion>
        <!-- ------------------------------------------------- -->
        <div *ngIf="erroreGetSurvey" class="nessuna-compilazione">
            Nessuna compilazione trovata
        </div>
    </div>
    <!-- ------------------------ G R A F I C O ---------------------- -->
    <div class="sezione-grafico">
        <div class="grafico-analisi">
            <div class="titolo-grafico">
                Grafico di Analisi
            </div>
            <div class="grafico">

                <canvas baseChart height="2" width="4" class="chart" [data]="scatterChartData"
                    [options]="scatterChartOptions" [type]="scatterChartType" [plugins]="scatterChartPlugins">
                </canvas>

            </div>
        </div>
        <!-- ------------------------ S L I D E R S ---------------------- -->
        <div class="sliders">
            <div class="slider-uno">
                <div class="sl1">
                    <mat-slider color="accent" style="margin: 8px;" [disabled]="interniDisabled" [max]="interniMax"
                        [min]="interniMin" [step]="interniStep" [showTickMarks]="interniShowTicks">
                        <input matSliderThumb [(ngModel)]="interniValue" #sliderInterni
                            (ngModelChange)="updateQuadranti()" (dragEnd)="salvaSoglie()">
                    </mat-slider>
                    <div class="valore-slide">
                        <label style="font-weight: bold; font-size: 14px;"> {{sliderInterni.value}}</label>
                    </div>
                </div>
                <div class="descrizione ">
                    Soglia Stakeholder Interni
                </div>
            </div>

            <div class="slider-due">
                <div class="sl1">
                    <mat-slider color="accent" style="margin: 8px;" [disabled]="esterniDisabled" [max]="esterniMax"
                        [min]="esterniMin" [step]="esterniStep" [showTickMarks]="esterniShowTicks">
                        <input matSliderThumb [(ngModel)]="esterniValue" #sliderEsterni
                            (ngModelChange)="updateQuadranti()" (dragEnd)="salvaSoglie()">
                    </mat-slider>
                    <div class="valore-slide">
                        <label style="font-weight: bold;  font-size: 14px;">{{sliderEsterni.value}}</label>
                    </div>
                </div>

                <div class="descrizione ">
                    Soglia Stakeholder Esterni
                </div>
            </div>

        </div>

    </div>
</div>
import { SurveyWebService } from './survey-web.service';
import { Injectable } from '@angular/core';
import { Survey } from '../materialita/materialita.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private surveyWebService: SurveyWebService) { }

  public getDatiSurvey(idMaterialita: string, idStakeholder: string, idSurvey: string) {
    return this.surveyWebService.get(idMaterialita, idStakeholder, idSurvey);

  }
  public getSurveyCompilata(idMaterialita: string, idSurveyCompilato: string) {
    return this.surveyWebService.getSurveyCompilata(idMaterialita, idSurveyCompilato);
  }

  public postDatiSurvey(idMaterialita: string, idStakeholder: string, idSurvey: string, surveyCompilata: Survey) {
    return this.surveyWebService.post(idMaterialita, idStakeholder, idSurvey, surveyCompilata);
  }

  /**
   * Ritorna la Survey della Materialità associata per la quale è stata richiesta la compilazione
   * @param token informazioni sono reperite all'interno del temporary token ( dura 24h )
   * @returns 
   */
  public getSurveyEsterna(token: string) {
    return this.surveyWebService.getSurveyEsterna(token);
  }

  /**
   * Manda la Survey della Materialità compilata per la quale è stata richiesta la compilazione
   * @param token informazioni sono reperite all'interno del temporary token ( dura 24h )
   * @param survey la survey che è  stata compilata
   * @returns 
   */
  public postSurveyEsterna(token: string, survey: any) {
    return this.surveyWebService.postSurveyEsterna(token, survey);
  }
}

<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Unità di Misura"
            subTitolo="Unità di misura previste per gli indicatori"></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd style="display: flex;justify-content: center; column-gap: 23px;">
            <div>
                <button mat-flat-button color="accent" class="accent-button" (click)="importaUnitaDM()"
                    *ngIf="this.utenteService.isPermessoAttivo('CREATE_CONFIGURAZIONI')">
                    Importa
                    <mat-icon>
                        file_upload
                    </mat-icon>
                </button>

            </div>
            <button mat-flat-button class="primary-button" color="primary" (click)="creaNuovaUnitaDiMisura()"
                *ngIf="this.utenteService.isPermessoAttivo('CREATE_CONFIGURAZIONI')">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>
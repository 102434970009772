<ng-scrollbar>
    <app-spinner-overlay></app-spinner-overlay>
    <div *ngIf="datiSurvey">
        <div class="contenuto">
            <app-anteprima-survey [idSurveyCompilato]="data.idSurveyCompilato" #survey *ngIf="struttraSurvey"
                [datiSurvey]="struttraSurvey" [landingPage]="landingPage"></app-anteprima-survey>
        </div>

        <!----------------------------- F O O T E R ------------------------>
        <div mat-dialog-actions class="dialog-footer-cont">

            <div *ngIf="!data.idSurveyCompilato">
                <button mat-flat-button color="primary" (click)="salva()" class="primary-button">
                    Salva
                </button>
            </div>

            <div *ngIf="data.idSurveyCompilato">
                <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
            </div>
        </div>
    </div>

    <div class="no-dati" *ngIf="!datiSurvey">
        <div class="no-dati">
            {{erroreDatiSurvey}}
        </div>
    </div>
</ng-scrollbar>
<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Stakeholder" subTitolo="Gestione degli stakeholder"></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">

        <div btnEnd style="display: flex;justify-content: center; column-gap: 23px;">
            <div>
                <button mat-flat-button color="accent" class="accent-button" (click)="importaStakeHolder()"
                    *ngIf="this.utenteService.isPermessoAttivo('CREATE_STAKEHOLDERS')">
                    Importa
                    <mat-icon>
                        file_upload
                    </mat-icon>
                </button>

            </div>
            <button mat-flat-button color="primary" class="primary-button" (click)="creaNuovo()"
                style="margin: 0 20px 0 0;" *ngIf="this.utenteService.isPermessoAttivo('CREATE_STAKEHOLDERS')">
                Nuovo

            </button>
        </div>
    </app-lista-tabellare>
</div>
import { NgStarRatingService } from 'src/app/services/ng-star-rating/ng-star-rating.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaterialitaService, Survey } from 'src/app/services/materialita/materialita.service';
import { SurveyService } from 'src/app/services/survey/survey.service';
import { AnteprimaSurveyComponent } from 'src/app/components/anteprima-survey/anteprima-survey.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-survey-esterno',
  templateUrl: './survey-esterno.component.html',
  styleUrls: ['./survey-esterno.component.scss']
})
export class SurveyEsternoComponent implements OnInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild('survey') surveyComponent!: AnteprimaSurveyComponent;

  public completato: boolean = false;
  public temporaryToken: string = "";
  public idSurvey: string = "";
  public idMaterialita: string = "";
  public idAzienda: string = "";
  public logoPath: string = 'assets/images/logo/logo-sostenibile.png';
  public messaggio: string = '';
  public landingPage: string = '';
  public surveyEsterna: Survey | undefined = undefined;
  public surveyVuoto: boolean = false;
  public anteprima: boolean = false;

  constructor(private route: ActivatedRoute,
    private surveyService: SurveyService,
    public ngStarRatingService: NgStarRatingService,
    private utilityService: UtilityService,
    private materialitaService: MaterialitaService,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.parametriDaURL();
    
  }

  /**
   * Metodo che mi prende il token dal URL
   */
  public parametriDaURL() {
    // Sottoscrivi all'observable paramMap per ottenere i parametri dall'URL
    this.spinnerOver.show();
    this.route.queryParams.subscribe(params => {
      // Ora puoi accedere ai parametri come segue
      this.temporaryToken = params['temporaryToken'];
      this.idMaterialita = params['id'];
      this.idSurvey = params['idS'];
      this.idAzienda = params['idA'];
    });
    if (this.temporaryToken) {
      this._getSurveyByTemporaryToken();
    } else if (this.idSurvey && this.idMaterialita && this.idAzienda) {
      this._getAnteprimaSurvey()
    } else {
      this.spinnerOver.hide();
    }
  }
  /**
    * Metodo che mi salva la survey compilata
    */
  salva() {
    

    if (!this.controlloPunteggioObbligatorio(this.surveyComponent.datiSurvey)) {
      this.spinnerOver.show();
      this.surveyService.postSurveyEsterna(this.temporaryToken, this.surveyComponent.datiSurvey).subscribe({
        next: (esito) => {
           
          this.messaggio = "Survey compilata. Grazie della sua disponibilità."
          this.spinnerOver.hide();
        },
        error: (err) => {
          console.error(err);
          this.messaggio = err.error.message;
          this.spinnerOver.hide();
        }
      })
      this.completato = true;
    } else {
      this.utilityService.opneSnackBar('Rispondi a tutti gli impatti', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  };

  /**
   * Metodo che mi prende i dati della survey dal token
   */
  private _getSurveyByTemporaryToken() {
    this.anteprima = false;
    this.surveyService.getSurveyEsterna(this.temporaryToken).subscribe({
      next: (esito) => {
        this.surveyEsterna = esito.questionarioDaCompilare;
        this.landingPage = esito.landing;
        this.surveyVuoto = false;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);

        this.messaggio = err?.error?.message || "Nessun dato trovato"

        this.surveyVuoto = true;
        this.spinnerOver.hide();
      }
    })
  }

  private _getAnteprimaSurvey() {
    this.anteprima = true;

    this.materialitaService.getAnteprimaMaterialitaCompilata(this.idMaterialita, this.idAzienda, this.idSurvey).subscribe({
      next: (esito) => {
         
        this.surveyEsterna = esito.questionarioDaCompilare;
        this.landingPage = esito.landing;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
        this.messaggio = err?.error?.message || "Nessun dato trovato"
        this.surveyVuoto = true;
      },
    })


  }


  /**
   * Metodo che mi controlla se sono presente tutte le risposte 
   * @param survey 
   * @returns 
   */
  controlloPunteggioObbligatorio(survey: Survey) {
    for (let ambiti of survey.ambiti) {
      for (let tematica of ambiti.tematiche) {
        for (let impatto of tematica.impatti) {
          if (!impatto.valoreAttribuito) {
            let el = document.getElementById(tematica.id);
            el?.scrollIntoView({ behavior: 'smooth' });
            return true;
          }
        }
      }
    }
    return false;
  }

}

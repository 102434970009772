<h2 mat-dialog-title>Settore</h2>
<div mat-dialog-content class="dialog-center-cont">
    <app-spinner-overlay></app-spinner-overlay>
    <form [formGroup]="formSottoSettori" appFormContainer [larghezza]="1">

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Sotto-settore </mat-label>
            <input matInput placeholder="Sotto-settore" formControlName="titolo" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
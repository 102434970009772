<h2 mat-dialog-title>Azioni e Buone Pratiche</h2>
<div mat-dialog-content class="dialog-center-cont">
    <form [formGroup]="formAzioneBP" appFormContainer [larghezza]="4">

        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
            <mat-label>Titolo</mat-label>
            <input matInput placeholder="Titolo" formControlName="titolo" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
            <mat-label>Descrizione</mat-label>
            <input matInput placeholder="Descrizione" formControlName="descrizione">
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field class="input-form" appFormElement [larghezza]="2" [riempi]="true"
            *ngIf="!this.data.contesto">
            <mat-label>Settori</mat-label>
            <mat-select formControlName="settori" placeholder="Settori" [(value)]="selectedSettori" multiple
                [compareWith]="compareSettori">
                <mat-option *ngFor="let set of arraySettori" [value]="set">{{set.titolo}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="2"
            *ngIf="!this.data.contesto ">
            <mat-label>Standard di rendicontazione</mat-label>
            <mat-select formControlName="stdr" placeholder="Standard di rendicontazione" multiple>
                <mat-option *ngFor="let stdr of arrayStdr" [value]="stdr.chiave">{{stdr.descrizione}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="4" [riempi]="true">
            <mat-label>Note</mat-label>
            <textarea matInput placeholder="Note" formControlName="note" rows="4"></textarea>
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="3" [riempi]="true"
            *ngIf="!this.data.contesto">
            <mat-label>TAG</mat-label>
            <input matInput placeholder="TAG" formControlName="tag" />
            <mat-error appFormError></mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
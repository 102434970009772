import { Injectable } from '@angular/core';
import { AziendaService } from '../azienda/azienda.service';
import { WebService } from '../web/web.service';

@Injectable({
  providedIn: 'root'
})
export class ImportazioniWebService {
  private _idAzienda: string | undefined = undefined;
  constructor(
    private aziendaService: AziendaService,
    private webService: WebService
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }

  public uploadFile(file: File, data: { [key: string]: string }, azienda: boolean, nomeImport: string) {
    let aziendaWs = '';
    if (azienda) {
      aziendaWs = 'azienda/' + this._idAzienda + '/'
    }

    return this.webService.uploadFile('/api/v1/' + aziendaWs + nomeImport + '/import-excel', file, data);
  }

  //LOGO AZIENDA SU CREAZIONE AZIENDA 
  public uploadLogo(logo: File, data: { [key: string]: string }, idAzienda: string,) {
    return this.webService.uploadImgPut('/api/v1/' + 'aziende/' + idAzienda + '/update-logo', logo, data, true, 'logo');
  }

  public deleteLogoAzienda(idAzienda: string,) {
    return this.webService.doDelete('/api/v1/aziende/' + idAzienda + '/delete-logo', {});
  }


  // Immagine su compilazione della certificazione bilancio
  public importImgCertificazioneBilancio(file: File, data: { [key: string]: string; }, listaID: any) {
    return this.webService.uploadImgPut('/api/v1/azienda/' + listaID?.idAzienda + '/bilancio-di-sostenibilita/' + listaID?.idContesto + '/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/upload', file, data, true, 'file');
  }

  // Immagine su compilazione della certificazione bilancio da esterna
  public importImgCertificazioneBilancioDaEsterna(file: File, data: { [key: string]: string }, listaID: any) {
    return this.webService.uploadImgPut(
      '/api/v1/compila-bilancio/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/upload-file', file, data, true, 'file');
  }
  public deleteImgCertificazioneBilancio(listaID: any) {
    return this.webService.doDelete('/api/v1/azienda/' + listaID?.idAzienda + '/bilancio-di-sostenibilita/' + listaID?.idContesto + '/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/delete-file', {});
  }

  public deleteImgCertificazioneBilancioDaEsterna(listaID: any, temporaryToken: string) {
    return this.webService.doDelete(
      '/api/v1/compila-bilancio/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/delete-file', { temporaryToken });
  }

  // Immagine su compilazione della certificazione sintesi
  public importImgCertificazioneSintesi(file: File, data: { [key: string]: string }, listaID: any) {
    return this.webService.uploadImgPut('/api/v1/azienda/' + listaID?.idAzienda + '/sintesi-sostenibilita/' + listaID?.idContesto + '/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/upload', file, data, true, 'file',);
  }

  // Immagine su compilazione della certificazione sintesi
  public importImgCertificazioneSintesiDaEsterna(file: File, data: { [key: string]: string }, listaID: any) {
    return this.webService.uploadImgPut(
      '/api/v1/compila-sintesi/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/upload-file', file, data, true, 'file');
  }

  public deleteImgCertificazioneSintesi(listaID: any) {
    return this.webService.doDelete('/api/v1/azienda/' + listaID?.idAzienda + '/sintesi-sostenibilita/' + listaID?.idContesto + '/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/delete-file', {});
  }

  public deleteImgCertificazioneSintesiDaEsterna(listaID: any, temporaryToken: string) {
    return this.webService.doDelete(
      '/api/v1/compila-sintesi/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/delete-file', { temporaryToken });
  }

  // Immagine su compilazione della certificazione policy
  public importImgCertificazionePolicy(file: File, data: { [key: string]: string }, listaID: any) {
    return this.webService.uploadImgPut('/api/v1/azienda/' + listaID?.idAzienda + '/policy-di-sostenibilita/' + listaID?.idContesto + '/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/upload', file, data, true, 'file',);
  }

  // Immagine su compilazione della certificazione policy
  public importImgCertificazionePolicyDaEsterna(file: File, data: { [key: string]: string }, listaID: any) {
    return this.webService.uploadImgPut(
      '/api/v1/compila-policy/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/upload-file', file, data, true, 'file');
  }

  public deleteImgCertificazionePolicy(listaID: any) {
    return this.webService.doDelete('/api/v1/azienda/' + listaID?.idAzienda + '/policy-di-sostenibilita/' + listaID?.idContesto + '/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/delete-file', {});
  }

  public deleteImgCertificazionePolicyDaEsterna(listaID: any, temporaryToken: string) {
    return this.webService.doDelete(
      '/api/v1/compila-policy/questionario/ambito/' + listaID?.idAmbito + '/tematica/' + listaID?.idTematica + '/certificazione/' + listaID?.idElemento + '/delete-file', { temporaryToken });
  }

  // Immagine su cfg stampa 
  public importImgStampa(file: File, data: { [key: string]: string }, listaID: any) {
    return this.webService.uploadImgPut('/api/v1/azienda/' + listaID?.idAzienda + '/bilancio-di-sostenibilita/' + listaID?.idBilancioSostenibilita + '/cfg-stampa/' + listaID?.chiaveCfg + '/upload-img', file, data, true, 'file');
  }
  public deleteImgStampa(listaID: any) {
    return this.webService.doDelete('/api/v1/azienda/' + listaID?.idAzienda + '/bilancio-di-sostenibilita/' + listaID?.idBilancioSostenibilita + '/cfg-stampa/' + listaID?.chiaveCfg + '/delete-img', {});
  }

  // Immagine su cfg stampa Sintesi
  public importImgStampaSintesi(file: File, data: { [key: string]: string }, listaID: any) {
    return this.webService.uploadImgPut('/api/v1/azienda/' + listaID?.idAzienda + '/sintesi-sostenibilita/' + listaID?.idSintesiSostenibilita + '/cfg-stampa/' + listaID?.chiaveCfg + '/upload-img', file, data, true, 'file');
  }
  public deleteImgStampaSintesi(listaID: any) {
    return this.webService.doDelete('/api/v1/azienda/' + listaID?.idAzienda + '/sintesi-sostenibilita/' + listaID?.idSintesiSostenibilita + '/cfg-stampa/' + listaID?.chiaveCfg + '/delete-img', {});
  }

  // Immagine su cfg stampa Policy
  public importImgStampaPolicy(file: File, data: { [key: string]: string }, listaID: any) {
    return this.webService.uploadImgPut('/api/v1/azienda/' + listaID?.idAzienda + '/policy-di-sostenibilita/' + listaID?.idPolicySostenibilita + '/cfg-stampa/' + listaID?.chiaveCfg + '/upload-img', file, data, true, 'file');
  }
  public deleteImgStampaPolicy(listaID: any) {
    return this.webService.doDelete('/api/v1/azienda/' + listaID?.idAzienda + '/policy-di-sostenibilita/' + listaID?.idPolicySostenibilita + '/cfg-stampa/' + listaID?.chiaveCfg + '/delete-img', {});
  }
}

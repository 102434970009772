<app-spinner-overlay></app-spinner-overlay>

<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Configurazione linee guida stampe"
            subTitolo="Definizione delle linee guida mostrate nelle configurazioni di stampa."></app-titolo-subtitolo>
    </div>

    <mat-tab-group>

        <mat-tab *ngFor="let contesto of contesti" [label]="contesto.label">
            <ng-scrollbar #scrollConfStampa>
                <div class="contenuto" scrollViewport>

                    <mat-accordion dropListScroller cdkDropList #selList="cdkDropList"
                        [cdkDropListData]="contesto.config" (cdkDropListDropped)="dropCfgStampa($event)">

                        <mat-expansion-panel class="cfg-stampa" #expCfgStampa (opened)="openGroup(elemStampa)"
                            *ngFor="let elemStampa of contesto.config; let idx = index" cdkDrag cdkDragDisabled>

                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <!-- <mat-icon cdkDragHandle>drag_handle</mat-icon> -->
                                    <span class="nome-grp-cfg">{{elemStampa.titolo}}</span>
                                </mat-panel-title>
                                <!-- <div class="bottoni-header-stampa">
                            <button mat-icon-button style="transform: scale(0.9);"
                                (click)="$event.stopPropagation(); editTitoloElemStampa(elemStampa)">
                                <mat-icon class="icone-label-chack">edit</mat-icon>
                            </button>

                            <mat-slide-toggle [checked]="elemStampa.toggle"
                                (click)="$event.preventDefault(); $event.stopPropagation();"
                                (change)="toggleTestaCfgStampa($any($event), elemStampa)">
                            </mat-slide-toggle>
                        </div> -->
                            </mat-expansion-panel-header>
                            <ng-container>

                                <div class="paragrafo">
                                    <div class="editor-cont">
                                        <div>Linea guida</div>
                                        <!-- <app-editor [(ngModel)]="elemStampa.descrizione"></app-editor> -->
                                        <textarea class="descrizione" matInput [(ngModel)]="elemStampa.descrizione"
                                            rows="4"></textarea>
                                    </div>
                                </div>

                                <div class="btn-container">
                                    <button mat-flat-button color="primary" (click)="salva(contesto.tipo, elemStampa)"
                                        class="primary-button salva" [disabled]="salvataggioInCorso[elemStampa.chiave]">
                                        Salva
                                    </button>

                                    <mat-spinner *ngIf="salvataggioInCorso[elemStampa.chiave]" diameter="20"></mat-spinner>
                                </div>
                            </ng-container>
                        </mat-expansion-panel>
                    </mat-accordion>

                </div>
            </ng-scrollbar>

        </mat-tab>
    </mat-tab-group>

</div>
<div class="contenitore">
    <app-spinner-overlay></app-spinner-overlay>
    <div class="testata">

        <div class="prova">
            <div class="titolo">
                {{data.titoloImportazione}}
            </div>
            <div class="bottoni">
                <div>
                    <button mat-flat-button color="primary" class="primary-button" (click)="scaricaTemplate()">
                        Scarica Template
                    </button>

                </div>
                <div btnEnd style="display: flex;justify-content: start; column-gap: 23px;">
                    <div class="fileinput">

                        <button mat-flat-button color="accent" class="accent-button" style="max-width: 150px ;">
                            Importa
                            <mat-icon>
                                file_upload
                            </mat-icon>
                        </button>
                        <input #fileInput type="file" name="fileDaCaricare"
                            (change)="fileSelezionato($any($event.target)?.files)"
                            (click)="$any($event.target).value=null" />

                    </div>
                </div>
            </div>
        </div>
        <div class="sub-titolo">
            {{data.subTitoloImportazione}}
        </div>


    </div>
    <div class="segnalazione" [ngClass]="{'show': isIconaGuida}">
        <div style="display: flex;">
            <mat-icon>error</mat-icon>
        </div>
        <div class="descrizione">
            {{descrizioneErrore}}
        </div>
    </div>
</div>
<div class="table-container-semplice">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table">
    
            <!-- Consulente Column -->
            <ng-container matColumnDef="consulente" >
                <th mat-header-cell *matHeaderCellDef>
                    <div class="titolo-consulente">Consulenti</div>
                </th>
                <td mat-cell class="td-consulente" *matCellDef="let element">
                    <div class="img-consulente">{{element.imgConsulente}}</div>
                    <div class="consulente">{{element.consulente}}</div>
                </td>
            </ng-container>

        <!-- aziende assegnate Column -->
        <ng-container matColumnDef="aziende-assegate">
            <th mat-header-cell *matHeaderCellDef>
                <div>Aziende Assegnate</div>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.aziendeAssegate}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


</div>
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-separatore-label',
  templateUrl: './separatore-label.component.html',
  styleUrls: ['./separatore-label.component.scss']
})
export class SeparatoreLabelComponent implements OnInit {
  @ViewChild('separatore') separatore!: ElementRef<HTMLElement>;

  private _titoloSeparatore: string = '';
  private _styleSeparatore: string = '';
  private _spazioSinistra: number = 0;
  private _spazioDestra: number = 0;

  @Input()
  /* titoloSeparatore */
  set titoloSeparatore(titoloSeparatore: string) {
    this._titoloSeparatore = titoloSeparatore;
  }
  get titoloSeparatore() {
    return this._titoloSeparatore;
  }
  @Input()
  /* titoloSeparatore */
  set styleSeparatore(styleSeparatore: string) {
    this._styleSeparatore = styleSeparatore;
  }
  get styleSeparatore() {
    return this._styleSeparatore;
  }
  @Input()
  /* spazio sinistra separatore */
  set spazioSinistra(spazioSinistra: number) {
    this._spazioSinistra = spazioSinistra;
  }
  get spazioSinistra() {
    return this._spazioSinistra;
  }
  @Input()
  /* spazio destra  separatore */
  set spazioDestra(spazioDestra: number) {
    this._spazioDestra = spazioDestra;
  }
  get spazioDestra() {
    return this._spazioDestra;
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.updateSpazioSize();
  }
  updateSpazioSize() {
    const separatoreElement = this.separatore.nativeElement;

    separatoreElement.style.setProperty('--separatore-sinistra', `${this.spazioSinistra}px`);
    separatoreElement.style.setProperty('--separatore-destra', `${this.spazioDestra}px`);

    if (this.titoloSeparatore && this.spazioSinistra === 0) {
      separatoreElement.style.setProperty('--separatore-sinistra', '10px');
    }
  }
}

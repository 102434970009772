import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { AziendaService } from '../azienda/azienda.service';
import { WebService } from '../web/web.service';
import { Impegno } from '../impegni/impegni.service';
import { Ambito } from '../ambiti/ambiti.service';
import { Settore } from '../settori/settori.service';
import { Impatto } from '../impatto/impatto.service';
import { AzioneBP } from '../azione-e-buona-pratica/azione-e-buona-pratica.service';
import { InformativaAziendale } from '../informativa-aziendale/informativa-aziendale.service';
import { Certificazione } from '../certificazioni/certificazioni.service';
import { GruppoCfgStampa } from '../config-stampe/config-stampe.service';

@Injectable({
  providedIn: 'root'
})
export class BilancioWebService {

  private nomeWs = 'bilancio-di-sostenibilita';
  private _idAzienda: string | undefined = undefined;
  private lang = 'it-IT';

  constructor(
    private aziendaService: AziendaService,
    private webService: WebService
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }
  /**
   * lista Bilancio 
   * @param page 
   * @param pageSize 
   * @param ricerca 
   * @param filters 
   * @param sortBy 
   * @returns 
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/delete', { id: idRiga })
  }

  public getAnteprimaBilancioCompilato(idBilancio: string, idAzienda: string): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + idAzienda + '/' + this.nomeWs + '/' + idBilancio + '/anteprima-bilancio-compilato', {});
  }


  /* --------------------------- FIGURE AZIENDALI - ---------------*/

  public getListaAssegnazioniFigAz(idBilancio: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancio + '/assegnazioni', {});
  }

  /* ---------------- C R E A Z I O N E   B I L A N C I O ---- step1 ------------*/

  /**
   * Creazione step 1 bilancio
   * @param bilancioSostenibilita oggetto bilancio di sostenibilita
   * @returns 
   */
  public postCreazioneBilancioStep1(bilancioSostenibilita: any) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs,
      bilancioSostenibilita
    );
  }

  /**
   * Aggiornamento step 1 bilancio
   * @param bilancioSostenibilita id bilancio di sostenibilita
   * @returns 
   */
  public putAggiornamentoBilancioStep1(idBilancioSostenibilita: any, fatturato: number, nrDipendenti: number) {

    const updBilancio = {
      fatturato: fatturato,
      nrDipendenti: nrDipendenti
    }

    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita,
      updBilancio
    );
  }

  /* ---------------- C R E A Z I O N E  S T D R ---- step2 ------------*/
  /**
   * Aggiornamento step 2 bilancio
   * @param bilancioSostenibilita id bilancio di sostenibilita
   * @returns 
   */
  public putBilancioStep2(idBilancioSostenibilita: any, bilancioStep2: any) {

    const stdrBilancio = {
      stdr: bilancioStep2?.stdr,
      idSintesi: bilancioStep2?.idSintesiSostenibilita?.id,
      idMaterialita: bilancioStep2?.idMaterialita?.idMaterialita,
    }

    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/stdr',
      stdrBilancio
    );
  }

  /* ---------------- I N F O R M A T I V A ---- step 3 ------------*/

  /**
 * lista informativa aziendale
 * @param page 
 * @param pageSize 
 * @param ricerca 
 * @param filters 
 * @param sortBy 
 * @returns 
 */
  public getInformativaList(idBilancioSostenibilita: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return of()
    /* return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/list-elementi-per-figura',
      {
        page,
        pageSize,
        ricerca,
        filters,
        sortBy
      }); */
  }

  /* ---------------- C R E A Z I O N E  S T E P  3---- step3 ------------*/
  /**
   * Compilazione 
   * @param idBilancioSostenibilita 
   * @param infoAz 
   * @returns 
   */
  putSalvaCompilazioneInformativa(idBilancioSostenibilita: string, infoAz: InformativaAziendale) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/informativa',
      infoAz
    );
  }

  /**
   * get della compilazione informativa aziendale 
   * @param idBilancioSostenibilita 
   * @returns 
   */
  getCompilazioneInformativa(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/lang/' + this.lang + '/informativa', {});
  }

  /**
   * Tabella informativa azeidnale
   * @param idBilancioSostenibilita 
   * @returns 
   */
  getInformativa(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/figura', {});
  }

  /**
   * Cambio figura 
   * @param idBilancioSostenibilita 
   * @param idFigura 
   * @returns 
   */
  putCambiaFiguraInformativa(idBilancioSostenibilita: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/informativa/figura/' + idFigura
    );
  }

  /**
   * inviaMail
   * @param idBilancioSostenibilita 
   * @returns 
   */
  inviaMailInformativa(idBilancioSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/lang/' + this.lang + '/informativa/invio-mail', {
      idBilancioSostenibilita
    });
  }

  /**
   * inviaNotifica 
   * @param idBilancioSostenibilita 
   * @returns 
   */
  inviaNotificaInformativa(idBilancioSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/lang/' + this.lang + '/informativa/invio-notifica', {
      idBilancioSostenibilita
    });
  }


  /* ------------------------- T E M A T I C H E step 4 -------------------------------- */


  public getQuestionarioBilancio(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/questionario-di-bilancio', {});
  }

  /**
 * 
 * @returns 
 */
  public putQuestionarioBilancio(idBilancioSostenibilita: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], titolo: string, stato: string, tipo: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/questionario-di-bilancio', {
      id: idSurvey,
      ambiti,
      settori,
      bilancio: [idBilancioSostenibilita],
      aziende: [this._idAzienda],
      titolo,
      stato,
      tipo
    });
  }

  /* ------------------------- C H E C K   I M P A T T I   step 5 -------------------- */
  /**
 * GET IMPATTI BILANCIO
 * @param idBilancioSostenibilita 
 * @returns obj con lista ambiti - tematiche - impatti
 */
  public getImpattiBilancio(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/impatti', {});
  }

  /**
   * SALVATAGGIO IMPATTI BILANCIO SELEZIONATI
   * @param idBilancioSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putImpattiBilancioSelezionati(idBilancioSostenibilita: string, impattiSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/impatti', impattiSelezionati, {});
  }

  /**
     * ASSEGNA FIGURA AD IMPATTO BILANCIO
     * @param idBilancioSostenibilita 
     * @param bilancio
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraImpattoBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idImpatto: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impatto/' + idImpatto + '/figura/' + idFigura, {});
  }

  public putImpattiEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, impatto: Impatto) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impatti-effimeri',
      impatto,
      {
        idAzienda: this._idAzienda,
        idBilancioSostenibilita
      }
    );
  }
  /* ------------------------- I M P E G N I     step 6 -------------------------------- */
  /**
 * GET IMPEGNI BILANCIO
 * @param idBilancioSostenibilita 
 * @returns obj con lista ambiti - tematiche - impegni
 */
  public getImpegniBilancio(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/impegni', {});
  }

  /**
   * SALVATAGGIO IMPEGNI BILANCIO SELEZIONATI
   * @param idBilancioSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putImpegniBilancioSelezionati(idBilancioSostenibilita: string, impegniSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/impegni', impegniSelezionati, {});
  }

  /**
     * ASSEGNA FIGURA AD IMPEGNI BILANCIO
     * @param idBilancioSostenibilita 
     * @param bilancio
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraImpegnoBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idImpegno: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impegno/' + idImpegno + '/figura/' + idFigura, {});
  }
  public putImpegniEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, impegno: Impegno) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impegni-effimeri',
      impegno,
      {
        idAzienda: this._idAzienda,
        idBilancioSostenibilita
      }
    );
  }
  /* ------------------------- A Z I O N I    B P    step 7 -------------------------------- */

  /**
 * GET AZIONI BP BILANCIO
 * @param idBilancioSostenibilita 
 * @returns obj con lista ambiti - tematiche - azioniBP
 */
  public getAzioniBPBilancio(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/azioni-buone-pratiche', {});
  }

  /**
   * SALVATAGGIO AZIONI BP BILANCIO SELEZIONATI
   * @param idBilancioSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putAzioniBPBilancioSelezionati(idBilancioSostenibilita: string, azioniBPSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/azioni-buone-pratiche', azioniBPSelezionati, {});
  }

  /**
     * ASSEGNA FIGURA AD AZIONI BP BILANCIO
     * @param idBilancioSostenibilita 
     * @param bilancio
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraAzioniBPBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idAzioneBP: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/azioni-buone-pratiche/' + idAzioneBP + '/figura/' + idFigura, {});
  }

  public putAzioniBPEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, azioneBP: AzioneBP) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/azioni-buone-pratiche-effimere',
      azioneBP,
      {
        idAzienda: this._idAzienda,
        idBilancioSostenibilita
      }
    );
  }

  /* ------------------------- K P I STEP 8 -------------------------------- */
  /**
      * GET KPI bilancio
      * @param idBilancioSostenibilita 
      * @returns obj con lista ambiti - tematiche - kpi
      */
  public getKpiBilancio(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/kpi', {});
  }

  /**
   * SALVATAGGIO KPI bilancio SELEZIONATI
   * @param idBilancioSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putKpiBilancioSelezionati(idBilancioSostenibilita: string, kpiSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/kpi', kpiSelezionati, {});
  }

  /**
   * ASSEGNA FIGURA A KPI bilancio
   * @param idBilancioSostenibilita 
   * @param idAmbito 
   * @param idTematica 
   * @param idKpi 
   * @param idFigura 
   * @returns 
   */
  public putAssegnaFiguraKpiBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idKpi: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/kpi/' + idKpi + '/figura/' + idFigura, {});
  }
  /**
    * GET ASSEGNAZIONI KPI bilancio (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
    * @param idBilancioSostenibilita 
    * @returns 
    */
  public getAssegnazioniKpi(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/kpi/assegnazioni', {});
  }

  public putKpiEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, kpi: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/kpi-effimeri',
      kpi,
      {
        idAzienda: this._idAzienda,
        idBilancioSostenibilita
      }
    );
  }

  /**
 * ritorna il valroe del toggler
 * @param idBilancioSostenibilita 
 * @returns 
 */
  public getAnniPrecKpi(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/kpi/includiAnniPrecedentiKpi', {});
  }

  /**
 * Attiva il toggled
 * @param idBilancioSostenibilita 
 * @returns 
 */
  public postAnniPrecKpi(idBilancioSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/kpi/includiAnniPrecedentiKpi', {}, {});
  }

  /**
 * mette a false il taggle
 * @param idBilancioSostenibilita 
 * @returns 
 */
  public delAnniPrecKpi(idBilancioSostenibilita: string) {
    return this.webService.doDelete('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/kpi/includiAnniPrecedentiKpi', {});
  }

  /* ------------------------- C R U S C O T T O     STEP 9  -------------------------------- */
  public getTotaliCruscotto(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/totale-compilazioni', {});
  }

  /**
 * lista cruscotti
 * @param page 
 * @param pageSize 
 * @param ricerca 
 * @param filters 
 * @param sortBy 
 * @returns 
 */
  public getCruscotti(idBilancioSostenibilita: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {

    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/list-elementi-per-figura',
      {
        page,
        pageSize,
        ricerca,
        filters,
        sortBy
      });
  }

  /**
   * Cambio figura
   * @param idBilancioSostenibilita 
   * @param idFiguraPrecedente 
   * @param idFiguraNuova 
   * @returns 
   */
  public putCambiaFigura(idBilancioSostenibilita: string, idFiguraPrecedente: string, idFiguraNuova: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/figura/' + idFiguraPrecedente + '/cambia/' + idFiguraNuova);
  }

  public inviaMailMultiple(idBilancioSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/invio-multiplo', {
      idBilancioSostenibilita
    });
  }

  public inviaNotificaMultiple(idBilancioSostenibilita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/notifica-multiplo', {
      idBilancioSostenibilita
    });
  }

  public inviaMail(idBilancioSostenibilita: string, idFigura: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/figura/' + idFigura + '/invio-singolo', {
      idBilancioSostenibilita,
      idFigura
    });
  }

  public inviaNotifica(idBilancioSostenibilita: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/figura/' + idFigura + '/notifica-singolo', {
      idBilancioSostenibilita,
      idFigura
    });
  }

  public putTemplateMail(idBilancioSostenibilita: string, templateMail: any): Observable<any> {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/template', templateMail, { idBilancioSostenibilita });
  }

  public getTemplateMail(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/template', { idBilancioSostenibilita });
  }

  /* ------------------------- C E R T I F I C A Z I O N I    -------------------------------- */

  /**
 * GET CERTIFICAZIONI BILANCIO
 * @param idBilancioSostenibilita 
 * @returns obj con lista ambiti - tematiche - certificazioni
 */
  public getCertificazioniBilancio(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/certificazioni', {});
  }

  /**
   * SALVATAGGIO CERTIFICAZIONI BILANCIO SELEZIONATI
   * @param idBilancioSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putCertificazioniBilancioSelezionate(idBilancioSostenibilita: string, certificazioniSelezionate: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/certificazioni', certificazioniSelezionate, {});
  }

  /**
     * ASSEGNA FIGURA AD CERTIFICAZIONI BILANCIO
     * @param idBilancioSostenibilita 
     * @param bilancio
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraCertificazioniBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idCertificazioni: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/certificazioni/' + idCertificazioni + '/figura/' + idFigura, {});
  }

  public putCertificazioniEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, certificazioni: Certificazione) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/certificazioni-effimere',
      certificazioni,
      {
        idAzienda: this._idAzienda,
        idBilancioSostenibilita
      }
    );
  }


  /* ------------------------- S T A M P A   STEP 10  -------------------------------- */

  /**
  * GET Configurazioni stampa BILANCIO (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
  * @param idBilancioSostenibilita 
  * @returns List<GruppoConfigRisultatiBilancioValori>
  */
  public getConfigStampa(idBilancioSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/cfg-stampa/list', {});
  }

  public postSalvaElementiStampa(idBilancioSostenibilita: string, cfgStampaBilancio: GruppoCfgStampa) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/cfg-stampa', cfgStampaBilancio);
  }
  
  public postStampaBilancio(idBilancioSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/stampa', {});
  }

  public getElementoDaCompilare(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idElemento: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' +
      idBilancioSostenibilita + '/questionario/ambito/' + idAmbito + '/tematica/' + idTematica + '/elemento/' + idElemento, {

      idAzienda: this._idAzienda,
      idAmbito,
      idTematica,
      idElemento,
      idBilancioSostenibilita
    });
  }

  public postElementoDaCompilare(
    idBilancioSostenibilita: string,
    idAmbito: string,
    idTematica: string,
    idElemento: string,
    risposta: string | number,
    idCategoriaUnitaDiMisura?: string,
    idUnitaDiRiferimento?: string
  ) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' +
      idBilancioSostenibilita + '/questionario/ambito/' + idAmbito + '/tematica/' + idTematica + '/elemento/' + idElemento, {
      idAmbito,
      idTematica,
      idElemento,
      risposta,
      idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento
    })
  }


  public getElementiEsterniDaCompilare(temporaryToken: string) {
    return this.webService.doGet('/api/v1/compila-bilancio/get', {
      temporaryToken
    });
  }

  public postElementiEsterniDaCompilare(temporaryToken: string, objEsterna: any[]) {
    return this.webService.doPost('/api/v1/compila-bilancio/compila',
      objEsterna
      , {
        temporaryToken
      });
  }
  public invioTestMail(messaggio: string, email: string, idBilancioSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/test-invio-mail', {
      messaggio,
      email
    });
  }

  /**
    * ritorna materialità pubblicate
    * @returns 
    */
  public getBilanciPubblicati(idSettore?: string, idSottoSettori?: string[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/settore/' + idSettore + '/list', {
      idSottoSettori
    });
  }
  
  /**
   * Cambio stato a pubblicato del bilancio
   * @param idBilancioSostenibilita 
   * @returns 
   */
  public pubblicaBilancio(idBilancioSostenibilita: string){
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idBilancioSostenibilita + '/pubblica', {});
  }
}

import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-edit-generale',
  templateUrl: './dialog-edit-descrizione-indice.component.html',
  styleUrls: ['./dialog-edit-descrizione-indice.component.scss']
})
export class DialogEditDescrizioneIndiceComponent {

  public form = new FormGroup({

    descrizioneIndice: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  })

  constructor(
    public dialogRef: MatDialogRef<DialogEditDescrizioneIndiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      isModifica: boolean
    }
  ) { }

  conferma() {
    if (this.form.valid) {

   
      const descrizioneIndice: any = (this.form.get('descrizioneIndice')?.value)
      this.dialogRef.close(descrizioneIndice);
    } else {
      Object.values(this.form.controls).forEach(
        (control) => {
          control.markAsUntouched()
        }
      )
    }

  }

}

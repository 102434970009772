import { Injectable } from '@angular/core';
import { AutovalutazioniWebService } from './autovalutazioni-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Observable } from 'rxjs';
import { Ambito } from '../ambiti/ambiti.service';
import { Settore, SottoSettore } from '../settori/settori.service';
import { Azienda } from '../azienda/azienda.service';
import { Figura } from '../KPI/kpi.service';

export interface AutovalutazioneCompilata {
  id?: string;
  dataCompilazione?: string;
  idUtenteCompilazione?: string;
  punteggioOttenuto?: number;
  punteggioMaxOttenibile?: number;
  punteggioPercentuale?: number;
  versione?: number;
  codiceLingua?: string;
  titolo?: string;
  descrizione?: string | null;
  ambiti?: Ambito[];
  settori?: Settore[];
  tipo?: string;
  aziende?: string[];
  note?: string;
  stato?: string;
  tipoAutovalutazione?: string;
  esercizio?: number[];
  figura?: Figura[];

}

@Injectable({
  providedIn: 'root'
})
export class AutovalutazioniService {

  constructor(private autovalutazioniWebSerivce: AutovalutazioniWebService) { }


  public getCfgAutovalutazioni(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.autovalutazioniWebSerivce.getCfgAutovalutazioni(page, pageSize, ricerca, filters, sortBy);
  }

  public getCfgAutovalutazioneById(id: string): Observable<any> {
    return this.autovalutazioniWebSerivce.getCfgAutovalutazioneById(id);
  }

  public getAutovalutazioni(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.autovalutazioniWebSerivce.getAutovalutazioni(page, pageSize, ricerca, filters, sortBy);
  }

  public getAutovalutazioniAzienda(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.autovalutazioniWebSerivce.getAutovalutazioniAzienda(page, pageSize, ricerca, filters, sortBy);
  }

  public getAutovalutazioniById(id: string) {
    return this.autovalutazioniWebSerivce.getAutovalutazioniById(id);
  }

  public getAutovalutazioneCodiceAteco(codAteco: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.autovalutazioniWebSerivce.getAutovalutazioneCodiceAteco(codAteco, page, pageSize, ricerca, filters, sortBy);
  }

  public putPubblicaAutovalutazione(id: string) {
    return this.autovalutazioniWebSerivce.putPubblicaAutovalutazione(id);
  }

  public postCfgAutovalutazione(titolo: string, ambiti: Ambito[], tipo: string, tipoAutovalutazione: 'GENERICO' | 'SETTORE', settori: Settore[], sottoSettori: SottoSettore[]) {
    return this.autovalutazioniWebSerivce.postCfgAutovalutazione(titolo, ambiti, tipo, tipoAutovalutazione, settori, sottoSettori);
  }

  public putCfgAutovalutazione(id: string, titolo: string, chiave: string, ambiti: Ambito[], tipo: string, tipoAutovalutazione: 'GENERICO' | 'SETTORE', settori: Settore[], sottoSettori: SottoSettore[], stato: string) {
    return this.autovalutazioniWebSerivce.putCfgAutovalutazione(id, titolo, chiave, ambiti, tipo, tipoAutovalutazione, settori, sottoSettori, stato);
  }

  public delCfgAutovalutazione(id: string) {
    return this.autovalutazioniWebSerivce.delCfgAutovalutazione(id);
  }

  public duplicaAutovalutazione(id: string) {
    return this.autovalutazioniWebSerivce.duplicaAutovalutazione(id);
  }

  public salvaCompilazione(idAutovalutazioneCompilata: string, autovalutazioneCompilata: any) {
    return this.autovalutazioniWebSerivce.salvaCompilazione(idAutovalutazioneCompilata, autovalutazioneCompilata);
  }

  public creaAutovalutazioneCompilate(annoDiRiferimento: number, idAutovalutazione: string, idFigura: string) {
    return this.autovalutazioniWebSerivce.creaAutovalutazioneCompilate(annoDiRiferimento, idAutovalutazione, idFigura);
  }

  public getAutovalutazioniCompilate(anno?: string, idSettore?: string, idSottoSettori?: string[]) {
    return this.autovalutazioniWebSerivce.getAutovalutazioneCompilate(anno, idSettore, idSottoSettori);
  }
  public stampaAutovalutazione(idAutovalutazioneCompilata: string) {
    return this.autovalutazioniWebSerivce.stampaAutovalutazione(idAutovalutazioneCompilata);
  }

  public getAutovalutazioneEsternaDaCompilare(temporaryToken: string) {
    return this.autovalutazioniWebSerivce.getAutovalutazioneEsternaDaCompilare(temporaryToken);
  }
  public postAutovalutazioneEsterniDaCompilare(temporaryToken: string, compilazioneEsterna: any) {
    return this.autovalutazioniWebSerivce.postAutovalutazioneEsterniDaCompilare(temporaryToken, compilazioneEsterna);
  }

  public inviaNotifica(idAutovalutazioneCompilata: string){
    return this.autovalutazioniWebSerivce.inviaNotifica(idAutovalutazioneCompilata);
  }
}

<div class="white-container">
  <div class="search-bar-div">
    <input type="text" class="search-bar-input" [(ngModel)]="query" placeholder="Inserisci chiave di ricerca...">
  </div>
  <div class="bottone-ricerca-div">
    <button mat-mini-fab color="primary" class="bottone-ricerca">
      <mat-icon>search</mat-icon>
    </button>
  </div>

</div>

<div class="imgLoginPage">
</div>
<div class="destra">
    <app-spinner-overlay></app-spinner-overlay>
    <div class="loginForm">
        <img [src]="logoPath">
        <div class="bentornato">Bentornato! Accedi al tuo account.</div>
        <div class="formContainer">
            <form [formGroup]="formLogin">
                <div>
                    <!-- Username -->
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="username" required />
                        <mat-error appFormError></mat-error>
                        <mat-icon matSuffix>account_circle</mat-icon>
                    </mat-form-field>
                </div>
                <!-- Password -->
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" />
                        <mat-error appFormError></mat-error>
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" tabindex="-1">
                            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="opzioni">
                    <mat-checkbox formControlName="rememberMe" class="checkBoxRA">Ricorda Accesso</mat-checkbox>
                    <a mat-flat-button [routerLink]="'/password-dimenticata'">Password dimenticata</a>
                </div>
                <div class="error" *ngIf="loginError">{{loginError}}</div>
                <div class="bottoniLogin">
                    <!-- <button mat-raised-button color="accent" class="btnRegistrati"
                        (click)="ctrAuth()">Registrati</button> -->
                    <button type="submit" mat-raised-button color="primary" class="btnAccedi"
                        [disabled]="formLogin.invalid" (click)="ctrAuth()">Accedi</button>
                </div>
            </form>
        </div>
    </div>
    <div class="policy">
        <!-- <p>Term of use. Privacy Policy</p> -->
    </div>
</div>
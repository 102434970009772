import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { AziendaService } from '../azienda/azienda.service';
import { Ambito } from '../ambiti/ambiti.service';
import { Settore, SottoSettore } from '../settori/settori.service';
import { Impegno } from '../impegni/impegni.service';
import { AzioneBP } from '../azione-e-buona-pratica/azione-e-buona-pratica.service';
import { Certificazione } from '../certificazioni/certificazioni.service';
import { GruppoCfgStampa } from '../config-stampe/config-stampe.service';

@Injectable({
  providedIn: 'root'
})
export class SintesiWebService {
  private nomeWs = 'sintesi-sostenibilita';
  private _idAzienda: string | undefined = undefined;

  constructor(
    private aziendaService: AziendaService,
    private webService: WebService
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }
  /**
   * lista sintesi 
   * @param page 
   * @param pageSize 
   * @param ricerca 
   * @param filters 
   * @param sortBy 
   * @returns 
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }


  /**
    * ritorna materialità pubblicate
    * @returns 
    */
  public getSintesiPubblicate(idSettore?: string, idSottoSettori?: string[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/settore/' + idSettore + '/list', {
      idSottoSettori
    });
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/delete', { id: idRiga })
  }

  public getAnteprimaSintesiCompilata(idSintesi: string, idAzienda: string): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + idAzienda + '/' + this.nomeWs + '/' + idSintesi + '/anteprima-sintesi-compilata', {});
  }

  /* --------------------------- FIGURE AZIENDALI ---- step1 ---------------*/

  public getListaAssegnazioniFigAz(idSintesi: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesi + '/assegnazioni', {});
  }

  /* --------------------------- C R E A Z I O N E   S I N T E S I ---- STEP1 ---------------*/
  /**
   * 
   * @param sintesiSostenibilita oggetto sintesi step1
   * @returns 
   */
  public postCreazioneSintesiStep1(sintesiSostenibilita: any) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs,
      sintesiSostenibilita
    );
  }
  /**
   * 
   * @param sintesiSostenibilita oggetto sintesi step1
   * @returns 
   */
  public putAggiornamentoSintesiStep1(idSintesi: any, fatturato: number, nrDipendenti: number) {

    const updSintesi = {
      fatturato: fatturato,
      nrDipendenti: nrDipendenti
    }

    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesi,
      updSintesi
    );
  }

  /* ------------------------- T E M A T I C H E step2 -------------------------------- */


  public getSintesiSurveyStep2(idSintesi: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesi + '/questionario', {});
  }

  /**
 * 
 * @param sintesiSostenibilita oggetto sintesi step1
 * @returns 
 */
  public putSintesiSurveyStep2(idSintesi: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], sottoSettori: SottoSettore[], titolo: string, stato: string, tipo: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesi + '/questionario', {
      id: idSurvey,
      ambiti,
      settori,
      sottoSettori,
      sintesi: [idSintesi],
      aziende: [this._idAzienda],
      titolo,
      stato,
      tipo
    });
  }
  /* ------------------------- I M P E G N I -------------------------------- */

  /**
    * GET IMPEGNI SINTESI
    * @param idSintesiSostenibilita 
    * @returns obj con lista ambiti - tematiche - impegni
    */
  public getImpegniSintesi(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/impegni', {});
  }

  /**
   * SALVATAGGIO IMPEGNI SINTESI SELEZIONATI
   * @param idSintesiSostenibilita 
   * @param idAmbito 
   * @param idTematica 
   * @param idImpegni 
   * @returns  Status 200 (senza body)
   */

  public putImpegniSintesiSelezionati(idSintesiSostenibilita: string, impegniSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/impegni', impegniSelezionati, {});
  }

  /**
    * CREAZIONE IMPEGNO DALLA SINTESI (esisterà solo nella sintesi)
    * @param idSintesiSostenibilita 
    * @param impegno
    * @returns <idImpegno creato>
    */
  public postImpegniSintesi(idSintesiSostenibilita: string, impegno: any) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/impegni', {
      impegno
    });
  }

  /**
    * MODIFICA IMPEGNO NELLA SINTESI (non sincronizzare - la modifica esisterà solo nella sintesi)
    * @param idSintesiSostenibilita 
    * @param idImpegno
    * @returns <idImpegno modificato>
    */
  public putImpegnoSintesiModificato(idSintesiSostenibilita: string, idImpegno: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/impegni/' + idImpegno, {});
  }

  /**
    * ASSEGNA FIGURA AD IMPEGNO SINTESI
    * @param idSintesiSostenibilita 
    * @param impegno
    * @returns <Status 200 (senza body)>
    */
  public putAssegnaFiguraImpegnoSintesi(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idImpegno: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impegno/' + idImpegno + '/figura/' + idFigura, {});
  }

  /**
    * GET ASSEGNAZIONI IMPEGNI SINTESI (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
    * @param idSintesiSostenibilita 
    * @returns List<{idTematica: string, nomeBreve: string, // nomeBreve della tematica  impegni: List<ImpegnoSinstesi>
    */
  public getAssegnazioniImpegni(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/impegni/assegnazioni', {});
  }

  public putImpegniEffimeri(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, impegno: Impegno) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impegni-effimeri',
      impegno,
      {
        idAzienda: this._idAzienda,
        idSintesiSostenibilita
      }
    );
  }

  /* ------------------------- AZIONIE E BUONE PRATICHE  -------------------------------- */
  /**
     * GET AZIONE E BUONE PRATICHE SINTESI
     * @param idSintesiSostenibilita 
     * @returns obj con lista ambiti - tematiche - azioni-buone-pratiche
     */
  public getAzioneBPSintesi(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/azioni-buone-pratiche', {});
  }

  /**
   * SALVATAGGIO AZIONE E BUONE PRATICHE SINTESI SELEZIONATI
   * @param idSintesiSostenibilita 
   * @param idAmbito 
   * @param idTematica 
   * @param idAzioneBP 
   * @returns  Status 200 (senza body)
   */

  public putAzioneBPSintesiSelezionati(idSintesiSostenibilita: string, azioneBPSelezionate: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/azioni-buone-pratiche', azioneBPSelezionate, {});
  }

  /**
    * CREAZIONE AZIONE E BUONE PRATICHE DALLA SINTESI (esisterà solo nella sintesi)
    * @param idSintesiSostenibilita 
    * @param azioneBP
    * @returns <idAzioniBP creato>
    */
  public postAzioneBPSintesi(idSintesiSostenibilita: string, azioneBP: any) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/azioni-buone-pratiche', {
      azioneBP
    });
  }

  /**
    * MODIFICA AZIONE E BUONE PRATICHE NELLA SINTESI (non sincronizzare - la modifica esisterà solo nella sintesi)
    * @param idSintesiSostenibilita 
    * @param idAzioneBP
    * @returns <idAzioneBP modificato>
    */
  public putAzioniBPSintesiModificato(idSintesiSostenibilita: string, idAzioneBP: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/azioni-buone-pratiche/' + idAzioneBP, {});
  }

  /**
    * ASSEGNA FIGURA AD AZIONE E BUONE PRATICHE SINTESI
    * @param idSintesiSostenibilita 
    * @param azioneBP
    * @returns <Status 200 (senza body)>
    */
  public putAssegnaFiguraAzioniBPSintesi(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, azioneBP: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/azioni-buone-pratiche/' + azioneBP + '/figura/' + idFigura, {});
  }
  /**
    * GET ASSEGNAZIONI AZIONE E BUONE PRATICHE SINTESI (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
    * @param idSintesiSostenibilita 
    * @returns List<{idTematica: string, nomeBreve: string, // nomeBreve della tematica  azioni-buone-pratiche: List<AzioniBPSinstesi>
    */
  public getAssegnazioniAzioneBP(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/azioni-buone-pratiche/assegnazioni', {});
  }

  public putAzioneBPEffimeri(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, azioneBP: AzioneBP) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/azioni-buone-pratiche-effimeri',
      azioneBP,
      {
        idAzienda: this._idAzienda,
        idSintesiSostenibilita
      }
    );
  }

  /* ------------------------- K P I -------------------------------- */
  /**
      * GET KPI SINTESI
      * @param idSintesiSostenibilita 
      * @returns obj con lista ambiti - tematiche - kpi
      */
  public getKpiSintesi(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/kpi', {});
  }

  /**
   * ritorna il valroe del toggler
   * @param idSintesiSostenibilita 
   * @returns 
   */
  public getAnniPrecKpi(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/kpi/includiAnniPrecedentiKpi', {});
  }

  /**
   * Attiva il toggled
   * @param idSintesiSostenibilita 
   * @returns 
   */
  public postAnniPrecKpi(idSintesiSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/kpi/includiAnniPrecedentiKpi', {}, {});
  }

  /**
   * mette a false il taggle
   * @param idSintesiSostenibilita 
   * @returns 
   */
  public delAnniPrecKpi(idSintesiSostenibilita: string) {
    return this.webService.doDelete('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/kpi/includiAnniPrecedentiKpi', {});
  }

  /**
   * SALVATAGGIO KPI SINTESI SELEZIONATI
   * @param idSintesiSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putKpiSintesiSelezionati(idSintesiSostenibilita: string, kpiSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/kpi', kpiSelezionati, {});
  }

  /**
    * CREAZIONE KPI DALLA SINTESI (esisterà solo nella sintesi)
    * @param idSintesiSostenibilita 
    * @param kpi
    * @returns <idKpi creato>
    */
  public postKpiSintesi(idSintesiSostenibilita: string, kpi: any) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/kpi', {
      kpi
    });
  }

  /**
    * MODIFICA KPI NELLA SINTESI (non sincronizzare - la modifica esisterà solo nella sintesi)
    * @param idSintesiSostenibilita 
    * @param idKpi
    * @returns <idKpi modificato>
    */
  public putKpiSintesiModificato(idSintesiSostenibilita: string, idKpi: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/kpi/' + idKpi, {});
  }

  /**
   * ASSEGNA FIGURA A KPI SINTESI
   * @param idSintesiSostenibilita 
   * @param idAmbito 
   * @param idTematica 
   * @param idKpi 
   * @param idFigura 
   * @returns 
   */
  public putAssegnaFiguraKpiSintesi(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idKpi: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/kpi/' + idKpi + '/figura/' + idFigura, {});
  }
  /**
    * GET ASSEGNAZIONI KPI SINTESI (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
    * @param idSintesiSostenibilita 
    * @returns List<{idTematica: string, nomeBreve: string, // nomeBreve della tematica  kpi: List<KpiSinstesi>
    */
  public getAssegnazioniKpi(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/kpi/assegnazioni', {});
  }

  public putKpiEffimeri(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, kpi: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/kpi-effimeri',
      kpi,
      {
        idAzienda: this._idAzienda,
        idSintesiSostenibilita
      }
    );
  }
  /* ------------------------- C E R T I F I C A Z I O N I    -------------------------------- */

  /**
 * GET CERTIFICAZIONI SINTESI
 * @param idSintesiSostenibilita 
 * @returns obj con lista ambiti - tematiche - certificazioni
 */
  public getCertificazioniSintesi(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/certificazioni', {});
  }

  /**
   * SALVATAGGIO CERTIFICAZIONI SINTESI SELEZIONATI
   * @param idSintesiSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putCertificazioniSintesiSelezionate(idSintesiSostenibilita: string, certificazioniSelezionate: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/certificazioni', certificazioniSelezionate, {});
  }

  /**
     * ASSEGNA FIGURA AD CERTIFICAZIONI SINTESI
     * @param idSintesiSostenibilita 
     * @param sintesi
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraCertificazioniSintesi(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idCertificazioni: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/certificazioni/' + idCertificazioni + '/figura/' + idFigura, {});
  }

  public putCertificazioniEffimeri(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, certificazioni: Certificazione) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/certificazioni-effimere',
      certificazioni,
      {
        idAzienda: this._idAzienda,
        idSintesiSostenibilita
      }
    );
  }


  /**
 * lista cruscotti
 * @param page 
 * @param pageSize 
 * @param ricerca 
 * @param filters 
 * @param sortBy 
 * @returns 
 */
  public getCruscotti(idSintesiSostenibilita: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {

    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/list-elementi-per-figura',
      {
        page,
        pageSize,
        ricerca,
        filters,
        sortBy
      });
  }

  public putCambiaFigura(idSintesiSostenibilita: string, idFiguraPrecedente: string, idFiguraNuova: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/figura/' + idFiguraPrecedente + '/cambia/' + idFiguraNuova);
  }

  public putTemplateMail(idSintesiSostenibilita: string, templateMail: any): Observable<any> {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/template', templateMail, { idSintesiSostenibilita });
  }

  public getTemplateMail(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/template', { idSintesiSostenibilita });
  }

  public getTotaliCruscotto(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/totale-compilazioni', {});
  }

  public getElementoDaCompilare(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idElemento: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' +
      idSintesiSostenibilita + '/questionario/ambito/' + idAmbito + '/tematica/' + idTematica + '/elemento/' + idElemento, {

      idAzienda: this._idAzienda,
      idAmbito,
      idTematica,
      idElemento,
      idSintesiSostenibilita
    });
  }

  public postElementoDaCompilare(
    idSintesiSostenibilita: string,
    idAmbito: string,
    idTematica: string,
    idElemento: string,
    risposta: string | number,
    idCategoriaUnitaDiMisura?: string,
    idUnitaDiRiferimento?: string
  ) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' +
      idSintesiSostenibilita + '/questionario/ambito/' + idAmbito + '/tematica/' + idTematica + '/elemento/' + idElemento, {
      idAmbito,
      idTematica,
      idElemento,
      risposta,
      idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento
    })
  }

  public getElementiEsterniDaCompilare(temporaryToken: string) {
    return this.webService.doGet('/api/v1/compila-sintesi/get', {
      temporaryToken
    });
  }

  public postElementiEsterniDaCompilare(temporaryToken: string, objEsterna: any[]) {
    return this.webService.doPost('/api/v1/compila-sintesi/compila',
      objEsterna
      , {
        temporaryToken
      });
  }

  public inviaMailMultiple(idSintesiSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/invio-multiplo', {
      idSintesiSostenibilita
    });
  }

  public inviaNotificaMultiple(idSintesiSostenibilita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/notifica-multiplo', {
      idSintesiSostenibilita
    });
  }

  public inviaMail(idSintesiSostenibilita: string, idFigura: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/figura/' + idFigura + '/invio-singolo', {
      idSintesiSostenibilita,
      idFigura
    });
  }

  public inviaNotifica(idSintesiSostenibilita: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/figura/' + idFigura + '/notifica-singolo', {
      idSintesiSostenibilita,
      idFigura
    });
  }

  public invioTestMail(messaggio: string, email: string, idSintesiSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/test-invio-mail', {
      messaggio,
      email
    });
  }

  public getConfigStampa(idSintesiSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/cfg-stampa/list', {});
  }

  public postStampaSintesi(idSintesi: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesi + '/stampa', {});
  }
  public postSalvaElementiStampa(idSintesiSostenibilita: string, cfgStampaSintesi: GruppoCfgStampa) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/cfg-stampa', cfgStampaSintesi);
  }

  /**
   * Cambio stato a pubblicato della sintesi
   * @param idSintesiSostenibilita 
   * @returns 
   */
  public pubblicaSintesi(idSintesiSostenibilita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idSintesiSostenibilita + '/pubblica', {});
  }
}

<div class="titolo-slide" style="margin-bottom: 15px;">
    <div class="sottotitolo">
        Configurazione/Selezione elementi per la Stampa
    </div>
</div>

<div class="cont-conf-stampa">
    <div class="titolo">
        Configurazione Stampa
    </div>
    <ng-scrollbar #scrollConfStampa>
        <div class="contenuto" scrollViewport>

            <mat-accordion dropListScroller cdkDropList #selList="cdkDropList" [cdkDropListData]="cfgStampa?.cfgStampa"
                (cdkDropListDropped)="dropCfgStampa($event)">

                <mat-expansion-panel class="cfg-stampa" #expCfgStampa (opened)="openGroup(elemStampa)"
                    [expanded]="indicePanelStampa === elemStampa.index" [disabled]="elemStampa.contenutoStampa.tipo === 'calc'"
                    *ngFor="let elemStampa of cfgStampa?.cfgStampa; let idx = index" cdkDrag>

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon cdkDragHandle>drag_handle</mat-icon>
                            <span class="nome-grp-cfg">{{elemStampa.titolo}}</span>
                        </mat-panel-title>
                        <div class="bottoni-header-stampa">
                            <button mat-icon-button style="transform: scale(0.9);"
                                (click)="$event.stopPropagation(); editTitoloElemStampa(elemStampa)">
                                <mat-icon>edit</mat-icon>
                            </button>

                            <mat-slide-toggle [checked]="elemStampa.toggle"
                                (click)="$event.preventDefault(); $event.stopPropagation();"
                                (change)="elemStampa.toggle = $any($event).checked">
                            </mat-slide-toggle>
                        </div>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="elemStampa.contenutoStampa.tipo !== 'calc'">

                        <div class="descr-stampa" *ngIf="elemStampa.descrizione">
                            {{elemStampa.descrizione}}
                        </div>

                        <div class="importazione">
                            <div btnEnd style="display: flex;justify-content: start; align-items: center;">
                                <ng-container>
                                    <div class="fileinput">

                                        <button mat-stroked-button *ngIf="!elemStampa.contenutoStampa.attach"
                                            style="height: 35px !important;width: 170px !important;font-weight: bold;">
                                            Importa Immagine
                                            <mat-icon>
                                                file_upload
                                            </mat-icon>
                                        </button>
                                        <button mat-flat-button color="accent" *ngIf="elemStampa.contenutoStampa.attach"
                                            style="height: 35px !important;width: 170px !important;">
                                            Cambia Immagine
                                            <mat-icon>
                                                file_upload
                                            </mat-icon>
                                        </button>
                                        <input #fileInput type="file" name="fileDaCaricare"
                                            (change)="importaImmagine($any($event.target)?.files, elemStampa)"
                                            (click)="$any($event.target).value=null" />

                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div style="padding-left: 20px;">
                                        <div style="display: flex;"
                                            *ngIf="elemStampa.contenutoStampa.attach && (elemStampa.contenutoStampa.tipo === 'img' || elemStampa.contenutoStampa.tipo === 'img_txt')">
                                            <p><a style="color: var(--grigio); font-size: 13px;" target="_blank"
                                                    [href]="elemStampa.contenutoStampa.attach">{{elemStampa.contenutoStampa.originalFileName}}</a>
                                            </p>
                                            <button matSuffix mat-icon-button type="button" color="warn"
                                                aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                                (click)="eseguiEliminaImg(elemStampa)">
                                                <mat-icon>clear</mat-icon>
                                            </button>
                                        </div>
                                        <p *ngIf="!elemStampa.contenutoStampa.attach"
                                            style="color: var(--grigio); font-size: 12px;">
                                            Descrizione immagine caricata...
                                        </p>
                                    </div>

                                </ng-container>

                            </div>
                        </div>

                        <div class="paragrafo">
                            <div class="editor-cont">
                                <app-editor [(ngModel)]="elemStampa.contenutoStampa.content"
                                    (cambioValoreEditor)="ctrValoreEditor(elemStampa)"></app-editor>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="elemStampa.contenutoStampa.tipo === 'calc'">
                        <ng-container *ngIf="elemStampa.chiave === 'grafico_materialita'">
                        </ng-container>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </ng-scrollbar>
</div>
<div style="padding-top:5px;">
    <button mat-stroked-button (click)="aggiungiNuovoIndiceStampa()"
        style="height: 40px;width: 100%;font-weight: bold; background-color: #F5F5F5;">
        + Aggiungi Elemento
    </button>
</div>
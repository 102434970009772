<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Configurazione Guida alla Scrittura di un Impatto"
            subTitolo=""></app-titolo-subtitolo>
    </div>

    <app-spinner-overlay></app-spinner-overlay>

    <div class="white-container">
        <div class="cnt-campi-input">
            <ng-scrollbar>
                <form [formGroup]="formScritturaImpatto" appFormContainer [larghezza]="3">

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Potenziale/Positivo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="PotenzialePositivo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Potenziale/Negativo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="PotenzialeNegativo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Potenziale/Positivo/Negativo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="PotenzialePositivoNegativo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Potenziale/Effettivo/Positivo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="PotenzialeEffettivoPositivo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Potenziale/Effettivo/Negativo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="PotenzialeEffettivoNegativo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Potenziale/Effettivo/Positivo/Negativo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="PotenzialeEffettivoPositivoNegativo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Effettivo/Positivo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="EffettivoPositivo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Effettivo/Negativo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="EffettivoNegativo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Effettivo/Positivo/Negativo</mat-label>
                        <input matInput placeholder="Descrizione" formControlName="EffettivoPositivoNegativo">
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                </form>
                <div class="bottone-salva">

                    <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
                        Salva
                    </button>
                </div>
            </ng-scrollbar>
        </div>

    </div>
</div>
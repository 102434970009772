import { SurveyService } from 'src/app/services/survey/survey.service';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MaterialitaService, Survey } from 'src/app/services/materialita/materialita.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnteprimaSurveyComponent } from 'src/app/components/anteprima-survey/anteprima-survey.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-dialog-anteprima-survey',
  templateUrl: './dialog-anteprima-survey.component.html',
  styleUrls: ['./dialog-anteprima-survey.component.scss']
})
export class DialogAnteprimaSurveyComponent implements OnInit, AfterViewInit {

  @ViewChild('survey') surveyComponent!: AnteprimaSurveyComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public struttraSurvey: Survey | undefined = undefined;
  public landingPage: string = '';
  public datiSurvey: boolean = false;
  public erroreDatiSurvey: string = '';
  public inizioTempoDiCompilazione: Date | undefined = undefined;
  public tempoDiCompilazione: number = 0;

  constructor(private surveyService: SurveyService,
    private materialitaService: MaterialitaService,
    private utilityService: UtilityService,
    public dialogRef: MatDialogRef<DialogAnteprimaSurveyComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
      idMaterialita: string,
      idStakeholder: string,
      idSurvey: string,
      idSurveyCompilato: string,
    }
  ) {
  }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {

    this.surveyInterna();
    this.getLandingPage();
  }

  async salva() {

    if (!this.controlloPunteggioObbligatorio(this.surveyComponent.datiSurvey)) {

      const dialog = this.utilityService.openDialog({
        titolo: "Compilazione Terminata",
        descrizione: 'Le risposte non saranno modificabili. Confermi?',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }
      this.spinnerOver.show();
      if (this.inizioTempoDiCompilazione) {
        this.tempoDiCompilazione = new Date().getTime() - this.inizioTempoDiCompilazione.getTime();
        
      }

      // Now you can safely push to tempoDiCompilazione
      this.surveyComponent.datiSurvey.tempoDiCompilazione = this.tempoDiCompilazione;
      this.surveyComponent.datiSurvey.tipo = 'SURVEY';

      
      return this.surveyService.postDatiSurvey(this.data.idMaterialita, this.data.idStakeholder, this.data.idSurvey, this.surveyComponent.datiSurvey).subscribe(
        {
          next: (result: any) => {
            this.spinnerOver.hide();
            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });
            this.dialogRef.close();
          },
          error: (err: any) => {
            console.error('Errore durante il salvataggio della survey', err);
            this.spinnerOver.hide();
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel salvataggio della survey',
              bottoni: [{ nome_btn: 'Chiudi' }]
            })
          }

        }
      )
    } else {
      return this.utilityService.opneSnackBar('Rispondi a tutti gli impatti', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  surveyInterna() {
    this.spinnerOver.show();
    //survey compilata
    if (this.data.idSurveyCompilato) {
      this.surveyService.getSurveyCompilata(this.data.idMaterialita, this.data.idSurveyCompilato).subscribe({
        next: (result) => {
          this.struttraSurvey = result
          this.spinnerOver.hide();
          
          this.datiSurvey = true;
        },
        error: (err: any) => {
          this.spinnerOver.hide();
          console.error('errore get survey', err.error.message);
        }
      })
    } else {

      //survey non compilata
      this.surveyService.getDatiSurvey(this.data.idMaterialita, this.data.idStakeholder, this.data.idSurvey).subscribe(
        {
          next: (result: any) => {
            this.struttraSurvey = result;
            this.spinnerOver.hide();
            
            this.datiSurvey = true;
            // inizializzo il tempo di compilazione 
            this.inizioTempoDiCompilazione = new Date();
          },
          error: (err: any) => {
            this.spinnerOver.hide();
            console.error('errore get survey', err.error.message);
            this.erroreDatiSurvey = err.error.message;
          }
        }
      );
    }
  }

  public getLandingPage() {
    this.materialitaService.getTemplateMail(this.data.idMaterialita).subscribe(
      {
        next: (template) => {
          this.landingPage = template.landingPage || '';
        },
        error: (err: any) => {
          console.error('Errore durante la chiamata al servizio', err);
        }
      }
    );
  }

  controlloPunteggioObbligatorio(survey: Survey) {
    for (let ambiti of survey.ambiti) {
      for (let tematica of ambiti.tematiche) {
        for (let impatto of tematica.impatti) {
          if (!impatto.valoreAttribuito) {
            let el = document.getElementById(tematica.id);
            el?.scrollIntoView({ behavior: 'smooth' });
            /*   this.scroller.scrollToAnchor(tematica.id); */
            return true;
          }
        }
      }
    }
    return false;
  }

}

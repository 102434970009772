import { Injectable } from '@angular/core';
import { ConfigStampeWebService } from './config-stampe-web.service';

export interface GruppoCfgStampa {
  id: string;
  contesto: string;
  cfgStampa: CfgStampa[];
  idTesto: string;
  headerAndFooterInfo: { [key: string]: any };
}

export interface CfgStampa {
  index: number;
  chiave: string;
  titolo: string;
  descrizione: string;
  contenutoStampa: ContenutoStampa;
  toggle: boolean;
}

interface ContenutoStampa {
  tipo: string;
  content?: string;
  attach?: string;
  originalFileName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigStampeService {

  constructor(
    private configStampeWebService: ConfigStampeWebService
  ) { }

  public getLineeGuida(contesto: 'QUESTIONARIO_GENERICO' | 'AUTOVALUTAZIONE' | 'SURVEY' | 'BILANCIO' | 'SINTESI' | 'INFORMATIVA_AZIENDALE' | 'POLICY') {
    return this.configStampeWebService.getLineeGuida(contesto);
  }

  public salvaLineaGuida(config: CfgStampa, contesto: 'QUESTIONARIO_GENERICO' | 'AUTOVALUTAZIONE' | 'SURVEY' | 'BILANCIO' | 'SINTESI' | 'INFORMATIVA_AZIENDALE' | 'POLICY') {
    return this.configStampeWebService.salvaLineaGuida(config, contesto);
  }

}

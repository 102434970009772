import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';

@Injectable({
  providedIn: 'root'
})
export class LicenzeWebService {
  private lang = '/it-IT';
  private nomeWs = 'licenze';
  constructor(
    private webService: WebService
  ) { }

  public getLicenzeByUtente(idUtente: string, codiceLicenza: string) {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente, {});
  }

  public putLicenzeByUtente(idUtente: string, codiceLicenza: string, licenza: any) {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente + "/aggiorna-licenza", licenza);

  }

  public postAbilitaLicenza(idUtente: string, licenza: any) {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/utente/' + idUtente + "/abilita-licenza", licenza);
  }
  public postLicenzeList() {
    return this.webService.doPost('/api/v1/' + this.nomeWs + "/list", {});

  }

  public postLicenzeCreate() {
    return this.webService.doPost('/api/v1/' + this.nomeWs + "/create", {});
  }
  public deleteLicenze(idUtente: string) {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/utente/' + idUtente + "/disabilita-licenza", {});
  }
  public getPromotoriList() {
    return this.webService.doPost('/api/v1/promotori/list', {});
  }
  public postPromotori() {
    return this.webService.doPost('/api/v1/promotori/create', {});
  }

  public getUserHasLicense(nomeModulo: string) {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/user-has-license/' + nomeModulo, {});

  }
}

<app-spinner-overlay></app-spinner-overlay>
<div>
    <header class="header">
        <img [src]="logoPath" alt="Sostenibile" class="logo-path" />
    </header>
    <div *ngIf="anteprima" class="anteprima">Anteprima</div>
</div>
<!-- <div>{{landingPage}}</div> -->
<div class="contenitore" *ngIf="!completato && surveyEsterna">
    <ng-scrollbar>
        <div class="contenuto">
            <app-anteprima-survey *ngIf="surveyEsterna" #survey [datiSurvey]="surveyEsterna"
                [landingPage]="landingPage"></app-anteprima-survey>
        </div>

        <!----------------------------- F O O T E R ------------------------>
        <div mat-dialog-actions class="dialog-footer-cont">
            <div *ngIf="!anteprima">
                <button mat-flat-button color="primary" (click)="salva()" class="primary-button">
                    Salva
                </button>
            </div>
        </div>
    </ng-scrollbar>
</div>

<div class="compilato" *ngIf="completato">
    {{messaggio}}
</div>


<div class="no-dati" *ngIf="surveyVuoto">
    <div class="no-dati">
        {{messaggio}}
    </div>
</div>
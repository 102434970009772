import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaAziendaComponent } from 'src/app/page/aziende/dialog/dialog-crea-azienda/dialog-crea-azienda.component';
import { DialogVisUtenteComponent } from 'src/app/page/aziende/dialog/dialog-vis-utente/dialog-vis-utente.component';
import { Utente, UtenteService } from 'src/app/services/utente/utente.service';

@Component({
  selector: 'app-lista-aziende',
  templateUrl: './lista-aziende.component.html',
  styleUrls: ['./lista-aziende.component.scss']
})
export class ListaAziendeComponent {

  private _id_azienda: string[] | undefined = undefined;
  private _utente: Utente | undefined = undefined;

  @Input()
  get id_azienda() {
    return this._id_azienda;
  }

  set id_azienda(id_azienda: string[] | undefined) {
    this._id_azienda = id_azienda || undefined;
  }
  @Input()
  get utente() {
    return this._utente;
  }

  set utente(id_utente: Utente | undefined) {
    this._utente = id_utente || undefined;
  }
  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Nome Impresa',
      forControlName: 'ragioneSociale',
      input: 'text',
    },
    {
      titolo: 'P.IVA',
      forControlName: 'piva',
      input: 'text',
    },
    {
      titolo: 'Codice Ateco',
      forControlName: 'codiciAteco',
      input: 'text',
    },
    {
      titolo: 'Informazioni Azienda',
      input: 'separatore',
      larghezza: 3,
      riempi: true,
    },
    {
      titolo: 'Indirizzo',
      forControlName: 'indirizzo',
      input: 'text',
    },
    {
      titolo: 'Regione',
      forControlName: 'regione',
      input: 'text',
    },
    {
      titolo: 'Comune',
      forControlName: 'comune',
      input: 'text',
    },
    {
      titolo: 'Provincia',
      forControlName: 'provincia',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
      larghezza: 2
    },
  ];

  public colonne: { [key: string]: Colonna } = {

    ragioneSociale: {
      title: 'Ragione Sociale',
      value: 'ragioneSociale',
      sortable: true
    },
    /*  utenti: {
       title: 'Utenti',
       value: (record: any) => (record?.idAziende?.length || 0),
     }, */

    codiciAteco: {
      title: 'Codice Ateco',
      value: (record: any) => (record?.codiciAteco?.length ? record?.codiciAteco[0] : ''),
      sortable: true
    },

    piva: {
      title: 'Partita Iva',
      value: 'piva',
      sortable: true
    },

    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      sortable: false,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_AZIENDA'))
    },
    utentiAssoc: {
      type: 'button',
      buttonIcon: 'person',
      title: 'Utenti',
      buttonId: 'utentiAssoc',
      buttonMostraSempre: true,
      sortable: false,
      nascondiButton: (record: any) => this.aziendaService.getNrChiamataDialogAzd() >= 1
    },
    /* duplica: {
      type: 'button',
      buttonIcon: 'content_copy',
      title: 'Duplica',
      buttonId: 'duplica',
    }, */
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      sortable: false,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_AZIENDA'))
    },
  };

  constructor(
    public dialog: MatDialog,
    private aziendaService: AziendaService,
    private utilityService: UtilityService,
    public utenteService: UtenteService
  ) {
  }
  ngAfterViewInit(): void {
  }


  ngOnInit(): void {
    /**
   * Metodo che mi riempie un array dei codici ateco.
   * E' stato inserito in questo componente poichè nella creazione dell'azienda
   * risultava abbastanza lento. 
   * 
   */
    this.aziendaService.setCodiciAteco();
  }
  /**
   * Apertura dialog filtri passati da l'esterno
   */
  crea(recordAzienda?: Azienda) {

    const _utente = this.utenteService.infoUtente?.utente;
    const ruoloUtente = this.utenteService.infoUtente?.utente.ruolo.roleName;
    let utente: any;
    if (!this.utente && ruoloUtente !== 'configuratore' && _utente) {
      utente = _utente
    } else {
      utente = this.utente;
    }

    const dialogCreaAzienda = this.dialog.open(DialogCreaAziendaComponent, {
      data: {
        azienda: recordAzienda,
        utente: utente
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '90%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaAzienda.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    const aziendaSortBy: SortBy = {
      chiave: 'ragioneSociale',
      sort: 'asc'
    };

    let actualSortBy: SortBy[] = [];

    if (sortBy) {
      // Ordinamento ricevuto, si controlla se presente un ordinamento per ragioneSociale
      const ordinatoPerRagSoc = sortBy.find((sort) => sort.chiave === 'ragioneSociale') ? true : false;

      if (ordinatoPerRagSoc) {
        // C'è già un ordinamento per ragioneSociale, si lascia invariato
        actualSortBy = sortBy;
      } else {
        // Non c'è un ordinamento per ragioneSociale, si accoda
        actualSortBy = sortBy?.concat(aziendaSortBy);
      }
    } else {
      // Nessun ordinamento ricevuto, si ordina per ragioneSociale
      actualSortBy = [aziendaSortBy];
    }

    if (this.id_azienda) {
      return this.aziendaService.getAziendeByIdAzienda(this.id_azienda, page, pageSize, ricerca, filters, actualSortBy)
    } else {
      return this.aziendaService.getAziende(page, pageSize, ricerca, filters, actualSortBy);
    }
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'elimina':
        this._eliminaAzienda(event.data.id);
        break;
      case 'duplica':
        this._duplicaAzienda();
        break;
      case 'utentiAssoc':
        this._utentiAssoc(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }
  /**
  * Funzione per eliminare una riga da una lista tabellare 
  * @param idRiga id della riga da eliminare 
  */
  private _eliminaAzienda(idRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare l\'Azienda?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.aziendaService.deleteAzienda(idRiga).subscribe(
              {
                next: (result: any) => {
                  this.utenteService.aggiornaInfoUtente().subscribe();
                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: 'Errore eliminazione' + err,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });
  }
  /**
   * Metodo per la duplicazione di un Azienda
   */
  public _duplicaAzienda() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }
  public _utentiAssoc(azienda: Azienda) {
    const dialogCreaUtente = this.dialog.open(DialogVisUtenteComponent, {
      data: {
        idAzienda: azienda.id,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaUtente.afterClosed().subscribe((result) => {
      this.aziendaService.setNrChiamataDialogAzd(0);
      this.utenteService.setNrChiamataDialogUsr(0);

      this.tabella.caricaDati();
    });
  };

  /**
   * controllo permessi per la visualizzazione del bottone nuova azienda
   * se this.utenteService.infoUtente?.nrMaxAziende === null vuoldire infinite
   * @returns 
   */
  controlloPermessi(): boolean {
    const aziendeUtente = this.utenteService.infoUtente?.utente?.idAziende ?? [];

    return (
      this.utenteService.isPermessoAttivo('CREATE_AZIENDA') &&
      (aziendeUtente.length < (this.utenteService.infoUtente?.nrMaxAziende ?? 0) || this.utenteService.infoUtente?.nrMaxAziende === null)
    );
  }
}


import { Component } from '@angular/core';
import { SelezioneElementiQuestionarioComponent } from '../../selezione-elementi-questionario.component';
import { of, Observable, map, catchError, tap, finalize, firstValueFrom } from 'rxjs';
import { DialogCreaCertificazioniComponent } from 'src/app/page/configurazioni/cfg-certificazioni/dialog/dialog-crea-certificazioni/dialog-crea-certificazioni.component';
@Component({
  selector: 'app-selezione-certificazioni-bilancio',
  templateUrl: '../../selezione-elementi-questionario.component.html',
  styleUrls: ['../../selezione-elementi-questionario.component.scss']
})
export class SelezioneCertificazioniBilancioComponent extends SelezioneElementiQuestionarioComponent {
  override editElementoListaChk(idAmbito: string, idTematica: string, certificazioni?: any): void {
    const dialogCreaCertificazioni = this.dialog.open(DialogCreaCertificazioniComponent, {
      data: {
        certificazioni: certificazioni,
        idContest: this.idContest,
        idAmbito: idAmbito,
        idTematica: idTematica,
        contesto: 'bilancio'
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaCertificazioni.afterClosed().subscribe((result) => {
       
      if (result) {
        this.spinnerOver.show();

        this.salvataggio(idAmbito, idTematica, certificazioni?.id);
      }
    });
  }

  async salvataggio(idAmbito: string, idTematica: string, idCertificazioni: string) {
    try {
      // Salvo i dati modificati
      await firstValueFrom(this.salva(true));

      // Get dei dati aggiornati 
      await firstValueFrom(this._getCertificazioniBilancio());

      // Scroll all'elemento modificato 
      setTimeout(() => {
        this.scrollToElement(idAmbito, idTematica, idCertificazioni);
      }, 0);
    } catch (error) {
      console.error(error);
    }
  }

  override addElementoListaChk(idAmbito: string, idTematica: string): void {
    // posso passare la stessa funzione poichè la gestione è identica, Cambia solameanete il passaggio della certificazione
    // a vuoto nella creazione.
    this.editElementoListaChk(idAmbito, idTematica);
  }

  override salvaFigAz(idAmbito: string, idTematica: string, idCertificazioni: string, idFigAziendale: string): void {
    this.spinnerOver.show();
    this.bilancioService.putAssegnaFiguraCertificazioniBilancio(this.idContest, idAmbito, idTematica, idCertificazioni, idFigAziendale).subscribe({
      next: () => {
        this.salvataggio(idAmbito, idTematica, idCertificazioni);
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }

  private _getCertificazioniBilancio() {
    this.spinnerOver.show();

    return this.bilancioService.getCertificazioniBilancio(this.idContest).pipe(
      tap((esito) => {
        this.objQuestionario = esito;
      }),
      catchError(() => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle certificazioni',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of();
      }),
      tap({
        finalize: () => this.spinnerOver.hide()
      })
    )
  }

  salva(noCtr?: boolean) {

    let certificazioniSelezionate = this.oggettoElementiSelezionati();

    /* if (!noCtr) {
      //Controllo se è stato selezionato almeno una certificazione buona pratica per tematica 
      if (this.ctrElemChecked(certificazioniSelezionate, 'certificazione')) {
        return of(false);
      }
    } */

    return this._salvaSurveyElemSel(certificazioniSelezionate);
  }

  private _salvaSurveyElemSel(certificazioniSelezionate: any): Observable<boolean> {

    if (this.idContest) {

      return this.bilancioService.putCertificazioniBilancioSelezionate(
        this.idContest, certificazioniSelezionate).pipe(
          map((result: any) => {
             

            this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.salvato.emit();

            return true;

          }),
          catchError((err: any) => {
            console.error('Errore :', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore salvataggio (sconosciuto)',
              bottoni: [{ nome_btn: 'Ok' }]
            });
            return of(false);
          })
        );
    } else {
      return of(false);
    }
  }

}

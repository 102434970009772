<div mat-dialog-title>Piano di Sostenibilità</div>

<div mat-dialog-content class="dialog-center-cont" style="padding: 0px !important;">
    <app-spinner-overlay></app-spinner-overlay>
    <mat-stepper [linear]="piano?.stato !== 'PUBBLICATO'" #stepper (selectionChange)="cambioStep($event)">
        <mat-step [stepControl]="formPianoSostenibilita" [editable]="true">

            <ng-scrollbar [visibility]="'hover'">
                <ng-template matStepLabel>
                    Piano di sostenibilità
                </ng-template>

                <form [formGroup]="formPianoSostenibilita" appFormContainer [larghezza]="12" [gap]="10" [rowGap]="5"
                    style="align-items: center;">

                    <div appFormElement [larghezza]="12" [riempi]="true">

                        <div class="titolo-sezione-dialog">Creazione Piano di Sostenibilità</div>

                        <div class="descrizione-sezione-dialog">
                            Seleziona l'anno di riferimento per la tua Piano di Sostenibilità
                        </div>
                    </div>

                    <mat-form-field appFormElement [larghezza]="4">
                        <mat-label>Anno</mat-label>
                        <mat-select (selectionChange)="onSelectionChangeAnno()" formControlName="anno"
                            placeholder="Anno">
                            <mat-option *ngFor="let anno of arrayAnni" [value]="anno"
                                [disabled]="ctrAnnoPianoUtilizzata(anno)">{{anno}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div appFormElement [larghezza]="4" sty le="display:flex; flex-direction: column">
                        <mat-radio-group class="radio-tipo-sett" sty le="justify-content: space-around;"
                            aria-label="Tipo settore" formControlName="tipoSettore">
                            <mat-radio-button aria-label="Settore Generico" value="GENERALE"
                                [disabled]="this.formPianoSostenibilita.get('id')?.value || !this.settoreGenerale">Settore
                                Generico</mat-radio-button>
                            <mat-radio-button aria-label="Settore Specifico" value="SPECIFICO"
                                [disabled]="this.formPianoSostenibilita.get('id')?.value || !(this.settoriAzienda.length)">Settore
                                Specifico</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field appFormElement [larghezza]="4" class="input-form"
                        *ngIf="this.formPianoSostenibilita.get('tipoSettore')?.value === 'SPECIFICO'">
                        <mat-label>Settore</mat-label>
                        <mat-select formControlName="settori" placeholder="Settore" [compareWith]="compareById">
                            <mat-option *ngFor="let set of settoriAzienda" [value]="set"
                                [disabled]="ctrSettorePianoUtilizzata(set)">{{set.titolo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appFormElement [larghezza]="12" class="input-form"
                        *ngIf="this.formPianoSostenibilita.get('tipoSettore')?.value === 'SPECIFICO'">
                        <mat-label>Sotto-settori</mat-label>
                        <mat-select formControlName="sottoSettori" placeholder="Sotto-settori"
                            [compareWith]="compareById" multiple>
                            <mat-option *ngFor="let sottoSet of arraySottoSettori"
                                [value]="sottoSet">{{sottoSet.titolo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="separatore" appFormElement [larghezza]="12">
                        <app-separatore-label [styleSeparatore]="'small'"></app-separatore-label>
                    </div>

                    <div appFormElement [larghezza]="12" [riempi]="true">
                        <div class="titolo-sezione-dialog">Non partire da zero, lega la tua
                            Piano ad
                            altre
                            attività</div>

                        <div class="descrizione-sezione-dialog">
                            Lega il bilancio ad una analisi di materialità / bilancio di sostenibilità / sintesi di
                            sostenibilità / Autovalutazione precedentemente creata.
                        </div>
                    </div>

                    <div appFormElement [larghezza]="4">
                        <mat-form-field
                            *ngIf="formPianoSostenibilita.get('id')?.value || (formPianoSostenibilita.get('settori')?.value && arrayBilancioSostenibilita?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneBilancio()}}</mat-label>
                            <mat-select formControlName="idBilancioSostenibilita"
                                placeholder="Bilancio di Sostenibilità"
                                (selectionChange)="onSelectionChangeBilancio($event)">
                                <mat-option *ngFor="let bilancio of arrayBilancioSostenibilita"
                                    [compareWith]="compareBilancioSostenibilita"
                                    [value]="bilancio">{{bilancio.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idBilancioSostenibilita')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idBilancioSostenibilita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button [disabled]="this.formPianoSostenibilita.get('id')?.value" mat-stroked-button
                            *ngIf="!formPianoSostenibilita.get('id')?.value && !arrayBilancioSostenibilita?.length"
                            (click)="addBilancio()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Bilancio di Sostenibilità
                        </button>
                    </div>

                    <div appFormElement [larghezza]="4">
                        <mat-form-field
                            *ngIf="formPianoSostenibilita.get('id')?.value || (formPianoSostenibilita.get('settori')?.value && arraySintesiSostenibilita?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneSintesi()}}</mat-label>
                            <mat-select formControlName="idSintesiSostenibilita" placeholder="Sintesi di Sostenibilità"
                                (selectionChange)="onSelectionChangeSintesi($event)">
                                <mat-option *ngFor="let sintesi of arraySintesiSostenibilita"
                                    [compareWith]="compareSintesiSostenibilita"
                                    [value]="sintesi">{{sintesi.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idSintesiSostenibilita')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idSintesiSostenibilita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button [disabled]="this.formPianoSostenibilita.get('id')?.value" mat-stroked-button
                            *ngIf="!formPianoSostenibilita.get('id')?.value && !arraySintesiSostenibilita?.length"
                            (click)="addSintesi()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Sintesi di Sostenibilità
                        </button>
                    </div>

                    <div appFormElement [larghezza]="4">
                        <mat-form-field
                            *ngIf="formPianoSostenibilita.get('id')?.value || (formPianoSostenibilita.get('settori')?.value && arrayPolicySostenibilita?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizionePolicy()}}</mat-label>
                            <mat-select formControlName="idPolicySostenibilita" placeholder="Piano di Sostenibilità"
                                (selectionChange)="onSelectionChangePolicy($event)">
                                <mat-option *ngFor="let policy of arrayPolicySostenibilita"
                                    [compareWith]="comparePolicySostenibilita"
                                    [value]="policy">{{policy.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idPolicySostenibilita')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idPolicySostenibilita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button [disabled]="this.formPianoSostenibilita.get('id')?.value" mat-stroked-button
                            *ngIf="!formPianoSostenibilita.get('id')?.value && !arrayPolicySostenibilita?.length"
                            (click)="addPolicy()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Policy di Sostenibilità
                        </button>
                    </div>

                    <div appFormElement [larghezza]="4">

                        <mat-spinner [diameter]="30" *ngIf="loadingAnalisiMaterialita"></mat-spinner>

                        <mat-form-field
                            *ngIf="!loadingAnalisiMaterialita && (formPianoSostenibilita.get('id')?.value || (formPianoSostenibilita.get('settori')?.value && arrayAnalisiMaterialita.length))"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneMaterialita()}}</mat-label>
                            <mat-select formControlName="idMaterialita" placeholder="Analisi Materialità"
                                (selectionChange)="onSelectionChangeMaterialita($event)">
                                <mat-option *ngFor="let mate of arrayAnalisiMaterialita"
                                    [compareWith]="compareAnalisiMaterialita" [value]="mate">{{mate.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idMaterialita')" matSuffix mat-icon-button type="button"
                                aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idMaterialita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button mat-stroked-button
                            *ngIf="!formPianoSostenibilita.get('id')?.value && !loadingAnalisiMaterialita && formPianoSostenibilita.get('settori')?.value && !arrayAnalisiMaterialita.length"
                            (click)="addMaterialita()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Analisi di Materialità
                        </button>


                        <mat-label style="font-size: 14px;"
                            *ngIf="!formPianoSostenibilita.get('id')?.value && !loadingAnalisiMaterialita && !formPianoSostenibilita.get('settori')?.value">
                            Seleziona anno e settore per aggiungere altre attività
                        </mat-label>
                    </div>

                    <div appFormElement [larghezza]="4">
                        <mat-spinner [diameter]="30" *ngIf="loadingAutovalutazione"></mat-spinner>
                        <mat-form-field
                            *ngIf="!loadingAutovalutazione && (formPianoSostenibilita.get('id')?.value || (formPianoSostenibilita.get('settori')?.value && formPianoSostenibilita.get('anno')?.value && arrayAnalisiAutovalutazione.length))"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneAssessment()}}</mat-label>
                            <mat-select formControlName="idAutovalutazioneCompilata"
                                [placeholder]="arrayAnalisiAutovalutazione.length > 0 ? 'Assessment' : 'Nessun assessment selezionato'"
                                (selectionChange)="onSelectionChangeAutovalutazione($event)">
                                <mat-option *ngFor="let auto of arrayAnalisiAutovalutazione"
                                    [compareWith]="compareAnalisiAutovalutazione"
                                    [value]="auto">{{auto.titolo}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idAutovalutazioneCompilata')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idAutovalutazioneCompilata', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>



                    <div appFormElement [larghezza]="12" class="segnalazione" [ngClass]="{'show': mostraDescrizione}">
                        <div style="display: flex;">
                            <mat-icon>error</mat-icon>
                        </div>
                        <div class="descrizione">
                            {{guidaDescrizione}}
                        </div>
                    </div>

                </form>

            </ng-scrollbar>
        </mat-step>

        <!-- SELEZIONE TEMATICHE   -->
        <mat-step [editable]="true" errorMessage="Campi obbligatori">
            <ng-template matStepLabel>
                Tematiche
            </ng-template>

            <app-cfg-questionario #surveyComp [tipoQuestionario]="'PIANO'" [cfgQuestionario]="piano"
                [idSostenibilita]="formPianoSostenibilita.get('id')?.value"
                [nascondiTitolo]="true"></app-cfg-questionario>

        </mat-step>
        <!--  ---------------------- O B I E T T I V I ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Obiettivi
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione degli Obiettivi
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona gli obiettivi da seguire per ridurre il proprio impatto ambientale e per promuovere la
                        sostenibilità.
                    </div>

                </div>
            </div>

            <app-selezione-obiettivi-piano [objQuestionario]="objQuestionarioObiettivi" chiaveElemento="elementi"
                [idContest]="this.formPianoSostenibilita.get('id')?.value" [stato]="piano?.stato"
                chiaveElementoSelezione="obiettivo">
            </app-selezione-obiettivi-piano>

        </mat-step>

        <!--  ---------------------- T A R G E T ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Target
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Lega i tuoi Obiettivi ai Target
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Rivedi gli obiettivi che hai selezionato e legali almeno a un tipo di target proposto
                    </div>

                </div>
            </div>

            <app-lista-tabellare #tabellaTarget [fnCaricamentoDati]="fnCaricamentoDatiTarget" [colonne]="colonneTarget"
                [maxNrButton]="1" [isFiltri]="false" [fnCaricamentoStyle]="fnCaricamentoStyleTarget"
                (bottoniListaClick)="bottoniListaClickTarget($event)">
            </app-lista-tabellare>

            <ng-container *ngIf="isTargetNonCompletto">
                <div class="info-risposta">
                    <span class="material-icons icone-tem icone-label-chack info-icon">
                        info
                    </span>
                    <mat-label class="risposta-read-only" appFormElement [larghezza]="3" [riempi]="true">Attenzione:
                        alcuni
                        obiettivi non sono stati legati ad alcun tipo di target
                    </mat-label>
                </div>
            </ng-container>
        </mat-step>

        <!--  ---------------------- C R U S C O T T O ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Cruscotto
            </ng-template>
            <div class="barra-titolo-cruscotto">

                <div style="display: flex;flex-direction: column;flex: 1;">
                    <div class="titolo-sezione-dialog">Cruscotto Piano di Sostenibilità</div>

                    <div class="descrizione-sezione-dialog">Gestisci, Invia e Ricorda. Tutto per la tua organizzazione.
                    </div>
                </div>

                <div class="contatori">
                    <div style="text-align: center;">
                        <div class="titolo-cont">T. QUALITATIVI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{tQualitativiCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{tQualitativiNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">T. QUANTITATIVI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{tQuantitativiCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">
                                    {{tQuantitativiNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ng-scrollbar>
                <div style="padding-right: 15px;">
                    <app-lista-tabellare #tabellaCruscotto [defaultPageSize]="4"
                        [fnCaricamentoDati]="fnCaricamentoDatiCruscotto" [colonne]="colonneCruscotto" [maxNrButton]="1"
                        [filtri]="filtriCruscotto" (bottoniListaClick)="bottoniListaClickCruscotto($event)"
                        *ngIf="this.formPianoSostenibilita.get('id')?.value">
                        <ng-container btnEnd>

                            <button mat-flat-button class="primary-button" color="primary" (click)="template()">
                                <mat-icon matSuffix>settings</mat-icon>
                                Testi Mail
                            </button>

                            <button mat-flat-button class="primary-button" color="primary" (click)="inviaTutti()">
                                <mat-icon matSuffix>mail</mat-icon>
                                Invia a Tutti
                            </button>

                            <button mat-flat-button class="primary-button" color="primary" (click)="notificaTutti()">
                                <mat-icon matSuffix>notifications_active</mat-icon>
                                Notifica Tutti
                            </button>

                        </ng-container>
                    </app-lista-tabellare>
                </div>
            </ng-scrollbar>

        </mat-step>
        <!--  ---------------------- D O C U M E N T O  --------- -->
        <mat-step>
            <ng-template matStepLabel>
                Documento
            </ng-template>

            <mat-tab-group class="mat-tab-group-documento">
                <mat-tab label="Scrittura Documento">
                    <div appFormContainer [larghezza]="2" style="align-items: center; padding-top: 20px;">

                        <div appFormElement [larghezza]="1" [riempi]="true">
                            <div class="titolo-sezione-dialog">
                                Scrittura Documento
                            </div>
                            <div class="descrizione-sezione-dialog">
                                La tua strategia di sostenibilità: definisci chiaramente obiettivi, azioni specifiche e
                                misurazioni dettagliate dei risultati.
                            </div>

                        </div>
                    </div>
                    <div class="paragrafo" style="flex:1"  *ngIf="objDocumento">

                            <app-editor [(ngModel)]="objDocumento.scritturaDocumento"></app-editor>

                    </div>
                </mat-tab>

                <mat-tab label="Obiettivi Aziendali">
                    <div appFormContainer [larghezza]="2"
                        style="align-items: center; padding-top: 15px; padding-bottom: 10px;">

                        <div appFormElement [larghezza]="1" [riempi]="true">
                            <div class="titolo-sezione-dialog">
                                Selezione degli Obiettivi Aziendali
                            </div>
                            <div class="descrizione-sezione-dialog">
                                Seleziona i tuoi traguardi strategici per raggiungere e per migliorare il tuo impatto
                                ambientale e sociale.
                            </div>
                        </div>
                    </div>

                    <div class="cont-titolo">
                        <mat-label class="titolo">Obiettivi Aziendali</mat-label>
                    </div>
                    <div style="flex: 1;padding: 10px 0 10px 10px;">
                        <ng-scrollbar>
                            <div appFormContainer [larghezza]="3"
                                *ngFor="let elemento of objDocumento?.obiettiviAziendaliPiano">
                                <div appFormElement [larghezza]="0.7" [riempi]="true">
                                    <mat-checkbox [(ngModel)]="elemento.selected" [checked]="elemento.selected"
                                        (mousedown)="$event.stopPropagation()" color="primary">
                                        <div class="nome-figura">{{elemento.descrizione}}</div>
                                    </mat-checkbox>
                                </div>

                                <button class="icone-button" mat-icon-button
                                    (click)="modificaObiettivoAziendale(elemento); $event.stopPropagation();"
                                    title="Compila" [attr.aria-label]="'Compila'">
                                    <mat-icon class="icone">
                                        edit
                                    </mat-icon>
                                </button>
                            </div>
                        </ng-scrollbar>
                    </div>
                    <div class="btn-aggiungi-nuovo">
                        <button mat-stroked-button (click)="modificaObiettivoAziendale()"
                            style="height: 40px;width: 95%;font-weight: bold; background-color: #F5F5F5;">
                            + Aggiungi Obiettivi Aziendali
                        </button>
                    </div>

                </mat-tab>
                <mat-tab label="Modelli di Sviluppo Piano">
                    <div appFormContainer [larghezza]="2"
                        style="align-items: center; padding-top: 15px; padding-bottom: 10px;">

                        <div appFormElement [larghezza]="1" [riempi]="true">
                            <div class="titolo-sezione-dialog">
                                Selezione dei Modelli di Sviluppo Piano
                            </div>
                            <div class="descrizione-sezione-dialog">
                                Seleziona gli approcci da utilizzare per pianificare e implementare strategie
                                sostenibili.
                            </div>
                        </div>
                    </div>

                    <div class="cont-titolo">
                        <mat-label class="titolo">Modelli di Sviluppo Piano</mat-label>
                    </div>

                    <div style="flex: 1; padding: 10px 0 10px 10px;">
                        <ng-scrollbar>
                            <div appFormContainer [larghezza]="3"
                                *ngFor="let elemento of objDocumento?.modelliSviluppoPiano">
                                <div appFormElement [larghezza]="0.7" [riempi]="true">
                                    <mat-checkbox [(ngModel)]="elemento.selected" [checked]="elemento.selected"
                                        (mousedown)="$event.stopPropagation()" color="primary">
                                        <div class="nome-figura">{{elemento.descrizione}}</div>
                                    </mat-checkbox>
                                </div>

                                <button class="icone-button" mat-icon-button
                                    (click)="modificaModelloSviluppo(elemento); $event.stopPropagation();"
                                    title="Compila" [attr.aria-label]="'Compila'">
                                    <mat-icon class="icone">
                                        edit
                                    </mat-icon>
                                </button>
                            </div>
                        </ng-scrollbar>

                    </div>
                    <div class="btn-aggiungi-nuovo">
                        <button mat-stroked-button (click)="modificaModelloSviluppo()"
                            style="height: 40px;width: 95%;font-weight: bold; background-color: #F5F5F5;">
                            + Aggiungi Modelli di Sviluppo Piano
                        </button>
                    </div>
                </mat-tab>
            </mat-tab-group>


        </mat-step>
    </mat-stepper>

</div>

<footer mat-dialog-actions class="dialog-footer-cont">

    <button mat-flat-button color="accent" [disabled]="stepper.selectedIndex === 0" (click)="indietro()"
        class="accent-button">Indietro</button>

    <button mat-flat-button color="primary" (click)="avanti()" class="primary-button" #btnAvanti
        *ngIf="stepper.selectedIndex !== 5">
        Avanti
    </button>

    <button mat-flat-button color="primary" (click)="salvaDocumentoInHTML()" class="primary-button"
        *ngIf="stepper.selectedIndex === 5">
        Salva
    </button>


    <button *ngIf="stepper.selectedIndex === 3" mat-flat-button color="primary" (click)="assegnazioni()"
        class="primary-button">
        <mat-icon>person_edit</mat-icon> Assegnazioni
    </button>

    <button *ngIf="stepper.selectedIndex === 5" mat-flat-button color="accent" (click)="stampa()" class="accent-button"
        style="margin-left: 10px !important;">
        <mat-icon class="material-icons-outlined">
            print
        </mat-icon>Stampa
    </button>
</footer>
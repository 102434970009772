import { Injectable } from '@angular/core';
import { SintesiWebService } from './sintesi-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Settore, SottoSettore } from '../settori/settori.service';
import { Ambito } from '../ambiti/ambiti.service';
import { ValoriKpi } from '../KPI/kpi.service';
import { GruppoCfgStampa } from '../config-stampe/config-stampe.service';

export interface AnalisiMaterialita {
  idMaterialita: string,
  nome: string
  anno: string
}
export interface PolicySelection {
  idPolicy: string,
  nome: string
  anno: string
}
export interface PianoSelection {
  idPiano: string,
  nome: string
  anno: string
}

export interface SintesiSostenibilita {
  id?: string;
  idAzienda?: string;
  anno?: string;
  fatturato?: number;
  nrDipendenti?: number;
  idMaterialita?: string;
  idQuestionarioSintesi?: string;
  idPolicySostenibilita: string,
  idPianoSostenibilita: string,
  nome?: string;
  idAutovalutazioneCompilata: string;
}

export interface ElementiCruscotto {
  compilato: boolean;
  creatoDaContesto: boolean;
  dataCompilazione: string;
  descrizioneTipo: string;
  figura: string;
  id: string;
  nome: string;
  selected: boolean;
  tipo: 'Impegno' | 'Kpi' | 'Impatto' | 'AzioniEBuonePratiche';
  idTematica: string;
  idAmbito: string;

  idObiettivo?: string;

  descrizione: string;
  note: string;
  risposta: string;
  rispostaBreve: string;
  settori: Settore[]
  tag: string;

  titolo: string;
  valoreAttribuito: number;

  idUnitaDiRiferimento: string;
  idCategoriaUnitaDiMisura: string;
  valoriKpi?: ValoriKpi[];
}

export interface CompilazioneSintesiSostenibilita {
  descrizioneAmbito: string;
  descrizioneTematica: string;
  elemento: ElementiCruscotto
  idAmbito: string;
  idTematica: string;
  titoloAmbito: string;
  titoloTematica: string;
}

@Injectable({
  providedIn: 'root'
})
export class SintesiService {

  constructor(private sintesiWebService: SintesiWebService) { }



  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getSintesi(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.sintesiWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
 *  ritorna sintesi pubblicate
 * @returns 
 */
  public getSintesiPubblicate(idSettore?: string, idSottoSettori?: string[]) {
    return this.sintesiWebService.getSintesiPubblicate(idSettore, idSottoSettori);
  }

  /**
    * Funzione per eliminare una riga da una lista 
  * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteSintesi(idRiga: any) {
    return this.sintesiWebService.delete(idRiga);
  }

  public getAnteprimaSintesiCompilata(idSintesi: string, idAzienda: string) {
    return this.sintesiWebService.getAnteprimaSintesiCompilata(idSintesi, idAzienda);
  }

  /* --------------------------- FIGURE AZIENDALI ---- step1 ---------------*/
  public getListaAssegnazioniFigAz(idSintesi: string) {
    return this.sintesiWebService.getListaAssegnazioniFigAz(idSintesi);
  }



  /* --------------------------- C R E A Z I O N E   S I N T E S I ---- step1 ---------------*/
  /**
   * 
   * @param sintesiSostenibilita oggetto sintesi step1
   * @returns 
   */
  public postCreazioneSintesiStep1(sintesiSostenibilita: any) {
    return this.sintesiWebService.postCreazioneSintesiStep1(sintesiSostenibilita);
  }
  /**
   * 
   * @param sintesiSostenibilita oggetto sintesi step1
   * @returns 
   */
  public putAggiornamentoSintesiStep1(idSintesi: string, fatturato: number, nrDipendenti: number) {
    return this.sintesiWebService.putAggiornamentoSintesiStep1(idSintesi, fatturato, nrDipendenti);
  }

  /* ------------------------- T E M A T I C H E step2 -------------------------------- */


  public getSintesiSurveyStep2(idSintesi: string) {
    return this.sintesiWebService.getSintesiSurveyStep2(idSintesi);
  }

  /**
 * 
 * @param sintesiSostenibilita oggetto sintesi step1
 * @returns 
 */
  public putSintesiSurveyStep2(idSintesi: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], sottoSettori: SottoSettore[], titolo: string, stato: string, tipo: string) {
    return this.sintesiWebService.putSintesiSurveyStep2(idSintesi, idSurvey, ambiti, settori, sottoSettori, titolo, stato, tipo);
  }



  /* ------------------------- I M P E G N I step3 -------------------------------- */

  /**
    * GET IMPEGNI SINTESI
    * @param idSintesiSostenibilita 
    * @returns obj con lista ambiti - tematiche - impegni
    */
  public getImpegniSintesi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getImpegniSintesi(idSintesiSostenibilita);
  }

  /**
   * SALVATAGGIO IMPEGNI SINTESI SELEZIONATI
   * @param idSintesiSostenibilita 
   * @param idAmbito 
   * @param idTematica 
   * @param idImpegni 
   * @returns  Status 200 (senza body)
   */

  public putImpegniSintesiSelezionati(idSintesiSostenibilita: string, impegniSelezionati: any) {
    return this.sintesiWebService.putImpegniSintesiSelezionati(idSintesiSostenibilita, impegniSelezionati);
  }

  /**
    * CREAZIONE IMPEGNO DALLA SINTESI (esisterà solo nella sintesi)
    * @param idSintesiSostenibilita 
    * @param impegno
    * @returns <idImpegno creato>
    */
  public postImpegniSintesi(idSintesiSostenibilita: string, impegno: any) {
    return this.sintesiWebService.postImpegniSintesi(idSintesiSostenibilita, impegno);
  }

  /**
    * MODIFICA IMPEGNO NELLA SINTESI (non sincronizzare - la modifica esisterà solo nella sintesi)
    * @param idSintesiSostenibilita 
    * @param idImpegno
    * @returns <idImpegno modificato>
    */
  public putImpegnoSintesiModificato(idSintesiSostenibilita: string, idImpegno: string) {
    return this.sintesiWebService.putImpegnoSintesiModificato(idSintesiSostenibilita, idImpegno);
  }

  /**
    * ASSEGNA FIGURA AD IMPEGNO SINTESI
    * @param idSintesiSostenibilita 
    * @param impegno
    * @returns <Status 200 (senza body)>
    */
  public putAssegnaFiguraImpegnoSintesi(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idImpegno: string, idFigura: string) {
    return this.sintesiWebService.putAssegnaFiguraImpegnoSintesi(idSintesiSostenibilita, idAmbito, idTematica, idImpegno, idFigura);
  }

  /**
    * GET ASSEGNAZIONI IMPEGNI SINTESI (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
    * @param idSintesiSostenibilita 
    * @returns List<{idTematica: string, nomeBreve: string, // nomeBreve della tematica  impegni: List<ImpegnoSinstesi>
    */
  public getAssegnazioniImpegni(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getAssegnazioniImpegni(idSintesiSostenibilita);
  }

  public putImpegniEffimeri(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, impegno: any) {
    return this.sintesiWebService.putImpegniEffimeri(idSintesiSostenibilita, idAmbito, idTematica, impegno);
  }
  /* ------------------------- AZIONIE E BUONE PRATICHE  step4-------------------------------- */
  /**
   * GET AZIONE E BUONE PRATICHE SINTESI
   * @param idSintesiSostenibilita 
   * @returns obj con lista ambiti - tematiche - azioni-buone-pratiche
   */
  public getAzioneBPSintesi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getAzioneBPSintesi(idSintesiSostenibilita);
  }

  /**
  * SALVATAGGIO AZIONE E BUONE PRATICHE SINTESI SELEZIONATI
  * @param idSintesiSostenibilita 
  * @param idAmbito 
  * @param idTematica 
  * @param idAzioneBP 
  * @returns  Status 200 (senza body)
  */
  public putAzioneBPSintesiSelezionati(idSintesiSostenibilita: string, azioneBPSelezionate: any) {
    return this.sintesiWebService.putAzioneBPSintesiSelezionati(idSintesiSostenibilita, azioneBPSelezionate);
  }

  /**
  * CREAZIONE AZIONE E BUONE PRATICHE DALLA SINTESI (esisterà solo nella sintesi)
  * @param idSintesiSostenibilita 
  * @param azioneBP
  * @returns <idAzioniBP creato>
  */
  public postAzioneBPSintesi(idSintesiSostenibilita: string, azioneBP: any) {
    return this.sintesiWebService.postAzioneBPSintesi(idSintesiSostenibilita, azioneBP);
  }

  /**
  * MODIFICA AZIONE E BUONE PRATICHE NELLA SINTESI (non sincronizzare - la modifica esisterà solo nella sintesi)
  * @param idSintesiSostenibilita 
  * @param idAzioneBP
  * @returns <idAzioneBP modificato>
  */
  public putAzioniBPSintesiModificato(idSintesiSostenibilita: string, idAzioneBP: string) {
    return this.sintesiWebService.putAzioniBPSintesiModificato(idSintesiSostenibilita, idAzioneBP);
  }

  /**
  * ASSEGNA FIGURA AD AZIONE E BUONE PRATICHE SINTESI
  * @param idSintesiSostenibilita 
  * @param azioneBP
  * @returns <Status 200 (senza body)>
  */

  public putAssegnaFiguraAzioniBPSintesi(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idAzioniBP: string, idFigura: string) {
    return this.sintesiWebService.putAssegnaFiguraAzioniBPSintesi(idSintesiSostenibilita, idAmbito, idTematica, idAzioniBP, idFigura);
  }
  /**
  * GET ASSEGNAZIONI AZIONE E BUONE PRATICHE SINTESI (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
  * @param idSintesiSostenibilita 
  * @returns List<{idTematica: string, nomeBreve: string, // nomeBreve della tematica  azioni-buone-pratiche: List<AzioniBPSinstesi>
  */
  public getAssegnazioniAzioneBP(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getAssegnazioniAzioneBP(idSintesiSostenibilita);
  }

  public putAzioneBPEffimeri(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, azioneBP: any) {
    return this.sintesiWebService.putAzioneBPEffimeri(idSintesiSostenibilita, idAmbito, idTematica, azioneBP);
  }


  /* ------------------------- K P I step5-------------------------------- */
  /**
   * GET KPI SINTESI
   * @param idSintesiSostenibilita 
   * @returns obj con lista ambiti - tematiche - kpi
   */
  public getKpiSintesi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getKpiSintesi(idSintesiSostenibilita);
  }

  public getAnniPrecKpi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getAnniPrecKpi(idSintesiSostenibilita);
  }
  public postAnniPrecKpi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.postAnniPrecKpi(idSintesiSostenibilita);
  }

  public delAnniPrecKpi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.delAnniPrecKpi(idSintesiSostenibilita);
  }

  /**
   * SALVATAGGIO KPI SINTESI SELEZIONATI
   * @param idSintesiSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putKpiSintesiSelezionati(idSintesiSostenibilita: string, kpiSelezionate: any) {
    return this.sintesiWebService.putKpiSintesiSelezionati(idSintesiSostenibilita, kpiSelezionate);
  }

  /**
  * CREAZIONE KPI DALLA SINTESI (esisterà solo nella sintesi)
  * @param idSintesiSostenibilita 
  * @param kpi
  * @returns <idKpi creato>
  */
  public postKpiSintesi(idSintesiSostenibilita: string, kpi: any) {
    return this.sintesiWebService.postKpiSintesi(idSintesiSostenibilita, kpi);
  }

  /**
  * MODIFICA KPI NELLA SINTESI (non sincronizzare - la modifica esisterà solo nella sintesi)
  * @param idSintesiSostenibilita 
  * @param idKpi
  * @returns <idKpi modificato>
  */
  public putKpiSintesiModificato(idSintesiSostenibilita: string, idKpi: string) {
    return this.sintesiWebService.putKpiSintesiModificato(idSintesiSostenibilita, idKpi);
  }

  /**
  * ASSEGNA FIGURA AD KPI SINTESI
  * @param idSintesiSostenibilita 
  * @param kpi
  * @returns <Status 200 (senza body)>
  */

  public putAssegnaFiguraKpiSintesi(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idKpi: string, idFigura: string) {
    return this.sintesiWebService.putAssegnaFiguraKpiSintesi(idSintesiSostenibilita, idAmbito, idTematica, idKpi, idFigura);
  }
  /**
  * GET ASSEGNAZIONI KPI SINTESI (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
  * @param idSintesiSostenibilita 
  * @returns List<{idTematica: string, nomeBreve: string, // nomeBreve della tematica  kpi: List<KpiSinstesi>
  */
  public getAssegnazioniKpi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getAssegnazioniKpi(idSintesiSostenibilita);
  }

  public putKpiEffimeri(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, kpi: any) {
    return this.sintesiWebService.putKpiEffimeri(idSintesiSostenibilita, idAmbito, idTematica, kpi);
  }

  /* ------------------------- C E R T I F I C A Z I O N I  */
  /**
  * GET CERTIFICAZIONI SINTESI
  * @param idSintesiSostenibilita 
  * @returns obj con lista ambiti - tematiche - certificazioni
  */
  public getCertificazioniSintesi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getCertificazioniSintesi(idSintesiSostenibilita);
  }

  /**
  * SALVATAGGIO CERTIFICAZIONI SINTESI SELEZIONATI
  * @param idSintesiSostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putCertificazioniSintesiSelezionate(idSintesiSostenibilita: string, certificazioniSelezionate: any) {
    return this.sintesiWebService.putCertificazioniSintesiSelezionate(idSintesiSostenibilita, certificazioniSelezionate);
  }

  /**
  * ASSEGNA FIGURA AD CERTIFICAZIONI SINTESI
  * @param idSintesiSostenibilita 
  * @param sintesi
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraCertificazioniSintesi(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idCertificazioni: string, idFigura: string) {
    return this.sintesiWebService.putAssegnaFiguraCertificazioniSintesi(idSintesiSostenibilita, idAmbito, idTematica, idCertificazioni, idFigura);
  }

  public putCertificazioniEffimeri(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, certificazioni: any) {
    return this.sintesiWebService.putCertificazioniEffimeri(idSintesiSostenibilita, idAmbito, idTematica, certificazioni);
  }



  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public getCruscotti(
    idSintesiSostenibilita: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.sintesiWebService.getCruscotti(idSintesiSostenibilita, page, pageSize, ricerca, filters, sortBy);
  }

  /**
   * Cambio figura
   * @param idSintesiSostenibilita 
   * @param idFiguraPrecedente 
   * @param idFiguraNuova 
   * @returns 
   */
  putCambiaFigura(idSintesiSostenibilita: string, idFiguraPrecedente: string, idFiguraNuova: string) {
    return this.sintesiWebService.putCambiaFigura(idSintesiSostenibilita, idFiguraPrecedente, idFiguraNuova);
  }


  public putTemplateMail(idSintesiSostenibilita: string, templateMail: any) {
    return this.sintesiWebService.putTemplateMail(idSintesiSostenibilita, templateMail);
  }


  public getTemplateMail(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getTemplateMail(idSintesiSostenibilita);
  }


  public getTotaliCruscotto(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getTotaliCruscotto(idSintesiSostenibilita);
  }

  public getElementoDaCompilare(idSintesiSostenibilita: string, idAmbito: string, idTematica: string, idElemento: string) {
    return this.sintesiWebService.getElementoDaCompilare(idSintesiSostenibilita, idAmbito, idTematica, idElemento);
  }

  /**
  * GET Configurazioni stampa SINTESI (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
  * @param idSintesiSostenibilita 
  * @returns List<GruppoConfigRisultatiSintesiValori>
  */
  public getConfigStampa(idSintesiSostenibilita: string) {
    return this.sintesiWebService.getConfigStampa(idSintesiSostenibilita);
  }


  public postStampaSintesi(idSintesi: string) {
    return this.sintesiWebService.postStampaSintesi(idSintesi);
  }

  public postSalvaElementiStampa(idSintesiSostenibilita: string, cfgStampaSintesi: GruppoCfgStampa) {
    return this.sintesiWebService.postSalvaElementiStampa(idSintesiSostenibilita, cfgStampaSintesi);
  }

  public postElementoDaCompilare(
    idSintesiSostenibilita: string,
    idAmbito: string,
    idTematica: string,
    idElemento: string,
    risposta: string | number,
    idCategoriaUnitaDiMisura?: string,
    idUnitaDiRiferimento?: string
  ) {
    return this.sintesiWebService.postElementoDaCompilare(
      idSintesiSostenibilita,
      idAmbito,
      idTematica,
      idElemento,
      risposta,
      idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento);
  }


  public postElementiEsterniDaCompilare(temporaryToken: string, objEsterna: any[]) {
    return this.sintesiWebService.postElementiEsterniDaCompilare(temporaryToken, objEsterna);
  }


  public getElementiEsterniDaCompilare(temporaryToken: string) {
    return this.sintesiWebService.getElementiEsterniDaCompilare(temporaryToken);
  }


  public inviaMailMultiple(idSintesiSostenibilita: string) {
    return this.sintesiWebService.inviaMailMultiple(idSintesiSostenibilita);
  }

  public inviaMail(idSintesiSostenibilita: string, idFigura: string) {
    return this.sintesiWebService.inviaMail(idSintesiSostenibilita, idFigura);
  }

  public inviaNotificaMultiple(idSintesiSostenibilita: string) {
    return this.sintesiWebService.inviaNotificaMultiple(idSintesiSostenibilita);
  }

  public inviaNotifica(idSintesiSostenibilita: string, idFigura: string) {
    return this.sintesiWebService.inviaNotifica(idSintesiSostenibilita, idFigura);
  }
  public invioTestMail(messaggio: string, email: string, idSintesi: string) {
    return this.sintesiWebService.invioTestMail(messaggio, email, idSintesi);
  }

  public pubblicaSintesi(idSintesiSostenibilita: string) {
    return this.sintesiWebService.pubblicaSintesi(idSintesiSostenibilita);
  }
}

import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit, AfterViewInit {

  @ViewChild('tooltipContent') tooltipContent!: ElementRef<HTMLElement>;
  val = true;

  /**
   * Elemento sul quale è applicata la direttiva 
   */
  @Input()
  trigger: TemplateRef<any> | null = null;

  @Input()
  contentTmpl: TemplateRef<any> | null = null;

  @Input()
  contentText: string | null = null;

  @Input()
  posizione: string | undefined;

  @Input()
  sizeContainer: number | undefined;

  @Input()
  offsetTooltip: number | undefined;

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.updateTooltipSize();
  }
  /**
   * Calcolo la dimensione del tooltip in base al contenuto che ha.
   * Dopo setto la classe assolito che ha un position: absolute; 
   * Questa posizione viene settata dopo il calcolo della largheza poichè settandola prima 
   * prende una dimensoine sbagliata
   */
  updateTooltipSize() {
    const tooltipElement = this.tooltipContent.nativeElement;
    const tooltipWidth = tooltipElement.clientWidth !== 0 ? tooltipElement.clientWidth : this.sizeContainer;


    // Aggiorna le proprietà CSS dinamiche in base alle dimensioni calcolate
    tooltipElement.style.setProperty('--tooltip-width', `${tooltipWidth}px`);
    tooltipElement.style.setProperty('--tooltip-offset', `${this.offsetTooltip}px`);
    /*    tooltipElement.style.setProperty('--tooltip-height', `${tooltipHeight}px`); */

    tooltipElement.classList.add('assoluto');
  }
}

import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, switchMap, throwError, of, map, catchError, finalize, filter, take, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  public tokenSubject = new BehaviorSubject<string | null>(null);
  public isRefreshingToken = false;

  private _urlServer: string = environment.serverUrl;

  constructor(
    private utilityService: UtilityService,
    private httpClient: HttpClient,
    private router: Router
  ) { }


  doGet(
    endpoint: string,
    data: { [key: string]: any },
    tryRefreshTokenWhen401 = true
  ): Observable<any> {

    const url = this._urlServer + endpoint;

    const headers: {
      [header: string]: string | string[];
    } = {
      'Content-Type': 'application/json',
    };


    const request = this.httpClient.get(url, {
      headers,
      observe: 'response',
      params: data,
      responseType: 'json',
      withCredentials: true
    });


    return request.pipe(
      switchMap((val) => {
        // lancia un errore se lo status è diverso da 2xx
        if (val.status < 200 || val.status > 299) {
          return throwError(() => val);
        }
        return of(val.body);
      }),
      catchError(err => {
        console.error(err);
        if (err?.status === 401) {
          if (tryRefreshTokenWhen401) {
            return this.handle401Error().pipe(switchMap((tokenRinnovato) => {
              if (tokenRinnovato) {
                return this.doGet(endpoint, data);
              } else {
                return throwError(() => err);
              }
            }));
          } else {
            return throwError(() => err);
          }
        } else {
          return throwError(() => err);
        }
      })
    );

  };


  doPost(
    endpoint: string,
    data: { [key: string]: any },
    params?: { [key: string]: any } | undefined,
    tryRefreshTokenWhen401 = true
  ): Observable<any> {

    const url = this._urlServer + endpoint;

    const headers: {
      [header: string]: string | string[];
    } = {
      'Content-Type': 'application/json'
    };

    const request = this.httpClient.post(url, data, {
      headers,
      observe: 'response',
      params: params,
      responseType: 'json',
      withCredentials: true
    });


    return from(request).pipe(
      switchMap((val) => {
        // lancia un errore se lo status è diverso da 2xx
        if (val.status < 200 || val.status > 299) {
          return throwError(() => val);
        }
        return of(val.body);
      }),
      catchError(err => {
        console.error(err);
        if (err?.status === 401) {
          if (tryRefreshTokenWhen401) {
            return this.handle401Error().pipe(switchMap((tokenRinnovato) => {
              if (tokenRinnovato) {
                return this.doPost(endpoint, data);
              } else {
                return throwError(() => err);
              }
            }));
          } else {
            return throwError(() => err);
          }
        } else {
          return throwError(() => err);
        }
      })
    );

  }


  doPut(
    endpoint: string,
    body?: { [key: string]: any } | undefined,
    queryParams?: { [key: string]: any } | undefined,
    tryRefreshTokenWhen401 = true
  ): Observable<any> {

    const url = this._urlServer + endpoint;

    const headers: {
      [header: string]: string | string[];
    } = {
      'Content-Type': 'application/json'
    };

    const request = this.httpClient.put(url, body, {
      headers,
      observe: 'response',
      params: queryParams,
      responseType: 'json',
      withCredentials: true
    });


    return from(request).pipe(
      switchMap((val) => {
        // lancia un errore se lo status è diverso da 2xx
        if (val.status < 200 || val.status > 299) {
          return throwError(() => val);
        }
        return of(val.body);
      }),
      catchError(err => {
        console.error(err);
        if (err?.status === 401) {
          if (tryRefreshTokenWhen401) {
            return this.handle401Error().pipe(switchMap((tokenRinnovato) => {
              if (tokenRinnovato) {
                return this.doPut(endpoint, body, queryParams);
              } else {
                return throwError(() => err);
              }
            }));
          } else {
            return throwError(() => err);
          }
        } else {
          return throwError(() => err);
        }
      })
    );

  }


  doDelete(
    endpoint: string,
    data: { [key: string]: any },
    tryRefreshTokenWhen401 = true
  ): Observable<any> {

    const url = this._urlServer + endpoint;

    const headers: {
      [header: string]: string | string[];
    } = {
      'Content-Type': 'application/json'
    };

    const request = this.httpClient.delete(url, {
      headers,
      params: data,
      observe: 'response',
      responseType: 'json',
      withCredentials: true
    });


    return from(request).pipe(
      switchMap((val) => {
        // lancia un errore se lo status è diverso da 2xx
        if (val.status < 200 || val.status > 299) {
          return throwError(() => val);
        }
        return of(val.body);
      }),
      catchError(err => {
        console.error(err);
        if (err?.status === 401) {
          if (tryRefreshTokenWhen401) {
            return this.handle401Error().pipe(switchMap((tokenRinnovato) => {
              if (tokenRinnovato) {
                return this.doDelete(endpoint, data);
              } else {
                return throwError(() => err);
              }
            }));
          } else {
            return throwError(() => err);
          }
        } else {
          return throwError(() => err);
        }
      })
    );

  }

  uploadFile(endpoint: string, file: File, data: { [key: string]: string }, tryRefreshTokenWhen401 = true): Observable<{
    inProgress: boolean;
    progress: number;
    type: number;
    body?: any; // Aggiunto il campo 'body'
  }> {

    const url = this._urlServer + endpoint;

    const formData = new FormData();

    formData.append('file', file);

    if (data) {
      Object.keys(data).forEach((val) => {
        formData.append(val, data[val].toString());
      });
    }

    return this.httpClient.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events',
      withCredentials: true,
    }).pipe(
      map(event => {
        
        // Aggiunto il campo 'body' nell'oggetto restituito
        const result: {
          inProgress: boolean;
          progress: number;
          type: number;
          body?: any;
        } = {
          inProgress: false,
          progress: 0,
          type: 0
        };

        switch (event.type) {
          case HttpEventType.UploadProgress:
            result.inProgress = true;
            result.progress = Math.round((event.loaded * 100) / (event?.total || 0));
            result.type = event.type;
            break;
          case HttpEventType.Response:
            result.inProgress = false;
            result.progress = 100;
            result.type = event.type;
            result.body = event.body; // Imposta il corpo della risposta
            break;
          default:
            break;
        }

        return result;
      }),
      filter((value) => value !== undefined),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error?.status === 401) {
          if (tryRefreshTokenWhen401) {
            return this.handle401Error().pipe(switchMap((tokenRinnovato) => {
              if (tokenRinnovato) {
                return this.uploadFile(endpoint, file, data, false);
              } else {
                return throwError(() => error);
              }
            }));
          } else {
            return throwError(() => error);
          }
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  uploadImgPut(endpoint: string, file: File, data: { [key: string]: string }, tryRefreshTokenWhen401 = true, tipoImportazione: string): Observable<{
    inProgress: boolean;
    progress: number;
    type: number;
    body?: any; // Aggiunto il campo 'body'
  }> {

    const url = this._urlServer + endpoint;

    const formData = new FormData();

    formData.append(tipoImportazione, file);

    if (data) {
      Object.keys(data).forEach((val) => {
        formData.append(val, data[val].toString());
      });
    }

    return this.httpClient.put<any>(url, formData, {
      reportProgress: true,
      observe: 'events',
      withCredentials: true,
    }).pipe(
      map(event => {
        
        // Aggiunto il campo 'body' nell'oggetto restituito
        const result: {
          inProgress: boolean;
          progress: number;
          type: number;
          body?: any;
        } = {
          inProgress: false,
          progress: 0,
          type: 0
        };

        switch (event.type) {
          case HttpEventType.UploadProgress:
            result.inProgress = true;
            result.progress = Math.round((event.loaded * 100) / (event?.total || 0));
            result.type = event.type;
            break;
          case HttpEventType.Response:
            result.inProgress = false;
            result.progress = 100;
            result.type = event.type;
            result.body = event.body; // Imposta il corpo della risposta
            break;
          default:
            break;
        }

        return result;
      }),
      filter((value) => value !== undefined),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error?.status === 401) {
          if (tryRefreshTokenWhen401) {
            return this.handle401Error().pipe(switchMap((tokenRinnovato) => {
              if (tokenRinnovato) {
                return this.uploadFile(endpoint, file, data, false);
              } else {
                return throwError(() => error);
              }
            }));
          } else {
            return throwError(() => error);
          }
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  /* doPostFileUpload(
    endpoint: string,
    data: { [key: string]: any },
    file: any
  ): Observable<any> {

    const formData = new FormData();
    formData.append('file', file, file.name);

    for (var chiave in data) {
      formData.append(chiave, data[chiave]);
    }

    const url = this._urlServer + endpoint;

    const headers: {
      [header: string]: string | string[];
    } = {
      'Content-Type': 'multipart/form-data'
    };

    const request = this.httpClient.post(url, formData, {
      headers,
      observe: 'response',
      responseType: 'json'
    });

    return from(request).pipe(
      switchMap((val) => {
        // lancia un errore se lo status è diverso da 2xx
        if (val.status < 200 || val.status > 299) {
          return throwError(() => val);
        }
        return of(val);
      }),
      map((val) => {
        return val.body;
      }),
      catchError(err => {
        console.error(err);
        if (err?.status === 401) {
          return this.handle401Error().pipe(switchMap((tokenRinnovato) => {
            if (tokenRinnovato) {
              return this.doPostFileUpload(endpoint, data, file);
            } else {
              return throwError(() => err);
            }
          }));
        } else {
          return throwError(() => err);
        }
      })
    );
  } */


  /**
   * Gestione rinnovo accessToken
   * @returns 
   */
  private handle401Error(): Observable<boolean> {

    if (!this.isRefreshingToken) {

      this.tokenSubject.next('');
      this.isRefreshingToken = true;

      // First, get a new access token
      return this.getNewAccessToken().pipe(
        map((_response: any) => {
          return true;
        }),
        catchError((err) => {
          // Impossibile refreshare il token, si slogga
          console.error(err);
          this.logout();
          return of(false);
        }),
        finalize(() => {
          // Unblock the token reload logic when everything is done
          this.isRefreshingToken = false;
        })
      );
    } else {
      // "Queue" other calls while we load a new token
      return this.tokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(_token =>
          // Perform the request again now that we got a new token!
          of(true)
        )
      );
    }
  }

  getNewAccessToken(): Observable<any> {
    return this.doPost('/key/refresh-token', {}, undefined, false);
  }

  logout() {
    this.utilityService.closeDialog();
    this.doPost('/key/logout', {}, {}, false).subscribe();
    this.router.navigate(['/login']);
  }

}

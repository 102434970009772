
<div  role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-right">

    <ng-container *ngTemplateOutlet="trigger"></ng-container>
    <div class="tooltip-content" [ngClass]="posizione" *ngIf="contentTmpl" #tooltipContent>
        <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
    </div>
    <div class="tooltip-content" *ngIf="!contentTmpl" >
        {{contentText}}
    </div>
</div>
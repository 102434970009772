<app-spinner-overlay></app-spinner-overlay>
<div>
    <header>
        <img [src]="logoPath" alt="Sostenibile" class="logo-path" />
    </header>
</div>
<div class="contenitore" *ngIf="!loading && !completato && infoAz">
    <div class="contenuto">
        <app-compila-informativa-aziendale [infoAz]="infoAz" (completato)="finitaCompilazione($event)"
            [inConf]="false"></app-compila-informativa-aziendale>
    </div>

</div>

<div class="compilato" *ngIf="!loading && completato">
    Survey compilato. Grazie della sua disponibilità.
</div>

<div class="compilato" *ngIf="!loading && !completato && messaggio">
    {{messaggio}}
</div>

import { Observable } from 'rxjs';
import { AziendaService } from '../azienda/azienda.service';
import { WebService } from './../web/web.service';
import { Injectable } from '@angular/core';
import { Survey } from '../materialita/materialita.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyWebService {
  private lang = '/it-IT';
  private nomeWs = 'materialita';
  private _idAzienda: string | undefined = undefined;


  constructor(
    private aziendaService: AziendaService,
    private webService: WebService
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
      /*   this._idAzienda = '65a007cb6ec39112abf9d944'; */
    })
  }

  public get(idMaterialita: string, idStakeholder: string, idSurvey: string): Observable<any> {
    return this.webService.doGet('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/'
      + this.nomeWs + '/' + idMaterialita + '/survey-compilate/stakeholder/' + idStakeholder + '/survey/' + idSurvey + '/get', {});
  }

  public getSurveyCompilata(idMaterialita: string, idSurveyCompilato: string): Observable<any> {
    return this.webService.doGet('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/'
      + this.nomeWs + '/' + idMaterialita + '/survey-compilate/survey-compilata/' + idSurveyCompilato + '/get', {});
  }

  public post(idMaterialita: string, idStakeholder: string, idSurvey: string, surveyCompilata: Survey): Observable<any> {

    return this.webService.doPost('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/'
      + this.nomeWs + '/' + idMaterialita + '/survey-compilate/stakeholder/' + idStakeholder + '/survey/' + idSurvey + '/compila',
      surveyCompilata
    );
  }

  /**
 * Ritorna la Survey della Materialità associata per la quale è stata richiesta la compilazione
 * @param token informazioni sono reperite all'interno del temporary token ( dura 24h )
 * @returns 
 */

  public getSurveyEsterna(temporaryToken: string) {
    return this.webService.doGet('/api/v1/compila-survey/get', { temporaryToken: temporaryToken });
  }

  /**
     * Manda la Survey della Materialità compilata per la quale è stata richiesta la compilazione
     * @param token informazioni sono reperite all'interno del temporary token ( dura 24h )
     * @param survey la survey che è  stata compilata
     * @returns 
     */
  public postSurveyEsterna(temporaryToken: any, survey: any) {
    return this.webService.doPost('/api/v1/compila-survey/compila', survey, { temporaryToken: temporaryToken });
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { CertificazioniService } from 'src/app/services/certificazioni/certificazioni.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-certificazioni',
  templateUrl: './dialog-crea-certificazioni.component.html',
  styleUrls: ['./dialog-crea-certificazioni.component.scss']
})
export class DialogCreaCertificazioniComponent implements OnInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public arraySettori: Settore[] = [];

  public formCertif = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    nomeBreve: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    settori: new FormControl<Settore[]>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    compilato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    figura: new FormControl<any>(undefined, {
      nonNullable: true,
      validators: [],
    }),

  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaCertificazioniComponent>,
    private settoriService: SettoriService,
    private utilityService: UtilityService,
    private certificazioniService: CertificazioniService,
    private bilancioService: BilancioService,
    private sintesiService: SintesiService,
    private policyService: PolicyService,
    @Inject(MAT_DIALOG_DATA) public data: {
      certificazioni: any,
      idContest: string,
      idAmbito: string,
      idTematica: string,
      contesto: string,
    },
  ) {
    if (this.data.certificazioni) {
      Object.keys(this.data.certificazioni).forEach((value, index, array) => {
        this.formCertif.get(value)?.setValue((this.data.certificazioni as any)[value]);
      })
    }
    this._getSettori();

    /* Se sono in contesto allora disattivo i validatori sul settore, perchè sarà nascosto. Mi serve per passare il controllo valid in salvataggio */
    if (this.data.contesto === 'sintesi' || this.data.contesto === 'bilancio' || this.data.contesto === 'policy') {
      this.formCertif.get('settori')?.setValidators([]);
      this.formCertif.get('settori')?.updateValueAndValidity();

    }

  }

  ngOnInit(): void {
  }

  compareSettori(settore1: Settore, settore2: Settore): boolean {
    return settore1.id === settore2.id; // Sostituisci "id" con la proprietà univoca degli impegni
  }

  salva() {

    if (this.formCertif.valid) {
      this.spinnerOver.show();
      const id = this.formCertif.get('id')?.value;
      // Se sono in contesto: sintesi, bilancio, policy etc...
      if (this.data.idContest) {
        return this.salvaInContesto();
      }

      if (id) {
        return this.certificazioniService.putCert(id, this.formCertif.getRawValue()).subscribe(
          {
            next: () => {
              this.spinnerOver.hide();
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();

            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio :', err);
              this.spinnerOver.hide();
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })

            }

          }
        );
      } else {
        return this.certificazioniService.postCert(this.formCertif.getRawValue()).subscribe(
          {
            next: () => {
              this.spinnerOver.hide();
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              this.spinnerOver.hide();
              console.error('Errore durante il salvataggio :', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formCertif.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  private _getSettori() {

    this.settoriService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'desc'
    }]).subscribe({
      next: (result: any) => {
        

        this.arraySettori = result?.content || [];
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento dei settori:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei settori',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  /**
      * Metodo che mi salva una nuova certificazione effimera in bilancio/sintesi
      * @returns 
      */
  public salvaInContesto() {

    if (!this.data.idAmbito) {
      this.mostraMessaggio('Attenzione', 'Ambito non trovato o non riconosciuto');
      return;
    } else if (!this.data.idTematica) {
      this.mostraMessaggio('Attenzione', 'Tematica non trovata o non riconosciuta');
      return;
    }

    if (!this.formCertif.get('id')?.value) {
      this.formCertif.get('creatoDaContesto')?.setValue(true);
    }

    let serviceCall;

    if (this.data.contesto == 'sintesi') {
      serviceCall = this.sintesiService.putCertificazioniEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formCertif.getRawValue());
    } else if (this.data.contesto == 'bilancio') {
      serviceCall = this.bilancioService.putCertificazioniEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formCertif.getRawValue());
    } else if (this.data.contesto == 'policy') {
      serviceCall = this.policyService.putCertificazioniEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formCertif.getRawValue());
    }

    if (serviceCall) {
      serviceCall.subscribe({
        next: (result: any) => {
          this.spinnerOver.hide();
          this.mostraMessaggioSnackbar('Salvataggio effettuato');
          this.dialogRef.close(result);
        },
        error: (err: any) => {
          this.spinnerOver.hide();
          console.error('Errore durante il salvataggio dell\'impatto:', err);
          this.mostraMessaggio('Attenzione', 'Errore nel salvataggio della certificazione');
        }
      });
    }
  }
  private mostraMessaggio(titolo: string, descrizione: string) {
    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      bottoni: [{ nome_btn: 'Chiudi' }]
    });
  }

  private mostraMessaggioSnackbar(messaggio: string) {
    this.utilityService.opneSnackBar(messaggio, '', {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }
}

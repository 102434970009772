import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Injectable({
  providedIn: 'root'
})
export class AzioneEBuonaPraticaWebService {
  private nomeWs = 'azioni-e-buone-pratiche';

  constructor(private webService: WebService) { }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del azione-e-buona-pratica
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione postAzioneBP per la creazione azione-e-buona-pratica
   */
  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs, data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/delete', { id: idRiga })
  }

  /**
   * 
   * @param id 
   * @param AzioneBP 
   * @returns 
   */
  public put(id: string, AzioneBP: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/update', AzioneBP, {
      id
    });
  }

  /**
*Funzione per selezionare i tag degli Azioni e buone pratiche
* Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
* @param page pagina
* @param pageSize record per pagina
* @param ricerca risultato per ricerca 
* @param filters valori da filtrare 
* @param sortBy sort asc desc
* @returns lista di righe
*/
  public getAzioneBPTags(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/tags', { page, pageSize, ricerca, filters, sortBy });
  }

}

import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Filters } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogCreaCategoriaStakeholderComponent } from 'src/app/page/configurazioni/cfg-categorie-stakeholder/dialog/dialog-crea-categoria-stakeholder/dialog-crea-categoria-stakeholder.component';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { Categorie, ModalitaCompilazione, StakeholderService, Tipologie } from 'src/app/services/stakeholder/stakeholder.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-stakeholder',
  templateUrl: './dialog-crea-stakeholder.component.html',
  styleUrls: ['./dialog-crea-stakeholder.component.scss']
})
export class DialogCreaStakeholderComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public arrayTipologia: Tipologie[] = [];
  public arrayCategorie: Categorie[] = [];
  public arrayModalita: ModalitaCompilazione[] = [];

  /* Form Controls */
  public formStakeholder = new FormGroup({

    /* Id */
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* idAzienda */
    idAzienda: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* nome */
    nome: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* cognome */
    cognome: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* Modalità Compilazione */
    modalitaCompilazione: new FormControl<'INVIAMAIL' | 'MANUALE' | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* email */
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }), // gestione del validatore nell'AfterViewInit
    /* tipologia */
    tipologia: new FormControl<'ESTERNI' | 'INTERNI' | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* categoria */
    categoria: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    })
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaStakeholderComponent>,
    private stakeholderService: StakeholderService,
    private utilityService: UtilityService,
    private aziendaService: AziendaService,
    public dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data: {
      stakeholder: any,
      isStakeHolderInMat: boolean
    }
  ) { }

  ngAfterViewInit(): void {

    this.spinnerOver.show();

    if (this.data.isStakeHolderInMat) {
      this.formStakeholder.get('tipologia')?.disable();
      this.formStakeholder.get('categoria')?.disable();
    }

    this.stakeholderService.getTipologia().subscribe((tipologie) => {
      this.arrayTipologia = tipologie;
    });

    this.stakeholderService.getModalitaCompilazione().subscribe((modalita) => {
      this.arrayModalita = modalita;
    });

    this.formStakeholder.get('tipologia')?.valueChanges.subscribe((valore) => {
      
      //chiama le categorie
      const tipologie: Filters = {
        chiave: 'tipologia',
        operator: 'eq',
        values: [valore]
      }
      this.stakeholderService.getCategorieStake(0, 1000, '', [tipologie]).subscribe((cate) => {
        this.arrayCategorie = cate.content;
      })
    })

    if (this.data?.stakeholder) {
      Object.keys(this.data.stakeholder).forEach((value, index, array) => {
        if (value === 'categoria') {
          this.formStakeholder.get('categoria')?.setValue(this.data.stakeholder.categoria?.id);
        } else {
          this.formStakeholder.get(value)?.setValue((this.data.stakeholder as any)[value]);
        }
      })

      this.formStakeholder.get('tipologia')?.disable();
      this.formStakeholder.get('categoria')?.disable();
    }

    this.aziendaService.azienda.then((azienda) => {
      this.formStakeholder.get('idAzienda')?.setValue(azienda?.id);
    });

    this.formStakeholder.get('modalitaCompilazione')?.valueChanges.subscribe((valore) => {

      if (valore === 'INVIAMAIL') {
        this.formStakeholder.get('email')?.addValidators([Validators.required]);
      } else {
        this.formStakeholder.get('email')?.removeValidators(Validators.required);
      }
      this.formStakeholder.get('email')?.updateValueAndValidity();
    });

    setTimeout(() => {
      this.spinnerOver.hide();
    }, 0);

  }

  public salva() {
    

    const valueForm = (this.formStakeholder.getRawValue() as any);

    const categoria = this.arrayCategorie.find((val) => {
      return val.id === this.formStakeholder.get('categoria')?.value
    });

    valueForm.categoria = categoria;

    if (this.formStakeholder.valid) {

      const id = this.formStakeholder.get('id')?.value;

      if (id) {
        return this.stakeholderService.putStakeholder(id, valueForm).subscribe(
          {
            next: (result: any) => {

              

              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });

              this.dialogRef.close(true);
            },
            error: (err: any) => {

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {
        return this.stakeholderService.postStakeholder(valueForm).subscribe(
          {
            next: (result: any) => {
              

              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close(true);
            },
            error: (err: any) => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formStakeholder.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  async addCategoria() {
    const dialogCrea = this.dialog.open(DialogCreaCategoriaStakeholderComponent, {
      data: {
        cate_stakeholder: {
          idAzienda: this.formStakeholder.get('idAzienda')?.value,
          tipologia: this.formStakeholder.get('tipologia')?.value
        },
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCrea.afterClosed().subscribe((result) => {
       

      const tipologie: Filters = {
        chiave: 'tipologia',
        operator: 'eq',
        values: [this.formStakeholder.get('tipologia')?.value]
      }

      this.stakeholderService.getCategorieStake(0, 1000, '', [tipologie]).subscribe((categorie) => {
        this.arrayCategorie = categorie?.content || [];
      });
    });
  }

  public validaMail(evento: MatSelectChange) {
    
    if (evento.value === 'INVIAMAIL') {
      this.formStakeholder.get('email')?.addValidators([Validators.required, UtilityService.emailValidator]);
      this.formStakeholder.get('email')?.updateValueAndValidity();
    } else {
      this.formStakeholder.get('email')?.removeValidators([Validators.required, UtilityService.emailValidator]);
      this.formStakeholder.get('email')?.updateValueAndValidity();
    }
  }
}

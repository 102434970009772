<div class="contenitore">
  <div class="titolo-pagina">
    <app-titolo-subtitolo titolo="Assessment di Sostenibilità"
      subTitolo="Potrai successivamente cambiarla in base alle tue esigenze."></app-titolo-subtitolo>
  </div>
  <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [filtri]="filtri"
    (bottoniListaClick)="bottoniListaClick($event)">
    <div btnEnd>
      <button mat-flat-button class="primary-button" color="primary" (click)="crea()"
        *ngIf="utenteService.isPermessoAttivo('CREATE_AUTOVALUTAZIONI')">
        Nuovo
      </button>
    </div>
  </app-lista-tabellare>
</div>
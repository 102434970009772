import { Observable } from 'rxjs';
import { WebService } from './../web/web.service';
import { Injectable } from '@angular/core';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Settore } from '../settori/settori.service';

@Injectable({
  providedIn: 'root'
})
export class AziendaWebService {

  private nomeWs = 'aziende';

  constructor(
    private webService: WebService
  ) {

  }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del Azienda
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione postAzienda per la creazione Azienda
   */
  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs, data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/delete', { id: idRiga })
  }
  /**
   * Aggiornamento Azienda
   * @param id 
   * @param azienda 
   * @returns 
   */
  public put(id: string, azienda: any): Observable<void> {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/update', azienda, {
      id
    });
  }

  /*  public getUtentiByIdAzienda(id: string) {
     return this.webService.doPost('/api/v1/' + this.nomeWs + '/' + id + '/utenti-list', {});
   } */


  public getAziendeByIdAzienda(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    /* return this.webService.doGet('/api/v1/' + this.nomeWs + '/list-by-ids', {page, pageSize, ricerca, filters, sortBy }, {ids}); */
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }
  /* public getAziendeByIdUtente( id: string | string[], page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
  return this.webService.doPost('/api/v1/' + this.nomeWs + '/' + id + '/aziende-list', {page, pageSize, ricerca, filters, sortBy });
} */

  /**
   * Metodo get che ritorna l'azienda tramite id solo una azienda
   * @param id 
   * @returns 
   */
  public getAziendaByIdAzienda(id: string) {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/get', { id });
  }

  /**
   * Recupera i settori associati al codice ateco dell'azienda
   * 
   * @param idAzienda 
   * @returns 
   */  
  public getSettoriAzienda(idAzienda: string): Observable<Settore[]> {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/' + idAzienda + '/settori', {});
  }

  /************************************************************ FIGURE AZIENDALI *************/

  /**
   * Lista figure aziendali
   * @param idAzienda 
   * @param page 
   * @param pageSize 
   * @param ricerca 
   * @param filters 
   * @param sortBy 
   * @returns 
   */
  public getFigureAz(idAzienda: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + idAzienda + '/figure-aziendali/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
 * Funzine ws per la creazione del Azienda
 * @param data dati da inserire nel MongoDB
 * @returns chiama la funzione postAzienda per la creazione Azienda
 */
  public postFigureAz(idAzienda: string, data: any): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + idAzienda + '/figure-aziendali', data);
  }

  /**
 * Aggiornamento Azienda
 * @param id 
 * @param azienda 
 * @returns 
 */
  public putFigureAz(idAzienda: string, id: string, figuraAz: any): Observable<any> {
    return this.webService.doPut('/api/v1/azienda/' + idAzienda + '/figure-aziendali/' + id, figuraAz, {
      id
    });
  }

  /**
 * Funzione per eliminare una riga da una lista 
 * @param idRiga id della riga da eliminare
 * @returns 
 */
  public deleteFigureAz(idAzienda: string, idFiguraAziendale: string): Observable<any> {
    return this.webService.doDelete('/api/v1/azienda/' + idAzienda + '/figure-aziendali/' + idFiguraAziendale, { id: idFiguraAziendale })
  }
  
  
  public getSoglia(idAzienda?: string): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + idAzienda + '/sintesi-sostenibilita/soglia', {})
  }

}

import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Observable } from 'rxjs';
import { AziendaService } from '../azienda/azienda.service';
import { Tipologie } from './stakeholder.service';

@Injectable({
  providedIn: 'root'
})
export class StakeholderWebService {

  private nomeWs = 'stakeholders';

  private nomeCategorieWs = 'categorie';

  private _idAzienda: string | undefined = undefined;

  constructor(
    private aziendaService: AziendaService,
    private webService: WebService
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }

  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public listNoPage(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/list-no-page', { page, pageSize, ricerca, filters, sortBy });
  }


  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs, data);
  }

  public delete(id: any): Observable<any> {
    return this.webService.doDelete('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/delete', { id })
  }

  public put(id: string, stakeholder: any): Observable<any> {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/update', stakeholder, {
      id
    });
  }

  public uploadFile(file: File, data: { [key: string]: string }) {
    return this.webService.uploadFile('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/import-stakeholders', file, data);
  }

  public getStakeMate(id: string, tipologia: 'ESTERNI' | 'INTERNI') {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/list/materialita/' + id, {}, {
      tipologia
    });
  }

  /******* CATEGORIE STAKEHOLDER */

  public getCate(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeCategorieWs + '/aziende/' + this._idAzienda + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public getCateCfg(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeCategorieWs + '/config-list', { page, pageSize, ricerca, filters, sortBy });
  }


  public postCate(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeCategorieWs, data);
  }

  public deleteCate(id: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeCategorieWs + '/delete', { id })
  }

  public putCate(id: string, categoria: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.nomeCategorieWs + '/update', categoria, {
      id
    });
  }

}

<h2 mat-dialog-title>Configurazioni Standard di Rendicontazione e Target</h2>
<div mat-dialog-content class="dialog-center-cont">
    <app-spinner-overlay></app-spinner-overlay>
    <section *ngIf="arrayStrCheck.length">
        <div *ngFor="let str of arrayStrCheck">
            <p>
                <mat-checkbox [checked]="str.checked" [(ngModel)]="str.checked"
                    (change)="toggleCheckbox($event,str)">{{str.descrizione}}</mat-checkbox>
                <mat-slide-toggle [(ngModel)]="str.target" [disabled]="!str.checked"></mat-slide-toggle>
            </p>
        </div>
    </section>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { WebService } from '../web/web.service';

@Injectable({
  providedIn: 'root'
})
export class TematicheWebService {

  private nomeWs = 'tematiche';
  constructor(private webService: WebService) { }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del Tematica
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione postAmbiti per la creazione Tematica
   */
  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs, data, {
    });
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/delete', { id: idRiga })
  }

  /**
   * Aggiornamento tematica
   * @param id 
   * @param tematica 
   * @returns 
   */
  public put(id: string, tematica: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/update', tematica, {
      id,
    });
  }

  public getAree(): Observable<any> {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/aree-list', {});
  }

  public getStdr(): Observable<any> {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/stdr-list', {});
  }

  /**
  * Get degli Obiettivi di Sviluppo Sostenibile
  * @returns 
  */
  public getSDGS() {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/sdg-list', {});
  }

  /**
   * Put degli Obiettivi di Sviluppo Sostenibile
   * @returns 
   */
  public putSDGS(idTematica: string) {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/' + idTematica + '/sdg-list', {});
  }

}

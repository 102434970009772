import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogCreaFigureAziendeComponent } from 'src/app/page/aziende/figure-aziende/dialog/dialog-crea-figure-aziende/dialog-crea-figure-aziende.component';
import { AziendaService } from 'src/app/services/azienda/azienda.service';

@Component({
  selector: 'app-dialog-associa-figura-aziendale',
  templateUrl: './dialog-associa-figura-aziendale.component.html',
  styleUrls: ['./dialog-associa-figura-aziendale.component.scss']
})
export class DialogAssociaFiguraAziendaleComponent implements OnInit, AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public arrayAssFigAz: any[] = [];
  public selectedAssFigAz: string[] = [];
  private _idAzienda: string | undefined = undefined;
  public formAssFigAziendale = new FormGroup({


    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    assFigAziendale: new FormControl<String>('', {
      nonNullable: true,
    }),
  });

  constructor(private aziendaService: AziendaService, public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogAssociaFiguraAziendaleComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
      figura: any,
    }

  ) {

    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.datiIniziali();
  }

  compareAssFigAz(fig1: any, fig2: any): boolean {
    return fig1.id === fig2.id;
  }

  conferma() {
    const idFigura: any = (this.formAssFigAziendale.get('assFigAziendale')?.value)
    this.dialogRef.close(idFigura.id);

  }

  datiIniziali() {
    if (this._idAzienda) {
      this.spinnerOver.show();
      this.aziendaService.getFigureAz(this._idAzienda, 0, 1000).subscribe({
        next: (esito) => {
          this.arrayAssFigAz = esito.content;

          if (this.data.figura) {

            const selectedFiguraId = this.data.figura.id;

            const selectedFig = this.arrayAssFigAz.find(fig => fig.id === selectedFiguraId);

            if (selectedFig) {
              this.formAssFigAziendale.get('assFigAziendale')?.setValue(selectedFig);
            }

          }
          this.spinnerOver.hide();
        },
        error: (err) => {
          console.error(err);
          this.spinnerOver.hide();
        }
      })

    }

  }

  creaFiguraAziendale() {
    const dialogCreaAmbito = this.dialog.open(DialogCreaFigureAziendeComponent, {
      data: {
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '60%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaAmbito.afterClosed().subscribe((result) => {
       
      this.datiIniziali();
    });
  }
}

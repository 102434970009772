import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Categorie, StakeholderService } from 'src/app/services/stakeholder/stakeholder.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaCategoriaStakeholderComponent } from './dialog/dialog-crea-categoria-stakeholder/dialog-crea-categoria-stakeholder.component';
import { UtenteService } from 'src/app/services/utente/utente.service';

@Component({
  selector: 'app-cfg-categorie-stakeholder',
  templateUrl: './cfg-categorie-stakeholder.component.html',
  styleUrls: ['./cfg-categorie-stakeholder.component.scss']
})
export class CfgCategorieStakeholderComponent {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Titolo',
      forControlName: 'titolo',
      input: 'text',
      riempi: true
    },
    {
      titolo: 'Tipologia',
      forControlName: 'tipologia',
      input: 'multiple-option',
      idValueOption: 'id',
      descValueOption: 'descrizione',
      fnDatiOption: () => this.stakeholderService.getTipologia(),
      riempi: true,
    }
  ];

  public colonne: { [key: string]: Colonna } = {
    titolo: {
      title: 'Titolo',
      value: 'titolo',
      sortable: true
    },
    /* descrizione: {
      title: 'Descrizione',
      value: 'descrizione',
    }, */
    tipologia: {
      title: 'Tipologia',
      value: 'tipologia',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_CONFIGURAZIONI'))
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_CONFIGURAZIONI'))
    },
  };

  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private stakeholderService: StakeholderService,
    public utenteService: UtenteService
  ) { }


  creaNuovo(record?: Categorie) {
    const dialogCreaCategoriaSh = this.dialog.open(DialogCreaCategoriaStakeholderComponent, {
      data: {
        cate_stakeholder: record,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaCategoriaSh.afterClosed().subscribe((result) => {
       
      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    /*     const ambitiSortBy: SortBy = {
          chiave: 'nomeBreve',
          sort: 'asc'
        };
        const actualSortBy = sortBy ? sortBy : [ambitiSortBy]; */

    return this.stakeholderService.getCategorieStakeCfg(page, pageSize, ricerca, filters, sortBy);

  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovo(event.data);
        break;
      case 'elimina':
        this._elimina(event.data.id);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  private _elimina(id: string) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare la Categoria?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI',
          background_color: 'var(--mat-color-primary)',
          handler: () => {
            this.stakeholderService.deleteCategorieStake(id).subscribe(
              {
                next: (result: any) => {
                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: 'Errore eliminazione' + err,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });
  }
}

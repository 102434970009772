import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ImpegniWebService } from './impegni-web.service';
import { Injectable } from '@angular/core';
import { Settore } from '../settori/settori.service';

export interface Impegno {
  id: string,
  titolo: string,
  descrizione: string,
  note: string,
  settori: Settore[],
  tag?: string,
  creatoDaContesto?: boolean;
  risposta?: string;
  preCompilazioneInfos?: string;

}

@Injectable({
  providedIn: 'root'
})
export class ImpegniService {

  constructor(private impegniWebService: ImpegniWebService) { }
  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getImpegni(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.impegniWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del impegno
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postimpegni per la creazione impegno
     */
  public postImpegni(data: any) {
    return this.impegniWebService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteImpegno(idRiga: any) {
    return this.impegniWebService.delete(idRiga);
  }

  /**
 * impegno
 * @param id 
 * @param impegno 
 * @returns 
 */
  public putImpegno(id: string, impegno: any) {
    return this.impegniWebService.put(id, impegno);
  }

  /**
     * Funzione per selezionare i tag degli impegni
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getImpegniTags(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.impegniWebService.getImpegniTags(page, pageSize, ricerca, filters, sortBy);
  }
}

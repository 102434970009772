import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import {
  BottoniListaEvent,
  Colonna,
  Filtri,
  ListaTabellareComponent,
} from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Utente, UtenteService } from 'src/app/services/utente/utente.service';
import { DialogCreaUtentiComponent } from './dialog/dialog-crea-utenti/dialog-crea-utenti.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.scss'],
})
export class UtentiComponent {

  constructor(
    public dialog: MatDialog,
    private utenteService: UtenteService,
    private utilityService: UtilityService,
    private aziendaService: AziendaService
  ) { }

}

<div class="rating">

    <star-rating [readOnly]="idSurveyCompilato ? true : false" (mouseleave)="onHoverRatingLeave()" [id]="idRating"
        [numOfStars]="numeroStelle" [hoverEnabled]="true" (starClickChange)="onClick($event)"
        (ratingChange)="onRatingChange($event)" (hoverRatingChange)="onHoverRatingChange($event)"
        [rating]="valoreAtribuito">
    </star-rating>

    <div class="punteggio" [style.background-color]="colorePunteggio">
        {{ punteggio }}/{{ numeroStelle }}</div>
</div>
<app-spinner-overlay></app-spinner-overlay>

<div>
    <header>
        <img [src]="logoPath" alt="Sostenibile" class="logo-path" />
    </header>
</div>
<div class="contenitore" *ngIf="!messaggioErrore && !completato && compilazione">
    <ng-scrollbar>
        <div >
            <app-compila-autovalutazione #compila *ngIf="!messaggioErrore && compilazione && esercizio && idAutovalutazioneCompilata"
                [idComp]="idComp" [esercizio]="esercizio" [idAutovalutazioneCompilata]="idAutovalutazioneCompilata" [temporaryToken]="temporaryToken"
                [compilazione]="compilazione" [idAzienda]="idAzienda" [salva]="salva" (completato)="compilazioneCompletata($event)"></app-compila-autovalutazione>
        </div>
    </ng-scrollbar>
</div>

<div class="messaggio" *ngIf="messaggioErrore" [innerHtml]="messaggioErrore"></div>

<div class="messaggio" *ngIf="!messaggioErrore && completato">
    Le tue risposte sono state salvate. Grazie.
</div>
import { Directive, ElementRef, Injector, AfterViewInit } from '@angular/core';
import { AbstractControlDirective, NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

@Directive({
  selector: '[appFormError]'
})
export class FormErrorDirective implements AfterViewInit {

  private control!: NgControl | AbstractControlDirective | null;

  private message = '';

  private _errMsg: {
    [key: string]: any;
  } = {
      required: 'Il campo è obbligatorio.',
      maxlength: 'Il campo deve essere lungo {requiredLength} caratteri.',
      minlength: 'Il campo deve essere lungo {requiredLength} caratteri.',
      pattern: 'Il campo deve contenere solo numeri non caratteri',
      max: 'Valore massimo consentito {max}',
      min: 'Valore minimo consentito {min}'
    }

  constructor(
    private el: ElementRef<HTMLElement>,
    private _inj: Injector
  ) {
  }

  ngAfterViewInit(): void {

    const container = this._inj.get(MatFormField);
    this.control = container._control?.ngControl;

    this.control?.statusChanges?.subscribe((risp) => {
      this._getTipoErrore();
    });
  }

  private _getTipoErrore() {
    if (this.control?.errors) {

      const error = Object.keys(this.control.errors)[0];

      if (error === 'maxlength' || error === 'minlength') {
        this.message = (this._errMsg[error] as string).replaceAll('{requiredLength}', this.control.errors[error].requiredLength);

      } else if (error === 'max') {

        this.message = (this._errMsg[error] as string).replaceAll('{max}', this.control.errors[error].max);

      } else if (error === 'min') {

        this.message = (this._errMsg[error] as string).replaceAll('{min}', this.control.errors[error].min);

      } else {
        this.message = this._errMsg[error];

      }

      // (this.el.nativeElement.querySelector('mat-error') as HTMLElement).textContent = this.message;
      this.el.nativeElement.textContent = this.message;
    }
  }


}

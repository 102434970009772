<div class="contenitore">
    <app-spinner-overlay></app-spinner-overlay>
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Configurazione Assessment di Sostenibilità"
            subTitolo="Gestione della configurazione di un assessment di Sostenibilità"></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [filtri]="filtri" #tabella
        (bottoniListaClick)="bottoniListaClick($event)" [larghezzaFiltro]="4">
        <div btnEnd>
            <button mat-flat-button class="primary-button" color="primary" (click)="crea()"
                *ngIf="this.utenteService.isPermessoAttivo('CREATE_CONFIGURAZIONI')">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>
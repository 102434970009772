import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { DialogCreaAziendaComponent } from './dialog/dialog-crea-azienda/dialog-crea-azienda.component';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogVisUtenteComponent } from './dialog/dialog-vis-utente/dialog-vis-utente.component';

@Component({
  selector: 'app-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.scss']
})
export class AziendeComponent {
  constructor(
    public dialog: MatDialog,
    private aziendaService: AziendaService,
    private utilityService: UtilityService
  ) { }
 
}


import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Injectable({
  providedIn: 'root'
})
export class ComitatiWebService {

  private nomeWs = 'comitati';
  private _idAzienda: string | undefined = undefined;
  constructor(private webService: WebService,
    private aziendaService: AziendaService,
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/config-list', { page, pageSize, ricerca, filters, sortBy });
  }

  public getComitatiLibreriaAndAzienda(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/aziende/' + this._idAzienda + '/list', { page, pageSize, ricerca, filters, sortBy });
  }


  /**
   * Funzine ws per la creazione del comitato
   * @param data dati da inserire nel MongoDB
   * @returns 
   */
  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs, data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/delete', { id: idRiga })
  }

  public put(id: string, comitato: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/update', comitato, {
      id,

    });
  }
}

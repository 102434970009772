<div class="container">
    <div class="titolo-desc">
        <h1 *ngIf="titolo" class="titolo" mat-dialog-title [innerHtml]="titolo"></h1>
        <div class="descrizione" mat-dialog-content [innerHtml]="descrizione"
            [ngStyle]="{'font-weight': fontWeight}">
            <p></p>
        </div>
    </div>

    <div mat-dialog-actions align="center">
        <button class="alert-button" mat-button *ngFor="let bottone of bottoni" [color]="bottone?.color || ''"
            [ngStyle]="{'background-color': bottone.background_color || 'var(--mat-color-primary)', 'color': bottone.color || 'white'}"
            (click)="bottone.handler ? bottone.handler() : null" [mat-dialog-close]="bottone.id_btn">
            <span [innerHtml]="getTraduzione(bottone.nome_btn)"></span>
        </button>
    </div>
</div>
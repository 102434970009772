import { Injectable } from '@angular/core';
import { StarRatingConfigService } from 'angular-star-rating';

export interface RatingTematica {
  id: string,
  rating: number
}

@Injectable({
  providedIn: 'root'
})
export class NgStarRatingService extends StarRatingConfigService {
  public ratingSelezionato: RatingTematica[] = [];
  constructor() {
    super();
    /* this.staticColor = 'ok'; */
    /* this.starType = 'icon' */
    this.size = 'medium';
  }

  public setRatingSelezionato(rating: RatingTematica[]) {
    this.ratingSelezionato = rating;
  }
  public getRatingSelezionato() {
    return this.ratingSelezionato;
  }
}

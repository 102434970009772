<mat-form-field [riempi]="true" *ngIf="datiFiltrati">
    <mat-label *ngIf="label">{{label}} {{required ? '*' : ''}}</mat-label>
    <input (input)="inputTyped($event)" (focus)="onFocus($event)" matInput [matAutocomplete]="autocomplete"
        [formControl]="autocompleteFormControl">

    <mat-autocomplete #autocomplete [displayWith]="displayFn" (optionSelected)="seleziona($event)">
        <mat-option *ngFor="let record of datiFiltrati | async | slice : 0 : 10" [value]="record"
            [compareWith]="compare">
            {{displayFn(record)}}
        </mat-option>
        <div *ngIf="this.showAltriRecordDisponibili" style="text-align: center; color: #807f7f;
        margin: 0 10px 0 10px;"> Numero record limitati.
            Modifica il filtro per restringere la ricerca
        </div>
    </mat-autocomplete>

</mat-form-field>
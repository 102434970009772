import { Component, OnDestroy, OnInit, Output, ViewChild, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CKEditorComponent, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import Editor from 'ckeditor5/build/ckeditor';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true
    }
  ]
})
export class EditorComponent implements ControlValueAccessor {
  @ViewChild(CKEditorComponent) editorComponent!: CKEditorComponent;

  @Output()
  cambioValoreEditor = new EventEmitter;

  public editor = Editor;
  value = '';
  disabled = false;
  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any): void {
    if (this.value !== value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changed(event: ChangeEvent) {
    this.onChange(this.editorComponent.editorInstance?.data.get());
    this.cambioValoreEditor.emit(this.editorComponent.editorInstance?.data.get())
  }

}

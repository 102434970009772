import { Component, OnInit, ViewChild } from '@angular/core';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { DialogCreaCfgTematicaComponent } from './dialog/dialog-crea-cfg-tematica/dialog-crea-cfg-tematica.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { AmbitiService, Ambito } from 'src/app/services/ambiti/ambiti.service';
import { Observable, forkJoin, map, of } from 'rxjs';
import { UtenteService } from 'src/app/services/utente/utente.service';

@Component({
  selector: 'app-cfg-tematiche',
  templateUrl: './cfg-tematiche.component.html',
  styleUrls: ['./cfg-tematiche.component.scss']
})
export class CfgTematicheComponent implements OnInit {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public arrayAree: any[] = [];

  public filtri: Filtri[] = [
    {
      titolo: 'Nome breve',
      forControlName: 'nomeBreve',
      input: 'text',
    },
    {
      titolo: 'Domanda',
      forControlName: 'domanda',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
    {
      forControlName: 'idsAmbiti',
      input: 'multiple-option',
      titolo: 'Ambiti',
      fnDatiOption: () => (this.ambitiService.getAmbiti(0, 1000, '', [],
        [
          {
            chiave: 'index',
            sort: 'asc',
          },
          {
            chiave: 'nomeBreve',
            sort: 'asc'
          }],
      )).pipe(map((value) => value?.content || [])),
      descValueOption: 'nomeBreve',
      idValueOption: 'id',
      riempi: true
    },
    {
      titolo: 'Note',
      forControlName: 'note',
      input: 'text',
      larghezza: 3,
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    nomeBreve: {
      title: 'Nome Breve',
      value: 'nomeBreve',
      sortable: true
    },
    domanda: {
      title: 'Domanda',
      value: 'domanda',
      sortable: true
    },
    ambito: {
      title: 'Ambiti',
      value: (record: any) => (record.datiAmbiti.map((amb: Ambito) => amb.nomeBreve))
    },
    /* aree: {
      title: 'Aree',
      value: (record: any) => (

        record?.aree?.map((area: any) =>
          area !== undefined
            ? area.charAt(0).toUpperCase() + area.slice(1)
            : ''
        ) || []
      ),
    }, */
    aree: {
      title: 'Aree',
      value: (record: any) => (
        record?.aree?.map((codice: any) => {
          const elementoCorrispondente = this.arrayAree.find(item => item.chiave === codice);
          return elementoCorrispondente ? elementoCorrispondente.descrizione : null;
        })
      )
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true
    },
    /*    duplica: {
         type: 'button',
         buttonIcon: 'content_copy',
         title: 'Duplica',
         buttonId: 'duplica',
       }, */
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina'
    },
  };

  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private tematicheService: TematicheService,
    private ambitiService: AmbitiService,
    public utenteService: UtenteService
  ) { }

  ngOnInit(): void {
  }


  crea(recordTematica?: any) {
    const dialogCreaAmbito = this.dialog.open(DialogCreaCfgTematicaComponent, {
      data: {
        tematica: recordTematica,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      height: '99%',
      maxHeight: '99%',
      autoFocus: false,
    });

    dialogCreaAmbito.afterClosed().subscribe((result) => {
       
      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    const tematicheSortBy: SortBy[] = [
      {
        chiave: 'index',
        sort: 'asc',
      },
      {
        chiave: 'domanda',
        sort: 'asc',
      },
    ];
    const actualSortBy = sortBy ? sortBy : tematicheSortBy;

    let chiamate: {
      aree: Observable<any>,
      tematiche: Observable<any>
    } = {
      aree: !this.arrayAree?.length ? this.tematicheService.getAree() : of(this.arrayAree),
      tematiche: this.tematicheService.getTematiche(page, pageSize, ricerca, filters, actualSortBy)
    };

    return forkJoin(chiamate).pipe(map((risposta) => {
      if (risposta.aree) {
        this.arrayAree = risposta.aree;
      }
      return risposta.tematiche;
    }))
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'elimina':
        this._elimina(event.data.id);
        break;
      case 'duplica':
        this._duplica();
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare 
   * @param idRiga id della riga da eliminare 
   */
  private _elimina(idRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare la tematica?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)'
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {

            this.tematicheService.deleteTematica(idRiga).subscribe(
              {
                next: (result: any) => {
        
                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err?.error?.message || 'Eliminazione non riuscita',
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );

          }
        }]
    });

  }
  /**
   * Metodo per la duplicazione di un ambito
   */
  public _duplica() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }
}

<h2 mat-dialog-title>Associa Figura Aziendale</h2>
<app-spinner-overlay></app-spinner-overlay>
<div mat-dialog-content class="dialog-center-cont" style="display: flex; column-gap: 10px;">
    <form [formGroup]="formAssFigAziendale" appFormContainer [larghezza]="4" style="width: 100%;">
        <mat-form-field class="input-form" appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Figura Aziendale</mat-label>
            <mat-select formControlName="assFigAziendale" placeholder="Figura Aziendale" [(value)]="selectedAssFigAz"
                [compareWith]="compareAssFigAz">
                <mat-option *ngFor="let fig of arrayAssFigAz" [value]="fig">{{fig.figura}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="creaFiguraAziendale()" class="primary-button">
            <mat-icon>person_edit</mat-icon> Nuova
        </button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" conferma()" class="primary-button">
            Conferma
        </button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs/internal/Observable';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Injectable({
  providedIn: 'root'
})
export class ImpattoWebService {

  constructor(private webService: WebService) { }

  /**
   * Funzione per la creazione di un Impatto
   * @param data dati form impatto
   * @returns 
  */
  public post(nomeWs: string, data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + nomeWs, data);
  }

  /**
   * Funzione per aggiornare la descrizione su Scrittura Impatto 
   * @param nomeWs 
   * @param data 
   * @returns 
   */
  public postScrittura(nomeWs: string, data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + nomeWs + '/update-all', data);
  }

  /**
 * Funzione per selezionare le righe da far vedere nella grid
 * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
 * @param page pagina
 * @param pageSize record per pagina
 * @param ricerca risultato per ricerca 
 * @param filters valori da filtrare 
 * @param sortBy sort asc desc
 * @returns lista di righe
 */
  public get(nomeWs: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
 *Funzione per selezionare i tag degli impatti
 * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
 * @param page pagina
 * @param pageSize record per pagina
 * @param ricerca risultato per ricerca 
 * @param filters valori da filtrare 
 * @param sortBy sort asc desc
 * @returns lista di righe
 */
  public getImpattiTags(nomeWs: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + nomeWs + '/tags', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzione per selezionare dai in ingresso da WS  
   * @param nomeWs 
   * @returns 
   */
  public getScritturaImpatti(nomeWs: string): Observable<any> {
    return this.webService.doPost('/api/v1/' + nomeWs + '/list', {});
  }

  public getScritturaImpattoByCodice(nomeWs: string, codice: string) {
    return this.webService.doGet('/api/v1/' + nomeWs + '/find-by-codice', { codice });
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(nomeWs: string, idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + nomeWs + '/delete', { id: idRiga })
  }
  /**
     * Aggiornamento impatto
     * @param id 
     * @param impatto 
     * @returns 
     */
  public put(nomeWs: string, id: string, impatto: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + nomeWs + '/update', impatto, {
      id
    });
  }
}

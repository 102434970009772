<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Benvenuto/a Azienda Srl" subTitolo=""></app-titolo-subtitolo>
    </div>
    <div class="bar-chart">
        <app-widget-dashboard-chart [titolo]="titolo[0]" [value]="value[0]" [maxValue]="maxValue[0]"
            [colore]="coloriLineChart[0]" [valoreFreccia]="valoreFreccia[0]"></app-widget-dashboard-chart>
        <app-widget-dashboard-chart [titolo]="titolo[1]" [value]="value[1]" [maxValue]="maxValue[1]"
            [colore]="coloriLineChart[1]" [valoreFreccia]="valoreFreccia[1]"></app-widget-dashboard-chart>
        <app-widget-dashboard-chart [titolo]="titolo[2]" [value]="value[2]" [maxValue]="maxValue[2]"
            [colore]="coloriLineChart[2]" [valoreFreccia]="valoreFreccia[2]"></app-widget-dashboard-chart>
    </div>
    <div class="line-chart">
        <app-line-chart></app-line-chart>
    </div>
</div>
<div mat-dialog-title *ngIf="this.data.isModifica">Modifica Indice</div>
<div mat-dialog-title *ngIf="!this.data.isModifica">Aggiungi Indice</div>
<div style="height: 20px;"></div>
<form [formGroup]="form" appFormContainer [larghezza]="3" [gap]="10" [rowGap]="5" style="align-items: center;">

    <mat-form-field appFormElement [larghezza]="3">
        <mat-label>Descrizione Indice</mat-label>
        <input matInput placeholde="Descrizione Indice" formControlName="descrizioneIndice" />
        <mat-error appFormError></mat-error>
    </mat-form-field>
</form>

<div style="display: flex; flex-direction: row;column-gap: 20px;">
    <button mat-flat-button mat-dialog-close color="accent" class="accent-button">Indietro</button>

    <button mat-flat-button color="primary" (click)="conferma()" class="primary-button">
        Conferma
    </button>
</div>
import { Injectable } from '@angular/core';
import { SettoriWebService } from './settori-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Ateco, JsonFileService } from '../json-file/json-file.service';

export interface Settore {
  id: string;
  titolo: string;
  chiave: string;
  descrizione: string;
  codiciAteco: string[];
  sottoSettori: SottoSettore[];
}

export interface SottoSettore {
  id?: string;
  titolo: string;
}

@Injectable({
  providedIn: 'root'
})
export class SettoriService {
  private codiciAtecoArray: Ateco[] | undefined = undefined;

  constructor(private settoriWebService: SettoriWebService, private jsonFileService: JsonFileService,) { }

  /**
    * Funzione per selezionare le righe da far vedere nella grid
    * @param page pagina
    * @param pageSize record per pagina
    * @param ricerca risultato per ricerca 
    * @param filters valori da filtrare 
    * @param sortBy sort asc desc
    * @returns lista di righe
    */
  public getSettori(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.settoriWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del Settore
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postAmbiti per la creazione Settore
     */
  public postSettori(data: any) {
    return this.settoriWebService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteSettore(idRiga: any) {
    return this.settoriWebService.delete(idRiga);
  }

  public putSettori(id: string, settore: any) {
    return this.settoriWebService.put(id, settore);
  }

  public setCodiciAteco() {

    this.jsonFileService.getAteco().subscribe(
      {
        next: (ateco: Ateco[]) => {
          this.codiciAtecoArray = ateco;
        },
        error: (err: any) => {
          console.error('errore get ateco', err);
        }
      }
    );
  }

  public getCodiciAteco() {
    return this.codiciAtecoArray;
  }

  public getSettoreGenerale() {
    return this.settoriWebService.getSettoreGenerale();
  }

  /* SOTTOSETTORI */

  public getSottoSettoriByIdSettore(idSettore: string) {
    return this.settoriWebService.getSottoSettoreByIdSettore(idSettore);
  }

  public deleteSottoSettore(idSettore: string, idSottoSettore: any) {
    return this.settoriWebService.deleteSottoSettore(idSettore, idSottoSettore);
  }

  public putSottoSettore(idSettore: string, sottoSettore: SottoSettore) {
    return this.settoriWebService.putSottoSettore(idSettore, sottoSettore);
  }

  public postSottoSettore(idSettore: string, sottoSettore: SottoSettore) {
    return this.settoriWebService.postSottoSettore(idSettore, sottoSettore);
  }

  /**
   * Recuepra un set di sottosettori a partire dagli ambiti.
   * NOTA: Fa una POST, non una GET
   * @param idAmbiti 
   * @returns 
   */
  public getSettoriDaAmbiti(idAmbiti: string[]) {
    return this.settoriWebService.getSettoriDaAmbiti(idAmbiti);
  }
}

import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { CfgStampa } from './config-stampe.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigStampeWebService {

  constructor(
    private webService: WebService
  ) { }


  public getLineeGuida(contesto: 'QUESTIONARIO_GENERICO' | 'AUTOVALUTAZIONE' | 'SURVEY' | 'BILANCIO' | 'SINTESI' | 'INFORMATIVA_AZIENDALE' | 'POLICY') {
    return this.webService.doGet('/api/v1/linee-guida-stampe/' + contesto, {});
  }

  public salvaLineaGuida(cfg: CfgStampa, contesto: 'QUESTIONARIO_GENERICO' | 'AUTOVALUTAZIONE' | 'SURVEY' | 'BILANCIO' | 'SINTESI' | 'INFORMATIVA_AZIENDALE' | 'POLICY') {
    return this.webService.doPut('/api/v1/linee-guida-stampe/' + contesto, cfg );
  }

}

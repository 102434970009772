import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, catchError, filter, map, of, switchMap, take, throwError } from 'rxjs';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaCfgSottoSettoriComponent } from './dialog/dialog-crea-cfg-sotto-settori/dialog-crea-cfg-sotto-settori.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-dialog-sotto-settori',
  templateUrl: './dialog-sotto-settori.component.html',
  styleUrls: ['./dialog-sotto-settori.component.scss']
})
export class DialogSottoSettoriComponent implements OnInit {

  @ViewChild(SpinnerOverlayComponent) spinner!: SpinnerOverlayComponent;
  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  private _settore: Settore | undefined = undefined;
  private _sottoSettori = new BehaviorSubject<SottoSettore[] | null>(null);

  get settore() {
    return this._settore;
  }

  public colonne: { [key: string]: Colonna } = {
    titolo: {
      title: 'SottoSettore',
      value: 'titolo',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina'
    },
  };

  constructor(
    private utilityService: UtilityService,
    private settoriService: SettoriService,
    public utenteService: UtenteService,
    private dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data: {
      settore: any
    }
  ) {
    this._settore = data.settore;
  }

  ngOnInit() {

    if (!this._settore) {
      console.error('SETTORE NON PASSATO');
      return;
    }

  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (this._settore) {
      return this.settoriService.getSottoSettoriByIdSettore(this._settore.id)
        .pipe(
          switchMap((result) => {
            this._sottoSettori.next(result.sottoSettori || []);

            return this._sottoSettori.pipe(
              filter(val => val !== null),
              take(1),
              switchMap((res) => {
                return this.utilityService.getDatiTableArray(res || [], ['titolo'], ricerca, page, pageSize, sortBy);
              })
            );
          }),
          catchError((err) => {

            console.error(err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Caricamento dati non riuscito: ' + (err.error.message || 'Errore sconosciuto'),
              bottoni: [
                {
                  nome_btn: 'Chiudi'
                }
              ]
            });

            return throwError(() => err)

          })
        )

    } else {
      return throwError(() => 'Nessun settore selezionato');
    }

  }


  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaModifica(event.data);
        break;
      case 'elimina':
        this._eliminaSottoSettore(event.data.id);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }


  public creaModifica(sottoSettore?: SottoSettore) {

    const dialogCreaModSottoSettore = this.dialog.open(DialogCreaCfgSottoSettoriComponent, {
      data: {
        settore: this._settore,
        sottoSettore
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaModSottoSettore.afterClosed().subscribe((result) => {
       
      this.tabella.caricaDati();
    });

  }

  private _eliminaSottoSettore(idSottoSettore: string) {

    if (this._settore) {

      this.spinner.show();

      this.settoriService.deleteSottoSettore(this._settore.id, idSottoSettore).subscribe({
        next: (val) => {
          this.spinner.hide();
          this.tabella.caricaDati();
        },
        error: (err) => {

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Eliminazione non riuscito: ' + (err.error.message || 'Errore sconosciuto'),
            bottoni: [
              {
                nome_btn: 'Chiudi'
              }
            ]
          });

          this.tabella.caricaDati();

        }
      });
    }

  }
}

import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { AziendaService } from '../azienda/azienda.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Ambito } from '../ambiti/ambiti.service';
import { Settore } from '../settori/settori.service';
import { Obiettivo } from './piano.service';
import { GruppoCfgStampa } from '../config-stampe/config-stampe.service';

@Injectable({
  providedIn: 'root'
})
export class PianoWebService {
  private nomeWs = 'piano-di-sostenibilita'
  private _idAzienda: string | undefined = undefined;
  constructor(
    private webService: WebService,
    private aziendaService: AziendaService,
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }

  /* Piano di Sostenibilità */
  public getPianoSostenibilita(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public deletePianoSostenibilita(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/delete', { id: idRiga })
  }

  public getObiettivi(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/obiettivi/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del obiettivo piano
   * @param data dati da inserire nel MongoDB
   * @returns 
   */
  public postObiettiviPiano(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/obiettivi', data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public deleteObiettiviPiano(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/obiettivi/delete', { id: idRiga })
  }

  public putObiettiviPiano(id: string, obiettivoPiano: any): Observable<any> {
    return this.webService.doPut('/api/v1/obiettivi/update', obiettivoPiano, {
      id
    });
  }

  /* ---------------- C R E A Z I O N E   P O L I C Y  ---- step1 ------------*/

  /**
   * Creazione step 1 piano
   * @param pianoSostenibilita oggetto piano di sostenibilita
   * @returns 
   */
  public postCreazionePianoStep1(pianoSostenibilita: any) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs,
      pianoSostenibilita
    );
  }

  /**
   * Aggiornamento step 1 piano
   * @param pianoSostenibilita id piano di sostenibilita
   * @returns 
   */
  public putAggiornamentoPianoStep1(idPianoSostenibilita: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita,
      {}
    );
  }

  /* ----------------T E M A T I C H E    P I A N O  ---- step2 ------------*/
  public getQuestionarioPiano(idPianoSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/questionario-di-piano', {});
  }

  public putQuestionarioPiano(idPianoSostenibilita: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], titolo: string, stato: string, tipo: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/questionario-di-piano', {
      id: idSurvey,
      ambiti,
      settori,
      piano: [idPianoSostenibilita],
      aziende: [this._idAzienda],
      titolo,
      stato,
      tipo
    });
  }

  /* -------------------------  O B I E T T I V I  -------------------------------- */
  /**
 * GET OBIETTIVI PIANO
 * @param idPianoSostenibilita 
 * @returns obj con lista ambiti - tematiche - obiettivi
 */
  public getObiettiviPiano(idPianoSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/obiettivi', {});
  }

  /**
   * SALVATAGGIO OBIETTIVI PIANO SELEZIONATI
   * @param idPianoSostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putObiettiviPianoSelezionati(idPianoSostenibilita: string, obiettiviSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/obiettivi', obiettiviSelezionati, {});
  }

  public putObiettiviEffimeri(idPianoSostenibilita: string, idAmbito: string, idTematica: string, obiettivo: Obiettivo) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/obiettivi-effimeri',
      obiettivo,
      {
        idAzienda: this._idAzienda,
        idPianoSostenibilita
      }
    );
  }

  /* ------------------------- T A R G E T  -------------------------------- */
  /* Lista obiettivi a cui associare i target nel Piano di Sostenibilità */
  public getObiettiviTarget(idPianoSostenibilita: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/obiettivi-target/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public getTargetQualitativiQuantitativi(listaId: any): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/'
      + listaId.idPianoSostenibilita + '/ambito/' + listaId.idAmbito + '/tematica/'
      + listaId.idTematica + '/obiettivo/'
      + listaId.idObiettivo + '/target', {});
  }

  /**
 * SALVATAGGIO TARGET Piano SELEZIONATI
 * @param idPianoSostenibilita 
 * @param objStep3 
 * @returns 
 */
  public putTargetSelezionati(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idObiettivo: string, targetQualitativiSelezionate: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/'
      + idPianoSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/obiettivo/'
      + idObiettivo + '/target', targetQualitativiSelezionate, {});
  }

  /* ------------------------- TARGET QUALITATIVI    -------------------------------- */

  /**
     * ASSEGNA FIGURA AD TARGET QUALITATIVI Piano
     * @param idPianoSostenibilita 
     * @param Piano
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraTargetQualitativiPiano(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idtargetQualitativi: string, idObiettivo: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/obiettivo/'
      + idObiettivo + '/target-qualitativo/' + idtargetQualitativi + '/figura/' + idFigura, {});
  }

  public putTargetQualitativiEffimeri(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idObiettivo: string, targetQualitativi: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/obiettivo/'
      + idObiettivo + '/target-qualitativi-effimeri',
      targetQualitativi,
      {
        idAzienda: this._idAzienda,
        idPianoSostenibilita
      }
    );
  }
  /* ------------------------- TARGET QUANTITATIVI    -------------------------------- */

  /**
     * ASSEGNA FIGURA AD TARGET QUANTITATIVI Piano
     * @param idPianoSostenibilita 
     * @param Piano
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraTargetQuantitativiPiano(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idtargetQuantitativi: string, idObiettivo: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/obiettivo/'
      + idObiettivo + '/target-quantitativo/' + idtargetQuantitativi + '/figura/' + idFigura, {});
  }

  public putTargetQuantitativiEffimeri(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idObiettivo: string, targetQuantitativi: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/obiettivo/'
      + idObiettivo + '/target-quantitativi-effimeri',
      targetQuantitativi,
      {
        idAzienda: this._idAzienda,
        idPianoSostenibilita
      }
    );
  }


  /* --------------------------- FIGURE AZIENDALI - ---------------*/
  public getListaAssegnazioniFigAz(idPiano: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPiano + '/assegnazioni', {});
  }

  /* ------------------------- C R U S C O T T O       -------------------------------- */
  public getTotaliCruscotto(idPianoSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/totale-compilazioni', {});
  }

  /**
 * lista cruscotti
 * @param page 
 * @param pageSize 
 * @param ricerca 
 * @param filters 
 * @param sortBy 
 * @returns 
 */
  public getCruscotti(idPianoSostenibilita: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {

    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/list-elementi-per-figura',
      {
        page,
        pageSize,
        ricerca,
        filters,
        sortBy
      });
  }

  /**
   * Cambio figura
   * @param idPianoSostenibilita 
   * @param idFiguraPrecedente 
   * @param idFiguraNuova 
   * @returns 
   */
  public putCambiaFigura(idPianoSostenibilita: string, idFiguraPrecedente: string, idFiguraNuova: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/figura/' + idFiguraPrecedente + '/cambia/' + idFiguraNuova);
  }

  public inviaMailMultiple(idPianoSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/invio-multiplo', {
      idPianoSostenibilita
    });
  }

  public inviaNotificaMultiple(idPianoSostenibilita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/notifica-multiplo', {
      idPianoSostenibilita
    });
  }

  public inviaMail(idPianoSostenibilita: string, idFigura: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/figura/' + idFigura + '/invio-singolo', {
      idPianoSostenibilita,
      idFigura
    });
  }

  public inviaNotifica(idPianoSostenibilita: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/figura/' + idFigura + '/notifica-singolo', {
      idPianoSostenibilita,
      idFigura
    });
  }

  public putTemplateMail(idPianoSostenibilita: string, templateMail: any): Observable<any> {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/template', templateMail, { idPianoSostenibilita });
  }

  public getTemplateMail(idPianoSostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/template', { idPianoSostenibilita });
  }

  /**
   * Cambio stato a pubblicato del Piano
   * @param idPianoSostenibilita 
   * @returns 
   */
  public pubblicaPiano(idPianoSostenibilita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/pubblica', {});
  }

  public getElementoDaCompilare(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idElemento: string, idObiettivo: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' +
      idPianoSostenibilita + '/questionario/ambito/' + idAmbito + '/tematica/' + idTematica + '/obiettivo/' + idObiettivo + '/elemento/' + idElemento, {

      idAzienda: this._idAzienda,
      idAmbito,
      idTematica,
      idElemento,
      idPianoSostenibilita
    });
  }

  public postElementoDaCompilare(
    idPianoSostenibilita: string,
    idAmbito: string,
    idTematica: string,
    idElemento: string,
    risposta: string | number,
    rispostaBreve: string | number,
    anno: string,
    idCategoriaUnitaDiMisura?: string,
    idUnitaDiRiferimento?: string,
    idObiettivo?: string,
  ) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' +
      idPianoSostenibilita + '/questionario/ambito/' + idAmbito + '/tematica/' + idTematica + '/obiettivo/' + idObiettivo + '/elemento/' + idElemento, {
      idAmbito,
      idTematica,
      idElemento,
      risposta,
      rispostaBreve,
      anno,
      idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento
    })
  }


  public getElementiEsterniDaCompilare(temporaryToken: string) {
    return this.webService.doGet('/api/v1/compila-piano/get', {
      temporaryToken
    });
  }

  public postElementiEsterniDaCompilare(temporaryToken: string, objEsterna: any[]) {
    return this.webService.doPost('/api/v1/compila-piano/compila',
      objEsterna
      , {
        temporaryToken
      });
  }
  public invioTestMail(messaggio: string, email: string, idPianoSostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/test-invio-mail', {
      messaggio,
      email
    });
  }

  public getAnteprimaPianoCompilato(idPianoSostenibilita: string, idAzienda: string): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/anteprima-piano-compilato', {});
  }


  /*  Obiettivi Aziendali cfg */

  public getObiettiviAziendali(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/obiettivi-aziendali-piano/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del obiettivo piano
   * @param data dati da inserire nel MongoDB
   * @returns 
   */
  public postObiettiviAziendaliPiano(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/obiettivi-aziendali-piano', data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public deleteObiettiviAziendaliPiano(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/obiettivi-aziendali-piano/delete', { id: idRiga })
  }

  public putObiettiviAziendaliPiano(id: string, obiettivoPiano: any): Observable<any> {
    return this.webService.doPut('/api/v1/obiettivi-aziendali-piano/update', obiettivoPiano, {
      id
    });
  }

  /* Modelli sviluppo piano cfg */

  public getModelliSviluppoPiano(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/modello-sviluppo-piano/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del obiettivo piano
   * @param data dati da inserire nel MongoDB
   * @returns 
   */
  public postModelliSviluppoPiano(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/modello-sviluppo-piano', data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public deleteModelliSviluppoPiano(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/modello-sviluppo-piano/delete', { id: idRiga })
  }

  public putModelliSviluppoPiano(id: string, obiettivoPiano: any): Observable<any> {
    return this.webService.doPut('/api/v1/modello-sviluppo-piano/update', obiettivoPiano, {
      id
    });
  }

  public getDocumento(idPianoSostenibilita: string): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/documento', {});
  }

  public putDocumento(idPianoSostenibilita: string, objDocumento: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/documento', { ...objDocumento });
  }

  public putObiettiviAziendaliEffimeri(idPianoSostenibilita: string, obiettivoAziendale: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/obiettivo-aziendale-effimero',
      obiettivoAziendale,
      {
        idAzienda: this._idAzienda,
        idPianoSostenibilita
      }
    );
  }

  public putModelloSviluppoEffimeri(idPianoSostenibilita: string, modelloSviluppo: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPianoSostenibilita + '/modello-sviluppo-effimero',
      modelloSviluppo,
      {
        idAzienda: this._idAzienda,
        idPianoSostenibilita
      }
    );
  }
  
  /* ------------------------- S T A M P A -------------------------------- */

  /**
  * GET Configurazioni stampa 
  * @param idPiano 
  * @returns List<GruppoConfigRisultatiPianoValori>
  */
  public getConfigStampa(idPiano: string): Observable<GruppoCfgStampa> {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPiano + '/cfg-stampa/list', {});
  }

  public postSalvaElementiStampa(idPiano: string, cfgStampa: GruppoCfgStampa) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPiano + '/cfg-stampa', cfgStampa);
  }

  public postStampaPiano(idPiano: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPiano + '/stampa', {});
  }

/* ------------------------------------------------------ */
   /**
  * ritorna piano pubblicate
  * @returns 
  */
   public getPianiPubblicate(idSettore?: string, idSottoSettori?: string[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/settore/' + idSettore + '/list', {
      idSottoSettori
    });
  }

  /**
  * ritorna materialità corrispondete all'id
  * @param idPiano 
  * @returns 
  */
  public getPianoById(id: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + id, {});
  }

  
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardWebService } from './dashboard-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private dashboardWebService: DashboardWebService
  ) {

  }

  public getInfoLicenze(idUtente: string): Observable<any> {
    return this.dashboardWebService.getInfoLicenze(idUtente);
  }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public getRiepilogoGenerale(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.dashboardWebService.getRiepilogoGenerale(page, pageSize, ricerca, filters, sortBy);
  }

}

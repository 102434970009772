import { AmbitiService, Ambito } from 'src/app/services/ambiti/ambiti.service';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-dialog-crea-ambito',
  templateUrl: './dialog-crea-ambito.component.html',
  styleUrls: ['./dialog-crea-ambito.component.scss']
})
export class DialogCreaAmbitoComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public arraySettori: Settore[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogCreaAmbitoComponent>,
    public ambitiService: AmbitiService,
    private utilityService: UtilityService,
    private settoriService: SettoriService,
    @Inject(MAT_DIALOG_DATA) public data: {
      ambito: Ambito
    }
  ) {

    if (this.data?.ambito) {
      Object.keys(this.data.ambito).forEach((value, index, array) => {
        this.formAmbiti.get(value)?.setValue((this.data.ambito as any)[value]);
      })
    }
  }

  /* Form Controls */
  public formAmbiti = new FormGroup({

    /* Id */
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* titolo */
    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* chiave */
    chiave: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    /* nommeBreve */
    nomeBreve: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* descrizione */
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    idSettore: new FormControl<string[]>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* note */
    note: new FormControl<string>(''),
    index: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(999999999999)],
    }),
    /* Titolo Breve */
    titoloBreve: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  ngAfterViewInit() {
    this._caricaSettori();
  }

  /**
   * Funzione di salvataggio di un nuovo Ambito
   * @returns 
   */
  public salva() {
   

    if (this.formAmbiti.valid) {

      const id = this.formAmbiti.get('id')?.value;

      if (id) {
        return this.ambitiService.putAmbito(id, this.formAmbiti.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio dell\'ambito:', err);

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio dell\'ambito',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {
        return this.ambitiService.postAmbiti(this.formAmbiti.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio degli ambiti:', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio degli Ambiti',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formAmbiti.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  private _caricaSettori() {

    this.spinnerOver.show();

    this.settoriService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'asc'
    }]).subscribe({
      next: (risp) => {
        this.arraySettori = risp?.content || [];

        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);

        this.spinnerOver.hide();

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei settori',
          bottoni: [{ nome_btn: 'Ok' }]
        });

        this.dialogRef.close();

      }
    })
  }
  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-"
    if (event.key === '-') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }
}

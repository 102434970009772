import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-titolo-subtitolo',
  templateUrl: './titolo-subtitolo.component.html',
  styleUrls: ['./titolo-subtitolo.component.scss']
})
export class TitoloSubtitoloComponent {
  private _titolo: string = '';
  private _subTitolo: string = '';

  @Input()
  /* titolo */
  set titolo(titolo: string) {
    this._titolo = titolo;
  }
  get titolo() {
    return this._titolo;
  }

  @Input()
  /* titolo */
  set subTitolo(subTitolo: string) {
    this._subTitolo = subTitolo;
  }
  get subTitolo() {
    return this._subTitolo;
  }
}

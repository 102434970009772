<h2 mat-dialog-title>
    {{data.nome}} - {{data.figura}}
</h2>
<div mat-dialog-content class="dialog-center-cont">

    <app-lista-tabellare *ngIf="colonne" class="ricerca-con-ombra" #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        [defaultPageSize]="5" [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">
    </app-lista-tabellare>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Indietro</button>
    </div>
</div>
import { UtilityService } from 'src/app/services/utility/utility.service';
import { LicenzeService } from './../../../../services/licenze/licenze.service';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromotoreLicenze } from 'src/app/services/licenze/licenze.service';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-dialog-gestione-licenze',
  templateUrl: './dialog-gestione-licenze.component.html',
  styleUrls: ['./dialog-gestione-licenze.component.scss']
})
export class DialogGestioneLicenzeComponent implements AfterViewInit {

  // Variabili 
  public minDate = new Date();
  public arrayCodicePromotore: PromotoreLicenze[] = [];

  //Form Control Group
  public formLicenze = new FormGroup({

    nrAutovalutazioni: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),

    nrBilanci: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),

    nrSintesi: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    nrMaterialita: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    nrPolicy: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    nrPiano: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    codicePromotore: new FormControl<PromotoreLicenze[] | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    dataAttivazione: new FormControl<Date | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
  })

  constructor(
    public dialogRef: MatDialogRef<DialogGestioneLicenzeComponent>,
    private licenzeService: LicenzeService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: {
      idUtente: string;
      codiceLicenza: string;
    }
  ) {

    /*  if (this.data?.ambito) {
       Object.keys(this.data.ambito).forEach((value, index, array) => {
         this.formAmbiti.get(value)?.setValue((this.data.ambito as any)[value]);
       })
     } */
  }

  ngAfterViewInit(): void {
    this._init();
  }


  private _init() {

    //Promotori
    this.licenzeService.getPromotoriList().subscribe({
      next: (esito) => {
         
        this.arrayCodicePromotore = esito;
      },
      error: (err) => {
        console.error(err);
      }
    })
    // Se ho già la licenza allora mi riprendo i dati
    if (this.data.codiceLicenza) {
      this.formLicenze.get('codicePromotore')?.disable();
      this.licenzeService.getLicenzeByUtente(this.data.idUtente, this.data.codiceLicenza).subscribe({
        next: (esito) => {
           
          if (esito) {
            Object.keys(esito).forEach((value, index, array) => {
              if (value === 'dataAttivazione') {
                if (esito[value]) {
                  this.formLicenze.get(value)?.setValue(dateFns.parseISO(esito[value]));
          
                }
              } else {
                this.formLicenze.get(value)?.setValue((esito as any)[value]);
              }
            })


          }
        },
        error: (err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Errore Generico',
            bottoni: [{ nome_btn: 'Ok' }]
          })

        }

      })

    }
  }

  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-" "," "."
    if (event.key === '-' || event.key === ',' || event.key === '.') {
      // Impedisce l'inserimento dei caratteri "-" "," "."
      event.preventDefault();
    }
  }

  salva() {
    if (this.data.codiceLicenza) {
      this.licenzeService.putLicenzeByUtente(this.data.idUtente, this.data.codiceLicenza, this.formLicenze.getRawValue()).subscribe({
        next: (esito) => {
           
          this.utilityService.opneSnackBar('Licenze aggiornate ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
          this.dialogRef.close(esito);
        },
        error: (err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Errore Generico',
            bottoni: [{ nome_btn: 'Ok' }]
          })

        }
      })
    } else {
      this.licenzeService.postAbilitaLicenza(this.data.idUtente, this.formLicenze.getRawValue()).subscribe({
        next: (esito) => {
           
          this.utilityService.opneSnackBar('Licenze salvate ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
          this.dialogRef.close(esito);
        },
        error: (err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Errore Generico',
            bottoni: [{ nome_btn: 'Ok' }]
          })

        }
      })
    }
  }

}

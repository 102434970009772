import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { KPIService, Stdr } from 'src/app/services/KPI/kpi.service';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-targhet-stdr',
  templateUrl: './dialog-targhet-stdr.component.html',
  styleUrls: ['./dialog-targhet-stdr.component.scss']
})
export class DialogTarghetStdrComponent implements AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public checked = false;

  public arrayStrCheck: Stdr[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogTarghetStdrComponent>,
    private utilityService: UtilityService,
    private tematicaService: TematicheService,
    private kpiService: KPIService,
    @Inject(MAT_DIALOG_DATA) public data: { idKpi: string, stdr: Stdr[] }
  ) { }

  ngAfterViewInit(): void {
    this.getStdr();
  }

  salva() {
    this.spinnerOver.show();
    

    if (this.arrayStrCheck?.length) {

      this.kpiService.putStdr(this.data.idKpi, this.arrayStrCheck.filter((value) => value.checked)).subscribe({
        next: (result) => {
           
          this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });

          this.dialogRef.close(this.arrayStrCheck.filter((value) => value.checked) || []);
          this.spinnerOver.hide();
        },
        error: (err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio del kpi',
            bottoni: [{ nome_btn: 'Chiudi' }]
          })
          console.error(err);
          this.spinnerOver.hide();
        }
      });
    }
  }
  toggleCheckbox(event: any, stdr: Stdr) {
    if (!event.checked) {
      stdr.target = false;
    }
  }

  private getStdr() {
    this.spinnerOver.show();
    this.tematicaService.getStdr().subscribe({
      next: (result: {
        chiave: string;
        descrizione: string
      }[]) => {

        result.forEach((lsCompleta) => {
          const foundItem = this.data.stdr?.find((ls) => ls.chiave === lsCompleta.chiave);
          if (foundItem) {
            this.arrayStrCheck.push({
              chiave: lsCompleta.chiave,
              descrizione: lsCompleta.descrizione,
              target: foundItem.target,
              checked: true
            });
          } else {
            this.arrayStrCheck.push({
              chiave: lsCompleta.chiave,
              descrizione: lsCompleta.descrizione,
              target: false,
              checked: false
            });
          }
        });
        this.spinnerOver.hide();
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento dei stdr:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei stdr',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        this.spinnerOver.hide();
      }
    })
  }
}

import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { catchError, debounceTime, filter, finalize, firstValueFrom, forkJoin, map, merge, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { CfgQuestionarioComponent } from 'src/app/components/cfg-questionario/cfg-questionario.component';
import { SelezioneAzioniBuonepratichePolicyComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-azioni-buonepratiche-policy/selezione-azioni-buonepratiche-policy.component';
import { SelezioneCertificazioniPolicyComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-certificazioni-policy/selezione-certificazioni-policy.component';
import { SelezioneImpegniPolicyComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-impegni-policy/selezione-impegni-policy.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogCreaMaterialitaComponent } from 'src/app/page/analisi-materialita/materialita/dialog/dialog-crea-materialita/dialog-crea-materialita.component';
import { DialogCreaBilancioSostenibilitaComponent } from 'src/app/page/bilancio-sostebilita/dialog/dialog-crea-bilancio-sostenibilita/dialog-crea-bilancio-sostenibilita.component';
import { DialogCreaComitatiComponent } from 'src/app/page/configurazioni/cfg-comitati/dialog/dialog-crea-comitati/dialog-crea-comitati.component';
import { DialogAssegnazioniComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-assegnazioni/dialog-assegnazioni.component';
import { DialogCreaSintesiSostenibilitaComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-crea-sintesi-sostenibilita/dialog-crea-sintesi-sostenibilita.component';
import { AutovalutazioneCompilata, AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { BilancioService, BilancioSostenibilita } from 'src/app/services/bilancio/bilancio.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { PolicyService, PolicySostenibilita } from 'src/app/services/policy/policy.service';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { AnalisiMaterialita, ElementiCruscotto, SintesiService, SintesiSostenibilita } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { SelezionaComitatiComponent } from '../seleziona-comitati/seleziona-comitati.component';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { DialogAssociaFiguraAziendaleComponent } from 'src/app/components/selezione-elementi-questionario/dialog/dialog-associa-figura-aziendale/dialog-associa-figura-aziendale.component';
import { DialogCompilazioneFigureComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-compilazione-figure/dialog-compilazione-figure.component';
import { DialogCreaTemplateMailComponent } from 'src/app/page/analisi-materialita/materialita/dialog/dialog-crea-template-mail/dialog-crea-template-mail.component';
import { SelezionaPerimetroDestinatariComponent } from '../seleziona-perimetro-destinatari/seleziona-perimetro-destinatari.component';
import { DialogEditDescrizioneIndiceComponent } from 'src/app/page/bilancio-sostebilita/dialog/dialog-edit-descrizione-indice/dialog-edit-descrizione-indice.component';
import { CfgStampa, GruppoCfgStampa } from 'src/app/services/config-stampe/config-stampe.service';
import { ImportazioniService } from 'src/app/services/importazioni/importazioni.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NgScrollbar } from 'ngx-scrollbar';
import { CfgStampaComponent } from 'src/app/components/cfg-stampa/cfg-stampa.component';
import { PianoService, PianoSostenibilita } from 'src/app/services/piano/piano.service';
import { DialogCreaPianoSostenibilitaComponent } from 'src/app/page/piano-sostenibilita/dialog/dialog-crea-piano-sostenibilita/dialog-crea-piano-sostenibilita.component';

@Component({
  selector: 'app-dialog-crea-policy-sostenibilita',
  templateUrl: './dialog-crea-policy-sostenibilita.component.html',
  styleUrls: ['./dialog-crea-policy-sostenibilita.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { showError: true },
  }],
})
export class DialogCreaPolicySostenibilitaComponent {

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('btnAvanti') btnAvanti!: MatButton;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(CfgQuestionarioComponent) componenteSurvey!: CfgQuestionarioComponent;
  @ViewChild(SelezioneImpegniPolicyComponent) componenteSelImpegniPolicy!: SelezioneImpegniPolicyComponent;
  @ViewChild(SelezioneAzioniBuonepratichePolicyComponent) componenteSelAzioneBPPolicy!: SelezioneAzioniBuonepratichePolicyComponent;
  @ViewChild(SelezioneCertificazioniPolicyComponent) componenteSelCertificazioniPolicy!: SelezioneCertificazioniPolicyComponent;
  @ViewChild(SelezionaComitatiComponent) selezionaComitatiPolicy!: SelezionaComitatiComponent;
  @ViewChild(SelezionaPerimetroDestinatariComponent) selezionaPerimetroDestinatari!: SelezionaPerimetroDestinatariComponent;
  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild('scrollConfStampa') scrollConfStampa!: NgScrollbar;
  @ViewChild(CfgStampaComponent) cfgStampaComponent!: CfgStampaComponent;

  public mappaAnnoSettore: { [anno: string]: { [settore: string]: boolean } } = {};
  public arrayAnni: string[] = [];
  public settoriAzienda: Settore[] = [];
  public guidaDescrizione: string | undefined = undefined;
  public mostraDescrizione: boolean = false;
  public arraySottoSettori: SottoSettore[] = [];
  public settoreGenerale: Settore | undefined = undefined;

  public selectedAnalisiMaterialita: string[] = [];
  public arrayAnalisiMaterialita: AnalisiMaterialita[] = [];
  public arrayAnalisiAutovalutazione: AutovalutazioneCompilata[] = [];
  public arraySintesiSostenibilita: SintesiSostenibilita[] = [];
  public arrayBilancioSostenibilita: BilancioSostenibilita[] = [];
  public arrayPianoSostenibilita: PianoSostenibilita[] = [];
  public loadingAnalisiMaterialita = false;
  public loadingAutovalutazione = false;
  public mostraBottoneAssegnazioni: boolean = false;
  public idQuestionario: string | undefined = undefined;

  public objQuestionarioImpegni = [];
  public objQuestionarioAzioniBP = [];
  public objQuestionarioCertificazioni = [];
  public objQuestionarioComitati = [];

  public certificazioniNonCompilate: number = 0;
  public certificazioniCompilate: number = 0;

  public azioneCompilati: number = 0;
  public azioneNonCompilati: number = 0;

  public impegniCompilati: number = 0;
  public impegniNonCompilati: number = 0;

  private _subCambioValoriSettoreSottoSettore: Subscription | undefined = undefined;
  private _subValueChangeSettore: Subscription | undefined = undefined;
  private _subValueChangeTipoSettore: Subscription | undefined = undefined;


  private _policy: any = {};

  get policy() {
    return this._policy;
  }

  public formPolicySostenibilita = new FormGroup({

    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    idAzienda: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    anno: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    tipoSettore: new FormControl<'GENERALE' | 'SPECIFICO' | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    settori: new FormControl<Settore | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    sottoSettori: new FormControl<SottoSettore[]>([], {
      nonNullable: true
    }),

    idMaterialita: new FormControl<string[] | AnalisiMaterialita>({ disabled: true, value: [] }, {
      nonNullable: true,
      validators: [],
    }),

    idAutovalutazioneCompilata: new FormControl<string | AutovalutazioneCompilata>('', {
      nonNullable: true,
      validators: [],
    }),

    idSintesiSostenibilita: new FormControl<string | SintesiSostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),

    idBilancioSostenibilita: new FormControl<string | BilancioSostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),

    idPianoSostenibilita: new FormControl<string | PianoSostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),

    tipo: new FormControl<string>('POLICY', {
      nonNullable: true,
      validators: [],
    }),
  });

  public filtri: Filtri[] = [
    {
      titolo: 'Figure Aziendali',
      forControlName: 'figureAziendali',
      input: 'text',
    },
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Cognome',
      forControlName: 'cognome',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    stakeholder: {
      title: 'Figure Aziendali',
      value: 'figura'
    },
    tipologia: {
      title: 'Nome',
      value: 'nome'
    },
    email: {
      title: 'Email',
      value: 'email'
    },
    stato: {
      title: 'Stato',
      value: (record: any) => {
        if (record?.stato === 'ROSSO') { // non compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'GIALLO') { // ha inviato la mail ma non è stato compilato (valore solo per gli stakeholder con modalita compilazione "INVIAMAIL")
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'VERDE') { // compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else {
          return '';
        }
      }
    },
    history: {
      type: 'button',
      buttonIcon: 'history_edu',
      title: 'Figure',
      buttonId: 'figure',
      buttonMostraSempre: true
    },

    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      nascondiButton: (record) => (record?.stato === 'VERDE'),
      buttonMostraSempre: true
    },
  };

  constructor(
    private importazioniService: ImportazioniService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogCreaPolicySostenibilitaComponent>,
    private materialitaService: MaterialitaService,
    private utilityService: UtilityService,
    private aziendaService: AziendaService,
    private settoriService: SettoriService,
    private sintesiService: SintesiService,
    private bilancioService: BilancioService,
    private policyService: PolicyService,
    private pianoService: PianoService,
    private autovalutazioniService: AutovalutazioniService,
    @Inject(MAT_DIALOG_DATA) public data: {
      policy: PolicySostenibilita
    }) {
    const annoCorrente = new Date().getFullYear();

    // Aggiungi gli anni indietro di 5 anni e in avanti di 1 anno all'array
    for (let i = annoCorrente - 5; i <= annoCorrente + 1; i++) {
      this.arrayAnni.push(i.toString());
    }

    this.aziendaService.azienda.then((azienda) => {
      if (azienda) {
        this.formPolicySostenibilita.get('idAzienda')?.setValue(azienda.id);

      }
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    }, 0);
  }

  async init() {

    this.spinnerOver.show();

    await this._caricaSettori();


    // Ad ogni cambio di settore o sottosettore bisogna refreshare materialità e autovalutazioni (assessment).
    // Si usa un merge per evitare che partano chiamate multiple parallele quando cambiano settori e/o sottoSettori.
    this._subCambioValoriSettoreSottoSettore = merge(
      this.formPolicySostenibilita.get('settori')!.valueChanges,
      this.formPolicySostenibilita.get('sottoSettori')!.valueChanges
    ).pipe(
      debounceTime(500)
    ).subscribe((value) => {
      if (this.formPolicySostenibilita.get('settori')?.value && !this.formPolicySostenibilita.get('id')?.value) {

        if (this.formPolicySostenibilita.get('settori')?.value) {
          this._cambioSelSettore();
        }
      }
    });

    this._subValueChangeSettore = this.formPolicySostenibilita.get('settori')!.valueChanges.subscribe((value) => {

      if (value) {
        this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === value.id)?.sottoSettori || [];
      }

      this._updateSottoSettoriSelezionati();

    });

    this._subValueChangeTipoSettore = this.formPolicySostenibilita.get('tipoSettore')?.valueChanges.subscribe((value) => {
      if (value === 'GENERALE') {
        this.formPolicySostenibilita.get('settori')?.setValue(this.settoreGenerale);
      } else if (value === 'SPECIFICO') {
        this.formPolicySostenibilita.get('settori')?.setValue(this.settoriAzienda?.length ? this.settoriAzienda[0] : undefined);
      }
    });

    if (this.data.policy) {
      await this._setDatiPolicy(this.data.policy);

    } else if (this.settoriAzienda?.length) {
      // Preselezione settore
      this.formPolicySostenibilita.get('tipoSettore')?.setValue('SPECIFICO');
      this.formPolicySostenibilita.get('settori')?.setValue(this.settoriAzienda[0]);
    } else {
      this.formPolicySostenibilita.get('tipoSettore')?.setValue('GENERALE');
    }


    this.formPolicySostenibilita.get('idSintesiSostenibilita')?.disable();
    this.formPolicySostenibilita.get('idMaterialita')?.disable();
    this.formPolicySostenibilita.get('idAutovalutazioneCompilata')?.disable();
    this.formPolicySostenibilita.get('idBilancioSostenibilita')?.disable();
    this.formPolicySostenibilita.get('idPianoSostenibilita')?.disable();
    this.spinnerOver.hide();

  }

  ngOnDestroy(): void {
    this._subValueChangeSettore?.unsubscribe();
    this._subValueChangeTipoSettore?.unsubscribe();
    this._subCambioValoriSettoreSottoSettore?.unsubscribe();
  }

  /**
   * Metodo che nel cambio del settore mi ricalcola le selezioni
   * di sintesi materialità, bilancio e assessment pubblicate
   */
  private _cambioSelSettore() {
    this.spinnerOver.show()
    this.formPolicySostenibilita.get('idSintesiSostenibilita')?.enable();
    this.formPolicySostenibilita.get('idMaterialita')?.enable();
    this.formPolicySostenibilita.get('idAutovalutazioneCompilata')?.enable();
    this.formPolicySostenibilita.get('idBilancioSostenibilita')?.enable();
    this.formPolicySostenibilita.get('idPianoSostenibilita')?.enable();

    const anno = this.formPolicySostenibilita.get('anno')?.value;
    const idSettore = this.formPolicySostenibilita.get('settori')?.value?.id;
    const idSottoSettori: string[] = (this.formPolicySostenibilita.get('sottoSettori')?.value?.map(sottoSet => sottoSet.id).filter(val => val !== undefined) || []) as string[];

    if (idSettore) {
      firstValueFrom(this.getForkDatiIniziali(idSettore, idSottoSettori));
    }

    if (anno && idSettore) {
      firstValueFrom(this._getAutovalutazioniCompilateObs(anno, idSettore, idSottoSettori));
    }

  }

  /**
* Setta nel formGroup i dati della policy
* @param policy
*/
  private async _setDatiPolicy(policy: PolicySostenibilita) {

    let settoreSel: Settore | undefined = policy.settori?.length ? policy.settori[0] : undefined;
    let anno: string | undefined = policy.anno;

    const idSottoSettori: string[] = ((policy.sottoSettori || []).map((sottoSet: SottoSettore) => sottoSet.id).filter((val: string | undefined) => val !== undefined) || []) as string[];

    if (settoreSel) {
      await firstValueFrom(this.getForkDatiIniziali(settoreSel.id, idSottoSettori))
      if (anno) {
        await firstValueFrom(this._getAutovalutazioniCompilateObs(anno, settoreSel.id, idSottoSettori))
      }
    }

    Object.keys(policy).forEach((value) => {

      if (value === 'settori') {
        // Settore si imposta per ultimo, per non far scattare il change quando non è ancora settato l'array dei sottosettori
        settoreSel = (policy as any)[value][0];
      } else {
        this.formPolicySostenibilita.get(value)?.setValue((policy as any)[value]);
      }
    });

    if (settoreSel) {

      if (!this.settoriAzienda.find((set) => set.id === settoreSel!.id)) {
        // Il settore del bilancio non è negli array dei settori azienda.
        // Potrebbe essere stato tolto a posteriori, dopo la creazione del policy.
        // Per non lasciare il campo vuoto, si setta nell'array il settore che viene dal policy.
        this.settoriAzienda = [settoreSel];
      }

      this.formPolicySostenibilita.get('settori')?.setValue(settoreSel);
    }

    if (policy.idMaterialita) {
      // cerco il nome della materilita in base all id
      const analisiMaterialita = this.arrayAnalisiMaterialita.find(analisi => analisi.idMaterialita === policy.idMaterialita)
      if (analisiMaterialita) {
        this.formPolicySostenibilita.get('idMaterialita')?.setValue(analisiMaterialita);
        this._cambiaInfoBoxDesc(analisiMaterialita.anno);
      }
    }

    if (policy.idSintesiSostenibilita) {
      const sintesiSostenibilita = this.arraySintesiSostenibilita.find(sintesi => sintesi.id === policy.idSintesiSostenibilita)
      if (sintesiSostenibilita) {
        this.formPolicySostenibilita.get('idSintesiSostenibilita')?.setValue(sintesiSostenibilita);
      }
    }

    if (policy.idBilancioSostenibilita) {
      const bilancioSostenibilita = this.arrayBilancioSostenibilita.find(bilancio => bilancio.id === policy.idBilancioSostenibilita)
      if (bilancioSostenibilita) {
        this.formPolicySostenibilita.get('idBilancioSostenibilita')?.setValue(bilancioSostenibilita);
      }
    }

    if (policy.idPianoSostenibilita) {
      const pianoSostenibilita = this.arrayPianoSostenibilita.find(piano => piano.id === policy.idPianoSostenibilita)
      if (pianoSostenibilita) {
        this.formPolicySostenibilita.get('idPianoSostenibilita')?.setValue(pianoSostenibilita);
      }
    }

    if (policy.idAutovalutazioneCompilata) {
      const autovalutazioneCompilata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === policy.idAutovalutazioneCompilata)
      if (autovalutazioneCompilata) {
        this.formPolicySostenibilita.get('idAutovalutazioneCompilata')?.setValue(autovalutazioneCompilata);
      }
    }

    //Disabilito campi in modifica
    if (this.formPolicySostenibilita.get('id')?.value) {
      this.formPolicySostenibilita.get('anno')?.disable();
      this.formPolicySostenibilita.get('settori')?.disable();
      this.formPolicySostenibilita.get('sottoSettori')?.disable();
    }
  }

  /**
   * Bottone indietro
   * @returns step precedente
   */
  public indietro() {
    switch (this.stepper.selectedIndex) {
      case 0:
        return true;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        this.stepper.previous();
        return true;
      default:
        return null;
    }
  }

  /**
   * Bottone avanti che salva i dati sullo step attuale
   * @returns metodo in base allo step attuale
   */
  public avanti() {
    switch (this.stepper.selectedIndex) {
      case 0:
        return this._salvaStepPolicy();
      case 1:
        return this._salvaStepTematiche();
      case 2:
        return this._salvaStepImpengi();
      case 3:
        return this._salvaStepAzioniBP();
      case 4:
        return this._salvaStepCertificazioni();
      case 5:
        return this._salvaStepComitati();
      case 6:
        this.stepper.next();
        return true;
      case 7:
        return this._salvaPerimetriDestinatari();
      default:
        return;
    }
  }

  /* Mi carica i dati nello step selezionato */
  cambioStep(event: StepperSelectionEvent) {
    this.mostraBottoneAssegnazioni = false;

    switch (event.selectedIndex) {
      case 0:
        return;

      case 1:
        this.btnAvanti.disabled = true;
        return this._caricaStepTematiche();

      case 2:
        this.mostraBottoneAssegnazioni = true;
        this.btnAvanti.disabled = true;

        return this._getImpegniPolicy();

      case 3:
        this.mostraBottoneAssegnazioni = true;
        this.btnAvanti.disabled = true;

        return this._getAzioniBPPolicy();

      case 4:
        this.mostraBottoneAssegnazioni = true;
        this.btnAvanti.disabled = true;

        return this._getCertificazioniPolicy();

      case 5:
        return this._getComitatiPolicy();

      case 6:
        return this._getCruscottoPolicy();

      case 7:
        return this._getPerimetriDestinatari();

      case 8:
        return this.cfgStampaComponent.caricaStampa();

      default:
        return;
    }
  }

  onSelectionChangeMaterialita(event: MatSelectChange) {
    if (event?.value?.anno) {
      this._cambiaInfoBoxDesc(event?.value?.anno)
    }
  }

  onSelectionChangeAnno() {

    const anno = this.formPolicySostenibilita.get('anno')?.value;
    const idSettore = this.formPolicySostenibilita.get('settori')?.value?.id;
    const annoMaterialita = (this.formPolicySostenibilita.get('idMaterialita')?.value as AnalisiMaterialita).anno;
    const idSottoSettori: string[] = (this.formPolicySostenibilita.get('sottoSettori')?.value?.map(sottoSet => sottoSet.id).filter(val => val !== undefined) || []) as string[];

    if (anno && idSettore) {
      firstValueFrom(this._getAutovalutazioniCompilateObs(anno, idSettore, idSottoSettori));
    }
    if (annoMaterialita) {
      this._cambiaInfoBoxDesc(annoMaterialita)
    }

  }

  onSelectionChangeAutovalutazione(event: MatSelectChange) { }

  compareById(elemento1: any, elemento2: any): boolean {
    return elemento1?.id === elemento2?.id; // Sostituisci "id" con la proprietà univoca dei settori
  }

  compareAnalisiMaterialita(materialita1: AnalisiMaterialita, materialita2: AnalisiMaterialita): boolean {
    return materialita1.idMaterialita === materialita2.idMaterialita; // Sostituisci "id" con la proprietà univoca delle materialità
  }
  compareAnalisiAutovalutazione(autovalutazione1: AutovalutazioneCompilata, autovalutazione2: AutovalutazioneCompilata): boolean {
    return autovalutazione1.id === autovalutazione2.id;
  }

  onSelectionChangeSintesi(event: MatSelectChange) {
  }
  compareSintesiSostenibilita(sintesi1: SintesiSostenibilita, sintesi2: SintesiSostenibilita): boolean {
    return sintesi1.id === sintesi2.id; // Sostituisci "id" con la proprietà univoca delle sintesi
  }

  onSelectionChangeBilancio(event: MatSelectChange) {
  }
  compareBilancioSostenibilita(bilancio1: BilancioSostenibilita, bilancio2: BilancioSostenibilita): boolean {
    return bilancio1.id === bilancio2.id; // Sostituisci "id" con la proprietà univoca delle bilancio
  }

  onSelectionChangePiano(event: MatSelectChange) {
  }
  comparePianoSostenibilita(piano1: PianoSostenibilita, piano2: PianoSostenibilita): boolean {
    return piano1.id === piano2.id; // Sostituisci "id" con la proprietà univoca delle piano
  }

  /* Gestione cancellazione in mat select */
  hasValue(formControlName: string): boolean {
    const control = this.formPolicySostenibilita.get(formControlName);
    const controlValue = control?.value;

    if (formControlName === 'idMaterialita') {
      return !!(this.data.policy || !controlValue || (Array.isArray(controlValue) && controlValue.length === 0) || control.status === 'DISABLED');
    } else {
      return !!(this.data.policy || !controlValue || control.status === 'DISABLED');
    }
  }

  clearInput(formControlName: string, event?: Event): void {
    const field = this.formPolicySostenibilita.get(formControlName);
    if (!field?.disabled) {
      field?.reset();
      field?.markAsDirty();
      field?.markAsTouched();
    }
    if (event) event.stopPropagation();

    const idMaterialitaValue = this.formPolicySostenibilita.get('idMaterialita')?.value;
    if (!idMaterialitaValue || !Array.isArray(idMaterialitaValue) || idMaterialitaValue.length === 0) {
      this.mostraDescrizione = false;
    }
  }

  mostraDescrizioneAssessment() {
    return this.arrayAnalisiAutovalutazione.length ? 'Assessment' : 'Assessment non selezionato'
  }
  mostraDescrizioneMaterialita() {
    return this.arrayAnalisiMaterialita.length ? 'Analisi Materialità' : 'Materialità non selezionata'
  }
  mostraDescrizioneSintesi() {
    return this.arraySintesiSostenibilita.length ? 'Sintesi di Sostenibilità' : 'Sintesi non selezionata'
  }
  mostraDescrizioneBilancio() {
    return this.arrayBilancioSostenibilita.length ? 'Bilancio di Sostenibilità' : 'Bilancio non selezionato'
  }
  mostraDescrizionePiano() {
    return this.arrayPianoSostenibilita.length ? 'Piano di Sostenibilità' : 'Piano non selezionato'
  }

  /**
   * Metodo gestione infobox quando scelta la materialità
   * Controllo se anno della materialità ha anzianità >= 2 anni
   * @param annoMaterialita
   */
  private _cambiaInfoBoxDesc(annoMaterialita: string) {

    const annoControl = this.formPolicySostenibilita.get('anno');
    if (annoControl) {

      const annoSintesi = parseInt(annoControl.value, 10);
      const annoMaterialitaNum: number = parseInt(annoMaterialita, 10);

      const diffAnno: number = annoSintesi - annoMaterialitaNum;
      if (diffAnno >= 2) {
        this.guidaDescrizione = "Sono passati 2 anni dall'Analisi di Materialità selezionata";
        this.mostraDescrizione = true;
      } else {
        this.mostraDescrizione = false;
      }
    }
  }

  /**
   * Gestione caricamento settori e sotto settori
   */
  private async _caricaSettori() {
    this.spinnerOver.show();
    try {
      const risp = await firstValueFrom(
        forkJoin({
          generale: this.settoriService.getSettoreGenerale().pipe(
            catchError((err) => {
              console.error('ERRORE GET SETTORE GENERALE : ', err);
              // Errore recupero settore generale, potrebbe non esistere. Si torna undefined
              return of(undefined);
            })
          ),
          specifici: this.aziendaService.getSettoriAzienda(),
          policyCreate: this.policyService.getPolicySostenibilita(0, 1000, '', []).pipe(
            catchError((err) => {
              console.error('ERRORE GET POLICY CREATE : ', err);
              return of(undefined);
            })
          ),
        })
      );

      this.settoriAzienda = risp?.specifici || [];
      this.settoreGenerale = risp?.generale;

      for (const policy of risp?.policyCreate?.content) {
        const anno = policy.anno;
        const settore = policy.settori[0]?.id;

        if (!this.mappaAnnoSettore[anno]) {
          this.mappaAnnoSettore[anno] = {};
        }

        this.mappaAnnoSettore[anno][settore] = true;
      }

      if (!this.settoriAzienda?.length && !this.settoreGenerale) {
        console.error('NESSUN SETTORE DISPONIBILE, IMPOSSIBILE PROSEGUIRE');
      }

      this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === this.formPolicySostenibilita.get('settori')?.value?.id)?.sottoSettori || [];

      this.spinnerOver.hide();

    } catch (error) {

      console.error(error);

      this.spinnerOver.hide();

      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Errore nel recupero dei settori',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      this.dialogRef.close();

    }

  }

  private _updateSottoSettoriSelezionati() {
    const sottoSettoriSel = this.formPolicySostenibilita.get('sottoSettori')?.value || [];

    const sottoSettoriaggiornati = sottoSettoriSel.filter(
      (sottoSettSel) => {
        return this.arraySottoSettori && sottoSettSel.id && !!this.arraySottoSettori.find(
          (sottoSett) => {
            return sottoSett.id === sottoSettSel.id;
          });
      }
    );

    this.formPolicySostenibilita.get('sottoSettori')?.setValue(sottoSettoriaggiornati || []);
  }

  /**
     * ForkJoin che mi valorizza array delle sintesi bilancio e materialità pubblicate
     * @param idSettore
     * @param idSottoSettori
     * @returns
     */
  public getForkDatiIniziali(idSettore: string, idSottoSettori?: string[]): Observable<any> {
    if (idSettore) {
      return forkJoin({
        materialita: this._getMaterialitaPubblicateObs(idSettore),
        sintesi: this._getSintesiPubblicateObs(idSettore, idSottoSettori),
        bilnacio: this._getBilanciPubblicatiObs(idSettore, idSottoSettori),
        piano: this._getPianiPubblicatiObs(idSettore, idSottoSettori),
      }).pipe(
        switchMap((results) => {
          this.arrayAnalisiMaterialita = results.materialita;
          this.arraySintesiSostenibilita = results.sintesi;
          this.arrayBilancioSostenibilita = results.bilnacio;
          this.arrayPianoSostenibilita = results.piano;
          this.spinnerOver.hide();
          return of(true)
        }),
        catchError((err) => {
          console.error(err);
          this.spinnerOver.hide();
          return of(false)
        })
      )
    } else {
      this.spinnerOver.hide();
      return of(false)
    }
  }

  /**
  * Metodo che mi ritorna la lista delle materialità consolidate
  */
  private _getMaterialitaPubblicateObs(idSettore: string): Observable<any> {
    const sottoSettori = (this.formPolicySostenibilita.get('sottoSettori')?.value || []).map(s => s.id).filter(id => id !== undefined) as string[];
    return this.materialitaService.getMaterialitaPubblicate(idSettore, sottoSettori).pipe(
      map((esito: any) => esito.map((item: any) => ({
        idMaterialita: item.id,
        nome: 'Analisi Materialità ' + item.anno,
        anno: item.anno
      }))),
      catchError(() => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento materialità pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  /**
  * Metodo che mi ritorna la lista delle sintesi pubblicate
  */
  private _getSintesiPubblicateObs(idSettore?: string, idSottoSettore?: string[]): Observable<any> {

    return this.sintesiService.getSintesiPubblicate(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Sintesi Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento sintesi pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  /**
* Metodo che mi ritorna la lista dei bilanci pubblicati
*/
  private _getBilanciPubblicatiObs(idSettore?: string, idSottoSettore?: string[]): Observable<any> {

    return this.bilancioService.getBilanciPubblicati(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Bilancio Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento dei bilanci pubblicati',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  /**
* Metodo che mi ritorna la lista dei piano pubblicati
*/
  private _getPianiPubblicatiObs(idSettore?: string, idSottoSettore?: string[]): Observable<any> {

    return this.pianoService.getPianiPubblicate(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Piano Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento dei piano pubblicati',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  /**
  * Metodo che mi ritorna la lista delle autovalutazioni compilate
  */
  private _getAutovalutazioniCompilateObs(anno: string, idSettore: string, idSottoSettori?: string[]): Observable<any> {

    this.spinnerOver.show();

    return this.autovalutazioniService.getAutovalutazioniCompilate(anno, idSettore, idSottoSettori).pipe(
      switchMap((result) => {
        this.arrayAnalisiAutovalutazione = result;

        // Controlla il valore già presente nella form. Se non esiste nell'array, si azzera
        const autovalPreSelez = this.formPolicySostenibilita.get('idAutovalutazioneCompilata')?.value;
        let autovalutazioneTrovata: AutovalutazioneCompilata | undefined = undefined;
        if (autovalPreSelez && typeof autovalPreSelez !== 'string') {
          autovalutazioneTrovata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === autovalPreSelez.id);
        }

        if (!autovalutazioneTrovata) {
          // valore preselezionato non trovato, si azzera
          this.formPolicySostenibilita.get('idAutovalutazioneCompilata')?.setValue('');
        }

        this.formPolicySostenibilita.get('idAutovalutazioneCompilata')?.updateValueAndValidity();

        this.spinnerOver.hide();
        return of(true)
      }),
      catchError((err) => {
        console.error(err);
        this.spinnerOver.hide();
        return of(false)
      })
    )
  }

  /**
   * Metodo per aggiungere una materialità se lista materialità consolidate è vuota
   */
  addMaterialita() {
    let idSettore = this.formPolicySostenibilita.get('settori')?.value?.id;

    if (idSettore) {

      const dialogCreaAmbito = this.dialog.open(DialogCreaMaterialitaComponent, {
        data: {},
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaAmbito.afterClosed().subscribe(() => {

        idSettore && this._getMaterialitaPubblicateObs(idSettore).subscribe();

      });

    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  /**
 * Metodo per aggiungere una sintesi se lista sintesi pubblicate è vuota
 */
  addSintesi() {
    if (this.formPolicySostenibilita.get('settori')?.value?.id) {

      let idSettore = this.formPolicySostenibilita.get('settori')?.value?.id;

      const dialogCreaAmbito = this.dialog.open(DialogCreaSintesiSostenibilitaComponent, {
        data: {
          /* sintesi: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaAmbito.afterClosed().subscribe((result) => {
        this._getSintesiPubblicateObs(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  /**
  * Metodo per aggiungere una sintesi se lista bilanci pubblicati è vuota
  */
  addBilancio() {
    if (this.formPolicySostenibilita.get('settori')?.value?.id) {

      let idSettore = this.formPolicySostenibilita.get('settori')?.value?.id;

      const dialogCreaBilancio = this.dialog.open(DialogCreaBilancioSostenibilitaComponent, {
        data: {
          /* sintesi: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaBilancio.afterClosed().subscribe((result) => {
        this._getBilanciPubblicatiObs(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  /**
  * Metodo per aggiungere una sintesi se lista piani pubblicati è vuota
  */
  addPiano() {
    if (this.formPolicySostenibilita.get('settori')?.value?.id) {

      let idSettore = this.formPolicySostenibilita.get('settori')?.value?.id;

      const dialogCreaPiano = this.dialog.open(DialogCreaPianoSostenibilitaComponent, {
        data: {
          /* sintesi: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaPiano.afterClosed().subscribe((result) => {
        this._getPianiPubblicatiObs(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  /**
   * Metodo che mi fa la get del questionario della Policy
   * @param id id Policy
   * @returns
   */
  private _getQuestionarioPolicy(id: string) {

    return this.policyService.getQuestionarioPolicy(id).pipe(catchError((err) => {
      console.error(err);
      if (err.status === 404) {
        return of(false);
      } else {
        return throwError(() => err);
      }
    })).pipe(map((policy) => {
      if (policy) {
        this._policy = policy;
      }
      return policy;
    }));
  }


  //******************************* METODI CARICAMENTO PER cambioStep() *************************************/

  private _caricaStepTematiche() {
    const id = this.formPolicySostenibilita.get('id')?.value;

    const idSettore = (this.formPolicySostenibilita.get('settori')?.value as Settore).id
    this.componenteSurvey.datiInizialiCfgQuestionario(idSettore);

    if (!id) {
      return;
    }

    this.spinnerOver.show();

    this._getQuestionarioPolicy(id).subscribe(
      {
        next: (policy: any) => {

          if (policy) {

            this._policy = policy;

          }
          this.btnAvanti.disabled = false;
          this.spinnerOver.hide();
        },
        error: (err: any) => {
          console.error(err);
          this.btnAvanti.disabled = false;

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero della policy',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          this.spinnerOver.hide();
        }
      }
    )
  }

  /**
 * Get ws impegni policy
 * @returns
 */
  private _getImpegniPolicy() {
    const id = this.formPolicySostenibilita.get('id')?.value;

    if (!id) {
      return;
    }
    this.spinnerOver.show();
    this.policyService.getImpegniPolicy(id).subscribe({
      next: (esito) => {
        this.objQuestionarioImpegni = esito
        this.btnAvanti.disabled = false;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero degli impegni',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        this.spinnerOver.hide();
      }
    })
  }

  /**
 * Get ws azioni buone pratiche policy
 * @returns
 */
  private _getAzioniBPPolicy() {
    const id = this.formPolicySostenibilita.get('id')?.value;

    if (!id) {
      return;
    }
    this.spinnerOver.show();
    this.policyService.getAzioniBPPolicy(id).subscribe({
      next: (esito) => {
        this.objQuestionarioAzioniBP = esito
        this.btnAvanti.disabled = false;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle azioni buone pratiche',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        this.spinnerOver.hide();
      }
    })
  }

  /**
  * Get Certificazioni Policy
  * @returns
  */
  private _getCertificazioniPolicy() {
    const id = this.formPolicySostenibilita.get('id')?.value;

    if (!id) {
      return;
    }
    this.spinnerOver.show();
    this.policyService.getCertificazioniPolicy(id).subscribe({
      next: (esito) => {
        this.objQuestionarioCertificazioni = esito
        this.btnAvanti.disabled = false;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle certificazioni',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        this.spinnerOver.hide();
      }
    })
  }

  /**
  * Get Comitati Policy
  * @returns
  */
  private _getComitatiPolicy() {
    const id = this.formPolicySostenibilita.get('id')?.value;

    if (!id) {
      return;
    }

    this.selezionaComitatiPolicy.getComitatiConFigureAziendali();
  }

  /**
   * Get Dati Cruscotto policy
   */
  private _getCruscottoPolicy() {
    this.spinnerOver.show();
    this.tabella.caricaDati();

    const idPolicySostenibilita = this.formPolicySostenibilita.get('id')?.value
    if (idPolicySostenibilita) {
      this._caricaTotaliCruscotto(idPolicySostenibilita);
    }
  }

  /**
   * Carica i dati della tabella cruscotto policy
   * @param page
   * @param pageSize
   * @param ricerca
   * @param filters
   * @param sortBy
   * @returns
   */
  public fnCaricamentoDatiCruscotto: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    const idPolicySostenibilita = this.formPolicySostenibilita.get('id')?.value

    if (idPolicySostenibilita) {
      return this.policyService.getCruscotti(idPolicySostenibilita, page, pageSize, ricerca, filters, sortBy);
    } else {
      return of(false);
    }
  };

  /**
   * Valorizza le liste con i totali del cruscotto
   * @param idPolicySostenibilita
   */
  private _caricaTotaliCruscotto(idPolicySostenibilita: string) {
    this.spinnerOver.show();
    this.policyService.getTotaliCruscotto(idPolicySostenibilita).subscribe({
      next: (totaliCruscotto) => {
        this.certificazioniCompilate = totaliCruscotto.nrCertificazioniCompilate || 0;
        this.certificazioniNonCompilate = (totaliCruscotto.nrCertificazioni || 0) - this.certificazioniCompilate;
        this.azioneCompilati = totaliCruscotto.nrAzioniCompilate || 0;
        this.azioneNonCompilati = (totaliCruscotto.nrAzioni || 0) - this.azioneCompilati;
        this.impegniCompilati = totaliCruscotto.nrImpegniCompilati || 0;
        this.impegniNonCompilati = (totaliCruscotto.nrImpegni || 0) - this.impegniCompilati;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }

  /**
   * Get Perimetri e Destinatari da compilare
   * @returns
   */
  private _getPerimetriDestinatari() {

    const id = this.formPolicySostenibilita.get('id')?.value;

    if (!id) {
      return;
    }

    this.selezionaPerimetroDestinatari.getPerimetriDestinatari().subscribe();
  }

  //******************************* METODI SALVATAGGIO PER avanti() *************************************/

  /**
* Metodo salvataggio STEP 1 Policy
* @returns
*/
  private async _salvaStepPolicy() {
    if (!this.data?.policy?.idQuestionario && this._policy.stato !== 'PUBBLICATO') {
      if (this.formPolicySostenibilita.valid) {
        const dialog = await this.utilityService.openDialog({
          titolo: 'Avviso',
          descrizione: 'Continuando non sarà più possibile modificare queste informazioni.',
          bottoni: [
            {
              nome_btn: 'Annulla',
              id_btn: 'N'
            },
            {
              nome_btn: 'Avanti',
              id_btn: 'S'
            },
          ]
        });

        const valDialog = await firstValueFrom(dialog.beforeClosed());
        if (valDialog === 'N') {
          return;
        }
      }
    }

    if (this.formPolicySostenibilita.valid) {
      this.spinnerOver.show();

      let formCrtSostenibilita = this.formPolicySostenibilita.getRawValue();

      const nuovaPolicy = {
        ...formCrtSostenibilita,
        idMaterialita: (formCrtSostenibilita.idMaterialita as AnalisiMaterialita)?.idMaterialita || '',
        idSintesiSostenibilita: (formCrtSostenibilita.idSintesiSostenibilita as SintesiSostenibilita)?.id || '',
        idAutovalutazioneCompilata: (formCrtSostenibilita.idAutovalutazioneCompilata as AutovalutazioneCompilata)?.id || '',
        idBilancioSostenibilita: (formCrtSostenibilita.idBilancioSostenibilita as BilancioSostenibilita)?.id || '',
        idPianoSostenibilita: (formCrtSostenibilita.idPianoSostenibilita as PianoSostenibilita)?.id || '',
      };

      const idPolicy = this.formPolicySostenibilita.get('id')?.value;
      if (idPolicy) {
        return this.policyService.putAggiornamentoPolicyStep1(idPolicy).subscribe({
          next: (esito => {
            /*    this.idQuestionario = esito?.idQuestionario; */ //!Valutare se rimuovere questa variabile globalmente

            this.stepper.next();
          }),
          error: (err => {
            console.error('erroe salvataggio step1', err)
          })
        })
      } else {

        return this.policyService.postCreazionePolicyStep1(nuovaPolicy).subscribe({
          next: (esito => {
            /*  this.idQuestionario = esito?.idQuestionario; */
            this.formPolicySostenibilita.get('id')?.setValue(esito.id)

            this.formPolicySostenibilita.get('anno')?.disable();
            this.formPolicySostenibilita.get('settori')?.disable();
            this.formPolicySostenibilita.get('sottoSettori')?.disable();
            this.formPolicySostenibilita.get('idSintesiSostenibilita')?.disable();
            this.formPolicySostenibilita.get('idMaterialita')?.disable();
            this.formPolicySostenibilita.get('idAutovalutazioneCompilata')?.disable();
            this.formPolicySostenibilita.get('idBilancioSostenibilita')?.disable();
            this.formPolicySostenibilita.get('idPianoSostenibilita')?.disable();

            this.stepper.next();
          }),
          error: (err => {
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err.error.message,
              bottoni: [{ nome_btn: 'Ok' }]
            })
            this.spinnerOver.hide();

          })
        })
      }
    } else {
      Object.values(this.formPolicySostenibilita.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  /**
    * Metodo salvataggio STEP  TEMATICHE
    * @returns
    */
  private _salvaStepTematiche() {

    if (this.componenteSurvey.stato === 'PUBBLICATO') {
      this.stepper.next();
    } else {
      this.spinnerOver.show();
      this.componenteSurvey.salva().subscribe((esito) => {
        if (esito) {
          this.stepper.next();
        } else {
          this.spinnerOver.hide();
        }
      });
    }
    return true;
  }

  /**
    * Metodo salvataggio STEP 6 IMPEGNI
    * @returns
    */
  private _salvaStepImpengi() {

    if (this.componenteSurvey.stato === 'PUBBLICATO') {
      this.stepper.next();

    } else {
      this.spinnerOver.show();
      this.componenteSelImpegniPolicy.salva().subscribe((esito) => {
        if (esito) {
          this.stepper.next();
        } else {
          this.spinnerOver.hide();
        }
      });
    }
    return true;
  }
  /**
    * Metodo salvataggio STEP 7 AZIONI E BUONE PRATICHE
    * @returns
    */
  private _salvaStepAzioniBP() {

    if (this.componenteSurvey.stato === 'PUBBLICATO') {
      this.stepper.next();
    } else {
      this.spinnerOver.show();
      this.componenteSelAzioneBPPolicy.salva().subscribe((esito) => {
        if (esito) {
          this.stepper.next();
        } else {
          this.spinnerOver.hide();
        }
      });
    }
    return true;
  }
  /**
    * Metodo salvataggio Certificazioni
    * @returns
    */
  private _salvaStepCertificazioni() {
    if (this.componenteSurvey.stato === 'PUBBLICATO') {
      this.stepper.next();
    } else {
      this.spinnerOver.show();
      this.componenteSelCertificazioniPolicy.salva().subscribe((esito) => {
        if (esito) {
          this.stepper.next();
          this.spinnerOver.hide();
        } else {
          this.spinnerOver.hide();
        }
      });
    }
    return true;
  }

  private _salvaStepComitati() {
    if (this.selezionaComitatiPolicy.isComitatiSelezionatiCompilati()) {
      this.selezionaComitatiPolicy.salvaStepComitati().subscribe();
      this.stepper.next();
    }
  }

  private _salvaPerimetriDestinatari() {
    if (this.selezionaPerimetroDestinatari.isPerimetriDestinataoriSelezionatiCompilati()) {
      this.selezionaPerimetroDestinatari.salvaPerimetriDestinatari().subscribe({
        next: () => {
          this.utilityService.opneSnackBar('Salvataggio effettuato', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
        }
      });
      this.stepper.next();
    }
  }

  /* ********************************************************************** */

  salvataggioSelezioni(stepperIndex: number): Observable<boolean> {
    if (this._policy?.stato === 'PUBBLICATO') {
      return of(true);
    }

    switch (stepperIndex) {
      case 2: //Impegni
        this.spinnerOver.show();
        return this.componenteSelImpegniPolicy.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 3: //Azioni e buone pratiche
        this.spinnerOver.show();
        return this.componenteSelAzioneBPPolicy.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 4: //Certificazioni
        this.spinnerOver.show();
        return this.componenteSelCertificazioniPolicy.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      default:
        return of();
    }
  }

  /**
  * Lista di tutte le assegnazioni delle figure
  * @param stepperIndex index dello stepper in qui sono
  */
  assegnazioni(stepperIndex: number) {
    this.salvataggioSelezioni(stepperIndex)
      .pipe(
        switchMap(() => {
          const dialogAssegnazioniFigAzz = this.dialog.open(DialogAssegnazioniComponent, {
            data: {
              idContesto: this.formPolicySostenibilita.get('id')?.value,
              stato: this.policy?.stato,
              contesto: 'policy'
            },
            panelClass: 'dialog-container',
            disableClose: false,
            width: '100%',
            maxHeight: '95%',
            autoFocus: false,
          });

          return dialogAssegnazioniFigAzz.afterClosed();
        })
      )
      .subscribe(() => {
        if (this._policy?.stato === 'PUBBLICATO') {
          return;
        }

        switch (stepperIndex) {
          case 2: //Impegni
            this._getImpegniPolicy();
            break;
          case 3: //Azioni e buone pratiche
            this._getAzioniBPPolicy();
            break;
          case 4: //Certificazioni
            this._getCertificazioniPolicy();
            break;
          default:
            break;
        }

      });
  }

  async creaComitato() {

    await firstValueFrom(this.selezionaComitatiPolicy.salvaStepComitati())

    const dialogCreaComitato = this.dialog.open(DialogCreaComitatiComponent, {
      data: {
        idAzienda: this.formPolicySostenibilita.get('idAzienda')?.value
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '75%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaComitato.afterClosed().subscribe((result) => {
      if (result) {
        this.selezionaComitatiPolicy.getComitatiConFigureAziendali();
      }
    });
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.modificaFigura(event.data);
        break;
      case 'figure':
        this.eleCompilazioneFigura(event.data.elementi, event.data.nome, event.data.figura, event.data.id);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Modifica figura su cruscotto
   * @param dati
   */
  modificaFigura(dati: any) {

    const dialogAssociaFiguraAziendale = this.dialog.open(DialogAssociaFiguraAziendaleComponent, {
      data: {
        figura: { id: dati.id }
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogAssociaFiguraAziendale.afterClosed().subscribe((idFiguraNuova) => {

      const idFiguraPrecedente = dati?.id;

      const idPolicySostenibilita = this.formPolicySostenibilita.get('id')?.value;

      if (idPolicySostenibilita && idFiguraPrecedente && idFiguraNuova) {

        this.policyService.putCambiaFigura(
          idPolicySostenibilita,
          idFiguraPrecedente,
          idFiguraNuova
        ).subscribe({
          next: () => {
            this.tabella.caricaDati();
          },
          error: (err) => {
            this.tabella.caricaDati();
            console.error(err);
          }
        });
      }

    });
  }

  private async _pubblicazionePolicy(desc?: string): Promise<Observable<boolean> | undefined> {
    const idPolicySostenibilita = this.formPolicySostenibilita.get('id')?.value;
    let titolo: string = 'Pubblicazione Policy di Sostenibilità';
    let descrizione: string = `
        <div>
          <p>
            <strong>Si prega di notare che questa operazione pubblicherà la Policy di Sostenibilità.</strong>
          </p>
          <ul>
            <li>La pubblicazione è definitiva e <strong>NON</strong> potrà essere annullata.</li>
            <li>Inoltre non sarà più possibile <strong>aggiungere</strong> o <strong>modificare</strong> le informazioni precedentemente configurate.</li>
          </ul>
          <p><strong>Vuoi procedere ${desc ? desc : ' '}?</strong></p>
        </div>
      `;


    const dialog = await this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      fontWeight: '500',
      bottoni: [
        { nome_btn: 'No', id_btn: 'N' },
        { nome_btn: 'Si', id_btn: 'S' }
      ]
    });

    const valDialog = await firstValueFrom(dialog.beforeClosed());
    if (valDialog === 'N') {
      return of(false);
    }
    this.spinnerOver.show();
    await firstValueFrom(this.policyService.pubblicaPolicy(idPolicySostenibilita!));
    return of(true);
  }

  /**
   * Get cruscotto filtrato per figura
   * @param idFigura
   * @param idPolicySostenibilita
   * @returns
   */
  getFiguraCruscotto(idFigura: string, idPolicySostenibilita: string) {
    return new Promise((resolve, reject) => {

      let filtroFiguraSelezionata: Filters = {
        values: [idFigura],
        operator: 'eq',
        chiave: 'id'
      };

      this.policyService.getCruscotti(idPolicySostenibilita ?? '', 0, 1000, '', [filtroFiguraSelezionata])
        .subscribe({
          next: data => resolve(data),
          error: err => reject(new Error(err))
        });
    });
  }

  /**
   *
   * @param elementiCruscotto
   * @param nome
   * @param figura
   */
  public async eleCompilazioneFigura(elementiCruscotto: ElementiCruscotto[], nome: string, figura: string, idFigura: string) {

    const idPolicySostenibilita = this.formPolicySostenibilita.get('id')?.value;

    if (idPolicySostenibilita) {

      if (this._policy.stato !== 'PUBBLICATO') {

        /* --- Modifica richiesta: Se ci sono elementi precompilati lo posso sapere solamente se pubblico
          il questionario, una volta pubblicato, il BE calcola le precompilazioni, mi rifaccio la get del cruscotto filtrato
          per idFigura selezionata e mi reperisco gli elementi aggiornati e precompilati. --- */

        const risultatoObservable = await this._pubblicazionePolicy('con la compilazione');
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
          try {
            // CHIAMO LA FIGURA FILTRTA E ASPETTO RISPOSTA
            const figura: any = await this.getFiguraCruscotto(idFigura!, idPolicySostenibilita!);
            elementiCruscotto = figura?.content[0]?.elementi;
          } catch (err) {
            console.error(err);
          }
        }
        this.spinnerOver.hide();
      }


      const dialogCompilazioneFigure = this.dialog.open(DialogCompilazioneFigureComponent, {
        data: {
          elementiCruscotto,
          idPolicySostenibilita,
          nome,
          figura
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        maxHeight: '95%',
        autoFocus: false,
      });


      dialogCompilazioneFigure.afterClosed().pipe(
        switchMap(() => {
          this.spinnerOver.show();
          return this._getQuestionarioPolicy(idPolicySostenibilita)
        })
      ).subscribe(() => {
        this.tabella.caricaDati();
        this._caricaTotaliCruscotto(idPolicySostenibilita);
      })
    }
  }

  async inviaTutti() {
    const idPolicySostenibilita = this.formPolicySostenibilita.get('id')?.value;

    if (idPolicySostenibilita) {

      const testiMail = await firstValueFrom(this.policyService.getTemplateMail(idPolicySostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }

      if (this._policy.stato !== 'PUBBLICATO') {
        const risultatoObservable = await this._pubblicazionePolicy('con l\'invio mail');
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
        }
        this.spinnerOver.hide();
      }
      try {
        this.spinnerOver.show();
        await firstValueFrom(this.policyService.inviaMailMultiple(idPolicySostenibilita));
        await firstValueFrom(this._getQuestionarioPolicy(idPolicySostenibilita));
        this.tabella.caricaDati();
        this.utilityService.opneSnackBar('Mail inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {
        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio mail',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }
    }
  }

  async notificaTutti() {

    const idPolicySostenibilita = this.formPolicySostenibilita.get('id')?.value;

    if (idPolicySostenibilita) {

      const testiMail = await firstValueFrom(this.policyService.getTemplateMail(idPolicySostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }


      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica a tutte le figure che hanno ricevuto la mail',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }

      try {
        this.spinnerOver.show();
        await firstValueFrom(this.policyService.inviaNotificaMultiple(idPolicySostenibilita));
        await firstValueFrom(this._getQuestionarioPolicy(idPolicySostenibilita))

        this.tabella.caricaDati();

        this.utilityService.opneSnackBar('Notifica inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {
        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio notifiche',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }

    }

  }

  template() {
    const idPolicySostenibilita = this.formPolicySostenibilita.get('id')?.value

    if (idPolicySostenibilita) {

      const dialogCreaTemplateMail = this.dialog.open(DialogCreaTemplateMailComponent, {
        data: {
          idPolicySostenibilita: idPolicySostenibilita,
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '95%',
        autoFocus: false,
      });

      dialogCreaTemplateMail.afterClosed().subscribe(() => {
      });

    }
  }


  /* ------------------------- STAMPA -------------------------*/

  public stampa() {

    if (!this.cfgStampaComponent.cfgStampa) {
      console.error('NESSUNA CFG STAMPA DA SALVARE');
      return;
    }

    this.spinnerOver.show()
    const idPolicy = this.formPolicySostenibilita.get('id')?.value;

    if (idPolicy && this.cfgStampaComponent.cfgStampa) {

      this.fnSalvaCfgStampa(this.cfgStampaComponent.cfgStampa).pipe(
        switchMap((result: any) => {
          if (result) {
            return this.policyService.postStampaPolicy(idPolicy);
          } else {
            return of()
          }
        })
      ).subscribe(
        {
          next: (urlStampa) => {
            window.open(urlStampa?.body, '_blank');

            this.spinnerOver.hide();
          },
          error: (err) => {
            console.error(err);

            this.spinnerOver.hide();

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Stampa non riuscita. Verificare le configurazioni e riprovare.',
              bottoni: [{ nome_btn: 'Ok' }]
            });
          }
        }
      )
    }
  }


  /**
   * Lancia il salvataggio della configurazione, passando dal componente.
   */
  public salvaCfgStampa() {

    if (!this.cfgStampaComponent.cfgStampa) {
      console.error('NESSUNA CFG STAMPA DA STAMPARE');
      return;
    }

    this.spinnerOver.show();

    this.fnSalvaCfgStampa(this.cfgStampaComponent.cfgStampa).subscribe((esito) => {

      if (!esito) {
        // Salvataggio fallito
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Salvataggio configurazione non riuscito. Verificare le informazioni e riprovare.',
          bottoni: [{ nome_btn: 'Ok' }]
        });
      }

      this.spinnerOver.hide();

    })
  }

  /**
   * 
   * @returns Funzione di get cfg stampa da impostare sul componente cfgStampaComponent
   */
  public fnGetConfigStampa = () => {
    const idPolicy = this.formPolicySostenibilita.get('id')?.value;
    if (idPolicy) {
      return this.policyService.getConfigStampa(idPolicy);
    } else {
      return throwError(() => "ID Policy non valorizzato");
    }
  }

  /**
   * Funzione di salvataggio cfg stampa da impostare sul componente cfgStampaComponent
   * @param grpCfgStampa 
   * @returns 
   */
  public fnSalvaCfgStampa = (grpCfgStampa: GruppoCfgStampa) => {
    const idPolicy = this.formPolicySostenibilita.get('id')?.value;

    if (idPolicy) {
      return this.policyService.postSalvaElementiStampa(idPolicy, grpCfgStampa)
    } else {
      return throwError(() => "ID Policy non valorizzato");
    }
  }

  /**
   * Metodo che mi fa l'importazione del file immagine 
   * @param elemStampa 
   */
  public fnImportaImmagine = (file: File, chiaveCfg: string) => {

    const listaId: any = {
      idAzienda: this.formPolicySostenibilita.get('idAzienda')?.value,
      idPolicySostenibilita: this.formPolicySostenibilita.get('id')?.value,
      chiaveCfg
    }

    return this.importazioniService.importImgStampaPolicy(file, {}, listaId);

  }

  /**
   * Metodo per eliminazione immagine in cfg stampa 
   * Viene rimosso da google cloud storage 
   */
  public fnEliminaImg = (chiaveCfg: string) => {
    const listaId: any = {
      idAzienda: this.formPolicySostenibilita.get('idAzienda')?.value,
      idPolicySostenibilita: this.formPolicySostenibilita.get('id')?.value,
      chiaveCfg
    }

    return this.importazioniService.deleteImgStampaPolicy(listaId);
  }

  public toggleLoading(mostra: boolean) {
    if (mostra) {
      this.spinnerOver.show();
    } else {
      this.spinnerOver.hide();
    }
  }

  ctrAnnoPolicyUtilizzata(anno: string) {
    return this.isAnnoSettoreValid(anno, this.formPolicySostenibilita.get('settori')?.value?.id);
  }

  ctrSettorePolicyUtilizzata(settore: Settore) {
    const anno = this.formPolicySostenibilita.get('anno')?.value;
    return this.isAnnoSettoreValid(anno, settore?.id);
  }

  private isAnnoSettoreValid(anno: string | undefined, settoreId: string | undefined) {
    return anno && settoreId && this.mappaAnnoSettore[anno]?.[settoreId] ? true : false;
  }
}

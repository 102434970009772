import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { Comitati, ComitatiService } from 'src/app/services/comitati/comitati.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-figure-aziende',
  templateUrl: './dialog-crea-figure-aziende.component.html',
  styleUrls: ['./dialog-crea-figure-aziende.component.scss']
})
export class DialogCreaFigureAziendeComponent implements OnInit, AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public arrayComitati: Comitati[] = [];

  /* Form Controls */
  public formFigAz = new FormGroup({

    /* Id */
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* idAzienda */
    idAzienda: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* nome */
    nome: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* email */
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email, UtilityService.emailValidator],
    }),
    /* figura */
    figura: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    comitati: new FormControl<Comitati[]>([], {
      nonNullable: true,
    })

  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaFigureAziendeComponent>,
    private utilityService: UtilityService,
    private aziendaService: AziendaService,
    public dialog: MatDialog,
    private comitatiService: ComitatiService,

    @Inject(MAT_DIALOG_DATA) public data: {
      figAzienda: any,
      comitati: Comitati,
    }
  ) { }

  ngOnInit(): void {
    this.aziendaService.azienda.then((azienda) => {
      this.formFigAz.get('idAzienda')?.setValue(azienda?.id);
    });

    if (this.data?.figAzienda) {
      Object.keys(this.data.figAzienda).forEach((value, index, array) => {
        this.formFigAz.get(value)?.setValue((this.data.figAzienda as any)[value]);
      })
    }

    if (this.data?.comitati) {

      this.formFigAz.get('comitati')?.setValue([this.data.comitati]);

      this.formFigAz.get('comitati')?.disable();
    }

    if (this.data?.figAzienda?.figura.toLowerCase() === 'referente') {
      this.formFigAz.get('figura')?.disable();
    }

  }

  /* Inserito qui e non nel ngOnInit() per far funzionare lo spinner */
  ngAfterViewInit(): void {
    this._getComitati();
  }

  compareById(elemento1: any, elemento2: any): boolean {
    return elemento1?.id === elemento2?.id;
  }

  /**
   * Metodo che mi salva la figura aziendale
   * @returns 
   */
  public salva() {


    const valueForm = (this.formFigAz.getRawValue() as any);

    if (this.formFigAz.valid) {
      this.spinnerOver.show();

      const id = this.formFigAz.get('id')?.value;

      if (id) {
        return this.aziendaService.putFigureAz(this.formFigAz.get('idAzienda')?.value || '', id, valueForm).subscribe(
          {
            next: (result: any) => {

              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.spinnerOver.hide();
              this.dialogRef.close(true);
            },
            error: (err: any) => {
              this.spinnerOver.hide();
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {
        return this.aziendaService.postFigureAz(this.formFigAz.get('idAzienda')?.value || '', valueForm).subscribe(
          {
            next: (result: any) => {

              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.spinnerOver.hide();
              this.dialogRef.close(true);
            },
            error: (err: any) => {
              this.spinnerOver.hide();
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formFigAz.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  /**
   * Metodo per reperire la lista dei comitati
   */
  private _getComitati(): void {
    this.spinnerOver.show();
    this.comitatiService.getComitatiLibreriaAndAzienda(0, 1000).subscribe({
      next: (result) => {
         
        this.arrayComitati = result.content;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }
}

import { Injectable } from '@angular/core';
import { CertificazioniWebService } from './certificazioni-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Settore } from '../settori/settori.service';

export interface Certificazione {
  id: string;
  nomeBreve: string;
  descrizione: string;
  settori: Settore[];
  creatoDaContesto?: boolean;
  risposta?: string;
  tipo?: string;
  urlFile?: string;
  preCompilazioneInfos: string;
}

@Injectable({
  providedIn: 'root'
})
export class CertificazioniService {

  constructor(private certificazioniService: CertificazioniWebService) { }

  /**
    * Funzione per selezionare le righe da far vedere nella grid
    * @param page pagina
    * @param pageSize record per pagina
    * @param ricerca risultato per ricerca 
    * @param filters valori da filtrare 
    * @param sortBy sort asc desc
    * @returns lista di righe
    */
  public getCert(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.certificazioniService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del Settore
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postAmbiti per la creazione Settore
     */
  public postCert(data: any) {
    return this.certificazioniService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteCert(idRiga: any) {
    return this.certificazioniService.delete(idRiga);
  }

  public putCert(id: string, cert: any) {
    return this.certificazioniService.put(id, cert);
  }

}

import { Injectable } from '@angular/core';
import { MaterialitaWebService } from './materialita-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Ambito, AmbitoSurvey } from '../ambiti/ambiti.service';
import { Settore, SottoSettore } from '../settori/settori.service';
import { Impatto } from '../impatto/impatto.service';

export interface Survey {
  id: string;
  dataCompilazione?: string,
  versione: number;
  chiave: string;
  dataPubblicazione?: string;
  idUtenteCompilazione: string;
  punteggioOttenuto?: number,
  punteggioMaxOttenibile?: number,
  punteggioPercentuale?: number,
  stato: string;
  codiceLingua: string;
  titolo: string;
  descrizione: string;
  note: string;
  ambiti: Ambito[]; // AmbitoSurvey
  tipoPunteggio: string,
  landingPage?: string,
  settori?: Settore[];
  aziende?: string[];
  materialita?: string[];
  tipo?: string;
  scadenzaSurvey?: string[];
  tempoDiCompilazione?: number;
}

export interface Esclusioni {
  codice: string;
  descrizione: string;
}

export interface Soglie {
  sogliaInterni: number;
  sogliaEsterni: number;
}
export interface datiGrafico {
  titoloImpatti: string
  valoriInterni: number,
  valoriEsterni: number,
  sizePoint: number,
  colorePoint: string
}
@Injectable({
  providedIn: 'root'
})
export class MaterialitaService {

  constructor(private materialitaWebService: MaterialitaWebService) { }

  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getMaterialita(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.materialitaWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
   * ritorna materialità corrispondete all'id
   * @param idMaterialita 
   * @returns 
   */
  public getMaterialitaById(idMaterialita: string) {
    return this.materialitaWebService.getMaterialitaById(idMaterialita);
  }

  /**
   *  ritorna materialità pubblicate
   * @returns 
   */
  public getMaterialitaPubblicate(idSettore: string, idSottoSettori: string[]) {
    return this.materialitaWebService.getMaterialitaPubblicate(idSettore, idSottoSettori);
  }

  /**
    * Funzine ws per la creazione della Materialità
    * @param data dati da inserire nel MongoDB
    * @returns chiama la funzione post Materialita per la creazione Materialità
    */
  public postMaterialita(data: any) {
    return this.materialitaWebService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param id id della riga da eliminare
    * @returns 
    */
  public deleteMaterialita(id: any) {
    return this.materialitaWebService.delete(id);
  }

  /**
   * Materialita
   * @param id 
   * @param materialita 
   * @returns 
   */
  public putMaterialita(id: string, materialita: any) {
    return this.materialitaWebService.put(id, materialita);
  }

  public putMateStake(id: string, tipologia: 'ESTERNI' | 'INTERNI', stakeholder: any) {
    return this.materialitaWebService.putMateStake(id, tipologia, stakeholder);
  }
  /**
   * Aggiorna template mail della materialità
   * @param idMaterialita 
   * @returns 
   */
  public putTemplateMail(idMaterialita: string, templateMail: any) {
    return this.materialitaWebService.putTemplateMail(idMaterialita, templateMail);
  }

  public getTemplateMail(idMaterialita: string) {
    return this.materialitaWebService.getTemplateMail(idMaterialita);
  }

  public getAnteprimaMaterialitaCompilata(idMaterialita: string, idAzienda: string, idSurvey: string) {
    return this.materialitaWebService.getAnteprimaMaterialitaCompilata(idMaterialita, idAzienda, idSurvey);
  }

  /******************************************************** SURVEY **************/
  public getSurvey(idMaterialita: string) {
    return this.materialitaWebService.getSurvey(idMaterialita);
  }

  public postSurvey(idMaterialita: string, ambiti: Ambito[], settori: Settore[], sottoSettori: SottoSettore[], titolo: string) {
    return this.materialitaWebService.postSurvey(idMaterialita, ambiti, settori, sottoSettori, titolo);
  }

  public putSurvey(idMaterialita: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], sottoSettori: SottoSettore[], titolo: string, tipoPunteggio: string, scadenzaSurvey: string | Date, stato: string) {
    return this.materialitaWebService.putSurvey(idMaterialita, idSurvey, ambiti, settori, sottoSettori, titolo, tipoPunteggio, scadenzaSurvey, stato);
  }

  public getTotaliCruscotto(idMaterialita: string) {
    return this.materialitaWebService.getTotaliCruscotto(idMaterialita);
  }

  public getCruscotti(
    idMaterialita: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.materialitaWebService.getCruscotti(idMaterialita, page, pageSize, ricerca, filters, sortBy);
  }

  /**
  * Metodo che invia una mail singolarmente per stakeholder (pre prendere il token e fare la compilazione esterna della survey)
  * @param idMaterialita 
  * @param idStakeholder 
  */
  public postInvioMailSingolo(idMaterialita: string, idStakeholder: string) {
    return this.materialitaWebService.postInvioMailSingolo(idMaterialita, idStakeholder)
  }

  /**
  * Metodo che invia mail a tutti gli stakeholder che la devono ricevere (pre prendere il token e fare la compilazione esterna della survey)
  * @param idMaterialita 
  */
  public postInvioMailMultiplo(idMaterialita: string) {
    return this.materialitaWebService.postInvioMailMultiplo(idMaterialita)
  }

  /**
   * Metodo che invia una notifica singolarmente per stakeholder 
   * @param idMaterialita 
   * @param idStakeholder 
   */
  public putInviaNotificaSingolo(idMaterialita: string, idStakeholder: string) {
    return this.materialitaWebService.putInviaNotificaSingolo(idMaterialita, idStakeholder);
  }

  /**
   * Metodo che invia mail a tutti gli stakeholder che la devono ricevere (pre prendere il token e fare la compilazione esterna della survey)
   * @param idMaterialita 
   */
  public putInviaNotificaMultiplo(idMaterialita: string) {
    return this.materialitaWebService.putInviaNotificaMultiplo(idMaterialita)
  }

  public salvaTipoPunteggio(idMaterialita: string, tipoPunteggio: string) {
    return this.materialitaWebService.putTipoPunteggio(idMaterialita, tipoPunteggio);
  }

  public salvaScadenzaSurvey(idMaterialita: string, scadenzaSurvey: Date) {
    return this.materialitaWebService.putScadenzaSurvey(idMaterialita, scadenzaSurvey);
  }



  /********************************************************* IMPATTI **************/

  public getImpattiCheck(idMaterialita: string) {
    return this.materialitaWebService.getImpattiCheck(idMaterialita);
  }

  public putImpattiEffimeri(idMaterialita: string, idAmbito: string, idTematica: string, impatti: Impatto) {
    return this.materialitaWebService.putImpattiEffimeri(idMaterialita, idAmbito, idTematica, impatti);
  }
  public putImpattiCheck(idMaterialita: string, impattiCheck: any) {
    return this.materialitaWebService.putImpattiCheck(idMaterialita, impattiCheck);
  }


  /************************************************** ANALISI DI MATERIALITA ******************/

  public getEsclusioni() {
    return this.materialitaWebService.getEsclusioni();
  }

  public putEsclusioni(idMaterialita: string, esclusioni: string[]) {
    return this.materialitaWebService.putEsclusioni(idMaterialita, esclusioni);
  }

  public getRisultatiSurvey(idMaterialita: string) {
    return this.materialitaWebService.getRisultatiSurvey(idMaterialita)
  }

  public putSoglie(idMaterialita: string, soglie: Soglie) {
    return this.materialitaWebService.putSoglie(idMaterialita, soglie);
  }

  public putRaggruppaImpatti(idMaterialita: string, idTematica: string, raggruppa: boolean) {
    return this.materialitaWebService.putRaggruppaImpatti(idMaterialita, idTematica, raggruppa);
  }
  
  public putCosolidaRisultato(idMaterialita: string, imgGraficoMaterialita: string, legendaGrafico: any) {
    return this.materialitaWebService.putCosolidaRisultato(idMaterialita, imgGraficoMaterialita, legendaGrafico);
  }

  public stampa(idMaterialita: string) {
    return this.materialitaWebService.stampa(idMaterialita);
  }

  public invioTestMail(messaggio: string, email: string) {
    return this.materialitaWebService.invioTestMail(messaggio, email);
  }

  public pubblicaMaterialita(idMaterialita: string) {
    return this.materialitaWebService.pubblicaMaterialita(idMaterialita);
  }

}

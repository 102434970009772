import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { Impatto, ImpattoService, ScritturaImpatto } from 'src/app/services/impatto/impatto.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-cfg-impatto',
  templateUrl: './dialog-crea-cfg-impatto.component.html',
  styleUrls: ['./dialog-crea-cfg-impatto.component.scss'],
})
export class DialogCreaCfgImpattoComponent implements OnInit {
  public guidaDescrizione: string | undefined = "Selezionare la tipologia di impatto (potenziale/effettivo, positivo/negativo) e seguire la linea guida per modificare la descrizione precompilata";
  public isIconaGuida: boolean = true;
  public arrayImpattoPotEff: ScritturaImpatto[] = [];
  public arrayImpattoPosNeg: ScritturaImpatto[] = [];

  public arraySettori: Settore[] = [];
  public selectedImpattoPotEff: string[] = [];

  public selectedImpattoPosNeg: string[] = [
  ];
  public selectedSettori: string[] = [];


  public formImpatti = new FormGroup({
    id: new FormControl<string>("", {
      nonNullable: true,
      validators: [],
    }),

    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    impattoPotenzialeEffettivo: new FormControl<string[] | undefined>([], {
      nonNullable: true
    }),
    impattoPositivoNegativo: new FormControl<string[] | undefined>([], {
      nonNullable: true
    }),
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    note: new FormControl<string>('', {
      nonNullable: true,
    }),
    fatturatoDa: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(999999999999)],
    }),
    fatturatoA: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(999999999999)],
    }),
    settori: new FormControl<Settore[]>([], {
      nonNullable: true,
      validators: [],
    }),
    valoreAttribuito: new FormControl<number>(0, {
      nonNullable: true,
      validators: [],
    }),
    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    consigliato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    tag: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
  })

  /*   private _validaSettori(): ValidatorFn {
      return (control: AbstractControl) => {
        if (this.data?.isFatturato && !control.value?.length) {
          return { required: true };
        }
  
        return null;
      }
    } */

  constructor(
    private materialitaService: MaterialitaService,
    private cdr: ChangeDetectorRef,
    private settoriService: SettoriService,
    private impattoService: ImpattoService,
    private utilityService: UtilityService,
    private bilancioService: BilancioService,
    public dialogRef: MatDialogRef<DialogCreaCfgImpattoComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
      impatto: Impatto,
      isFatturato: boolean,
      idContest: string,
      idAmbito?: string,
      idTematica?: string,
      contesto: string
    }
  ) {

  }

  ngOnInit(): void {
    this.datiIniziali();
  }

  @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();

  /**
     * Funzione di salvataggio di un nuovo impatto
     * @returns 
     */
  public salva() {


    if (this.formImpatti.valid) {

      const id = this.formImpatti.get('id')?.value;

      // Se sono in materialità
      if (this.data.idContest) {
        return this.salvaInContesto();
      }

      if (id) {

        return this.impattoService.putImpatto(id, this.formImpatti.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Aggiornamento effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close(result);
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio dell\'impatto:', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err?.error?.message || 'Errore nel salvataggio dell\'impatto',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {

        return this.impattoService.postImpatto(this.formImpatti.getRawValue()).subscribe(
          {
            next: (result: any) => {

              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });

              this.dialogRef.close(result);
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio dell\'impatto:', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err?.error?.message || 'Errore nel salvataggio dell\'impatto',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formImpatti.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-"
    if (event.key === '-') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  private _getSettori() {

    this.settoriService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'desc'
    }]).subscribe({
      next: (result: any) => {
    

        this.arraySettori = result?.content || [];
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento dei settori:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei settori',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }


  onSelectionChange() {
    // Costruisco il codice da passare
    let codiceComposto: string = "";

    for (let elem of this.selectedImpattoPotEff) {
      codiceComposto += elem;
    }

    for (let elem of this.selectedImpattoPosNeg) {
      codiceComposto += elem;
    }

    //Eseguo la chiamata al WS solamente se entrambe le selezioni sono state fatte
    if (this.selectedImpattoPosNeg.length > 0 && this.selectedImpattoPotEff.length > 0) {
      //viusalizzo l'icona quando i campi sono valorizzati 
      /*   this.isIconaGuida = true; */

      this.getDescrizioneImpatto(codiceComposto);

    } else {
      /*   this.isIconaGuida = false; */
      this.guidaDescrizione = "Selezionare la tipologia di impatto (potenziale/effettivo, positivo/negativo) e seguire la linea guida per creare la descrizione";
    }

  }

  public getDescrizioneImpatto(codiceComposto: string) {
    this.impattoService.getScritturaImpattoByCodice(codiceComposto).subscribe({
      next: (result: ScritturaImpatto) => {
        this.guidaDescrizione = result.descrizione;
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  public datiIniziali() {

    this.impattoService.getImpattoPosNeg().subscribe((impattoPosNeg) => {
      this.arrayImpattoPosNeg = impattoPosNeg;
    });

    this.impattoService.getImpattoPotEff().subscribe((impattoPotEff) => {
      this.arrayImpattoPotEff = impattoPotEff;
    });

    this._getSettori();

    if (this.data?.isFatturato) {
      this.formImpatti.get('settori')?.setValidators([Validators.required]);
    }

    if (this.data.impatto) {
      Object.keys(this.data.impatto).forEach((value, index, array) => {
        this.formImpatti.get(value)?.setValue((this.data.impatto as any)[value]);
      })
    }
    this.selectedImpattoPotEff = this.formImpatti.get('impattoPotenzialeEffettivo')?.value || [];
    this.selectedImpattoPosNeg = this.formImpatti.get('impattoPositivoNegativo')?.value || [];
    this.onSelectionChange();

    if (this.data.contesto) {
      //TODO mettere obbligatorio le 2 combo e togliere le Note
      this.formImpatti.get('impattoPositivoNegativo')?.addValidators([Validators.required]);
      this.formImpatti.get('impattoPotenzialeEffettivo')?.addValidators([Validators.required]);
    } else {
      this.formImpatti.get('impattoPositivoNegativo')?.setValue(undefined);
      this.formImpatti.get('impattoPotenzialeEffettivo')?.setValue(undefined);
      // this.formImpatti.get('note')?.addValidators([Validators.required]);
    }
  }

  compareSettori(settore1: Settore, settore2: Settore): boolean {
    return settore1.id === settore2.id; // Sostituisci "id" con la proprietà univoca degli ambiti
  }

  /**
   * Metodo che mi salva un nuovo impatto sulla materialità
   * @returns 
   */
  public salvaInContesto() {
    if (!this.data.idAmbito) {
      this.mostraMessaggio('Attenzione', 'Ambito non trovato o non riconosciuto');
      return;
    } else if (!this.data.idTematica) {
      this.mostraMessaggio('Attenzione', 'Tematica non trovata o non riconosciuta');
      return;
    }

    if (!this.formImpatti.get('id')?.value) {
      this.formImpatti.get('creatoDaContesto')?.setValue(true);
    }

    let serviceCall;

    if (this.data.contesto == 'materialita') {
      serviceCall = this.materialitaService.putImpattiEffimeri(
        this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formImpatti.getRawValue());
    } else if (this.data.contesto == 'bilancio') {
      serviceCall = this.bilancioService.putImpattiEffimeri(
        this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formImpatti.getRawValue());
    }


    if (serviceCall) {
      serviceCall.subscribe({
        next: (result: any) => {
          this.mostraMessaggioSnackbar('Salvataggio effettuato');
          this.dialogRef.close(result);
        },
        error: (err: any) => {
          console.error('Errore durante il salvataggio dell\'impatto:', err);
          this.mostraMessaggio('Attenzione', err?.error?.message || 'Errore nel salvataggio dell\'impatto');
        }
      });
    }

    /*   return this.materialitaService.putImpattiEffimeri(
        this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formImpatti.getRawValue()
      ).subscribe(
        {
          next: (result: any) => {
            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });
            this.dialogRef.close(result);
          },
          error: (err: any) => {
            console.error('Errore durante il salvataggio dell\'impatto:', err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore nel salvataggio dell\'impatto',
              bottoni: [{ nome_btn: 'Chiudi' }]
            });
          }
  
        }
      ); */
  }
  private mostraMessaggio(titolo: string, descrizione: string) {
    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      bottoni: [{ nome_btn: 'Chiudi' }]
    });
  }

  private mostraMessaggioSnackbar(messaggio: string) {
    this.utilityService.opneSnackBar(messaggio, '', {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }
}
